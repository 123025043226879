import { type FC } from 'react'

import { useResourceContext } from 'ra-core'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import images from 'assets/images'
import {
    type CardListConfig,
    type DatagridColumnsProps,
    ListTotalBadge,
    ViewHeader,
    type ListBulkActions,
    datagridAvatarColumn,
    ListController,
    ListUi,
    type ListControllerProps,
} from 'components'
import {
    ResourceContextProviderWithClearEffect,
    type ResourceType,
    type ActionChildren,
    deleteOneAction,
    multiselectAction,
    deleteManyFromListAction,
    downloadFile,
} from 'core'
import { formatDate } from 'lib'
import { PageContent, IconBox, Anchor } from 'ui'
import { displayFileSize } from 'utils'

import { type FileDocument } from '../types'

import DocumentAvatar, { getDocumentAvatarProps } from './DocumentAvatar'
import DocumentEditor from './DocumentEditor'
import DocumentUploader from './DocumentUploader'

export interface DocumentsContentProps {
    readOnly?: boolean
    resource: ResourceType
}

const DocumentsContent: FC<DocumentsContentProps> = ({ readOnly: readOnlyProp, resource }) => {
    if (!resource) {
        return null
    }
    const readOnly = readOnlyProp || undefined

    const columns = getColumns(readOnly)
    const cards = getCards(readOnly)

    return (
        <ResourceContextProviderWithClearEffect value={resource}>
            <PageContent>
                <ListController sorts={sorts}>
                    <ViewHeader title="Documents">
                        <ListTotalBadge />
                        <ViewHeader.Content placement="rightMobile">
                            {readOnly ? null : (
                                <DocumentUploader>
                                    {(open) => (
                                        <IconBox
                                            onClick={open}
                                            title="Upload Documents"
                                        >
                                            <Icons.Add />
                                        </IconBox>
                                    )}
                                </DocumentUploader>
                            )}
                        </ViewHeader.Content>
                    </ViewHeader>
                    <ListUi
                        columnsCfg={columns}
                        cardsCfg={cards}
                        bulkActions={bulkActions}
                        listFTUProps={{
                            title: 'No Documents',
                            linkText: readOnly ? (
                                ''
                            ) : (
                                <DocumentUploader>
                                    {(open) => <Anchor onClick={open}>Upload Document</Anchor>}
                                </DocumentUploader>
                            ),
                            imageSrc: images.documentUpload,
                            linkAction: () => {
                                //
                            },
                            action: null,
                        }}
                        disableExportButton
                    />
                </ListController>
            </PageContent>
        </ResourceContextProviderWithClearEffect>
    )
}

export default DocumentsContent

const getColumns = (readOnly: boolean) => {
    const config: DatagridColumnsProps<FileDocument & { avatar: unknown; format: unknown }> = {
        checkboxSelection: !readOnly,
        mainField: 'name',
        avatarSource: 'avatar',
        columns: [
            datagridAvatarColumn({
                field: 'avatar',
                headerName: 'Avatar',
                avatar: (record) => (
                    <DocumentEditor
                        id={record.id}
                        readOnly={readOnly}
                    >
                        {(open) => (
                            <DocumentAvatar
                                file={record.file}
                                onClick={open}
                            />
                        )}
                    </DocumentEditor>
                ),
            }),
            {
                field: 'name',
                headerName: 'File Name',
                renderCell: ({ row }) => (
                    <DocumentEditor
                        id={row.id}
                        readOnly={readOnly}
                    >
                        {(open) => <Anchor onClick={open}>{row.name}</Anchor>}
                    </DocumentEditor>
                ),
            },
            {
                field: 'description',
                headerName: 'Description',
            },
            {
                field: 'created',
                headerName: 'Added on',
                valueFormatter: ({ value }) =>
                    formatDate(value, (format) => format.shortenedDateTime),
            },
            {
                field: 'size',
                headerName: 'Size',
                valueFormatter: ({ value }) => displayFileSize(value),
            },
            {
                field: 'extension',
                headerName: 'Format',
                valueFormatter: ({ value }) => '.' + value,
            },
        ],
        actions: ({ row }, { children }) => [
            <EditAction
                id={row.id}
                readOnly={readOnly}
                children={children}
                key="edit"
            />,
            <DownloadAction
                children={children}
                data={row}
                key="download"
            />,
            deleteOneAction({
                children,
                id: row.id,
                confirmConfig: {
                    title: 'Are you sure you want to delete this document?',
                },
                disabled: readOnly,
            }),
        ],
    }
    return config
}

const sorts: ListControllerProps<FileDocument>['sorts'] = [
    { id: 'name', label: 'Name' },
    { id: 'description', label: 'Description' },
    { id: 'created', label: 'Added on' },
    { id: 'size', label: 'Size' },
    { id: 'extension', label: 'Format' },
]

const getCards = (readOnly: boolean) => {
    const config: CardListConfig<FileDocument> = {
        avatarProps: (record) => getDocumentAvatarProps(record.file),
        titleSource: (record) => (
            <DocumentEditor
                readOnly={readOnly}
                id={record.id}
            >
                {(open) => (
                    <Anchor
                        fontWeight="bold"
                        onClick={open}
                    >
                        {record.name}
                    </Anchor>
                )}
            </DocumentEditor>
        ),
        disableTitleLink: true,
        details: [
            {
                source: 'description',
                label: 'Description',
            },
            {
                source: 'created',
                label: 'Added on',
                render: (value) => formatDate(value, (format) => format.shortenedDateTime),
            },
            {
                source: 'size',
                label: 'Size',
                render: displayFileSize,
            },
            {
                source: 'extension',
                label: 'Format',
                render: (value) => '.' + value,
            },
        ],
        actions: (data, { children }) => [
            readOnly ? null : multiselectAction({ children, id: data.id }),
            <EditAction
                id={data.id}
                readOnly={readOnly}
                children={children}
                key="edit"
            />,
            <DownloadAction
                children={children}
                data={data}
                key="download"
            />,
            deleteOneAction({
                children,
                id: data.id,
                disabled: readOnly,
                confirmConfig: {
                    title: 'Are you sure you want to delete this document?',
                },
            }),
        ],
    }

    return config
}

const DownloadAction = ({ children, data }: { children: ActionChildren; data: FileDocument }) => {
    const resource = useResourceContext()

    return children({
        Icon: Icons.Download,
        title: 'Download',
        onClick: () => {
            downloadFile({
                filename: data.name + '.' + data.extension,
                href: resource + '/' + data.id + '/download',
                type: data.extension,
            })
        },
        key: 'download',
    })
}

const EditAction = ({
    id,
    readOnly,
    children,
}: {
    id: Identifier
    readOnly: boolean
    children: ActionChildren
}) => {
    return (
        <DocumentEditor
            id={id}
            key="edit"
            readOnly={readOnly}
        >
            {(open) =>
                children({
                    onClick: open,
                    Icon: Icons.Edit,
                    title: 'Edit',
                })
            }
        </DocumentEditor>
    )
}

const bulkActions: ListBulkActions<FileDocument> = ({ children }) => {
    return [
        deleteManyFromListAction({
            children,
            confirmConfig: {
                title: 'Are you sure you want to delete the selected documents?',
            },
        }),
    ]
}
