import { type ReactElement } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { type ThemeColorType } from 'lib'
import { IconElement } from 'ui'

export interface StatusConfig<Status extends string> {
    text: string | ReactElement
    icon: ReactElement
    id: Status
    iconComponent: SvgIconElement
    color?: ThemeColorType
}

export const defaultStatuses = {
    open: {
        text: 'Open',
        icon: (
            <IconElement
                component={Icons.StatusOpen}
                color={(theme) => theme.palette.primary.main}
            />
        ),
        iconComponent: Icons.StatusOpen,
        color: (theme) => theme.palette.primary.main,
    },
    pending: {
        text: 'Pending',
        icon: (
            <IconElement
                component={Icons.Circle}
                color={(theme) => theme.palette.warning.main}
            />
        ),
        iconComponent: Icons.Circle,
        color: (theme) => theme.palette.warning.main,
    },
    inProgress: {
        text: 'In Progress',
        icon: (
            <IconElement
                component={Icons.StatusDraft}
                color={(theme) => theme.palette.primary.main}
            />
        ),
        iconComponent: Icons.StatusDraft,
        color: (theme) => theme.palette.primary.main,
    },
    completed: {
        text: 'Completed',
        icon: (
            <IconElement
                component={Icons.Circle}
                color={(theme) => theme.palette.warning.main}
            />
        ),
        iconComponent: Icons.Circle,
        color: (theme) => theme.palette.success.main,
    },
    closed: {
        text: 'Closed',
        icon: (
            <IconElement
                component={Icons.CheckCircle}
                color={(theme) => theme.palette.success.main}
            />
        ),
        iconComponent: Icons.CheckCircle,
        color: (theme) => theme.palette.text.secondary,
    },
    canceled: {
        text: 'Canceled',
        icon: (
            <IconElement
                component={Icons.Cancel}
                color={(theme) => theme.palette.error.main}
            />
        ),
        iconComponent: Icons.Cancel,
        color: (theme) => theme.palette.error.main,
    },
    scheduled: {
        text: 'Scheduled',
        icon: (
            <IconElement
                component={Icons.AccessTimeOutlined}
                color={(theme) => theme.palette.warning.main}
            />
        ),
        iconComponent: Icons.AccessTimeOutlined,
    },
} satisfies { [key: string]: Omit<StatusConfig<any>, 'id'> }
