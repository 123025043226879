import { type FC } from 'react'

import { type GetListResult } from 'ra-core'

import Icons from 'assets/icons'
import { useDialogSelectorContext } from 'components/inputs/DialogSelector'
import { type ActionChildren } from 'core/actions'
import api, { type ApiClientConfig } from 'core/api'
import { useFinalErrorHandler } from 'core/errors'
import { useQueryClient } from 'lib'

import { type CK33Model } from '../../types'
import { ck33Resource } from '../../utils'

import { type DisplayedFiltersInfo, type FilterValues } from './types'
import { favoritesSource } from './utils'

export interface ActionsProps {
    children: ActionChildren
    choice: CK33Model
    levels: number[]
    canBeSelected: (level: number) => boolean
}

export const Actions: FC<ActionsProps> = ({ children, choice, levels, canBeSelected }) => {
    const { onSelect, control } = useDialogSelectorContext<CK33Model>()
    const { filterValues, setFilters } = control
    const displayedFilters = control.displayedFilters as DisplayedFiltersInfo

    const isSelectable = canBeSelected(choice.level)

    return (
        <>
            {isSelectable
                ? children({
                      title: 'Select',
                      Icon: Icons.CheckOutlined,
                      onClick: () => onSelect(choice.id),
                  })
                : null}
            {!filterValues[favoritesSource] && levels.some((level) => level > choice.level)
                ? children({
                      title: 'Expand',
                      Icon: Icons.ExpandOutlined,
                      onClick: () => {
                          const _displayedFilters: DisplayedFiltersInfo = {
                              ...displayedFilters,
                              parents: displayedFilters?.parents
                                  ? [...displayedFilters.parents, choice]
                                  : [choice],
                          }

                          const _filterValues: FilterValues = {
                              parent: String(choice.id),
                          }

                          setFilters(_filterValues, _displayedFilters, false)
                      },
                  })
                : null}
            {isSelectable ? (
                <FavoriteAction
                    choice={choice}
                    children={children}
                />
            ) : null}
        </>
    )
}

const FavoriteAction: FC<{ choice: CK33Model; children: ActionChildren }> = ({
    choice,
    children,
}) => {
    const { control, queryMeta } = useDialogSelectorContext<CK33Model>()
    const errorHandler = useFinalErrorHandler()
    const queryClient = useQueryClient()

    const { filterValues, perPage, sort, refetch, total, allChoices } = control

    const handleFav = async () => {
        const favorite = !choice.favorite
        const clientConfig: ApiClientConfig = { preventCacheReset: true }

        try {
            await api.post(
                `${ck33Resource.resource}/${choice.id}/favorite`,
                {
                    favorite,
                },
                {
                    params: { clientConfig },
                },
            )

            // handle remove with refetch if favorites filter is on
            if (
                filterValues[favoritesSource] &&
                !favorite &&
                allChoices.length < total &&
                allChoices.length <= perPage / 2
            ) {
                refetch()
                return
            }

            queryClient.setQueryData(
                [
                    ck33Resource.resource,
                    'getList',
                    {
                        filter: filterValues,
                        pagination: { page: 1, perPage },
                        sort,
                        meta: queryMeta,
                    },
                ],
                (oldData: GetListResult<CK33Model>) => {
                    let data = oldData.data
                    let total = oldData.total
                    if (!favorite && filterValues[favoritesSource]) {
                        data = data.filter((item) => item.id !== choice.id)
                        total -= 1
                    } else {
                        data = data.map(
                            (item): CK33Model =>
                                item.id === choice.id ? { ...item, favorite } : item,
                        )
                    }

                    return {
                        ...oldData,
                        data,
                        total,
                    }
                },
            )
        } catch (e) {
            errorHandler(e)
        }
    }

    if (choice.favorite) {
        return children({
            title: 'Remove from Favorites',
            Icon: Icons.StarOutline,
            onClick: handleFav,
        })
    }

    return children({ title: 'Add To Favorites', Icon: Icons.Star, onClick: handleFav })
}
