import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { Scrollbar } from 'ui'

export const ScrollbarBottom: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Scrollbar
            replaceP="xb"
            alwaysVisible
            shadow="t"
            scrollbarMinSize={50}
        >
            {children}
        </Scrollbar>
    )
}
