import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type ObjectAny, type WithChildrenNode } from 'appTypes'
import Icons from 'assets/icons'
import {
    type DatagridColumnsProps,
    ListAvatar,
    CardCollapsibleContent,
    useDistanceLabel,
    formatMoney,
    costMaskParams,
    datagridAvatarColumn,
    FilterDateRangeValue,
    FilterRangeInput,
    ListController,
    type ListControllerProps,
    ListUi,
} from 'components'
import { useArchivedContext } from 'context'
import {
    deleteManyFromListAction,
    deleteOneAction,
    multiselectAction,
    editRedirectInListAction,
    archiveManyFromListAction,
    archiveOneAction,
    type AuthStore,
    authStore,
    type SortPayload,
    useRecordContext,
} from 'core'
import { alpha, formatDate, globalClassNames } from 'lib'
import { costCenterFilter, CostCenterRow, costCenterSort } from 'resources/costCenters'
import { divisionFilter, DivisionRow, divisionSort } from 'resources/divisions'
import { NotesCollapsibleContent, NotesCollapsibleIconHolder } from 'resources/notes'
import {
    tagFields,
    TagsCollapsibleContent,
    TagsCollapsibleIconHolder,
    TagsField,
} from 'resources/tags'
import { useResources } from 'resources/utils'
import { ck34Fields, ck2Fields, ck47Fields } from 'resources/vmrs'
import { Box, IconElement, TextWithIcon, Tooltip, Typography } from 'ui'
import { uppercaseFormat } from 'utils'

import { archiveUnitActionParams, deleteUnitAction } from '../actions'
import unitFields from '../fields'
import { type AllMeterTypes, type MeterModel, meterValue as meterValueBase } from '../meters'
import { type UnitSettings, type UnitModel } from '../types'
import unitResource from '../unitResource'

import { TitleWithTelematicsStatus } from './TitleWithTelematicsStatus'
import UnitDrawerToggler from './UnitDrawerToggler'

import type { CardListConfig, ListBulkActions } from 'components'

const bodyTypeSource = 'bodyVmrsType' as keyof UnitModel

interface UnitColumns extends UnitModel {
    lastOdometerDate: string
    lastEngineHoursDate: string
    lastHubometerDate: string
}

interface UnitsListUiProps {
    disableExportButton?: boolean
}

const meterValue = (meter: MeterModel, settings: UnitSettings, meterType: AllMeterTypes) => {
    if (!meter) {
        return null
    }

    const value = meterValueBase(meter.value, meterType)

    if (value == null) {
        return null
    }

    if (settings[meterType]?.tracked) {
        return value
    }

    return (
        <TextWithIcon
            endIcon={
                <Tooltip title="Not Tracked">
                    <Box
                        height="20px"
                        width="20px"
                        bgcolor={(theme) => alpha(theme.palette.grey[900], 0.1)}
                        borderRadius="4px"
                    >
                        <IconElement
                            component={Icons.SyncDisabled}
                            color={(theme) => theme.palette.action.disabled}
                        />
                    </Box>
                </Tooltip>
            }
        >
            {value}
        </TextWithIcon>
    )
}

export const UnitsListUi: FC<UnitsListUiProps> = inject('auth')(
    observer(({ auth, disableExportButton }: UnitsListUiProps & { auth: AuthStore }) => {
        const { isArchived } = useArchivedContext()
        const hasCustomers = auth.companySettings.hasCustomers
        const distanceLabel = useDistanceLabel({ textCase: 'capital' })
        const record = useRecordContext()
        const { unitStatus } = useResources()

        const columns: DatagridColumnsProps<UnitColumns>['columns'] = [
            datagridAvatarColumn({
                field: 'photo',
                avatar: (unit) => (
                    <ListAvatar
                        linkProps={{
                            'aria-label': `View Unit with unit number ${unit.number}`,
                        }}
                        id={unit.id}
                        imageSrc={unit.photo}
                        color={unitFields.avatar.unitAvatarColor(isArchived)}
                        defaultImage={<unitFields.avatar.DefaultIcon isArchived={isArchived} />}
                    />
                ),
            }),
            {
                field: 'number',
                headerName: 'Unit Number',
                renderCell: ({ row }) => {
                    return <TitleWithTelematicsStatus record={row} />
                },
            },
            unitFields.domicile.tableColumn({
                auth,
                dataToValue: (row: UnitModel) => row.domicileData?.name,
            }),
            { field: 'name', headerName: 'Name' },
            isArchived && {
                field: 'archived',
                headerName: 'Archived On',
                renderCell: (cell) => {
                    return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
                },
            },
            {
                field: 'licensePlate',
                headerName: 'License Plate',
                valueFormatter: ({ value }) => uppercaseFormat(value),
            },
            { field: 'vin', headerName: 'VIN' },
            { field: 'serialNumber', headerName: 'Serial Number' },
            {
                field: 'vmrsManufacturer',
                headerName: 'Manufacturer/Make',
                renderCell: ({ row }) => ck34Fields.self.value(row.vmrsManufacturerData),
            },
            {
                field: 'vmrsEquipmentCategory',
                headerName: 'Equipment Category',
                renderCell: ({ row }) => ck2Fields.self.value(row.vmrsEquipmentCategoryData),
            },
            {
                field: bodyTypeSource,
                headerName: 'Body Type',
                renderCell: ({ row }) => ck47Fields.self.value(row.body?.vmrsTypeData),
            },
            { field: 'model', headerName: 'Model' },
            { field: 'modelYear', headerName: 'Model Year' },
            { field: 'color', headerName: 'Color' },
            { field: 'tireSize', headerName: 'Tire Size' },
            {
                field: 'engineVmrsManufacturer',
                headerName: 'Engine Make',
                renderCell: ({ row }) => ck34Fields.self.value(row.engineVmrsManufacturerData),
            },
            { field: 'engineModel', headerName: 'Engine Model' },
            { field: 'engineHp', headerName: 'Engine HP' },
            {
                field: 'transmissionVmrsManufacturer',
                headerName: 'Transmission Make',
                renderCell: ({ row }) =>
                    ck34Fields.self.value(row.transmissionVmrsManufacturerData),
            },
            { field: 'transmissionModel', headerName: 'Transmission Model' },
            { field: 'transmissionGears', headerName: 'Transmission Gears' },
            unitStatus.tableColumn({
                render: (data: UnitModel) =>
                    unitStatus.value(data.statusData, isArchived ? null : data.id),
            }),
            {
                field: 'unresolvedDefects',
                headerName: 'Unresolved Defects',
                valueGetter: ({ value }) => value,
            },
            { field: 'pmIntervalsCount', headerName: 'PM Intervals' },
            { field: 'openIssuesCount', headerName: 'Open Issues' },
            {
                field: 'lastOdometer',
                headerName: 'Odometer (Last Known)',
                renderCell: ({ row }) => {
                    return meterValue(row.lastMeters.ODOMETER, row.settings, 'ODOMETER')
                },
            },
            {
                field: 'lastOdometerDate',
                headerName: 'Odometer Date',
                renderCell: ({ row }) => {
                    return formatDate(
                        row.lastMeters.ODOMETER?.timestamp,
                        (dateFormats) => dateFormats.shortenedDateTime,
                    )
                },
            },
            {
                field: 'lastEngineHours',
                headerName: 'Engine Hours (Last Known)',
                renderCell: ({ row }) => {
                    return meterValue(row.lastMeters.ENGINE_HOURS, row.settings, 'ENGINE_HOURS')
                },
            },
            {
                field: 'lastEngineHoursDate',
                headerName: 'Engine Hours Date',
                renderCell: ({ row }) => {
                    return formatDate(
                        row.lastMeters.ENGINE_HOURS?.timestamp,
                        (dateFormats) => dateFormats.shortenedDateTime,
                    )
                },
            },
            {
                field: 'lastHubometer',
                headerName: 'Hubometer (Last Known)',
                renderCell: ({ row }) => {
                    return meterValue(row.lastMeters.HUBOMETER, row.settings, 'HUBOMETER')
                },
            },
            {
                field: 'lastHubometerDate',
                headerName: 'Hubometer Date',
                renderCell: ({ row }) => {
                    return formatDate(
                        row.lastMeters.HUBOMETER?.timestamp,
                        (dateFormats) => dateFormats.shortenedDateTime,
                    )
                },
            },
            {
                field: 'lastApuHours',
                headerName: 'APU Hours (Last Known)',
                renderCell: ({ row }) => {
                    return meterValue(row.lastMeters.APU_HOURS, row.settings, 'APU_HOURS')
                },
            },
            {
                field: 'total',
                headerName: 'Maintenance Cost',
                align: 'right',
                renderCell: ({ value }) => formatMoney(value),
            },
            authStore.companySettings.hasCostCenters
                ? {
                      field: 'costCenter',
                      headerName: 'Cost Center',
                      renderCell: ({ row, value }) =>
                          value ? (
                              <Box className={globalClassNames.ellipsis}>
                                  <CostCenterRow record={row.costCenterData} />
                              </Box>
                          ) : null,
                  }
                : null,
            authStore.companySettings.hasDivisions
                ? {
                      field: 'division',
                      headerName: 'Division',
                      renderCell: ({ row, value }) =>
                          value ? (
                              <Box className={globalClassNames.ellipsis}>
                                  <DivisionRow record={row.divisionData} />
                              </Box>
                          ) : null,
                  }
                : null,
            {
                field: 'costPerDistance',
                headerName: `Cost per ${distanceLabel}`,
                align: 'right',

                renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
            },
            {
                field: 'costPerHour',
                headerName: 'Cost per Engine Hour',
                align: 'right',

                renderCell: ({ value }) => (value || value === 0 ? formatMoney(value) : null),
            },
            {
                field: 'created',
                headerName: 'Created on',
                renderCell: (cell) => {
                    return formatDate(cell.value, (dateFormats) => dateFormats.shortenedDateTime)
                },
            },
            hasCustomers && {
                field: 'customer',
                headerName: 'Customer',
                renderCell: (cell) => {
                    return cell.row.customerData?.name
                },
            },
            authStore.companySettings.hasAssignments
                ? unitFields.ongoingCustomerAssignment.tableColumn()
                : null,
            {
                field: 'tagsData',
                headerName: 'Tags',
                renderCell: ({ value }) => <TagsField tags={value} />,
            },
        ]

        const columnsConfig: DatagridColumnsProps<UnitColumns> = {
            resetColumns: {
                vmrsEquipmentCategory: false,
                color: false,
                costPerDistance: false,
                costPerHour: false,
                pmIntervalsCount: false,
                vmrsManufacturer: false,
                tireSize: false,
                engineVmrsManufacturer: false,
                engineModel: false,
                engineHp: false,
                lastOdometerDate: false,
                lastEngineHoursDate: false,
                lastEngineHours: false,
                lastHubometerDate: false,
                lastHubometer: false,
                transmissionVmrsManufacturer: false,
                transmissionModel: false,
                transmissionGears: false,
                unresolvedDefects: false,
                tagsData: false,
                serialNumber: false,
                [bodyTypeSource]: false,
                costCenter: false,
                division: false,
                [unitFields.ongoingCustomerAssignment.source]: false,
                lastApuHours: false,
            },
            mainField: 'number',
            avatarSource: 'photo',
            columns,
            actions: ({ row: unit }, { children }) => [
                editRedirectInListAction({
                    children,
                    id: unit.id,
                }),
                archiveOneAction({
                    ...archiveUnitActionParams(
                        isArchived,
                        Boolean(unit.telematicsData),
                        unit.hasActiveWorkOrders,
                        Boolean(unit.ongoingCustomerAssignment),
                    ),

                    children,
                    id: unit.id,
                }),
                isArchived
                    ? deleteOneAction({
                          children,
                          id: unit.id,
                          ...deleteUnitAction(unit.hasRelatedWorkOrders),
                      })
                    : null,
            ],
        }

        const details: CardListConfig<UnitModel>['details'] = [
            unitFields.domicile.dataCardRow({
                auth,
                dataToValue: (row: UnitModel) => row.domicileData?.name,
            }),
            { source: 'name', label: 'unit name' },
            {
                source: 'licensePlate',
                label: 'license plate',
                render: (value) => uppercaseFormat(value),
            },
            { source: 'vin', label: 'vin' },
            { source: 'serialNumber', label: 'serial number' },
            { source: 'model', label: 'model' },
            { source: 'modelYear', label: 'model year' },
            { source: 'color', label: 'color' },
            hasCustomers && {
                source: 'customer',
                label: 'Customer',
                render: (v, record) => record.customerData?.name,
            },
        ]

        const cards: CardListConfig<UnitModel> = {
            titleSource: (record) => <TitleWithTelematicsStatus record={record} />,
            disableTitleLink: true,
            subTitleSource: (record) =>
                unitStatus.value(record.statusData, isArchived ? null : record.id),
            avatarColor: unitFields.avatar.unitAvatarColor(isArchived),
            avatarOpacity: isArchived ? 0.12 : undefined,
            imageSource: 'photo',
            defaultImage: <unitFields.avatar.DefaultIcon isArchived={isArchived} />,
            collapsibleContent: (record) => (
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <NotesCollapsibleIconHolder
                                    notes={record.notes}
                                    key="notesIcon"
                                />
                            ),
                            component: (
                                <NotesCollapsibleContent
                                    notes={record.notes}
                                    key="notesContent"
                                />
                            ),
                        },
                        {
                            iconHolder: (
                                <TagsCollapsibleIconHolder
                                    tags={record.tagsData}
                                    key="tagsIcon"
                                />
                            ),
                            component: (
                                <TagsCollapsibleContent
                                    tags={record.tagsData}
                                    key="tagsContent"
                                />
                            ),
                        },
                    ]}
                />
            ),
            details: details.filter(Boolean),
            actions: (
                {
                    id,
                    telematicsData,
                    hasRelatedWorkOrders,
                    hasActiveWorkOrders,
                    ongoingCustomerAssignment,
                },
                { children },
            ) => [
                editRedirectInListAction({
                    children,
                    id,
                }),
                // exportAction({
                //     children,
                // }),
                multiselectAction({
                    children,
                    id,
                }),
                archiveOneAction({
                    ...archiveUnitActionParams(
                        isArchived,
                        Boolean(telematicsData),
                        hasActiveWorkOrders,
                        Boolean(ongoingCustomerAssignment),
                    ),
                    children,
                    id,
                }),
                isArchived
                    ? deleteOneAction({
                          children,
                          id,
                          ...deleteUnitAction(hasRelatedWorkOrders),
                      })
                    : null,
            ],
        }

        const bulkActions: ListBulkActions = ({ children, listContext }) => {
            return [
                isArchived ? null : unitStatus.unitStatusBulkAction({ children, listContext }),
                isArchived
                    ? deleteManyFromListAction({
                          children,
                          ...deleteUnitAction(
                              listContext.selectedIds.some(
                                  (selectedId) =>
                                      listContext.data.find(({ id }) => selectedId === id)
                                          ?.hasRelatedWorkOrders,
                              ),
                          ),
                      })
                    : null,
                archiveManyFromListAction({
                    ...archiveUnitActionParams(
                        isArchived,
                        Boolean(
                            listContext.data.find(
                                (unit) =>
                                    unit.telematicsData &&
                                    listContext.selectedIds.includes(unit.id),
                            ),
                        ),
                        listContext.selectedIds.some(
                            (selectedId) =>
                                listContext.data.find(({ id }) => selectedId === id)
                                    ?.hasActiveWorkOrders,
                        ),
                        listContext.selectedIds.some(
                            (selectedId) =>
                                listContext.data.find(({ id }) => selectedId === id)
                                    ?.ongoingCustomerAssignment,
                        ),
                    ),
                    children,
                }),
            ]
        }

        return (
            <ListUi
                disableExportButton={disableExportButton}
                bulkActions={bulkActions}
                columnsCfg={columnsConfig}
                cardsCfg={cards}
                listFTUProps={{
                    linkText: isArchived ? (
                        ''
                    ) : (
                        <UnitDrawerToggler defaultValues={{ customer: record?.id }}>
                            {({ onClick }) => (
                                <Typography
                                    variant="body1"
                                    onClick={onClick}
                                    color={(theme) => theme.palette.primary.main}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    Add New Unit{' '}
                                </Typography>
                            )}
                        </UnitDrawerToggler>
                    ),
                    linkAction: (e) => {
                        e.preventDefault()
                    },
                    secondaryTitle: isArchived ? '' : 'Would you like to add one?',
                }}
            />
        )
    }),
)

interface UnitsListControllerProps extends WithChildrenNode {
    removeFilters?: boolean
    isLoading?: boolean
    filter?: ObjectAny
    preferencesName?: string
    clearResource?: boolean
}

export const UnitsListController: FC<UnitsListControllerProps> = inject('auth')(
    observer(
        ({
            removeFilters,
            children,
            isLoading,
            filter,
            preferencesName,
            auth,
            clearResource,
        }: UnitsListControllerProps & { auth: AuthStore }) => {
            const { isArchived } = useArchivedContext()
            const hasCustomers = auth.companySettings.hasCustomers
            const distanceLabel = useDistanceLabel({ textCase: 'capital' })
            const { unitStatus } = useResources()

            const filters: ListControllerProps<UnitModel>['filters'] = removeFilters
                ? null
                : [
                      {
                          id: 'created',
                          label: 'Created on',
                          renderComponent: (props) => <FilterDateRangeValue {...props} />,
                      },
                      { id: 'number', label: 'Unit Number' },
                      unitFields.domicile.filter({
                          auth,
                      }),
                      { id: 'name', label: 'Name' },
                      { id: 'vin', label: 'VIN' },
                      { id: 'serialNumber', label: 'Serial Number' },
                      { id: 'licensePlate', label: 'License Plate' },
                      unitStatus.filter({ label: unitStatus.longLabel }),
                      !isArchived && { id: 'unresolvedDefects', label: 'Unresolved Defects' },
                      {
                          id: 'pmIntervalsCount',
                          label: 'PM Intervals',
                      },
                      {
                          id: 'openIssuesCount',
                          label: 'Open Issues',
                      },
                      { id: 'vmrsEquipmentCategory', label: 'Equipment Category' },
                      { id: bodyTypeSource, label: 'Body Type' },
                      { id: 'vmrsManufacturer', label: 'Manufacturer/Make' },
                      { id: 'model', label: 'Model' },
                      { id: 'modelYear', label: 'Model Year' },
                      { id: 'engineVmrsManufacturer', label: 'Engine Make' },
                      { id: 'engineModel', label: 'Engine Model' },
                      { id: 'engineHp', label: 'Engine HP' },
                      { id: 'transmissionVmrsManufacturer', label: 'Transmission Make' },
                      { id: 'transmissionModel', label: 'Transmission Model' },
                      { id: 'transmissionGears', label: 'Transmission Gears' },
                      { id: 'color', label: 'Color' },
                      { id: 'tireSize', label: 'Tire Size' },
                      {
                          id: 'total',
                          label: 'Maintenance Cost',
                          renderComponent: (props) => (
                              <FilterRangeInput
                                  inputProps={costMaskParams}
                                  {...props}
                              />
                          ),
                      },
                      auth.companySettings.hasCostCenters && costCenterFilter(),
                      auth.companySettings.hasDivisions && divisionFilter(),
                      {
                          id: 'costPerDistance',
                          label: `Cost per ${distanceLabel}`,
                          renderComponent: (props) => (
                              <FilterRangeInput
                                  inputProps={costMaskParams}
                                  {...props}
                              />
                          ),
                      },
                      {
                          id: 'costPerHour',
                          label: 'Cost per Engine Hour',
                          renderComponent: (props) => (
                              <FilterRangeInput
                                  inputProps={costMaskParams}
                                  {...props}
                              />
                          ),
                      },
                      auth.companySettings.hasAssignments &&
                          unitFields.ongoingCustomerAssignment.filter(),
                      tagFields.self.filter(),
                      hasCustomers && { id: 'customer', label: 'Customer' },
                  ]

            const sorts: ListControllerProps<UnitModel>['sorts'] = [
                isArchived && {
                    id: 'archived',
                    label: 'Archived on',
                },
                { id: 'created', label: 'Created on' },
                { id: 'number', label: 'Unit Number' },
                unitFields.domicile.sort({
                    auth,
                }),
                { id: 'name', label: 'Name' },
                { id: 'licensePlate', label: 'License Plate' },
                { id: 'vin', label: 'VIN' },
                { id: 'serialNumber', label: 'Serial Number' },
                { id: 'vmrsEquipmentCategory', label: 'Equipment Category' },
                { id: bodyTypeSource, label: 'Body Type' },
                { id: 'model', label: 'Model' },
                { id: 'modelYear', label: 'Model Year' },
                { id: 'color', label: 'Color' },
                unitStatus.sort({ label: unitStatus.longLabel }),
                !isArchived && { id: 'unresolvedDefects', label: 'Unresolved Defects' },
                { id: 'pmIntervalsCount', label: 'PM Intervals' },
                { id: 'openIssuesCount', label: 'Open Issues' },
                { id: 'total', label: 'Maintenance Cost' },
                auth.companySettings.hasCostCenters && costCenterSort(),
                auth.companySettings.hasDivisions && divisionSort(),
                { id: 'costPerDistance', label: `Cost per ${distanceLabel}` },
                { id: 'costPerHour', label: 'Cost per Engine Hour' },
                {
                    id: 'lastApuHours',
                    label: 'APU Hours (Last Known)',
                },
                auth.companySettings.hasCustomers && { id: 'customer', label: 'Customer' },
            ]

            const defaultSort = isArchived ? defaultArchivedUnitSort : defaultUnitSort

            return (
                <ListController
                    filters={filters}
                    sorts={sorts}
                    isLoading={isLoading}
                    sort={defaultSort}
                    resource={unitResource.resource}
                    filter={filter}
                    preferencesName={preferencesName || getPreferencesName(isArchived)}
                    clearResource={clearResource}
                >
                    {children}
                </ListController>
            )
        },
    ),
)

const defaultUnitSort: SortPayload<UnitModel> = {
    field: 'created',
    order: 'DESC',
}

const defaultArchivedUnitSort: SortPayload<UnitModel> = {
    field: 'archived',
    order: 'DESC',
}

const getPreferencesName = (isArchived: boolean): string => `units${isArchived ? '-archived' : ''}`
