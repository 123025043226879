import { inject, observer } from 'mobx-react'
import { ListBase, useShowContext } from 'ra-core'

import Icons from 'assets/icons'
import { type AuthStore, ActionsMenu, useCanAccess } from 'core'
import { CustomersSection } from 'resources/customers'
import {
    useIsVenderWo,
    WoJobDrawerToggler,
    woOperations,
    type WorkOrderModel,
} from 'resources/workOrders'
import { FlexReverse, PageContent } from 'ui'

import { WorkOrderJobsResource, WorkOrderJobsSlider } from '../Jobs'
import { WorkOrderTopSection } from '../components'

import {
    WorkOrderDetails,
    InvoiceSection,
    VendorSection,
    WorkOrderPurchaseInvoice,
} from './components'

const WorkOrderOverview = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const { record: workOrder, isLoading } = useShowContext<WorkOrderModel>()
        const isVendorWorkOrder = useIsVenderWo()
        const isStatusCancel = workOrder?.status === 'CANCELED'
        const getIsAllowed = useCanAccess()
        const { value } = getIsAllowed(woOperations.jobs)

        return (
            <div>
                <WorkOrderTopSection />
                <PageContent>
                    {isStatusCancel ? (
                        <WorkOrderDetails />
                    ) : (
                        <FlexReverse>
                            <WorkOrderDetails />
                            {auth.companySettings.hasCustomers && (
                                <CustomersSection
                                    customer={workOrder?.salesOrderData?.customerData}
                                    isLoading={isLoading}
                                />
                            )}
                            {auth.companySettings.hasInvoicing && <InvoiceSection />}
                            <VendorSection />
                            <WorkOrderJobsResource>
                                <ListBase perPage={3}>
                                    <WorkOrderJobsSlider
                                        action={
                                            <ActionsMenu
                                                record={workOrder}
                                                actions={(params, { children }) => [
                                                    <WoJobDrawerToggler
                                                        key="add-job"
                                                        children={(open) =>
                                                            children({
                                                                Icon: Icons.Add,
                                                                title: 'Add Job',
                                                                onClick: open,
                                                                disabled: !value,
                                                            })
                                                        }
                                                    />,
                                                    children({
                                                        Icon: Icons.GridView,
                                                        title: 'See All',
                                                        key: 'see-all',
                                                        to: 'jobs',
                                                    }),
                                                ]}
                                            />
                                        }
                                    />
                                </ListBase>
                            </WorkOrderJobsResource>
                            {isVendorWorkOrder ? <WorkOrderPurchaseInvoice /> : null}
                        </FlexReverse>
                    )}
                </PageContent>
            </div>
        )
    }),
)

export default WorkOrderOverview
