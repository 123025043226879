import { type FC, type ReactElement } from 'react'

import { FormProvider, useForm } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { type FormProps } from 'components/form'
import { useRenderAway } from 'core/portal'

type RenderInput = (params: {
    onClose: (id: Identifier | null, source: string) => void
    defaultOpen: boolean
    renderToggler: any
}) => ReactElement

export const useInputStep = () => {
    const renderAway = useRenderAway()

    return async (input: RenderInput): Promise<Pick<FormProps, 'set'>> => {
        return new Promise((res) => {
            const close = renderAway(() => (
                <InputForm
                    input={input}
                    onChange={(value, name) => {
                        if (!value) {
                            setTimeout(() => {
                                res(null)
                                close()
                            }, 2000)
                            return
                        }

                        res({
                            set: [
                                {
                                    name,
                                    value,
                                },
                            ],
                        })
                        close()
                    }}
                />
            ))
        })
    }
}

interface InputFormProps {
    onChange: (value: any, name: string) => void
    input: RenderInput
}

const InputForm: FC<InputFormProps> = ({ input, onChange }) => {
    const f = useForm()

    return (
        <FormProvider {...f}>
            {input({
                onClose: onChange,
                defaultOpen: true,
                renderToggler: () => null,
            })}
        </FormProvider>
    )
}
