import { isValidElement, type FC, type ReactElement } from 'react'

import { Button, buttonClasses } from '@mui/material'

import { type WithChildrenNode } from 'appTypes'
import Icons from 'assets/icons'
import { globalClassNames } from 'lib'
import { IconElement } from 'ui/images'

interface Props extends WithChildrenNode {
    isOpen?: boolean
    icon?: ReactElement | FC
    startIcon?: ReactElement | FC
    onClick?: (e) => void
}

const MenuButton: FC<Props> = ({
    children,
    isOpen,
    startIcon,
    icon = Icons.KeyboardArrowDownOutlined,
    onClick,
}) => {
    return (
        <Button
            size="small"
            variant="text"
            color="inherit"
            onClick={onClick}
            sx={{
                textTransform: 'none',
                py: 0,
                [`. ${buttonClasses.endIcon}`]: isOpen && {
                    transform: 'scaleY(-1)',
                },
            }}
            startIcon={
                startIcon &&
                (isValidElement(startIcon) ? (
                    startIcon
                ) : (
                    <IconElement component={startIcon as FC} />
                ))
            }
            endIcon={isValidElement(icon) ? icon : <IconElement component={icon as FC} />}
        >
            <span className={globalClassNames.ellipsis}>{children}</span>
        </Button>
    )
}

// const MenuButton: FC<Props> = ({
//     children,
//     isOpen,
//     startIcon,
//     icon = Icons.KeyboardArrowDownOutlined,
//     onClick,
// }) => {
//     return (
//         <Box
//             sx={{
//                 cursor: 'pointer',
//             }}
//             onClick={onClick}
//             role="button"
//         >
//             <TextWithIcon
//                 variant="body2"
//                 color={(theme) => theme.palette.text.primary}
//                 icon={
//                     startIcon &&
//                     (isValidElement(startIcon) ? (
//                         startIcon
//                     ) : (
//                         <IconElement component={startIcon as FC} />
//                     ))
//                 }
//                 endIcon={
//                     icon && (
//                         <StyledElement
//                             sx={{ transform: isOpen ? 'scaleY(-1)' : undefined, display: 'flex' }}
//                         >
//                             {isValidElement(icon) ? icon : <IconElement component={icon as FC} />}
//                         </StyledElement>
//                     )
//                 }
//             >
//                 {children}
//             </TextWithIcon>
//         </Box>
//     )
// }

export default MenuButton
