import { type ReactElement } from 'react'

import { useResourceContext } from 'ra-core'

import { type Identifier } from 'appTypes'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'

import { type PtFeeModel } from '../types'
import { ptFeeResourceName } from '../utils'

import PTFeeForm from './PTFeeForm'

export interface PTFeeDrawerTogglerProps {
    children: (open: () => void) => ReactElement
    id?: Identifier
}

const PTFeeDrawerToggler = ({ children, id }: PTFeeDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = useResourceContext()

    return children(() => {
        open({
            drawerArgs: {
                title: id ? 'Edit Fee' : 'Create Fee',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        serializer={ptFeeSerializer}
                    />
                ),
                renderContent: () => <PTFeeForm />,
            },
            extraArgs: {
                id,
                resource: {
                    resource,
                    name: ptFeeResourceName,
                },
            },
        })
    })
}

export default PTFeeDrawerToggler

const ptFeeSerializer: Serializer<PtFeeModel> = [
    'name',
    { name: 'price', parse: 'number' },
    { name: 'isTaxable', parse: 'boolean' },
    { name: 'onLabor', parse: 'boolean' },
    { name: 'onParts', parse: 'boolean' },
    { name: 'onServices', parse: 'boolean' },
    { name: 'markupPercent', parse: 'number' },
    { name: 'minValue', parse: 'number' },
    { name: 'maxValue', parse: 'number' },
]
