import { useListContext, type ListControllerResult } from 'ra-core'

import api from 'core/api'
import { useConfirm } from 'core/confirmation'
import { useFinalErrorHandler } from 'core/errors'
import { useNotify } from 'core/notifications'
import { useCreateResourcePath, type ResourceType, useResource } from 'core/resource'

import { type ArchiveParams } from './useArchive'

export interface ArchiveManyFromListParams extends Omit<ArchiveParams, 'path'> {
    listContext?: ListControllerResult
}

// TODO: use the logic from useArchive
const useArchiveManyFromList = () => {
    const notify = useNotify()
    const resourceFromContext = useResource()
    const listContextFromContext = useListContext()
    const createPath = useCreateResourcePath()
    const errorHandler = useFinalErrorHandler()
    const confirm = useConfirm()

    const archiveResource = (
        params:
            | ArchiveManyFromListParams
            | ((nestedParams: {
                  resource: ResourceType
                  listContext: ListControllerResult
              }) => ArchiveManyFromListParams),
    ) => {
        let finalParams =
            typeof params === 'function'
                ? params({
                      resource: resourceFromContext,
                      listContext: listContextFromContext,
                  })
                : params

        finalParams = {
            resource: resourceFromContext,
            listContext: listContextFromContext,
            ...finalParams,
        }

        const { notifyMessage, onSuccess, listContext, resource, confirmConfig, isArchived } =
            finalParams

        const action = isArchived ? 'unarchive' : 'archive'

        const finalListContext = listContext || listContextFromContext

        if (!finalListContext.selectedIds.length) {
            return
        }

        const archive = () => {
            api.post(
                createPath({ resource: resource.resource, type: 'list' }) +
                    '/' +
                    action +
                    '_bulk?id=' +
                    finalListContext.selectedIds.join(','),
            )
                .then((response) => {
                    onSuccess?.(response)
                    notify(
                        notifyMessage ??
                            (isArchived ? 'Successfully unarchived!' : 'Successfully archived!'),
                    )
                })
                .catch((err) => {
                    errorHandler(err)
                })
        }

        confirm({
            title: `Are you sure you want to ${isArchived ? 'unarchive' : 'archive'}?`,
            confirmType: isArchived ? 'UNARCHIVE' : 'ARCHIVE',
            ...confirmConfig,
            onConfirm: archive,
        })
    }

    return archiveResource
}

export default useArchiveManyFromList
