import { Form } from 'ra-core'

import {
    PeriodSelector as PeriodSelectorBase,
    type PeriodSelectorFormValues,
    type PeriodFilter,
    periodSelectorFormValues,
} from 'components'
import { periodSelectorCustomChoice } from 'components/inputs/PeriodSelector/utils'
import { useResourcePreferences, useListContext } from 'core'
import { useOptimizedRef } from 'hooks'
import { styled } from 'lib'
import { BoxContainer } from 'ui'

interface PeriodSelectorProps {
    className?: string
}

export const defaultReportPeriod = (): PeriodFilter => {
    const defaultValue = periodSelectorFormValues()
    return {
        periodStart: defaultValue.dateFrom,
        periodEnd: defaultValue.dateTo,
    }
}

const PeriodSelector = styled(({ className }: PeriodSelectorProps) => {
    const { displayedFilters, filterValues, setFilters } = useListContext()
    const preferences = useResourcePreferences()

    const defaultValues = useOptimizedRef<PeriodSelectorFormValues>(() => {
        const values: PeriodSelectorFormValues = {
            date: displayedFilters?.info?.period || preferences.value.period?.date,
            dateFrom: (filterValues as PeriodFilter)?.periodStart,
            dateTo: (filterValues as PeriodFilter)?.periodEnd,
        }

        return values
    })

    return (
        <Form
            defaultValues={defaultValues.current}
            className={className}
        >
            <BoxContainer height="100%">
                <PeriodSelectorBase
                    onChange={({ date, from, to }) => {
                        const newPeriod: PeriodFilter = {
                            periodStart: from,
                            periodEnd: to,
                        }

                        setFilters({ ...filterValues, ...newPeriod }, { info: { period: date } })
                        preferences.updateLocal(
                            'period',
                            date === periodSelectorCustomChoice
                                ? { date, start: from, end: to }
                                : { date },
                        )
                        preferences.syncLocal()
                    }}
                />
            </BoxContainer>
        </Form>
    )
})``

export default PeriodSelector
