import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import {
    ArrayControllerBox,
    ArrayControllerElements,
    SelectInput,
    EmailInput,
    useArrayControllerElementContext,
    CheckboxInput,
} from 'components'
import { type AuthStore, requiredValidation } from 'core'
import { memberFields, membersResource } from 'resources/members'
import { useGetRoles, type Role } from 'resources/roles'
import { ShopInput } from 'resources/shops'
import { formHelperTextClasses } from 'ui'

const MemberInviteFormContent = () => {
    const roles = useGetRoles()

    if (roles.isError || roles.isFetching || !roles.data?.length) {
        return null
    }

    return (
        <ArrayControllerElements<{ id: string }> itemKey={({ item }) => item.id}>
            <ArrayControllerBox title="New User">
                <MemberInviteFormElement roles={roles.data} />
            </ArrayControllerBox>
        </ArrayControllerElements>
    )
}

const MemberInviteFormElement: FC<{ roles: Role[] }> = inject('auth')(
    observer(({ roles, auth }: { roles: Role[] } & { auth: AuthStore }) => {
        const { index } = useArrayControllerElementContext()
        return (
            <>
                <EmailInput
                    source={`data.${index}.${memberFields.email.source}`}
                    required
                    label={memberFields.email.label}
                />

                <ShopInput
                    contextType={membersResource.name}
                    source={`data.${index}.shops`}
                    multiple
                    required
                    noSelectedShop
                />
                <SelectInput
                    helperText={false}
                    source={`data.${index}.${memberFields.role.source}`}
                    choices={roles}
                    validate={requiredValidation}
                    optionText="label"
                    label="Role"
                    sx={{
                        [`& .${formHelperTextClasses.root}`]: {
                            display: 'none',
                        },
                        pb: '16px',
                    }}
                    disableEmptyValue
                    clearable={false}
                    defaultValue={roles[0].id}
                />

                {auth.companySettings.hasDomiciles ? (
                    <CheckboxInput
                        source={`data.${index}.${memberFields.viewUnitsWithoutDomicile.source}`}
                        label={memberFields.viewUnitsWithoutDomicile.label}
                    />
                ) : null}
            </>
        )
    }),
)

export default MemberInviteFormContent
