import { Show, tabRoutes } from 'components'
import { useCreateResourcePath } from 'core'
import { Routes } from 'lib'
import { NotesTab } from 'resources/notes'
import { partsResource, vendorPartsResource } from 'resources/parts'

import { VendorPartOverview } from '../Overview'

import { VendorPartShowHeader } from './components'

const VendorPartShow = () => {
    const createPath = useCreateResourcePath()
    const { tabs, routes } = tabRoutes([
        {
            value: '',
            label: 'Overview',
            element: <VendorPartOverview />,
        },

        {
            value: 'notes',
            label: 'Notes',
            element: <NotesTab />,
        },
    ])

    return (
        <Show
            resource={partsResource.resource}
            actionConfig={() =>
                createPath({ type: 'list', resource: vendorPartsResource.resource })
            }
        >
            <>
                <VendorPartShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
            </>
        </Show>
    )
}

export default VendorPartShow
