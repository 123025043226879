import { type Dispatch, type FC, type SetStateAction, useRef, useState } from 'react'

import { Form } from 'react-admin'
import { useFormContext, type UseFormReturn } from 'react-hook-form'

import { dateTimeRangeFormats, DateRangeKeys, dateRanges, getDateRangeDates } from 'lib'
import {
    selectClasses,
    Popper,
    Button,
    Paper,
    ClickAwayListener,
    Stack,
    BoxContainer,
    StyledElement,
    menuItemClasses,
} from 'ui'

import DateInput from '../DateInput'
import SelectInput from '../SelectInput'

import { periodSelectorDefaultDate, periodSelectorCustomChoice } from './utils'

export interface ChangeProps {
    onChange?: (params: { date: string; from: string; to: string }) => void
}

const dateRangeOptions = dateRanges.filter((date) => date.id !== DateRangeKeys.TODAY)
const CustomPeriodFormContent = ({
    periodForm,
    setCurrMenu,
    onChange,
}: {
    setCurrMenu: Dispatch<SetStateAction<'select' | typeof periodSelectorCustomChoice>>
    periodForm: UseFormReturn
} & ChangeProps) => {
    const { getValues } = useFormContext()
    return (
        <Stack
            spacing="18px"
            padding="16px"
            sx={{
                '& .MuiFormHelperText-root': {
                    display: 'none',
                },
            }}
        >
            <DateInput
                returnDateFormat={dateTimeRangeFormats.from}
                source="customFrom"
                isRequired
                defaultValue={periodForm.getValues('dateFrom')}
                openTo="day"
                label="From"
            />

            <DateInput
                returnDateFormat={dateTimeRangeFormats.to}
                source="customTo"
                isRequired
                defaultValue={periodForm.getValues('dateTo')}
                openTo="day"
                label="To"
            />
            <BoxContainer
                justifyContent="space-between"
                mt="14px"
            >
                <Button
                    onClick={() => {
                        setCurrMenu('select')
                    }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        const fromValue = getValues('customFrom')
                        const toValue = getValues('customTo')

                        const shouldSwap = new Date(fromValue) > new Date(toValue)

                        const from = shouldSwap ? toValue : fromValue
                        const to = shouldSwap ? fromValue : toValue

                        if (!from || !to) {
                            return
                        }

                        periodForm.setValue('dateFrom', from)
                        periodForm.setValue('dateTo', to)
                        periodForm.setValue('date', periodSelectorCustomChoice)
                        setCurrMenu(null)
                        onChange?.({ date: periodSelectorCustomChoice, from, to })
                    }}
                >
                    Apply
                </Button>
            </BoxContainer>
        </Stack>
    )
}

const PeriodSelectorInput: FC<ChangeProps> = ({ onChange }) => {
    const form = useFormContext()
    const anchorEl = useRef<HTMLDivElement>(null)
    const [currMenu, setCurrMenu] = useState<'select' | typeof periodSelectorCustomChoice>(null)

    return (
        <>
            <Popper
                anchorEl={anchorEl.current}
                open={currMenu === periodSelectorCustomChoice}
                sx={{ zIndex: 1300 }}
            >
                <ClickAwayListener
                    onClickAway={() => {
                        setCurrMenu(null)
                    }}
                >
                    <Paper>
                        <Form>
                            <CustomPeriodFormContent
                                setCurrMenu={setCurrMenu}
                                periodForm={form}
                                onChange={onChange}
                            />
                        </Form>
                    </Paper>
                </ClickAwayListener>
            </Popper>
            <StyledElement ref={anchorEl}>
                <SelectInput
                    disableEmptyValue
                    helperText=""
                    source="date"
                    defaultValue={periodSelectorDefaultDate}
                    choices={[
                        ...dateRangeOptions,
                        {
                            id: periodSelectorCustomChoice,
                            name: 'Custom',
                        },
                    ]}
                    SelectProps={{
                        open: currMenu === 'select',
                        onOpen: () => setCurrMenu('select'),
                        onClose: (e) => {
                            const target = e.target as HTMLDivElement
                            setCurrMenu(
                                target.innerText === 'Custom' ? periodSelectorCustomChoice : null,
                            )
                        },
                        onChange: (event) => {
                            const value = event.target.value as string

                            if (value === periodSelectorCustomChoice) {
                                return
                            }

                            const [from, to] = getDateRangeDates(value as DateRangeKeys)

                            form.setValue('dateFrom', from)
                            form.setValue('dateTo', to)
                            form.setValue('date', value)
                            onChange?.({ date: value, from, to })
                        },

                        MenuProps: {
                            sx: ({ palette }) => ({
                                [`& .${menuItemClasses.root}[data-value=${periodSelectorCustomChoice}]`]:
                                    {
                                        borderTop: '1px solid ' + palette.text.disabled,
                                        color: palette.primary.main,
                                        py: '15px',
                                    },
                            }),
                        },
                    }}
                    optionText="name"
                    variant="standard"
                    label=""
                    FormHelperTextProps={{
                        sx: {
                            display: 'none',
                        },
                    }}
                    sx={{
                        width: 'unset',
                        mb: 0,
                    }}
                    InputProps={{
                        sx: ({ palette }) => ({
                            fontSize: '12px',
                            minWidth: '95px',
                            marginTop: 'unset !important',
                            backgroundColor: 'unset !important',
                            color: palette.text.primary + ' !important',
                            '&::before, &::after': {
                                content: 'unset',
                            },
                            [`& .${selectClasses.select}`]: {
                                padding: '5px 0px 5px 5px',
                                color: 'red',
                            },
                        }),
                    }}
                    clearable={false}
                />
            </StyledElement>
        </>
    )
}

export default PeriodSelectorInput
