import { type FC } from 'react'

import { Widget } from 'components'
import { urls } from 'configs'
import { emptyOptionValue } from 'core'
import { customerFields } from 'resources/customers'

import issueFields from '../fields'
import { IssuePriorityKeys, IssueStatusKeys, type IssuesByPriority } from '../types'

interface Props {
    data: IssuesByPriority
    link?: boolean
}

const pathBase = urls.issues + '?filter='
const IssuesByPriorityRows: FC<Props> = ({ data, link }) => {
    const counts = {
        [IssuePriorityKeys.CRITICAL]: data.criticalCount,
        [IssuePriorityKeys.LOW]: data.lowCount,
        [IssuePriorityKeys.MEDIUM]: data.mediumCount,
        [IssuePriorityKeys.HIGH]: data.highCount,
    }

    return (
        <Widget.Rows>
            {displayPriorities.map((item) => {
                const config = issueFields.priority.getConfig(item)
                return (
                    <Widget.Row
                        key={item}
                        label={issueFields.priority.value({ priority: item })}
                        value={<Widget.Chip color={config.color}>{counts[item] || 0}</Widget.Chip>}
                        to={
                            link
                                ? pathBase +
                                  encodeURIComponent(
                                      JSON.stringify({
                                          [issueFields.priority.source]: [item],
                                          [issueFields.status.source]: [
                                              IssueStatusKeys.OPEN,
                                              IssueStatusKeys.IN_PROGRESS,
                                              IssueStatusKeys.SCHEDULED,
                                          ],
                                          [customerFields.self.source]: [emptyOptionValue],
                                      }),
                                  )
                                : undefined
                        }
                    />
                )
            })}
        </Widget.Rows>
    )
}

export default IssuesByPriorityRows

const displayPriorities = [
    IssuePriorityKeys.CRITICAL,
    IssuePriorityKeys.HIGH,
    IssuePriorityKeys.MEDIUM,
    IssuePriorityKeys.LOW,
]
