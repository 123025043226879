import { type FilterBy, FilterDateRangeValue, type SortBy, type Column } from 'components'
import { formatDate } from 'lib'

import { WoStatusKeys, type WorkOrderModel } from '../types'

const source = 'closed' satisfies keyof WorkOrderModel

const label = 'Closed on'

const getValue = (value: string) =>
    formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)

const valueByRecord = (record: WorkOrderModel) => getValue(record.statusUpdated)

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => {
            if (row.status === WoStatusKeys.CLOSED) {
                return valueByRecord(row)
            }
        },
    }) as const satisfies Column<WorkOrderModel>

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    }) as const satisfies FilterBy

const closedDate = {
    source,
    label,
    tableColumn,
    sort,
    filter,
    value: valueByRecord,
    valueByRecord,
}

export default closedDate
