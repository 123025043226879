import {
    subDays,
    isValid,
    format,
    intervalToDuration,
    startOfWeek,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    parse,
    endOfWeek,
    endOfMonth,
    endOfQuarter,
} from 'date-fns'

import { type RemainingDate } from 'appTypes'

export const lastNDays = (n: number, to = new Date()): [Date, Date] => {
    const from = subDays(to, n - 1)
    return [from, to]
}

type dateRangeType = 'from' | 'to'

export {
    startOfWeek,
    startOfMonth,
    startOfQuarter,
    startOfYear,
    subDays,
    endOfYear,
} from 'date-fns'

const timePart = 'HH:mm:ss'

export const dateFormatsObject = {
    fullDateTime: 'MMMM dd, yyyy hh:mm a',
    shortenedDateTime: 'MMM dd yyyy h:mm a',
    shortenedDate: 'MMM dd yyyy',
    serverDateTime: `yyyy-MM-dd'T'${timePart}xxxx`,
    serverDate: 'yyyy-MM-dd',
    shortMonthYear: 'MMM yyyy',
    fullMonthYear: 'MMMM yyyy',
    shortMonth: 'MMM',
    fullYear: 'yyyy',
}

export const formatDate = (
    date: Date | string,
    formatType: string | ((dateFormats: typeof dateFormatsObject) => string),
) => {
    if (!date) {
        return ''
    }
    return format(
        typeof date === 'string' ? new Date(date) : date,
        typeof formatType === 'function' ? formatType(dateFormatsObject) : formatType,
    )
}
export const dateTimeRangeFormats: {
    [key in dateRangeType | 'format']: string
} = {
    format: dateFormatsObject.serverDateTime,
    from: dateFormatsObject.serverDateTime.replace(timePart, '00:00:00'),
    to: dateFormatsObject.serverDateTime.replace(timePart, '23:59:59'),
}

export const dateTimeParse = (date: string | Date) => {
    if (!date) {
        return null
    }
    const newDate = new Date(date)
    newDate.setSeconds(0, 0)
    if (!isValid(newDate)) {
        return null
    }

    return formatDate(newDate, dateFormatsObject.serverDateTime)
}

export const dateParse = (date: string | Date) => {
    if (!date) {
        return null
    }
    const newDate = date instanceof Date ? date : new Date(stringDateFixTimezone(date))

    if (!isValid(newDate)) {
        return null
    }

    return formatDate(newDate, dateFormatsObject.serverDate)
}

export const stringDateFixTimezone = (date: string | number): string | null => {
    if (!date) {
        return null
    }

    let newDate = String(date)
    if (newDate.length === 4) {
        newDate += '-03-03'
    }

    return newDate.split('T')[0] + 'T12:00:00'
}

export const timeLeftFormat = (left: RemainingDate): string => {
    if (!left) {
        return ''
    }
    const { years, months, days } = left
    const y = Math.abs(years)
    const m = Math.abs(months)
    const d = Math.abs(days)

    const content =
        [y && `${y}yr`, m && `${m}mo`, d && `${d}d`].filter((time) => time).join(' ') || '0d'

    if (years < 0 || months < 0 || days < 0) {
        return `${content} overdue`
    }

    return content
}

export const dateGetRelativeDelta = (_dateTo: Date, dateFrom = new Date()): RemainingDate => {
    const dateTo = _dateTo instanceof Date ? _dateTo : new Date(_dateTo)

    dateFrom.setHours(0, 0, 0, 0)
    dateTo.setHours(0, 0, 0, 0)

    const duration = intervalToDuration({
        start: dateFrom,
        end: dateTo,
    })

    const negative = dateTo < dateFrom

    const format = (value: number) => {
        if (negative) {
            return -value
        }
        return value
    }

    return {
        years: format(duration.years),
        months: format(duration.months),
        days: format(duration.days),
    }
}

export const dateReadableFormat = (date: string | Date) => {
    return formatDate(date, dateFormatsObject.shortenedDate + ' h:mm aaa')
}

export enum DateRangeKeys {
    TODAY = 'today',
    LAST_7_DAYS = 'last-7-days',
    LAST_30_DAYS = 'last-30-days',
    THIS_WEEK = 'this-week',
    LAST_WEEK = 'last-week',
    THIS_MONTH = 'this-month',
    LAST_MONTH = 'last-month',
    THIS_QUARTER = 'this-quarter',
    LAST_QUARTER = 'last-quarter',
    YTD = 'ytd',
    LAST_YEAR = 'last-year',
}

interface DateRange {
    id: DateRangeKeys
    name: string
    values: () => [string, string]
}

const rangesBase = [
    {
        id: DateRangeKeys.TODAY,
        name: 'Today',
        values: () => {
            const now = new Date()
            return [now, now]
        },
    },
    {
        id: DateRangeKeys.LAST_7_DAYS,
        name: 'Last 7 Days',
        values: () => lastNDays(7),
    },
    {
        id: DateRangeKeys.LAST_30_DAYS,
        name: 'Last 30 Days',
        values: () => lastNDays(30),
    },
    {
        id: DateRangeKeys.THIS_WEEK,
        name: 'This Week',
        values: () => {
            const now = new Date()
            const from = startOfWeek(now)
            const to = endOfWeek(now)
            return [from, to]
        },
    },
    {
        id: DateRangeKeys.LAST_WEEK,
        name: 'Last Week',
        values: () => {
            const now = new Date()
            const startOfThisWeek = startOfWeek(now)
            const to = subDays(startOfThisWeek, 1)
            const from = startOfWeek(to)
            return [from, to]
        },
    },
    {
        id: DateRangeKeys.THIS_MONTH,
        name: 'This Month',
        values: () => {
            const now = new Date()
            const from = startOfMonth(now)
            const to = endOfMonth(now)
            return [from, to]
        },
    },
    {
        id: DateRangeKeys.LAST_MONTH,
        name: 'Last Month',
        values: () => {
            const now = new Date()
            const startOfThisMonth = startOfMonth(now)
            const to = subDays(startOfThisMonth, 1)
            const from = startOfMonth(to)
            return [from, to]
        },
    },
    {
        id: DateRangeKeys.THIS_QUARTER,
        name: 'This Quarter',
        values: () => {
            const now = new Date()
            const from = startOfQuarter(now)
            const to = endOfQuarter(now)
            return [from, to]
        },
    },
    {
        id: DateRangeKeys.LAST_QUARTER,
        name: 'Last Quarter',
        values: () => {
            const now = new Date()
            const startOfThisQuarter = startOfQuarter(now)
            const to = subDays(startOfThisQuarter, 1)
            const from = startOfQuarter(to)
            return [from, to]
        },
    },
    {
        id: DateRangeKeys.YTD,
        name: 'YTD',
        values: () => {
            const now = new Date()
            const from = startOfYear(now)
            return [from, now]
        },
    },
    {
        id: DateRangeKeys.LAST_YEAR,
        name: 'Last Year',
        values: () => {
            const now = new Date()
            const startOfThisYear = startOfYear(now)
            const to = subDays(startOfThisYear, 1)
            const from = startOfYear(to)
            return [from, to]
        },
    },
] as const satisfies Readonly<
    Readonly<Omit<DateRange, 'values'> & { readonly values: () => [Date, Date] }>[]
>

export const dateRanges: DateRange[] = rangesBase.map((dateRange) => ({
    ...dateRange,
    values: () => {
        const [from, to] = dateRange.values()
        return [
            formatDate(from, dateTimeRangeFormats.from),
            formatDate(to, dateTimeRangeFormats.to),
        ]
    },
}))

const dateRangeObject = dateRanges.reduce(
    (obj, choice) => {
        obj[choice.id] = choice

        return obj
    },
    {} as { [key in DateRangeKeys]: DateRange },
)

const getDateRangeConfig = (dateRange: DateRangeKeys) => {
    return dateRangeObject[dateRange]
}

export const getDateRangeDates = (dateRange: DateRangeKeys) => {
    return getDateRangeConfig(dateRange)?.values()
}

export const formatDuration = (seconds: number, short: boolean = false) => {
    const days = Math.floor(seconds / (60 * 60 * 24))
    const hours = parseInt(new Date(seconds * 1000).toISOString().substr(11, 8).split(':')[0])
    if (short) {
        return `${days}d ${hours}h`
    }
    return `${days} days ${hours} hours`
}

const quarterMonths = {
    1: { start: new Date(2023, 0, 1), end: new Date(2023, 2, 31) }, // Jan to Mar
    2: { start: new Date(2023, 3, 1), end: new Date(2023, 5, 30) }, // Apr to Jun
    3: { start: new Date(2023, 6, 1), end: new Date(2023, 8, 30) }, // Jul to Sep
    4: { start: new Date(2023, 9, 1), end: new Date(2023, 11, 31) }, // Oct to Dec
}

export const getQuarterRange = (quarter: number) => {
    const { start, end } = quarterMonths[quarter]

    const firstMonth = format(start, 'MMM')
    const lastMonth = format(end, 'MMM')

    return `${firstMonth} - ${lastMonth}`
}

export const getMonthName = (monthNumber: number) => {
    const date = new Date(2000, monthNumber - 1, 1)
    return format(date, 'MMM')
}

export const parseDate = parse

export const isCurrenYear = (year: number | string) => {
    return getCurrentYear() === Number(year)
}

export const getCurrentYear = () => {
    return new Date().getFullYear()
}

export const getCurrentMonth = () => {
    return new Date().getMonth()
}

export const getLastDayOfMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate()
}

export const getCurrentDay = () => {
    return new Date().getDate()
}

export const yearQuarters = Array(4)
    .fill(0)
    .map((_, index) => getQuarterRange(index + 1))
