import { type DataRecord } from 'appTypes'
import { useRecordContext } from 'core/data'

import { useCanAccessContext } from './CanAccessContext'

const useCanAccess = () => {
    const contextRecord = useRecordContext()
    const getCanAccess = useCanAccessContext()

    return (action: string, record?: DataRecord) => getCanAccess(record || contextRecord, action)
}

export default useCanAccess
