import { useStoreContext } from 'ra-core'

import { type ConfirmConfig } from './types'

export const useConfirm = () => {
    const { setItem } = useStoreContext()

    return (params: ConfirmConfig) => {
        setItem('confirm', params)
    }
}
