import { timeLeftFormat } from 'lib'
import { getPmTypeChoice, type IntervalsType, type PmModel } from 'resources/pm'
import { meterValue } from 'resources/units'
import { woFields } from 'resources/workOrders'
import { Stack, BoxContainer, Typography } from 'ui'
import { displayBooleanValue } from 'utils'

import AddWorkOrderMenu from '../../components/AddWorkOrderMenu'

export const getIntervalValue = (selectedPM: IntervalsType) => {
    const type = selectedPM.type
    if (type === 'TIME') {
        const abbr = getPmTypeChoice(selectedPM.valueType).abbr
        return `${selectedPM.value}${abbr}`
    }

    return meterValue(selectedPM.value, type)
}

export const getLeft = (selectedPM: IntervalsType) => {
    const type = selectedPM.type
    if (type === 'TIME') {
        return timeLeftFormat(selectedPM.remainingTillOverduePretty)
    }

    const isOverdue = selectedPM.status === 'OVERDUE'

    if (selectedPM.threshold !== null || isOverdue) {
        return (
            <>
                {meterValue(Math.abs(selectedPM.remainingTillOverdue), type)}{' '}
                {isOverdue && selectedPM.remainingTillOverdue !== 0 ? 'overdue' : null}
            </>
        )
    }
    return (
        <>
            {meterValue(selectedPM.remainingTillOverdue, type)} out of{' '}
            {meterValue(selectedPM.value, type)}
        </>
    )
}

const BottomSection = ({
    selectedPM,
    pmReminder,
    isArchived,
}: {
    selectedPM: IntervalsType
    pmReminder: PmModel
    isArchived: boolean
}) => {
    const leftValue = getLeft(selectedPM)
    const intervalValue = getIntervalValue(selectedPM)
    const workOrderContent = pmReminder.workOrderData ? (
        <Typography variant="body2">{woFields.self.linkValue(pmReminder.workOrderData)}</Typography>
    ) : (
        <AddWorkOrderMenu<PmModel>
            options={{
                jobDefaultValues: {
                    notes: pmReminder.description
                        ? [
                              {
                                  createdByData: { name: pmReminder.name },
                                  source: 'PM_SCHEDULE',
                                  text: pmReminder.description,
                              },
                          ]
                        : [],
                },
                woDisabledFields: {
                    unit: true,
                },
            }}
            disabled={isArchived}
            parentRecord={pmReminder}
        />
    )
    return (
        <Stack
            spacing="10px"
            sx={{ paddingTop: '12px' }}
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                    textTransform="uppercase"
                >
                    LEFT
                </Typography>
                <BoxContainer overflow="hidden">
                    <Typography
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        variant="body2"
                        color={
                            isArchived
                                ? 'text.primary'
                                : selectedPM.status === 'OVERDUE'
                                  ? 'error.main'
                                  : 'text.primary'
                        }
                    >
                        {leftValue}
                    </Typography>
                </BoxContainer>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                    textTransform="uppercase"
                >
                    INTERVAL
                </Typography>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {intervalValue}
                </Typography>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    AUTO-CREATE WORK ORDER
                </Typography>
                <Typography
                    variant="body2"
                    color="text.primary"
                >
                    {displayBooleanValue(pmReminder.autoCreateWo)}
                </Typography>
            </BoxContainer>
            <BoxContainer justifyContent="space-between">
                <Typography
                    variant="tooltip"
                    color="text.disabled"
                >
                    WORK ORDER
                </Typography>
                {workOrderContent}
            </BoxContainer>
        </Stack>
    )
}
export default BottomSection
