import { type Identifier } from 'appTypes'
import { type ResourceConfig } from 'core'
import { createReportResource } from 'resources/reports'
import { pathJoin } from 'utils'

const resource = 'issues'

export const issuesResource = {
    resource,
    name: 'issues',
    close: (id: Identifier) => pathJoin(resource, id, 'close'),
} satisfies ResourceConfig

export const issuesByPriorityEndpoint = createReportResource(`issues-by-priority`)
