import { type FC, useCallback, useEffect } from 'react'

import { useFormState, useWatch, useFormContext, type FieldError } from 'react-hook-form'

import {
    SelectInput,
    TextInput,
    useArrayControllerElementContext,
    useUtilityDrawerContext,
    inputIntegerNonNegativeSpacedMaskParams,
} from 'components'
import {
    formErrorToString,
    validateLessThan,
    requiredValidation,
    type FormatedFormError,
    type Validator,
} from 'core'
import { FormHelperText, formHelperTextClasses, GridContainerColumns, GridItem } from 'ui'
import { getProperty } from 'utils'

import { PMIntervalTimeTypeKeys } from '../../types'
import { getPmTypeChoice, pmTypeChoices } from '../../utils'

import TriggerThresholdValidation from './TriggerValidation'
import { type PMIntervalRow } from './UnitPMFormIntervals'
import UnitPMMeterLine from './UnitPMMeterLine'
import UnitPMMeterValueInput from './UnitPMMeterValueInput'
import UnitPMFMeterCalcThreshold from './UnitPmMeterCalcThreshold'
import { type UnitPMDrawerEditorExtraState } from './types'
import useInputSource, { meterTypeItemSource } from './useInputSource'

const everyValidation = validateLessThan(1000, 'Should be less than 1000')

const UnitPMMeterTime = () => {
    const source = useInputSource()
    const { extra } = useUtilityDrawerContext()
    const { isArchived } = extra as UnitPMDrawerEditorExtraState
    return (
        <>
            <GridContainerColumns>
                <GridItem xs={6}>
                    <UnitPMMeterValueInput validate={everyValidation} />
                </GridItem>
                <GridItem xs={6}>
                    <SelectInput
                        source={source('valueType')}
                        disabled={isArchived}
                        label="Type"
                        validate={requiredValidation}
                        choices={pmTypeChoices}
                        defaultValue={PMIntervalTimeTypeKeys.DAY}
                        disableEmptyValue
                    />
                </GridItem>
                <DueSoonInput disabled={isArchived} />
            </GridContainerColumns>
            <ThresholdHelperText />
            <UnitPMFMeterTimeCalcThreshold />
            <UnitPMMeterTimeLine />
            <TriggerThresholdValidation
                extraSource={['valueType', 'threshold', 'thresholdType']}
                triggerExtraSource={['thresholdType']}
            />
        </>
    )
}

export default UnitPMMeterTime

const dueSoonValidate = (required: boolean, validation: Validator) =>
    [validation, required ? requiredValidation : null].filter(Boolean)

const DueSoonInput: FC<{ disabled: boolean }> = ({ disabled }) => {
    const source = useInputSource()
    const { index } = useArrayControllerElementContext<{ valueType: PMIntervalRow }>()
    const { watch, getValues } = useFormContext()

    const autoCreateWo = watch('autoCreateWo') || getValues('autoCreateWo')

    // TODO: this validation triggers on mount, but it should trigger only on change
    const thresholdValidation = useCallback((v, data) => {
        const row = getProperty(data, meterTypeItemSource(index)) || {}
        const { value, valueType, threshold, thresholdType } = row

        if (!value || !threshold) {
            return null
        }

        if (
            (Number(value) || 0) * (getPmTypeChoice(valueType)?.toDay || 0) <
            (Number(threshold) || 0) * (getPmTypeChoice(thresholdType)?.toDay || 0)
        ) {
            return 'The Threshold cannot be greater than the Meter interval'
        }
    }, [])

    const validate = dueSoonValidate(autoCreateWo, thresholdValidation)

    return (
        <>
            <GridItem xs={6}>
                <TextInput
                    disabled={disabled}
                    sx={{
                        [`& .${formHelperTextClasses.root}`]: {
                            display: 'none !important',
                        },
                    }}
                    source={source('threshold')}
                    label="“Due Soon” when"
                    {...inputIntegerNonNegativeSpacedMaskParams}
                    validate={validate}
                />
            </GridItem>
            <GridItem xs={6}>
                <SelectInput
                    source={source('thresholdType')}
                    disabled={disabled}
                    label="Type"
                    choices={pmTypeChoices.map((item) => ({
                        ...item,
                        name: item.name + ' left',
                    }))}
                    sx={{
                        [`& .${formHelperTextClasses.root}`]: {
                            display: 'none !important',
                        },
                    }}
                    disableEmptyValue
                    validate={validate}
                    defaultValue={PMIntervalTimeTypeKeys.DAY}
                />
            </GridItem>
        </>
    )
}

const ThresholdHelperText = () => {
    const source = useInputSource()
    const { errors, dirtyFields } = useFormState()
    const thresholdSource = source('threshold')
    const thresholdTypeSource = source('thresholdType')
    const field = getProperty(errors, thresholdSource) as FieldError | FormatedFormError // TO DO find better solution
    const error = formErrorToString(field, 'message')
    const { setValue, getValues, watch } = useFormContext()

    const thresholdValue = getValues(thresholdSource) || watch(thresholdSource)
    const areTouched =
        getProperty(dirtyFields, thresholdSource) || getProperty(dirtyFields, thresholdTypeSource)

    const touched = areTouched && thresholdValue
    useEffect(() => {
        if (!touched) {
            return
        }
        const makeTouched = (name: string) => {
            setValue(name, getValues(name), {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            })
        }

        makeTouched(thresholdTypeSource)
        makeTouched(thresholdSource)
    }, [touched])

    return (
        <FormHelperText
            error={Boolean(error)}
            sx={{ height: '39px', mx: '14px' }}
        >
            {error}
        </FormHelperText>
    )
}

const useTimeTypes = () => {
    const source = useInputSource()
    const [everyTypeValue, thresholdTypeValue] = useWatch({
        name: [source('valueType'), source('thresholdType')],
    })

    const getType = (type: string) => pmTypeChoices.find((choice) => choice.id === type)

    return {
        everyTypeValue,
        thresholdTypeValue,
        every: getType(everyTypeValue),
        threshold: getType(thresholdTypeValue),
    }
}

const UnitPMMeterTimeLine = () => {
    const { every, threshold } = useTimeTypes()

    const calc = (type) => (num: number) => {
        if (!type) {
            return num
        }

        return type.toDay * num
    }

    return (
        <UnitPMMeterLine
            getEvery={calc(every)}
            getThreshold={calc(threshold)}
        />
    )
}

const UnitPMFMeterTimeCalcThreshold = () => {
    const { every, threshold, everyTypeValue } = useTimeTypes()

    return (
        <UnitPMFMeterCalcThreshold
            dependencies={[everyTypeValue]}
            getValue={(num) => (every ? every.toDay * num : num)}
            getThresholdValue={(num) => (threshold ? num / threshold.toDay : num)}
        />
    )
}
