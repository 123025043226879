import { type FC } from 'react'

import { SvgIcon, ToggleButton, ToggleButtonGroup } from 'ui'

import { useDialogSelectorContext } from './DialogSelectorContext'

export interface DialogSelectorFilterButtonsProps {
    makeFilters?: (value: string | boolean) => Record<string, any>
    source: string
    items: {
        value: string | boolean
        Icon: FC
    }[]
    onChange?: (value: string | boolean) => void
}

const DialogSelectorFilterButtons = ({
    items,
    source,
    makeFilters,
    onChange,
}: DialogSelectorFilterButtonsProps) => {
    const { control, defaultFilter } = useDialogSelectorContext()
    const { setFilters, filterValues, isFetching } = control

    const handleChange = (event, value) => {
        // when clicked on same button - value is null
        if (value === null) {
            return
        }
        setFilters(
            makeFilters ? makeFilters(value) : { ...defaultFilter, [source]: value },
            {},
            false,
        )
        onChange?.(value)
    }

    return (
        <ToggleButtonGroup
            size="small"
            exclusive
            onChange={handleChange}
            value={String(filterValues[source] || '')}
            disabled={isFetching}
        >
            {items.map((item) => (
                <ToggleButton
                    size="small"
                    value={String(item.value)}
                    key={String(item.value)}
                >
                    <SvgIcon
                        inheritViewBox
                        component={item.Icon}
                    />
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}

export default DialogSelectorFilterButtons
