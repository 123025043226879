import { type FC } from 'react'

import { DateTimeInput } from 'components'
import { requiredValidation } from 'core'
import { PriorityInput } from 'resources/common'
import { CustomerPopUpInput } from 'resources/customers'
import { SectionTitleSmall } from 'ui'

import woFields from '../fields'

import WoSelectInput from './WoSelectInput'
import {
    VendorInputElement,
    VendorWoSwitch,
    WoCustomerPONumberInput,
    WOShopInput,
} from './WorkOrderForm'
import WorkOrderFormMeter from './WorkOrderFormMeter'

const WorkOrderOpenContent: FC = () => {
    return (
        <>
            <VendorWoSwitch />
            <VendorInputElement />
            <WOShopInput />
            <CustomerPopUpInput />
            <WoCustomerPONumberInput />
            <PriorityInput
                choices={woFields.priority.choices}
                optionText={woFields.priority.inputValue}
                isRequired
            />

            <WoSelectInput
                optionText={woFields.repairPriorityClass.inputValue}
                label={woFields.repairPriorityClass.label}
                source={woFields.repairPriorityClass.source}
                choices={woFields.repairPriorityClass.choices}
                validate={requiredValidation}
                disableEmptyValue
            />

            <SectionTitleSmall>Date & Time</SectionTitleSmall>

            <DateTimeInput
                source="started"
                label="Actual Start Date & Time"
                validate={requiredValidation}
                defaultValue={new Date()}
            />
            <WorkOrderFormMeter />
        </>
    )
}

export default WorkOrderOpenContent
