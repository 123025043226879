import { type FC, type ReactElement } from 'react'

import { type ObjectAny } from 'appTypes/common'
import Icons from 'assets/icons'
import api from 'core/api'
import { fileExtension, fileType, WindowURL } from 'utils'

export const simpleDownload = ({ download, href }: { download: string; href: string }) => {
    const a = document.createElement('a')
    a.download = download
    a.href = href
    a.classList.add('d-none')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

interface DownloadFileParams {
    href: string
    filename: string
    type: string
    headers?: ObjectAny
    queryParams?: ObjectAny
}

export const downloadFile = async ({
    href,
    filename,
    type,
    queryParams,
    headers,
}: DownloadFileParams) => {
    await api
        .get(href, queryParams, {
            responseType: 'blob',
            headers,
        })
        .then((blob) => {
            const newBlob = new Blob([blob], { type })

            const data = WindowURL.createObjectURL(newBlob)

            simpleDownload({ download: filename, href: data })

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                WindowURL.revokeObjectURL(data)
            }, 100)
        })
}

export const getFileExtensionData = (
    fileName: string,
    invalidType?: boolean,
): { color: string; icon: ReactElement; Icon: FC; extension: string } => {
    const extension = fileType(fileName)

    if (invalidType) {
        return {
            color: '#EA4335',
            icon: <Icons.FileAlert />,
            Icon: Icons.FileAlert,
            extension,
        }
    }

    if (fileExtension.isExcel(extension)) {
        return {
            color: '#34A853',
            icon: <Icons.Excel />,
            Icon: Icons.Excel,
            extension,
        }
    }

    if (fileExtension.isWord(extension)) {
        return {
            color: '#4285F4',
            icon: <Icons.Word />,
            Icon: Icons.Word,
            extension,
        }
    }

    if (fileExtension.isPdf(extension)) {
        return {
            color: '#EA4335',
            icon: <Icons.Pdf />,
            Icon: Icons.Pdf,
            extension,
        }
    }

    if (fileExtension.isImg(extension)) {
        return {
            color: '#EA4335',
            icon: <Icons.Image />,
            Icon: Icons.Image,
            extension,
        }
    }

    if (fileExtension.isVideo(extension)) {
        return {
            color: '#EA4335',
            icon: <Icons.Movie />,
            Icon: Icons.Movie,
            extension,
        }
    }

    if (fileExtension.isAudio(extension)) {
        return {
            color: '#EA4335',
            icon: <Icons.Headset />,
            Icon: Icons.Headset,
            extension,
        }
    }

    if (fileExtension.isTxt(extension)) {
        return {
            color: '#4285F4',
            icon: <Icons.Description />,
            Icon: Icons.Description,
            extension,
        }
    }

    return {
        color: '#4285F4',
        icon: <Icons.InsertDriveFile />,
        Icon: Icons.InsertDriveFile,
        extension,
    }
}

export const fileTypeError = { type: 'invalid-file-type', message: 'Invalid file format' }
