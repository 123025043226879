import { useListContext } from 'ra-core'

import { perPageLimit, useRecordContext, useShowContext } from 'core'
import { Navigate, useParams } from 'lib'
import { type ReceiptModel, type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Stack, FlexReverse } from 'ui'

import ReturnItems from '../ReturnItems'

import { type ParamsType, LineItemsList, ReceiptSection, ReceiptToggle } from './components'
import ReceiptsList from './components/ReceiptsList'

const LineItems = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    if (!record) {
        return null
    }

    if (record.type === 'CREDIT') {
        return <ReturnItems />
    }

    return (
        <ReceiptsList
            perPage={perPageLimit}
            disableSyncWithLocation
        >
            <POLineItems />
        </ReceiptsList>
    )
}

const POLineItems = () => {
    const { receipt } = useParams<ParamsType>()

    const { record } = useShowContext<PurchaseOrderModel>()
    const { data, isFetching } = useListContext()

    const receiptId = !receipt && record.status === 'CLOSED' && data ? data[0]?.id : receipt
    const receiptData: ReceiptModel = data?.find(({ id }) => id === receiptId)

    if (!data) {
        return null
    }

    if (record.status === 'CLOSED' && !receipt && !isFetching) {
        return (
            <Navigate
                to={receiptId}
                replace
            />
        )
    }

    if (receipt && !receiptData) {
        return <Navigate to=".." />
    }

    return (
        <FlexReverse flex={1}>
            <Stack flex={1}>
                {record?.dateLastReceived && <ReceiptToggle />}
                {receiptData && (
                    <ReceiptSection
                        receiptData={receiptData}
                        poRecord={record}
                    />
                )}
                <LineItemsList receipt={receiptData} />
            </Stack>
        </FlexReverse>
    )
}

export default LineItems
