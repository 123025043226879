import { type FC } from 'react'

import { inject } from 'mobx-react'
import { useWatch } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { AutocompleteInput, TextInputBase, type SelectInputProps } from 'components'
import { ReferenceArrayInput, ReferenceInput, requiredValidation } from 'core'
import { type AuthStore } from 'core/auth'

import { shopResource } from '../utils'

interface ShopInputProps
    extends Pick<SelectInputProps, 'disabled' | 'multiple' | 'source' | 'helperText' | 'onChange'> {
    contextType: string
    noSelectedShop?: boolean
    contextId?: Identifier
    label?: string
    filter?: Record<string, any>
    required?: boolean
    readOnly?: boolean
}

const ShopInput: FC<ShopInputProps> = inject('auth')(({
    required,
    disabled,
    multiple,
    source = 'shop',
    contextType,
    contextId,
    auth,
    noSelectedShop = false,
    label,
    helperText,
    filter,
    onChange,
}: ShopInputProps & { auth: AuthStore }) => {
    const Component = multiple ? ReferenceArrayInput : ReferenceInput

    const selectedShop = noSelectedShop ? null : auth.shopId

    return (
        <Component
            source={source}
            reference={shopResource.resource}
            disabled={disabled}
            perPage={100}
            contextType={contextType}
            contextId={contextId}
            filter={filter}
        >
            <>
                <AutocompleteInput
                    helperText={helperText}
                    label={label || `Repair shop${multiple ? 's' : ''}`}
                    validate={required && !disabled ? requiredValidation : undefined}
                    multiple={multiple}
                    disabled={disabled || Boolean(selectedShop)}
                    optionText="name"
                    defaultValue={selectedShop}
                    filterSelectedOptions
                    onChange={onChange}
                />
            </>
        </Component>
    )
})

export default ShopInput

export const ShopReadOnlyInput: FC<{ source?: string; label?: string }> = ({
    source = 'shop',
    label = 'Repair shop',
}) => {
    const v = useWatch({ name: source })

    return (
        <TextInputBase
            value={v?.name || ''}
            label={label}
            disabled
        />
    )
}
