import { type FC } from 'react'

import { useInput } from 'core'

interface Props {
    // eslint-disable-next-line react/no-unused-prop-types
    source: string
    // eslint-disable-next-line react/no-unused-prop-types
    defaultValue?: any
}

const RegisterInput: FC<Props> = (props) => {
    useInput(props)

    return null
}

export default RegisterInput
