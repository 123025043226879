import { type FC } from 'react'

import { Gallery, GalleryItem, type Photos } from 'components'
import { styled } from 'lib'

interface Props {
    photos: Photos
    deletePhoto?: (id: string) => Promise<void>
}

const PhotoGallery: FC<Props> = ({ photos, deletePhoto }) => {
    return (
        <Gallery
            onImageDelete={
                deletePhoto
                    ? (galleryCtx) => {
                          const item = galleryCtx.getItemData(galleryCtx.currIndex)
                          deletePhoto(item.element.id)
                      }
                    : null
            }
        >
            <PictureGrid>
                {Object.keys(photos)
                    .filter((photoIndex) => photos[photoIndex])
                    .map((photoIndex) => {
                        const file = photos[photoIndex]
                        return (
                            <GalleryItem
                                id={photoIndex}
                                key={photoIndex}
                                file={file}
                                sx={({ palette }) => ({
                                    height: '200px',
                                    background: palette.white,
                                    border: `1px solid ${palette.other.inputBorder}`,
                                })}
                            />
                        )
                    })}
            </PictureGrid>
        </Gallery>
    )
}

export default PhotoGallery

const PictureGrid = styled('ul')`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    list-style-type: none;
    width: 100%;
    padding: 10px;
`
