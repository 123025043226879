import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { InfoCard, InfoCardDetails } from 'components'
import { type AuthStore } from 'core'
import { dateReadableFormat } from 'lib'
import { ShopLink } from 'resources/shops'
import { type WorkOrderModel, woFields } from 'resources/workOrders'
import { Tooltip } from 'ui'

const DatePriorityClass: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        return (
            <InfoCard>
                <InfoCardDetails<WorkOrderModel>
                    details={[
                        {
                            label: 'Repair Shop',
                            source: ({ shopData }) =>
                                shopData && <ShopLink id={shopData.id}>{shopData.name}</ShopLink>,
                        },
                        ...(auth.companySettings.hasCostCenters
                            ? [
                                  {
                                      label: 'Unit Cost Center',
                                      source: ({ costCenterData }) => costCenterData?.name,
                                  },
                              ]
                            : []),
                        ...(auth.companySettings.hasDivisions
                            ? [
                                  {
                                      label: 'Unit Division',
                                      source: ({ divisionData }) => divisionData?.name,
                                  },
                              ]
                            : []),
                        {
                            label: 'Priority',
                            source: (record) => woFields.priority.value(record),
                        },
                        {
                            label: 'Repair class',
                            source: woFields.repairPriorityClass.value,
                        },
                        {
                            label: 'Description',
                            source: ({ description }) =>
                                description ? (
                                    <Tooltip title={description}>
                                        <span>{description}</span>
                                    </Tooltip>
                                ) : (
                                    description
                                ),
                        },
                        {
                            label: 'Created on',
                            source: ({ created }) => dateReadableFormat(created),
                        },
                        {
                            label: 'Created By',
                            source: ({ createdBy }) => createdBy?.name || createdBy?.email,
                        },
                    ]}
                />
            </InfoCard>
        )
    }),
)

export default DatePriorityClass
