import { type ReactElement, useEffect, useMemo, useRef, useState } from 'react'

import {
    ListContextProvider,
    ChoicesContextProvider,
    type UseReferenceInputControllerParams,
    type ChoicesContextValue,
    useRecordContext,
} from 'ra-core'
import { ResettableTextFieldClasses } from 'ra-ui-materialui'
import { useFormContext } from 'react-hook-form'

import { type DataRecord, type Identifier } from 'appTypes'
import { drawerWidth } from 'components/utils'
import { FormActionProvider } from 'context'
import {
    useCreateInputId,
    useSetBlocker,
    type ApiClientConfig,
    useReferenceInputController,
    requiredValidation,
} from 'core'
import { useInput } from 'core/inputs'
import { useMediaQuery } from 'lib'
import { drawerClasses, SwipeableDrawer, type DrawerProps, BoxContainer, Box } from 'ui'
import { getProperty, isEqual } from 'utils'

import DialogSelectorAppBar, { type DialogSelectorAppBarProps } from './DialogSelectorAppBar'
import { DialogSelectorProvider } from './DialogSelectorContext'
import DialogSelectorFilter, { type DialogSelectorFilterProps } from './DialogSelectorFilter'
import DialogSelectorList, { type DialogSelectorListProps } from './DialogSelectorList'
import DialogSelectorLoadMore from './DialogSelectorLoadMore'
import DialogSelectorResultCount from './DialogSelectorResultCount'
import DialogSelectorToggler, { type DialogSelectorTogglerProps } from './DialogSelectorToggler'
import { type DialogSelectorUniversalParam } from './types'

import type { ObjectAny, ExtendRecordType } from 'appTypes'

export interface DialogSelectorProps<OptionType extends DataRecord = any>
    extends Omit<DialogSelectorListProps<OptionType>, 'selectItem'>,
        DialogSelectorTogglerProps<OptionType> {
    source: string
    required?: boolean
    titleSource?: ExtendRecordType<OptionType, string | number>
    reference: string
    searchPlaceholder?: string

    filter?: DialogSelectorFilterProps
    referenceFilter?: ObjectAny
    onSelectedChange?: (params: DialogSelectorUniversalParam<OptionType>) => void
    defaultFilter?: any
    renderAboveList?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    renderTop?: (params: DialogSelectorUniversalParam<OptionType>) => ReactElement
    renderNextToResultCount?: (data: DialogSelectorUniversalParam<OptionType>) => ReactElement
    disabled?: boolean
    appBar?: Pick<DialogSelectorAppBarProps, 'leftButton' | 'paddingX'>

    onChange?: (control: ChoicesContextValue<OptionType>, id?: Identifier) => void
    queryOptions?: (
        open: boolean,
    ) => Omit<UseReferenceInputControllerParams<OptionType>['queryOptions'], 'enabled'>
    refetchOnOpen?: boolean
    resettable?: boolean
    contextType?: string
    contextId?: Identifier
    defaultOpen?: boolean
    onClose?: (id: Identifier | null, source: string) => void
}

const DialogSelector = <OptionType extends DataRecord = any>({
    defaultFilter,
    itemPrimary,
    itemSecondary,
    renderListItem,
    source,
    defaultSelectorProps,
    titleSource,
    reference,
    required,
    referenceFilter,
    defaultSelectorValueSource,
    filter,
    renderNoResults,
    onSelectedChange,
    renderAboveList,
    renderTop,
    disabled = false,
    renderNextToResultCount,
    appBar,
    noResults,
    renderToggler,
    renderSelectedToggler,
    onChange,
    queryOptions,
    refetchOnOpen,
    resettable,
    renderItem,
    contextType,
    contextId,
    onClose,
    defaultOpen,
}: DialogSelectorProps<OptionType>) => {
    const [open, setOpen] = useState(false)
    const { getFieldState } = useFormContext()
    const isOpened = useRef(open)
    const record = useRecordContext()

    if (open) {
        isOpened.current = true
    }

    useEffect(() => {
        setOpen(defaultOpen ?? false)
    }, [])

    const handleOpen = (e: React.MouseEvent<any, MouseEvent>) => {
        const target = e?.target as HTMLElement
        // Don't open if RA clear button clicked
        if (disabled || isElementRaClearButton(target)) {
            return null
        }
        if (refetchOnOpen) {
            controllerProps.refetch()
        }
        setOpen(true)
    }

    const handleClose = (value?: Identifier) => {
        setOpen(false)
        onClose?.(value, source)
    }

    useSetBlocker(
        {
            close: handleClose,
        },
        {
            isOpen: open,
        },
    )

    const isExtraSmall = useMediaQuery((theme) =>
        theme.breakpoints.down(theme.props.mobileViewBreakpoint),
    )
    const anchor: DrawerProps['anchor'] = isExtraSmall ? 'bottom' : 'right'

    const _queryOptions = queryOptions?.(open)

    const queryMeta = {
        ..._queryOptions?.meta,
        referenceInput: true,
    }
    const filters = useMemo(
        () => ({ ...defaultFilter, ...referenceFilter }),
        [referenceFilter, defaultFilter],
    )
    const controllerProps = useReferenceInputController<OptionType>({
        filter: { ...referenceFilter, contextType, contextId },
        reference,
        source,
        perPage: 100,
        contextType,
        contextId,
        queryOptions: {
            ..._queryOptions,
            meta: queryMeta,
        },
        enableGetChoices: () => {
            if (disabled) {
                return false
            }
            return isOpened.current
        },
    })

    const createId = useCreateInputId()

    const input = useInput({
        source,
        id: createId(source),
        validate: !disabled && required ? requiredValidation : undefined,
    })

    const { field } = input

    const onSelect: DialogSelectorUniversalParam['onSelect'] = (id) => {
        if (onChange) {
            onChange(controllerProps, id)
        } else {
            field.onChange(id)
            field.onBlur()
        }
        handleClose(id)
    }

    const dataValue = getProperty(record, source + 'Data')

    const selected =
        getFieldState(source).isDirty || !dataValue
            ? controllerProps.selectedChoices?.[0]
            : dataValue

    const data: DialogSelectorUniversalParam<OptionType> = {
        value: field.value,
        selected,
        control: controllerProps,
        input,
        onSelect,
        defaultFilter: defaultFilter || {},
        handleOpen,
        queryMeta,
        disabled,
        source,
        required: input.isRequired,
    }

    // TODO: Dialog selector fetches twice if defaultFilter is provided

    useEffect(() => {
        if (!open && !isEqual(filters, controllerProps.filter)) {
            controllerProps.setFilters(filters, {}, false)
        }
    }, [open, filters])

    useEffect(() => {
        // trigger onSelectedChange after fetch
        // track isTouched instead of isDirty because isDirty resets after selecting the initial value
        if (input.fieldState.isTouched && !controllerProps.isFetching) {
            onSelectedChange?.(data)
        }
    }, [selected, controllerProps.isFetching])

    return (
        <FormActionProvider value={formActionProviderValue}>
            <DialogSelectorProvider value={data}>
                <ListContextProvider value={controllerProps as any}>
                    <ChoicesContextProvider value={controllerProps}>
                        <div>
                            <DialogSelectorToggler
                                defaultSelectorProps={defaultSelectorProps}
                                defaultSelectorValueSource={defaultSelectorValueSource}
                                renderSelectedToggler={renderSelectedToggler}
                                renderToggler={renderToggler}
                                resettable={resettable}
                            />
                            <SwipeableDrawer
                                PaperProps={{
                                    sx: {
                                        height: '100%',
                                        [`&.${drawerClasses.paperAnchorRight}`]: {
                                            maxWidth: '100%',
                                            width: drawerWidth,
                                        },
                                    },
                                }}
                                anchor={anchor}
                                open={open}
                                onClose={() => handleClose()}
                                onOpen={handleOpen}
                            >
                                <DialogSelectorAppBar
                                    {...appBar}
                                    onClose={handleClose}
                                    selected={selected}
                                    titleSource={titleSource}
                                />
                                {/* HACK: for FilterLiveSearch */}
                                <DialogSelectorFilter {...filter} />
                                <Box
                                    overflow="auto"
                                    height="100%"
                                    display="flex"
                                    flexDirection="column"
                                >
                                    <Box p="20px">
                                        {renderTop?.(data)}
                                        <BoxContainer justifyContent="space-between">
                                            {typeof renderNextToResultCount === 'function'
                                                ? renderNextToResultCount(data)
                                                : null}
                                            <DialogSelectorResultCount />
                                        </BoxContainer>
                                        {renderAboveList?.(data)}
                                    </Box>
                                    <DialogSelectorList
                                        renderNoResults={renderNoResults}
                                        noResults={noResults}
                                        itemPrimary={itemPrimary}
                                        itemSecondary={itemSecondary}
                                        selectItem={onSelect}
                                        renderListItem={renderListItem}
                                        renderItem={renderItem}
                                    />
                                </Box>
                                <DialogSelectorLoadMore />
                            </SwipeableDrawer>
                        </div>
                    </ChoicesContextProvider>
                </ListContextProvider>
            </DialogSelectorProvider>
        </FormActionProvider>
    )
}

export default DialogSelector

const formActionProviderValue = {
    queryParams: {
        clientConfig: { preventCacheReset: true } satisfies ApiClientConfig,
    },
}

// it can be button, svg or path
const isElementRaClearButton = (target: HTMLElement | SVGElement) => {
    if (!target) {
        return false
    }

    if (
        target.classList.contains(ResettableTextFieldClasses.clearButton) ||
        target.classList.contains(ResettableTextFieldClasses.clearIcon)
    ) {
        return true
    }

    if (
        target.tagName.toLowerCase() === 'path' &&
        (target.parentNode as HTMLElement).classList.contains(ResettableTextFieldClasses.clearIcon)
    ) {
        return true
    }

    return false
}
