import { useGetList } from 'ra-core'
import { useFormContext } from 'react-hook-form'

import {
    useOpenUtilityDrawer,
    UtilityDrawerBackButton,
    UtilityDrawerNextButton,
    UtilityDrawerStepsContent,
    UtilityDrawerStepper,
    UtilityDrawerStepsContextProvider,
    type StepsParser,
    formatMoney,
} from 'components'
import { type RenderWrapperProps } from 'components/UtilityDrawer'
import { api, useNotify, serialize, perPageLimit, useRecordContext } from 'core'
import { useQuery } from 'lib'
import { taxesTotal } from 'resources/common'
import { type LineItemsModel, poDraftUrl, type PurchaseOrderModel } from 'resources/purchaseOrders'

import { useIsMismatch, usePOWillBeClosed } from '../../hooks'
import {
    DrawerTotal,
    poFeesStep,
    poFeesStepSerializer,
    poInvoiceStep,
    poInvoiceStepSerializer,
} from '../components'

import PoMismatchedItems, { isPoItemMismatched } from './PoMismatchedItems'
import PoReceiveItemsDrawerOptions from './PoReceiveItemsDrawerOptions'
import PoVerifyItems from './PoVerifyItems'
import { type ReceiveFormData, type ReceiveItemsExtraData } from './types'

const useReceiveItems = () => {
    const open = useOpenUtilityDrawer()
    const record = useRecordContext<PurchaseOrderModel>()
    return () => {
        open({
            drawerArgs: {
                title: 'Receive Items',
                renderWrapper: (props) => (
                    <ReceiveItemsWrapper
                        {...props}
                        po={record}
                    />
                ),
                renderBelowHeader: () => <UtilityDrawerStepper />,
                renderBottomLeft: () => <UtilityDrawerBackButton />,
                renderBottomRight: () => <UtilityDrawerNextButton />,
                renderContent: () => <UtilityDrawerStepsContent />,
                renderAboveFooter: () => (
                    <DrawerTotal
                        text="Receipt Total"
                        totalContent={<ReceiveDrawerTotal />}
                    />
                ),
                renderTopRight: () => <PoReceiveItemsDrawerOptions />,
            },
        })
    }
}

export default useReceiveItems

const ReceiveDrawerTotal = () => {
    const { watch } = useFormContext<ReceiveFormData>()
    const receivedItems = watch('receivedItems')
    const taxes = watch('taxes')

    return <span>{formatMoney(poItemsTotal(receivedItems, taxes))}</span>
}

const ReceiveItemsWrapper = ({ po, ...props }: RenderWrapperProps & { po: PurchaseOrderModel }) => {
    const items = useGetList<LineItemsModel>(
        `purchase-orders/${po.id}/items`,
        {
            pagination: {
                page: 1,
                perPage: perPageLimit,
            },
        },
        {
            cacheTime: 0,
        },
    )

    const draftUrl = poDraftUrl(po.id)

    const draft = useQuery(draftUrl, () => api.get(draftUrl), {
        enabled: po.status === 'DRAFT',
        cacheTime: 0,
    })

    const mismatch = useIsMismatch()
    const willBeClosed = usePOWillBeClosed()
    const notify = useNotify()

    if (items.isFetching || draft.isFetching) {
        return null
    }

    const extra: ReceiveItemsExtraData = {
        items: items.data,
        hasDraft: po.status === 'DRAFT',
        po,
    }

    return (
        <UtilityDrawerStepsContextProvider
            {...props}
            initialStep={draft.data?.step}
            parser={dataParser}
            extra={extra}
            successMessage="Successfully saved"
            submitEndpoint={`purchase-orders/${po.id}/receipts`}
            defaultValues={draft.data}
            onFieldError={(errors) => {
                if (errors.receivedItems?.message) {
                    notify({ title: errors.receivedItems.message, type: 'error' })
                    return true
                }
            }}
            onFormError={(error, { formValues, retry }) => {
                if (mismatch.hasError(error)) {
                    formValues = formValues as ReceiveFormData

                    mismatch.action({
                        objectTotal: poItemsTotal(formValues.receivedItems, formValues.taxes),
                        invoiceTotal: Number(formValues.invoice?.amount || 0),
                        objectName: 'Receipt',
                    })
                    return true
                }

                if (willBeClosed.hasError(error)) {
                    willBeClosed.action({
                        text: 'The PO will be Automatically closed because you completed the wizard without any backordered items',
                        onConfirm: () => {
                            retry({
                                successMessage: 'Successfully closed',
                                queryParams: {
                                    force: true,
                                },
                            })
                        },
                    })
                    return true
                }
            }}
            steps={[
                {
                    title: 'Verify Items',
                    Content: PoVerifyItems,
                },
                {
                    title: 'Mismatched Items',
                    Content: PoMismatchedItems,
                    skip: ({ stepsData, extra }) => {
                        const { items } = extra as ReceiveItemsExtraData
                        return !items.some((item) => {
                            return isPoItemMismatched(
                                (stepsData.formValues as ReceiveFormData).receivedItems[item.id],
                                item,
                            )
                        })
                    },
                },
                poFeesStep(),
                poInvoiceStep(),
            ]}
        />
    )
}

interface ParserExtra {
    isDraft?: boolean
}

const dataParser: StepsParser<ReceiveFormData, ReceiveFormData, ParserExtra> = [
    (data, { extra }) => {
        return {
            requestData: {
                receivedItems: Object.keys(data.receivedItems).reduce((items, id) => {
                    if (data.receivedItems[id]?.received) {
                        items[id] = serialize(data.receivedItems[id], [
                            extra?.isDraft ? { name: 'received', parse: 'boolean' } : null,
                            { name: 'price', parse: 'number' },
                            { name: 'quantity', parse: 'number' },
                        ])
                    }
                    return items
                }, {}),
            },
        }
    },
    (data, { context }) => {
        const items = (context.extra as ReceiveItemsExtraData).items
        return {
            requestData: {
                remainingItems: data.remainingItems
                    ? items.reduce((remainingItemsData, item) => {
                          const itemData = data.receivedItems[item.id]
                          const itemType = data.remainingItems[item.id]?.type
                          if (itemType && isPoItemMismatched(itemData, item)) {
                              remainingItemsData[item.id] = {
                                  type: itemType,
                              }
                          }
                          return remainingItemsData
                      }, {})
                    : {},
            },
        }
    },
    poFeesStepSerializer,
    poInvoiceStepSerializer,
]

const poItemsTotal = (
    receivedItems: ReceiveFormData['receivedItems'],
    taxes: ReceiveFormData['taxes'],
): number => {
    const itemsTotal = receivedItems
        ? Object.keys(receivedItems).reduce((total, id) => {
              const item = receivedItems[id]
              if (item.price && item.quantity) {
                  return total + item.price * item.quantity
              }
              return total
          }, 0)
        : 0

    return itemsTotal + taxesTotal(taxes)
}
