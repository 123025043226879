import { type ReactNode, type FC } from 'react'

import RGL, { WidthProvider } from 'react-grid-layout'
import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { alpha, globalClassNames } from 'lib'
import { Box, Card, IconButton, StyledElement, Typography } from 'ui'

const GridLayout = WidthProvider(RGL)

interface RepositionInputProps {
    data: {
        id: Identifier
        position: ReactNode
        label: ReactNode
        ariaLabel: string
    }[]
    name: string
}
const RepositionInput: FC<RepositionInputProps> = ({ data, name }) => {
    const formContext = useFormContext()

    return (
        <Box
            display="flex"
            gap="12px"
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                {data.map(({ position, id }) => (
                    <PositionLabel
                        position={position}
                        key={id}
                    />
                ))}
            </Box>
            <Box
                sx={{
                    flexGrow: 1,
                    '& .react-grid-placeholder': {
                        backgroundColor: 'unset',
                        border: (theme) => '2px dashed ' + theme.palette.other.inputBorder,
                        borderRadius: '4px',
                    },
                }}
            >
                <GridLayout
                    containerPadding={[0, 0]}
                    className="layout"
                    margin={[20, 20]}
                    onLayoutChange={(layout) => {
                        formContext.setValue(
                            name,
                            [...layout].sort((a, b) => a.y - b.y).map((item) => item.i),
                        )
                    }}
                    draggableHandle=".draggable-area"
                    rowHeight={60}
                    cols={1}
                    isResizable={false}
                >
                    {data.map(({ label, ariaLabel, id }, i) => (
                        <div
                            key={id}
                            data-grid={{ i, x: 0, y: 0, w: 1, h: 1 }}
                        >
                            <RepositionElement
                                label={label}
                                ariaLabel={ariaLabel}
                            />
                        </div>
                    ))}
                </GridLayout>
            </Box>
        </Box>
    )
}

export default RepositionInput

export const PositionLabel: FC<{ position: ReactNode }> = ({ position }) => {
    return (
        <StyledElement
            sx={{
                height: elementHeight,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            }}
        >
            <Typography
                bgcolor={(theme) => alpha(theme.palette.text.primary, 0.12)}
                variant="chartTitle"
                color="text.primary"
                borderRadius="4px"
                p="6px 10px"
            >
                {position}
            </Typography>
        </StyledElement>
    )
}

const RepositionElement: FC<{ label: ReactNode; ariaLabel: string }> = ({ label, ariaLabel }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            gap="12px"
            height={elementHeight}
        >
            <Card
                sx={{
                    px: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                    minHeight: '100%',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    overflow="hidden"
                    className={globalClassNames.ellipsis}
                >
                    {label}
                </Box>
                <IconButton
                    size="small"
                    className="draggable-area"
                    aria-label={ariaLabel}
                >
                    <Icons.DragIndicatorOutlined
                        fontSize="inherit"
                        sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.54) }}
                    />
                </IconButton>
            </Card>
        </Box>
    )
}

const elementHeight = '60px'
