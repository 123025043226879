import Icons from 'assets/icons'
import { withColor, type Theme } from 'lib'
import { Typography, Spacer } from 'ui'

import { useDialogSelectorContext } from './DialogSelectorContext'

const DialogSelectorResultCount = () => {
    const { control } = useDialogSelectorContext()

    const { total } = control

    return (
        <Spacer ml="auto">
            <Icons.SearchOutlined
                sx={(theme: Theme) => ({
                    color: theme.palette.text.disabled,
                    width: '16px',
                })}
            />
            <Typography
                variant="tooltip"
                color={withColor('text.disabled')}
                textTransform="uppercase"
            >
                {total} Results
            </Typography>
        </Spacer>
    )
}

export default DialogSelectorResultCount
