import { type ReactNode } from 'react'

import { type DatumId, Pie, type PieTooltipProps } from '@nivo/pie'
import { TooltipWrapper } from '@nivo/tooltip'

import { type SvgIconElement } from 'appTypes'
import { formatMoney, formatPercent } from 'components'
import { type ThemeColorType, useTheme } from 'lib'
import {
    SvgIcon,
    type TypographyProps,
    Stack,
    ChartCenterContent,
    ChartContainer,
    Typography,
} from 'ui'

import { TooltipBox } from '../../WidgetTooltip'

const WidgetPieTooltipRow = (props: TypographyProps) => {
    if (!props.children?.toString().length) {
        return null
    }

    return (
        <Typography
            {...props}
            variant="tooltip"
            color="white"
        />
    )
}
interface WidgetPieTooltipProps<PieData extends defaultPieData>
    extends PieTooltipProps<PieData>,
        Pick<WidgetPieChartBaseProps<PieData>, 'noDataId' | 'renderContent'> {}

export const calculateWidgetValue = (total: number, value: number) =>
    formatPercent(total ? (value / total) * 100 : value)
const WidgetPieTooltip = <PieData extends defaultPieData>({
    datum,
    renderContent,
    noDataId,
}: WidgetPieTooltipProps<PieData>) => {
    const data = datum.data
    const { id, value, total } = data
    if (value <= 0 || !value) {
        return null
    }
    if (datum.id === noDataId) {
        return <></>
    }
    const currValue = calculateWidgetValue(total, value)
    return (
        <TooltipWrapper
            anchor="left"
            position={[0, 0]}
        >
            <TooltipBox>
                <Stack>
                    {renderContent ? (
                        renderContent(data).map((v, i) => (
                            <WidgetPieTooltipRow key={i}>{v}</WidgetPieTooltipRow>
                        ))
                    ) : (
                        <>
                            <WidgetPieTooltipRow>{`${id} ${currValue}`}</WidgetPieTooltipRow>
                            {total && (
                                <WidgetPieTooltipRow>{formatMoney(value)}</WidgetPieTooltipRow>
                            )}
                        </>
                    )}
                </Stack>
            </TooltipBox>
        </TooltipWrapper>
    )
}

export interface WidgetPieChartBaseProps<PieData extends defaultPieData> {
    data: PieData[]
    disabled?: boolean
    icon?: SvgIconElement
    noDataId?: string
    renderContent?: (params: PieData) => ReactNode[]
    sortByValue?: boolean
    iconColor?: ThemeColorType
    iconOpacity?: number
}

type defaultPieData = {
    id: DatumId
    color: string
    value: number
    total?: number
    formatedValue?: number
}

export type ExtendPieData<obj> = defaultPieData & obj

const WidgetPieChartBase = <PieData extends defaultPieData>({
    disabled = false,
    icon,
    data,
    renderContent,
    noDataId = 'Empty',
    sortByValue = true,
    iconColor,
    iconOpacity,
}: WidgetPieChartBaseProps<PieData>) => {
    const { palette } = useTheme()

    return (
        <ChartContainer>
            <Pie<PieData>
                colors={{ datum: 'data.color' }}
                width={100}
                height={100}
                data={
                    disabled
                        ? ([{ id: noDataId, value: 1, color: palette.charts.disable }] as PieData[])
                        : data
                }
                margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
                innerRadius={0.8}
                padAngle={0}
                cornerRadius={0}
                sortByValue={sortByValue}
                activeOuterRadiusOffset={disabled ? 4.5 : null}
                borderWidth={0}
                tooltip={(props) => (
                    <WidgetPieTooltip
                        {...props}
                        renderContent={renderContent}
                        noDataId={noDataId}
                    />
                )}
                enableArcLinkLabels={false}
                enableArcLabels={false}
            />
            {icon ? (
                <ChartCenterContent
                    display="flex"
                    alignItems="center"
                >
                    <SvgIcon
                        color="secondary"
                        component={icon}
                        sx={{
                            width: '30px',
                            height: '30px',
                            opacity: iconOpacity || '0.3',
                            color: iconColor,
                        }}
                    />
                </ChartCenterContent>
            ) : null}
        </ChartContainer>
    )
}
export default WidgetPieChartBase
