import { type ReactElement, type FC } from 'react'

import { UtilityDrawerAboveFooter } from 'components'
import { BoxContainer } from 'ui'

interface Props {
    totalContent: ReactElement
    text: string
}

const DrawerTotal: FC<Props> = ({ totalContent, text }) => {
    return (
        <UtilityDrawerAboveFooter>
            <BoxContainer justifyContent="space-between">
                <span>{text}</span> {totalContent}
            </BoxContainer>
        </UtilityDrawerAboveFooter>
    )
}

export default DrawerTotal
