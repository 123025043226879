import { type FC } from 'react'

import Icons from 'assets/icons'
import { formatPercent } from 'components'
import { themeColor, useTheme } from 'lib'
import { issueFields, IssueStatusKeys } from 'resources/issues'

import { WidgetPieChartBase } from '../../components'
import { type CriticalIssuesData } from '../types'

interface Props {
    data: CriticalIssuesData
}

const Chart: FC<Props> = ({ data }) => {
    const theme = useTheme()

    const openConfig = issueFields.status.getConfig(IssueStatusKeys.OPEN)
    const scheduledConfig = issueFields.status.getConfig(IssueStatusKeys.SCHEDULED)
    const inProgressConfig = issueFields.status.getConfig(IssueStatusKeys.IN_PROGRESS)

    return (
        <WidgetPieChartBase
            disabled={!data.totalCount}
            icon={Icons.ErrorOutline}
            iconColor={(theme) => theme.palette.charts.red}
            iconOpacity={1}
            sortByValue={false}
            renderContent={({ id, value }) => [id + ' ' + formatPercent(value)]}
            data={[
                {
                    id: openConfig.text as string,
                    value: data.openPercent,
                    color: themeColor(openConfig.color, theme),
                },
                {
                    id: scheduledConfig.text as string,
                    value: data.scheduledPercent,
                    color: themeColor(scheduledConfig.color, theme),
                },
                {
                    id: inProgressConfig.text as string,
                    value: data.inProgressPercent,
                    color: themeColor(inProgressConfig.color, theme),
                },
            ]}
        />
    )
}

export default Chart
