import { type FC } from 'react'

import { type ObjectAny } from 'appTypes'
import { Widget } from 'components'
import { urls } from 'configs'
import { emptyOptionValue } from 'core'
import { customerFields } from 'resources/customers'
import { issueFields, IssueStatusKeys } from 'resources/issues'

interface Props {
    data: Data
    stretch?: boolean
    extraFilters?: ObjectAny
}

const IssuesByStatusRows: FC<Props> = ({ data, stretch, extraFilters }) => {
    const pathBase = urls.issues + '?filter='

    const counts = {
        [IssueStatusKeys.OPEN]: data.openCount,
        [IssueStatusKeys.SCHEDULED]: data.scheduledCount,
        [IssueStatusKeys.IN_PROGRESS]: data.inProgressCount,
    }

    return (
        <Widget.Rows stretch={stretch}>
            {displayStatuses.map((item) => {
                const config = issueFields.status.getConfig(item)
                return (
                    <Widget.Row
                        key={item}
                        label={issueFields.status.value({ status: item })}
                        value={<Widget.Chip color={config.color}>{counts[item] || 0}</Widget.Chip>}
                        to={
                            pathBase +
                            encodeURIComponent(
                                JSON.stringify({
                                    ...extraFilters,
                                    [issueFields.status.source]: [item],
                                    [customerFields.self.source]: [emptyOptionValue],
                                }),
                            )
                        }
                    />
                )
            })}
        </Widget.Rows>
    )
}

export default IssuesByStatusRows

interface Data {
    inProgressCount: number
    openCount: number
    scheduledCount: number
}

const displayStatuses = [
    IssueStatusKeys.OPEN,
    IssueStatusKeys.SCHEDULED,
    IssueStatusKeys.IN_PROGRESS,
]
