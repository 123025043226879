import { type ReactNode, type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { formHelperTextHeight, styled } from 'lib'
import { Typography } from 'ui/text'

interface Props extends WithChildrenNode {
    title: ReactNode
    className?: string
    required?: boolean
}

const Outlined: FC<Props> = styled(({ title, children, className, required }: Props) => {
    return (
        <fieldset className={className}>
            <Typography
                component="legend"
                variant="inputText"
            >
                {title} {required && <span className="input-required">*</span>}
            </Typography>
            {children}
        </fieldset>
    )
})<Props>`
    min-width: 0;
    border: 1px solid ${({ theme }) => theme.palette.other.inputBorder};
    border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    padding: 8px 16px 20px;
    margin-bottom: ${formHelperTextHeight};

    & > legend {
        font-size: 12px;
        margin-left: -8px;
        padding: 0 5px;
    }

    .input-required {
        color: ${({ theme }) => theme.palette.error.main};
    }
`

export default Outlined
