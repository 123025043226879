import * as React from 'react'

import { FieldTitle } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'

import { InputAdornment, TextField, type TextFieldProps } from 'ui'

const TextInputBase = ({ label, helperText, required, InputProps, ...rest }: TextFieldProps) => {
    return (
        <TextField
            {...rest}
            label={
                label !== '' && label !== false ? (
                    <FieldTitle
                        label={label as any}
                        isRequired={required}
                    />
                ) : null
            }
            helperText={
                <InputHelperText
                    // touched={isTouched || isSubmitted}
                    // error={error?.message}
                    touched
                    helperText={helperText as any}
                />
            }
            InputProps={{
                ...InputProps,
                endAdornment: InputProps?.endAdornment ? (
                    <InputAdornment
                        position="end"
                        component="div"
                        disableTypography
                    >
                        {InputProps?.endAdornment}
                    </InputAdornment>
                ) : undefined,
                startAdornment: InputProps?.startAdornment ? (
                    <InputAdornment
                        position="start"
                        component="div"
                        disableTypography
                    >
                        {InputProps?.startAdornment}
                    </InputAdornment>
                ) : undefined,
            }}
        />
    )
}

export default TextInputBase
