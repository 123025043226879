import { useCreatePath } from 'ra-core'

import { type Identifier } from 'appTypes'
import { api, dispatchAction, NotificationMessage, updatedMessage, useNotify } from 'core'
import { invoicesResource } from 'resources/invoices'

import { WoStatusKeys, type WorkOrderModel } from '../../types'
import { woResource } from '../../utils'

import { woActionPaths } from './utils'

const useUpdateWoStatus = () => {
    const notify = useNotify()
    const createPath = useCreatePath()
    return async (id: Identifier, status: WoStatusKeys, data?: any) => {
        const action = woActionPaths[status]
        await api.post(woResource.updateStatus(id, action), data).then((wo: WorkOrderModel) => {
            if (action === woActionPaths[WoStatusKeys.CLOSED] && data?.invoiceOnClose) {
                notify({
                    title: (
                        <>
                            Work order status updated!
                            <br />
                            Successfully created invoice!
                        </>
                    ),
                    message: (
                        <NotificationMessage.Navigate
                            to={createPath({
                                resource: invoicesResource.resource,
                                type: 'edit',
                                id: wo.salesOrderData.invoiceData.id,
                            })}
                        >
                            {wo.salesOrderData.invoiceData.number}
                        </NotificationMessage.Navigate>
                    ),
                })
            } else {
                notify(updatedMessage)
            }
        })
        dispatchAction(woResource.name, {
            name: 'statusChange',
        })
    }
}

export default useUpdateWoStatus
