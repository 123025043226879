import { type ReactElement, type FC, type ReactNode } from 'react'

import { inject, observer } from 'mobx-react'

import { type WithChildrenNode } from 'appTypes'
import { UtilityDrawerProvider } from 'components'
import { FormInfo } from 'context'
import {
    InputStateContextProvider,
    CompanyStateUpdate,
    SyncProvider,
    ResourceDataContextProvider,
    type AuthStore,
    RenderAwayPlaceholder,
} from 'core'
import { ResetCache } from 'core/store'
import { GoogleMapProvider } from 'lib'
import { TelematicsContextProvider } from 'resources/telematics'

const GlobalProvidersBase: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <>
            <CompanyStateUpdate />
            <ResetCache />
            <SyncProvider>
                <FormInfo>
                    <ResourceDataContextProvider>
                        <UtilityDrawerProvider>
                            <TelematicsContextProvider>
                                <InputStateContextProvider value={null}>
                                    <RenderAwayPlaceholder />
                                    <GoogleMapProvider>{children}</GoogleMapProvider>
                                </InputStateContextProvider>
                            </TelematicsContextProvider>
                        </UtilityDrawerProvider>
                    </ResourceDataContextProvider>
                </FormInfo>
            </SyncProvider>
        </>
    )
}

const GlobalProviders: FC<WithChildrenNode> = inject('auth')(
    observer(({ auth, children }: { auth: AuthStore; children: ReactElement }) => {
        if (auth.user) {
            return <GlobalProvidersBase>{children}</GlobalProvidersBase>
        }

        return children
    }),
)

export default GlobalProviders
