import { useQuery, type UseQueryOptions, type UseQueryResult, useQueryClient } from 'react-query'

import { type DataRecord } from 'appTypes'
import { useDataProvider } from 'core/data'
import { type PaginationPayload, type SortPayload } from 'core/types'

export const useGetList = <RecordType extends DataRecord = any>(
    resource: string,
    params: Partial<GetListParams> = {},
    options?: UseQueryOptions<GetListResult<RecordType>, Error>,
    { noGetOneCache }: { noGetOneCache?: boolean } = {},
): UseGetListHookValue<RecordType> => {
    const {
        pagination = { page: 1, perPage: 25 },
        sort = { field: 'id', order: 'DESC' },
        filter = {},
        meta,
    } = params
    const dataProvider = useDataProvider()
    const queryClient = useQueryClient()

    const queryKey: GetListQueryKey = [resource, 'getList', { pagination, sort, filter, meta }]

    const query = useQuery<GetListResult<RecordType>, Error, GetListResult<RecordType>>(
        queryKey,
        () =>
            dataProvider
                .getList<RecordType>(resource, {
                    pagination,
                    sort,
                    filter,
                    meta,
                })
                .then(({ data, total, pageInfo }) => ({
                    data,
                    total,
                    pageInfo,
                })),
        {
            ...options,
            onSuccess: (value) => {
                if (!noGetOneCache && value?.data) {
                    const { data } = value
                    // optimistically populate the getOne cache
                    data.forEach((record) => {
                        queryClient.setQueryData(
                            [resource, 'getOne', { id: String(record.id), meta }],
                            (oldRecord) => oldRecord ?? record,
                        )
                    })
                }
                // execute call-time onSuccess if provided
                if (options?.onSuccess) {
                    options.onSuccess(value)
                }
            },
        },
    )

    return {
        data: (query.data?.data ?? []) as RecordType[],
        total: query.data?.total,
        query,
        queryKey,
    }
}

export type UseGetListHookValue<RecordType extends DataRecord = any> = {
    query: UseQueryResult<GetListResult<RecordType>, Error>
    data: RecordType[]
    total?: number
    queryKey: GetListQueryKey
}

export type GetListQueryKey = [string, 'getList', GetListParams]

export interface GetListResult<RecordType extends DataRecord = any> {
    data: RecordType[]
    total?: number
}

export interface GetListParams {
    pagination: PaginationPayload
    sort: SortPayload
    filter: any
    meta?: any
    getOneCache?: boolean
}
