import { useCallback } from 'react'

import {
    ReferenceArrayInput,
    AutocompleteArrayInput,
    type AutocompleteArrayInputProps,
    useListContext,
} from 'react-admin'

import { type Identifier, type DataRecord } from 'appTypes'
import { emptyOptionValue } from 'core/data'
import { useResourceContext, getFilterReference } from 'core/resource'
import { Chip, formHelperTextClasses } from 'ui'

import { type FilterRenderProps } from '../../types'

interface FilterValueInputProps extends FilterRenderProps {
    makeItemLabel?: (item: DataRecord) => any
    itemReference?: string
    choices?: { id: Identifier; name?: string }[]
}

export const FilterValueInput = ({
    filterName,
    valueSource,
    makeItemLabel,
    itemReference,
    choices,
}: FilterValueInputProps) => {
    const resource = useResourceContext()

    const { filter } = useListContext()

    const optionLabel = useCallback(
        (option: DataRecord) => {
            if (option.id === emptyOptionValue) {
                return 'No Value'
            }
            return makeItemLabel ? makeItemLabel(option) : option.name || option.id
        },
        [makeItemLabel],
    )

    let autocompleteInput = (
        <AutocompleteArrayInput
            debounce={0}
            source={valueSource}
            label="Value"
            variant="outlined"
            sx={{
                [`& .${formHelperTextClasses.root}`]: {
                    display: 'none',
                },
            }}
            size="medium"
            choices={choices}
            componentsProps={{
                paper: {
                    elevation: 8,
                },
            }}
            filterToQuery={(filterText) => {
                return {
                    name: filterName,
                    text: filterText,
                }
            }}
            matchSuggestion={choices ? clientMatch : serverMatch}
            renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                    <Chip
                        sx={{
                            backgroundColor: (theme) => theme.palette.grey[50],
                        }}
                        label={optionLabel(option)}
                        {...getTagProps({ index })}
                    />
                ))
            }
            optionText={optionLabel}
            inputText={inputText}
        />
    )
    if (!choices && filterName) {
        const reference = getFilterReference(resource, filterName, itemReference)

        autocompleteInput = (
            <ReferenceArrayInput
                source={valueSource}
                reference={reference}
                filter={{ ...filter, name: filterName }}
            >
                {autocompleteInput}
            </ReferenceArrayInput>
        )
    }

    return autocompleteInput
}

const clientMatch = (filter, choice) => {
    return choice.name?.toLowerCase().includes(filter?.toLowerCase())
}

const inputText: AutocompleteArrayInputProps['inputText'] = (option) => option.id

const serverMatch = () => true
