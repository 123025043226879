import { type FC } from 'react'

import { ActionsMenu, useCanAccess } from 'core'
import {
    BaseStatusContent,
    BaseStatusIcon,
    defaultStatuses,
    options,
    type StatusConfig,
} from 'resources/common'

import { useWoStatusActions, woOperations } from '../actions'
import { type WorkOrderModel, WoStatusKeys } from '../types'

const source = 'status' as const satisfies keyof WorkOrderModel

export const items = [
    { ...defaultStatuses.open, id: WoStatusKeys.OPEN },
    { ...defaultStatuses.pending, id: WoStatusKeys.PENDING },
    { ...defaultStatuses.completed, id: WoStatusKeys.COMPLETED },
    { ...defaultStatuses.closed, id: WoStatusKeys.CLOSED },
    { ...defaultStatuses.canceled, id: WoStatusKeys.CANCELED },
]

const getConfig = options.createConfig<StatusConfig<WoStatusKeys>>(items)

const choices = options.createIdChoices(items)

const getMenuValues = (status: WoStatusKeys) =>
    items.filter(({ id }) => woStatusesChangeConfig[status].includes(id))
const woStatusesChangeConfig: { [key in WoStatusKeys]?: WoStatusKeys[] } = {
    [WoStatusKeys.PENDING]: [WoStatusKeys.OPEN, WoStatusKeys.CANCELED],
    [WoStatusKeys.OPEN]: [
        WoStatusKeys.PENDING,
        WoStatusKeys.COMPLETED,
        WoStatusKeys.CLOSED,
        WoStatusKeys.CANCELED,
    ],
    [WoStatusKeys.CLOSED]: [WoStatusKeys.OPEN],
    [WoStatusKeys.COMPLETED]: [WoStatusKeys.OPEN, WoStatusKeys.CLOSED],
}

export const value = (status: WoStatusKeys, withMenu?: boolean) => {
    const config = getConfig(status)

    if (withMenu && status !== WoStatusKeys.CANCELED) {
        return <StatusMenu config={config} />
    }

    return <BaseStatusContent color={config.color}>{config.text}</BaseStatusContent>
}

const StatusMenu: FC<{ config: StatusConfig<WoStatusKeys> }> = ({ config }) => {
    const statusAction = useWoStatusActions()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.changeStatus)
    return (
        <ActionsMenu
            renderToggler={(open, isOpen) => (
                <BaseStatusContent
                    isOpen={isOpen}
                    onClick={open}
                    color={config.color}
                    disabled={!canAccess.value}
                    titleOnDisabled={canAccess.message}
                >
                    {config.text}
                </BaseStatusContent>
            )}
            actions={(params, { children }) => {
                return getMenuValues(config.id).map((status) => {
                    return children({
                        title: status.text,
                        Icon: <BaseStatusIcon color={status.color} />,
                        key: status.id,
                        onClick: () => statusAction(status.id),
                    })
                })
            }}
        />
    )
}
const status = {
    source,
    label: 'Status',
    getConfig,
    value,
    choices,
    inputValue: options.makeIdValue(value),
}

export default status
