import { type FC, type ReactElement } from 'react'

import { inject, observer } from 'mobx-react'

import Icons from 'assets/icons'
import { DistanceLabel, PeriodSelector, periodSelectorCustomChoice } from 'components'
import { type AuthStore, ActionsMenu, authStore } from 'core'
import { type Breakpoint, useFlags, type Flags } from 'lib'
import { Box, Button, Divider, FormControlLabel, Switch, Stack, Typography, BoxContainer } from 'ui'

import GridLayout, { GridLayoutItem } from '../GridLayout'
import WidgetsForm from '../WidgetsForm'
import 'react-grid-layout/css/styles.css'
import { useWidgetLayoutContext } from '../WidgetsLayoutContext'

import ComplianceRateWidget from './ComplianceRateWidget'
import CostPerCategoryWidget from './CostPerCategoryWidget'
import CostPerEquipmentWidget from './CostPerEquipment'
import CostPerRepairReasonWidget from './CostPerRepairReasonWidget'
import CostPerUnitWidget from './CostPerUnitWidget'
import { CostPerVMRSWidget } from './CostPerVMRS'
import DowntimeWidget from './DowntimeWidget'
import FleetCostPerDistanceWidget from './FleetCostPerDistanceWidget'
import FleetCostPerHourWidget from './FleetCostPerHourWidget'
import { CriticalIssuesWidget, IssuesByPriorityWidget, IssuesByStatusWidget } from './Issues'
import MTBFWidget from './MTBF'
import URMEWidget from './URME'
import { UnitStatusesWidget } from './UnitStatusesWidget'
import VendorExpensesWidget from './VendorExpensesWidget'

const filterBreakpoint: Breakpoint = 'sm'

export type WidgetListType = {
    id: string
    name: string | ReactElement
    component: ReactElement
    hide?: (config: { flags: Flags; auth: AuthStore }) => boolean
}[]

export const WidgetList: WidgetListType = [
    { id: 'cost-per-unit', name: 'Maintenance Cost', component: <CostPerUnitWidget /> },
    {
        id: 'cost-per-distance',
        name: (
            <>
                Maintenance CP
                <DistanceLabel
                    variant="short-abbr"
                    textCase="upper"
                />
            </>
        ),
        component: <FleetCostPerDistanceWidget />,
    },
    { id: 'downtime', name: 'Downtime by Repair Class', component: <DowntimeWidget /> },
    {
        id: 'cost-per-category',
        name: 'Top 3 Categories Account for',
        component: <CostPerCategoryWidget />,
    },
    {
        id: 'cost-per-vmrs',
        name: 'Top 3 VMRS Groups Account for',
        component: <CostPerVMRSWidget />,
        hide: ({ auth }) =>
            !(auth.companySettings.ck33GroupsEnabled && auth.companySettings.ck33GroupsSelectable),
    },
    {
        id: 'cost-per-hour',
        name: 'Cost per Engine Hour',
        component: <FleetCostPerHourWidget />,
    },
    {
        id: 'cost-per-vendor',
        name: 'Total Vendor Expenses',
        component: <VendorExpensesWidget />,
    },
    {
        id: 'cost-per-repair',
        name: 'Top 3 Reasons for Repair Account for',
        component: <CostPerRepairReasonWidget />,
    },
    {
        id: 'cost-per-equipment',
        name: 'Cost per Equipment Categories',
        component: <CostPerEquipmentWidget />,
    },
    {
        id: 'compliance-rate',
        name: 'Preventive Maintenance Compliance Rate',
        component: <ComplianceRateWidget />,
    },
    {
        id: 'mtbf',
        name: 'Mean Time Between Failures',
        component: <MTBFWidget />,
    },
    {
        id: 'urme',
        name: 'Unscheduled Roadside Maintenance Event',
        component: <URMEWidget />,
    },
    {
        id: 'unit-statuses',
        name: 'Unit Statuses',
        component: <UnitStatusesWidget />,
        hide: ({ auth }) => !auth.companySettings.hasUnitStatuses,
    },
    {
        id: 'critical-issues',
        name: 'Critical Issues',
        component: <CriticalIssuesWidget />,
    },
    {
        id: 'issues-by-priority',
        name: 'Issues By Priority',
        component: <IssuesByPriorityWidget />,
    },
    {
        id: 'issues-by-status',
        name: 'Issues By Status',
        component: <IssuesByStatusWidget />,
    },
]

const ManageWidgetsButton: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const { mutate, visibleWidgets, reset } = useWidgetLayoutContext()
        const flags = useFlags()

        const widgets = WidgetList.filter((widget) => !widget.hide?.({ flags, auth }))

        const toggleColumn = (event) => {
            const name = event.target.name
            mutate(name)
        }
        return (
            <ActionsMenu
                actions={() => [
                    <Box
                        paddingX="15px"
                        paddingY="5"
                        key="0"
                    >
                        <BoxContainer>
                            <Typography
                                variant="subtitle1"
                                color="text.secondary"
                                mb="12px"
                            >
                                Current Arrangement
                            </Typography>
                            <Divider variant="inset" />
                        </BoxContainer>
                        <Stack gap="5px">
                            {widgets.map((widget) => {
                                return (
                                    <FormControlLabel
                                        key={widget.id}
                                        label={widget.name}
                                        control={
                                            <Switch
                                                checked={visibleWidgets[widget.id]}
                                                onChange={toggleColumn}
                                                name={widget.id}
                                                size="small"
                                            />
                                        }
                                    />
                                )
                            })}
                        </Stack>
                        <BoxContainer mt="15px">
                            <Button
                                size="small"
                                startIcon={<Icons.RefreshOutlined />}
                                onClick={reset}
                            >
                                Reset
                            </Button>
                        </BoxContainer>
                    </Box>,
                ]}
                renderToggler={(open) => (
                    <Button
                        sx={(theme) => ({
                            color: theme.palette.text.secondary,
                            gap: '4px',
                        })}
                        onClick={open}
                    >
                        <Icons.WidgetsOutlined fontSize="small" />
                        Manage
                    </Button>
                )}
            />
        )
    }),
)

const Widgets: FC = () => {
    const { widgets } = useWidgetLayoutContext()
    return (
        <WidgetsForm>
            <Box
                mb={{
                    xs: '20px',
                    [filterBreakpoint]: '16px',
                }}
                display="flex"
                justifyContent="space-between"
                flexDirection={{ xs: 'column', [filterBreakpoint]: 'row' }}
                alignItems={{ [filterBreakpoint]: 'center' }}
            >
                <BoxContainer
                    sx={{
                        flex: '1',
                        justifyContent: 'space-between',
                    }}
                    mb={{ xs: '12px', [filterBreakpoint]: '0' }}
                >
                    <Typography
                        sx={{
                            typography: {
                                xs: 'subtitle1',
                                [filterBreakpoint]: 'h6',
                            },
                        }}
                    >
                        Widgets
                    </Typography>
                    <ManageWidgetsButton />
                </BoxContainer>

                <div>
                    <PeriodSelector
                        onChange={({ date, from, to }) => {
                            authStore.preferences.dashboard.period =
                                date === periodSelectorCustomChoice
                                    ? { date, start: from, end: to }
                                    : { date }
                            authStore.syncPreferences()
                        }}
                    />
                </div>
            </Box>
            <GridLayout>
                {widgets.map((widget) => (
                    <GridLayoutItem
                        key={widget.id}
                        id={widget.id}
                        className="grid-item-handle"
                    >
                        {widget.component}
                    </GridLayoutItem>
                ))}
            </GridLayout>
        </WidgetsForm>
    )
}

export default Widgets
