import { type FC } from 'react'

import Icons from 'assets/icons'
import {
    costMaskParams,
    costMaskParamsDiscount,
    type StepConfig,
    TextInput,
    type TextInputProps,
} from 'components'
import { serialize } from 'core'
import { type Taxes } from 'resources/common'
import { SvgIcon, Stack, DataAvatar, Typography, BoxContainer, BoxedSection } from 'ui'

interface FeeProps {
    icon: FC
    title: string
    source: string
    inputProps?: Partial<TextInputProps>
}

const taxesName = 'taxes'

const Fee = ({ icon, title, source, inputProps }: FeeProps) => {
    return (
        <BoxedSection gap="16px">
            <BoxContainer gap="12px">
                <DataAvatar defaultImage={<SvgIcon component={icon} />} />
                <Typography
                    variant="h6"
                    lineHeight="20px"
                >
                    {title}
                </Typography>
            </BoxContainer>
            <TextInput
                label="Amount"
                source={taxesName + '.' + source}
                {...costMaskParams}
                {...inputProps}
            />
        </BoxedSection>
    )
}

const PoAdditionalFees = () => {
    return (
        <>
            <Typography
                variant="subtitle1"
                component={BoxContainer}
                gap="8px"
                mb="20px"
            >
                Additional Fees (Optional)
            </Typography>

            <Stack spacing="12px">
                <Fee
                    title="Shipping"
                    icon={Icons.Shipping}
                    source="shippingTax"
                />
                <Fee
                    title="Miscellaneous"
                    icon={Icons.Add}
                    source="miscellaneousTax"
                />
                <Fee
                    title="Discount"
                    icon={Icons.PercentOutlined}
                    source="discount"
                    inputProps={costMaskParamsDiscount}
                />
                <Fee
                    title="Sales Tax"
                    icon={Icons.AttachMoney}
                    source="salesTax"
                />
            </Stack>
        </>
    )
}

export const poFeesStep = (): StepConfig => ({
    title: 'Additional Fees',
    Content: PoAdditionalFees,
})

export const poFeesStepSerializer = (data: { taxes: Taxes }) => {
    return {
        requestData: {
            taxes: serialize(data.taxes, [
                { name: 'shippingTax', parse: 'number' },
                { name: 'miscellaneousTax', parse: 'number' },
                { name: 'discount', parse: 'number' },
                { name: 'salesTax', parse: 'number' },
            ]),
        },
    }
}
