import { type FC } from 'react'

import { SelectInput } from 'components'
import { ReferenceInput } from 'core'

import { uomResource } from '../utils'

interface Props {
    disabled?: boolean
    source?: string
}

export const UOMInput: FC<Props> = ({ disabled, source = 'unitOfMeasure' }) => {
    return (
        <ReferenceInput
            reference={uomResource.resource}
            disabled={disabled}
            source={source}
        >
            <SelectInput
                label="UOM"
                source="unitOfMeasure"
                disableEmptyValue
                disabled={disabled}
            />
        </ReferenceInput>
    )
}
