import { type FC } from 'react'

import { useRecordContext } from 'core'
import WorkOrderSimpleList from 'pages/WorkOrders/SimpleList'
import { ContactsSection } from 'resources/contacts'
import { type VendorModel } from 'resources/vendors'
import { WoStatusKeys } from 'resources/workOrders'
import { PageContent, FlexReverse } from 'ui'

import { vendorContactsResourceName } from '../constants/config'

import VendorDetails from './VendorDetails'
import VendorTopSection from './VendorTopSection'

const VendorOverview: FC = () => {
    const record = useRecordContext<VendorModel>()

    return (
        <>
            <VendorTopSection />
            <PageContent>
                <FlexReverse>
                    <VendorDetails />
                    <ContactsSection resourceName={vendorContactsResourceName} />
                    {(record?.type.length > 1 || record?.type[0] === 'REPAIR_SERVICES') && (
                        <WorkOrderSimpleList<VendorModel>
                            workOrderType="vendor"
                            createFormInitialValues={(record) => ({
                                vendor: record?.id,
                            })}
                            filter={(record) => ({
                                vendor: [record?.name],
                            })}
                            values={{
                                status: WoStatusKeys.OPEN,
                                vendorWorkOrderSwitch: true,
                            }}
                        />
                    )}
                </FlexReverse>
            </PageContent>
        </>
    )
}

export default VendorOverview
