import { type FC } from 'react'

import { observer } from 'mobx-react'

import {
    CollapsibleInfoCard,
    InfoCardDetails,
    InfoCardHeader,
    SkeletonSliderCardContainer,
} from 'components'
import { useRecordContext } from 'core'
import {
    AttachmentsCollapsibleContent,
    AttachmentsCollapsibleIconHolder,
} from 'resources/attachments'
import { customerFields } from 'resources/customers'
import { issueFields, issuesResource, IssueStatusKeys, type IssueModel } from 'resources/issues'
import { ck14Fields, ck18Fields, ck33Fields } from 'resources/vmrs'
import { woFields } from 'resources/workOrders'
import { Divider, Skeleton } from 'ui'
const DetailsCard: FC = observer(() => {
    const record = useRecordContext<IssueModel>()

    return (
        <CollapsibleInfoCard>
            <InfoCardHeader title="Basic Details" />
            <InfoCardDetails<IssueModel>
                details={[
                    {
                        label: issueFields.priority.label,
                        source: (record) => issueFields.priority.value(record),
                    },
                    {
                        label: issueFields.reportedDate.label,
                        source: (record) => issueFields.reportedDate.value(record),
                    },
                    {
                        label: 'Component',
                        source: (record) => ck33Fields.self.value(record.componentData),
                    },
                    {
                        label: ck14Fields.self.label,
                        source: (record) => ck14Fields.self.value(record),
                    },
                    {
                        label: ck18Fields.self.label,
                        source: (record) => ck18Fields.self.value(record),
                    },
                    {
                        label: issueFields.description.label,
                        source: (record) => issueFields.description.value(record),
                    },
                    {
                        label: woFields.self.label,
                        source: (record) => woFields.self.linkValue(record.workOrderData),
                    },
                    {
                        label: customerFields.self.label,
                        source: (record) => customerFields.self.value(record.unitData.customerData),
                    },
                ]}
            />
            <Divider
                sx={{
                    margin: '10px 0px',
                }}
            />
            <AttachmentsCollapsibleIconHolder />
            <AttachmentsCollapsibleContent
                disabled={record.status !== IssueStatusKeys.OPEN}
                uploadResource={issuesResource.resource}
                titleOnDisabled="Documents can be added to open issues only"
            />
        </CollapsibleInfoCard>
    )
})

export default DetailsCard

export const detailsCardSkeleton = (
    <SkeletonSliderCardContainer
        sx={{
            height: '272px',
            gap: '10px',
        }}
    >
        <Skeleton
            height="8px"
            width="120px"
            sx={{
                my: '20px',
            }}
        />
        <Skeleton />
    </SkeletonSliderCardContainer>
)
