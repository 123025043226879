import { MonitorW } from 'components'
import { api, useRecordContext } from 'core'
import { useQuery } from 'lib'
import { SliderWidgetSkeleton } from 'pages/Dashboard/components/WidgetBase'
import {
    type IssuesByPriority,
    issuesByPriorityEndpoint,
    IssuesByPriorityRows,
} from 'resources/issues'
import { type UnitModel } from 'resources/units'

export const UnitIssuesWidget = () => {
    const record = useRecordContext<UnitModel>()
    const query = useQuery<IssuesByPriority>(
        ['issues-by-priority'],
        () =>
            api.get(issuesByPriorityEndpoint, {
                unit: record?.id,
            }),
        {
            enabled: Boolean(record?.id),
        },
    )

    const data = query.data

    if (!data) {
        return <SliderWidgetSkeleton />
    }

    return (
        <MonitorW>
            <MonitorW.Title mb="10px">Issues By Priority</MonitorW.Title>
            <MonitorW.Body scrollable>
                <IssuesByPriorityRows data={data} />
            </MonitorW.Body>
        </MonitorW>
    )
}
