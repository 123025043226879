import { useListContext } from 'ra-core'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'

import { type ActionChildren, type ListActionExtendedOption } from './Actions'

interface MultiselectActionProps {
    id: Identifier
    children: ActionChildren<{}, ListActionExtendedOption>
    disabled?: boolean
}

export const MultiselectAction = ({ children, id, disabled }: MultiselectActionProps) => {
    const listContext = useListContext()

    return children(
        {
            disabled,
            title: 'Multiselect',
            Icon: Icons.FilterNone,
            onClick: () => {
                listContext.onToggleItem(id)
            },
        },
        {
            listContext,
        },
    )
}

export const multiselectAction = (params: MultiselectActionProps) => (
    <MultiselectAction
        {...params}
        key="multiselect"
    />
)
