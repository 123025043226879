import Icons from 'assets/icons'
import { type Column, type DataListDetails, type SortBy } from 'components'
import { IconElement, TextWithIcon } from 'ui'

import { type InventoryHistoryModel } from '../../types'

const source = 'quantityAdjustment' as const satisfies keyof InventoryHistoryModel
const label = 'Adjustment'

const getAdjustmentIcon = (quantityAdjustment: number) => {
    if (quantityAdjustment > 0) {
        return (
            <IconElement
                component={Icons.Add}
                color={(theme) => theme.palette.success.main}
            />
        )
    }
    if (quantityAdjustment < 0) {
        return (
            <IconElement
                component={Icons.RemoveOutlined}
                color={(theme) => theme.palette.charts.red}
            />
        )
    }
    return null
}

const value = (record: InventoryHistoryModel) => {
    if (!record.quantityAdjustment) {
        return null
    }

    return (
        <TextWithIcon icon={getAdjustmentIcon(record.quantityAdjustment)}>
            {Math.abs(record.quantityAdjustment)}
        </TextWithIcon>
    )
}

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const dataCardRow = () =>
    ({
        source,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

export const quantityAdjustment = {
    value,
    tableColumn,
    dataCardRow,
    sort,
}
