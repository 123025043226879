import { RecordContextProvider, useShowContext } from 'ra-core'

import {
    InfoCard,
    InfoCardDetails,
    Slider,
    ViewHeader,
    SkeletonSliderCardContainer,
    DistanceLabel,
} from 'components'
import api from 'core/api'
import { useTheme, useQuery, formatDate } from 'lib'
import { DVIRStatus, type DVIRModel, formatDvirInspectionType } from 'resources/dvir'
import { type UnitModel } from 'resources/units'
import { Section, Skeleton } from 'ui'

const LastSectionCard1 = () => {
    return (
        <InfoCard>
            <InfoCardDetails<DVIRModel>
                details={[
                    {
                        label: 'Status',
                        source: ({ status }) => {
                            return <DVIRStatus status={status} />
                        },
                    },
                    {
                        label: 'Date',
                        source: ({ timestamp }) => formatDate(timestamp, 'MMM dd yyyy hh:mm a'),
                    },
                    {
                        label: 'Inspection Type',
                        source: ({ type }) => {
                            return formatDvirInspectionType(type)
                        },
                    },
                ]}
            />
        </InfoCard>
    )
}

const LastSectionCard2 = () => {
    return (
        <InfoCard>
            <InfoCardDetails<DVIRModel>
                details={[
                    {
                        label: 'Author Name',
                        source: 'authorName',
                    },
                    {
                        label: 'Odometer',
                        source: ({ odometer }) =>
                            odometer ? <DistanceLabel value={odometer} /> : null,
                    },
                    {
                        label: 'Location (Note)',
                        source: 'location',
                    },
                ]}
            />
        </InfoCard>
    )
}

const skeletonSlides = new Array(2).fill(0).map((v, i) => (
    <SkeletonSliderCardContainer sx={{ height: '119px' }}>
        <Skeleton key={i} />
    </SkeletonSliderCardContainer>
))
const UnitDvirLastSection = () => {
    const { record, resource } = useShowContext<UnitModel>()
    const theme = useTheme()
    const { data, isLoading } = useQuery<DVIRModel>(['last_dvir'], async () => {
        const res = await api.get(`${resource}/${record.id}/last_dvir`)
        return res
    })
    const slides = isLoading ? skeletonSlides : [<LastSectionCard1 />, <LastSectionCard2 />]

    return (
        <Section>
            <ViewHeader
                title="Last DVIR"
                loading={isLoading}
            />
            <RecordContextProvider value={data}>
                <Slider
                    items={slides}
                    slidesToShow={4}
                    responsive={[
                        {
                            breakpoint: theme.breakpoints.values.lg,
                            settings: {
                                slidesToShow: 3,
                            },
                        },
                        {
                            breakpoint: theme.breakpoints.values.md,
                            settings: {
                                slidesToShow: 2,
                            },
                        },
                        {
                            breakpoint: theme.breakpoints.values.sm,
                            settings: {
                                arrows: false,
                                slidesToShow: 1.09,
                                swipe: true,
                            },
                        },
                    ]}
                />
            </RecordContextProvider>
        </Section>
    )
}
export default UnitDvirLastSection
