import { useWatch } from 'react-hook-form'

import { type WoKeys } from 'resources/workOrders'

import { CustomerReadOnlyInput } from './CustomerInput'

export const CustomerPopUpInput = () => {
    const customer = useWatch({ name: 'unitData.customer' satisfies WoKeys })

    if (!customer) {
        return null
    }

    return <CustomerReadOnlyInput source={'unitData.customerData' satisfies WoKeys} />
}
