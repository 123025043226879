import { useRef } from 'react'

import { ListContextProvider, type SaveButtonProps } from 'react-admin'

import {
    UtilityDrawer as UtilityDrawerBase,
    type UtilityDrawerProps as UtilityDrawerBaseProps,
} from 'components/UtilityDrawer'
import { FormActionProvider } from 'context'
import { RecordContextProvider } from 'core/data'
import { RaResourceContextProvider } from 'core/resource'
import { ErrorBoundary, withErrorBoundary } from 'lib'

import UtilityDrawerCancelButton, {
    type UtilityDrawerCancelButtonProps,
} from './UtilityDrawerCancelButton'
import { UtilityDrawerContext } from './UtilityDrawerContext'
import UtilityDrawerDeleteButton, {
    type UtilityDrawerDeleteButtonProps,
} from './UtilityDrawerDeleteButton'
import UtilityDrawerSaveButton from './UtilityDrawerSaveButton'
import { type UtilityDrawerContextValue, type BlockContext, type DrawerState } from './types'

const renderEmptyContent: UtilityDrawerBaseProps['renderContent'] = () => null
const renderEmptyWrapper: UtilityDrawerBaseProps['renderWrapper'] = () => <></>

const _renderBottomLeft = (params?: UtilityDrawerCancelButtonProps) => (
    <UtilityDrawerCancelButton {...params} />
)
const _renderBottomRight = (params?: SaveButtonProps) => <UtilityDrawerSaveButton {...params} />
const _renderBottomRightEmpty = () => null
const _renderTopRight = (params?: UtilityDrawerDeleteButtonProps) => (
    <UtilityDrawerDeleteButton {...params} />
)

export interface UseUtilityDrawerSetters
    extends Pick<UtilityDrawerContextValue, 'close' | 'controller' | 'forceClose'> {}

const defaultBlocker = () => {
    /* */
}

interface DrawerProps {
    close: () => void
    isOpen: boolean
    state: DrawerState
    updateState: UtilityDrawerContextValue['updateState']
}

const UtilityDrawer = ({ state, close, isOpen, updateState }: DrawerProps) => {
    const blocked = useRef<(((ctx: BlockContext) => void) | null)[]>([])
    const drawerRef = useRef<HTMLDivElement>()

    const blockedClose = () => {
        if (blocked.current.length) {
            blocked.current.forEach((blocked) =>
                blocked({
                    retry: () => close(),
                }),
            )
            return
        }
        close()
    }

    const block = (cb: (ctx: BlockContext) => void = defaultBlocker) => {
        blocked.current.push(cb)
        return () => (blocked.current = blocked.current.filter((blocked) => blocked !== cb))
    }

    const { drawerArgs = {}, extraArgs = {}, extra = {} } = state

    const {
        renderBottomLeft,
        renderBottomRight,
        renderTopRight,
        renderContent = renderEmptyContent,
        renderWrapper = renderEmptyWrapper,
        ...restDrawerArgs
    } = drawerArgs

    return (
        <UtilityDrawerBase
            drawerRef={drawerRef}
            onForceClose={close}
            onClose={blockedClose}
            renderWrapper={(params) => {
                let content = renderWrapper(params)
                content = extraArgs.recordContext ? (
                    <RecordContextProvider value={extraArgs.recordContext}>
                        {content}
                    </RecordContextProvider>
                ) : (
                    content
                )
                content = extraArgs.listContext ? (
                    <ListContextProvider value={extraArgs.listContext}>
                        {content}
                    </ListContextProvider>
                ) : (
                    content
                )
                content = extraArgs.resource ? (
                    <RaResourceContextProvider value={extraArgs.resource}>
                        {content}
                    </RaResourceContextProvider>
                ) : (
                    content
                )
                content = extraArgs.formActions ? (
                    <FormActionProvider value={extraArgs.formActions}>{content}</FormActionProvider>
                ) : (
                    content
                )

                return (
                    <UtilityDrawerContext.Provider
                        value={{
                            close: blockedClose,
                            forceClose: close,
                            state,
                            extraArgs,
                            extra,
                            controller: {
                                block,
                            },
                            updateState,
                            scrollTop: () => {
                                if (!drawerRef.current) {
                                    return
                                }
                                const content = drawerRef.current.querySelector('.content')
                                if (!content) {
                                    return
                                }
                                content.scrollTop = 0
                            },
                        }}
                    >
                        <ErrorBoundary>{content}</ErrorBoundary>
                    </UtilityDrawerContext.Provider>
                )
            }}
            anchorBottomHeightSize="big"
            title=""
            renderContent={renderContent}
            open={isOpen}
            setOpen={(open) => (open ? undefined : blockedClose())}
            renderBottomLeft={
                renderBottomLeft ? () => renderBottomLeft(_renderBottomLeft) : _renderBottomLeft
            }
            renderBottomRight={
                renderBottomRight === null
                    ? _renderBottomRightEmpty
                    : renderBottomRight
                      ? () => renderBottomRight(_renderBottomRight)
                      : _renderBottomRight
            }
            renderTopRight={renderTopRight ? () => renderTopRight(_renderTopRight) : () => null}
            {...restDrawerArgs}
        />
    )
}

export default withErrorBoundary(UtilityDrawer, {})
