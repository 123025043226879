import { type FC, type ReactNode } from 'react'

import { useWatch } from 'react-hook-form'

import {
    type PeriodFilter,
    Form,
    type PeriodSelectorFormValues,
    periodSelectorCustomChoice,
} from 'components'
import { authStore } from 'core'
import api from 'core/api'
import { useQuery, type FlagName, useFlags, DateRangeKeys, getDateRangeDates } from 'lib'

const defaultDate = DateRangeKeys.LAST_30_DAYS

interface useDashboardWidgetParms<Response> {
    resource: string
    sideEffect?: (data: Response) => Response
}
type Watched = {
    dateFrom: string
    dateTo: string
    date: string
}
export const useCreateDashboardWidgetLink = (config?: { flag: FlagName }) => {
    const time = useWatch<Watched>({ name: ['dateFrom', 'dateTo', 'date'] })

    const flags = useFlags()
    if (config && !flags[config.flag]) {
        return (to: string) => undefined
    }
    return (to: string) =>
        to +
        `?displayedFilters=${encodeURIComponent(
            JSON.stringify({
                info: {
                    period: time[2],
                },
            }),
        )}&filter=
        ${encodeURIComponent(JSON.stringify({ periodStart: time[0], periodEnd: time[1] }))}`
}
export const useDashboardWidget = <Response = unknown,>({
    resource,
    sideEffect,
}: useDashboardWidgetParms<Response>) => {
    const time = useWatch<Watched>({ name: ['dateFrom', 'dateTo', 'date'] })

    const query = useQuery<Response>([resource, ...time], async () => {
        const period: PeriodFilter = {
            periodStart: time[0],
            periodEnd: time[1],
        }
        const data: Response = await api.get(resource, period)
        const mutatedData = sideEffect ? sideEffect(data) : data
        return mutatedData
    })
    return query.data
}

const WidgetsForm: FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Form
            defaultValues={getDefaultFalues()}
            warnWhenUnsavedChanges={false}
        >
            {children}
        </Form>
    )
}

export default WidgetsForm

const getDefaultFalues = (): PeriodSelectorFormValues => {
    const periodPreferences = authStore.preferences.dashboard?.period
    const selectedRange = periodPreferences?.date
    if (selectedRange === periodSelectorCustomChoice) {
        return {
            date: selectedRange,
            dateFrom: periodPreferences.start,
            dateTo: periodPreferences.end,
        }
    }
    const date = selectedRange || defaultDate

    const range = getDateRangeDates(date as any)

    return {
        date,
        dateFrom: range[0],
        dateTo: range[1],
    }
}
