import images from 'assets/images'
import { InfoCard, InfoCardDetails, InfoCardHeader, NoResultsCard } from 'components'
import { urls } from 'configs'
import { useRecordContext } from 'core'
import { globalClassNames, Link } from 'lib'
import { invoiceFields, type InvoiceModel } from 'resources/invoices'
import { type WorkOrderModel } from 'resources/workOrders'
import { DataAvatar, GridContainerColumns, GridItemLayout, SvgIcon, Typography } from 'ui'
import { pathJoin } from 'utils'

import { WoInvoiceDrawer } from '../../components'

interface InvoiceCardProps {
    invoice?: InvoiceModel
}

const InvoiceWoCard = ({ invoice }: InvoiceCardProps) => {
    if (!invoice) {
        return <InvoiceEmptyCard />
    }
    const path = pathJoin(urls.invoices, invoice.id)

    return (
        <GridContainerColumns>
            <GridItemLayout>
                <InfoCard>
                    <InfoCardHeader
                        avatar={
                            <Link to={path}>
                                <DataAvatar
                                    defaultImage={
                                        <SvgIcon
                                            component={invoiceFields.avatar.getIcon(invoice)}
                                        />
                                    }
                                />
                            </Link>
                        }
                        title={
                            <Typography
                                component={Link}
                                to={path}
                                variant="avatar-initials"
                                color="primary.main"
                                fontWeight="500"
                                display="block"
                                className={globalClassNames.ellipsis}
                            >
                                {invoice.number}
                            </Typography>
                        }
                    />
                    <InfoCardDetails<InvoiceModel>
                        details={[
                            {
                                label: invoiceFields.status.label,
                                source: (record) => invoiceFields.status.value(record),
                            },
                            {
                                label: invoiceFields.total.label,
                                source: (record) => invoiceFields.total.value(record),
                            },
                        ]}
                        propRecord={invoice}
                    />
                </InfoCard>
            </GridItemLayout>
        </GridContainerColumns>
    )
}

export default InvoiceWoCard

const InvoiceEmptyCard = () => {
    const record = useRecordContext<WorkOrderModel>()
    const isAccessible = record.unitData.isAccessible
    return (
        <NoResultsCard
            direction="row"
            height="160px"
            title="No Invoice"
            imageSrc={images.noPayments}
            imageProps={{
                alt: 'No Invoice',
                width: '107px',
                mr: {
                    xs: '20px',
                    sm: '32px',
                },
            }}
            action={
                isAccessible ? (
                    <WoInvoiceDrawer record={record}>
                        {(open) => (
                            <Typography
                                component="span"
                                variant="body1"
                                color="primary"
                                sx={{ cursor: 'pointer' }}
                                onClick={open}
                            >
                                Create Invoice
                            </Typography>
                        )}
                    </WoInvoiceDrawer>
                ) : null
            }
        />
    )
}
