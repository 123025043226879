import { TextInput, useOpenUtilityDrawer, UtilityDrawerCreate } from 'components'
import { useConfirm, useRecordContext, maxLengthValidationText, requiredValidation } from 'core'
import { Alert } from 'ui'

import { WorkOrderOpenContent } from '../../components'
import { isVendorWo } from '../../isVendorWo'
import { isWorkOrderBillable } from '../../isWorkOrderBillable'
import { type WorkOrderModel, WoStatusKeys } from '../../types'
import { woResource, workOrderSerializer } from '../../utils'

import CloseContent from './CloseContent'
import CloseDialogUnsuccessful from './CloseDialogUnsuccessful'
import InvoicingContent from './InvoicingContent'
import PendingContent from './PendingContent'
import useUpdateWoStatus from './useUpdateStatus'
import { getCloseBody, woActionPaths } from './utils'
const useWoStatusActions = () => {
    const record = useRecordContext<WorkOrderModel>()
    const updateStatus = useUpdateWoStatus()
    const confirm = useConfirm()
    const open = useOpenUtilityDrawer()

    return async (statusAction: WoStatusKeys) => {
        if (statusAction === WoStatusKeys.PENDING) {
            confirm({
                divideContent: true,
                title: "Do you want to change this WO's status from Open to Pending?",
                closeOnError: false,
                content: <PendingContent workOrder={record} />,
                onConfirm: async () => {
                    await updateStatus(record.id, WoStatusKeys.PENDING)
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        }
        if (statusAction === WoStatusKeys.CANCELED) {
            confirm({
                title: 'Are you sure you want to cancel this work order?',
                closeOnError: false,
                content: (
                    <>
                        <Alert
                            severity="warning"
                            sx={{ mb: '22px' }}
                        >
                            Cancelling a work order is an irreversible action and will delete its
                            jobs and items. Any connected PM Intervals, DVIR Defects or Issues will
                            be detached. Issues will be returned to an Open status.
                        </Alert>
                        <TextInput
                            source="reason"
                            validate={descriptionValidation}
                            label="Reason for Cancellation"
                            multiline
                            rows={3}
                        />
                    </>
                ),
                onConfirm: async ({ formValues }) => {
                    await updateStatus(record.id, WoStatusKeys.CANCELED, formValues)
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        }
        if (statusAction === WoStatusKeys.CLOSED) {
            const isVendorType = isVendorWo(record)

            const isVendorWOClosable =
                Math.round(record.purchaseOrderData?.total) ===
                Math.round(record.purchaseOrderData?.totalInvoices)

            if (!isVendorWOClosable && isVendorType) {
                confirm({
                    title: `Vendor Work Order can't be closed.`,
                    content: <CloseDialogUnsuccessful data={record} />,
                    confirmButtonProps: null,
                    cancelButtonProps: {
                        children: 'Close',
                    },
                })
            } else if (
                record.status === WoStatusKeys.COMPLETED &&
                (isVendorType || !isWorkOrderBillable(record))
            ) {
                confirm({
                    title: 'Close Work Order',
                    onConfirm: async () => {
                        await updateStatus(record.id, WoStatusKeys.CLOSED)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                })
            } else if (record.status === WoStatusKeys.COMPLETED) {
                confirm({
                    divideContent: true,
                    closeOnError: false,
                    title: 'Close Work Order',
                    content: <InvoicingContent workOrder={record} />,
                    onConfirm: async ({ formValues }) => {
                        const body = getCloseBody({ formValues, isInvoicingOnly: true })

                        await updateStatus(record.id, WoStatusKeys.CLOSED, body)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                })
            } else {
                confirm({
                    divideContent: true,
                    title: 'Close Work Order',
                    closeOnError: false,
                    content: (
                        <CloseContent
                            workOrder={record}
                            status={WoStatusKeys.CLOSED}
                        />
                    ),
                    onConfirm: async ({ formValues }) => {
                        const body = getCloseBody({ formValues })

                        await updateStatus(record.id, WoStatusKeys.CLOSED, body)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                })
            }
        }
        if (statusAction === WoStatusKeys.OPEN) {
            if (record.status === WoStatusKeys.PENDING) {
                open({
                    drawerArgs: {
                        title: 'Open Work Order',
                        renderWrapper: (params) => (
                            <UtilityDrawerCreate
                                serializer={workOrderSerializer}
                                defaultValues={record}
                                {...params}
                            />
                        ),
                        renderContent: () => <WorkOrderOpenContent />,
                        renderBottomRight: (render) => render({ label: 'Confirm', icon: null }),
                    },
                    extraArgs: {
                        resource: {
                            ...woResource,
                            resource: woResource.updateStatus(record.id, woActionPaths.OPEN),
                        },
                    },
                })
            } else if (!record.unitData.domicile || record.shop === record.unitData.domicile) {
                confirm({
                    title: 'Are you sure you want to reopen this Work Order?',
                    content: (
                        <Alert severity="warning">
                            Reopening the Work Order will:
                            <br />
                            •&ensp;Erase the completion date and meter readings
                            <br />
                            •&ensp;Return any linked Issues to their previous status
                        </Alert>
                    ),
                    onConfirm: async () => {
                        await updateStatus(record.id, WoStatusKeys.OPEN)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                })
            } else {
                confirm({
                    title: `Work Order cannot be reopened`,
                    content:
                        "The Unit's domicile has changed and it no longer matches the original Repair Shop location.",
                    confirmButtonProps: null,
                    cancelButtonProps: {
                        children: 'Cancel',
                    },
                })
            }
        }
        if (statusAction === WoStatusKeys.COMPLETED) {
            confirm({
                divideContent: true,
                title: 'Complete Work Order',
                closeOnError: false,
                content: (
                    <CloseContent
                        status={WoStatusKeys.COMPLETED}
                        workOrder={record}
                        removeInvoicing
                    />
                ),
                onConfirm: async ({ formValues }) => {
                    const body = getCloseBody({ formValues })

                    await updateStatus(record.id, WoStatusKeys.COMPLETED, body)
                },
                confirmButtonProps: {
                    children: 'Confirm',
                },
                awaitOnConfirm: true,
            })
        }
    }
}

export default useWoStatusActions

const descriptionValidation = [maxLengthValidationText, requiredValidation]
