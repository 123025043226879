import { type FC } from 'react'

import { Resource, type ResourceProps } from 'ra-core'

import { RaResourceContextProvider, type ResourceType } from './RaResourceContextProvider'
import { useClearResource } from './useClearResource'

interface ResourceWithClearEffectProps extends Omit<ResourceProps, 'name'> {
    name: ResourceType
}

export const ResourceWithClearEffect: FC<ResourceWithClearEffectProps> = ({ name, ...rest }) => {
    useClearResource(name.resource)

    return (
        <RaResourceContextProvider value={name}>
            <Resource
                {...rest}
                name={name.resource}
            />
        </RaResourceContextProvider>
    )
}
