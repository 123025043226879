import { useEffect, type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { Form, PasswordInput, SubmitButton } from 'components'
import { requiredValidation } from 'core'
import { styled } from 'lib'
import { Dialog, Card, CardContent, BoxContainer, Button, Typography, Tooltip } from 'ui'

const IdentityVerificationCard = styled(Card)`
    box-shadow: ${({ theme }) => theme.shadows[1]};
    & > * {
        flex-grow: 1;
        flex-basis: 50%;
    }
    width: 356px;
    ${({ theme }) => theme.breakpoints.down('sm')} {
        width: 335px;
    }
`

interface IdentityVerificationDialogProps {
    onClose: () => void
    login: Function
    error: string
    onPasswordChange: () => void
    loading: boolean
}

const IdentityVerificationDialog: FC<IdentityVerificationDialogProps> = ({
    onClose,
    login,
    error,
    onPasswordChange,
    loading,
}) => {
    const handleSubmit = ({ confirmPassword }) => {
        login({ password: confirmPassword })
    }

    return (
        <Dialog
            open
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            PaperProps={{
                sx: {
                    m: '0px',
                },
            }}
        >
            <IdentityVerificationCard
                sx={{
                    width: '418px',
                }}
            >
                <CardContent sx={{ p: '24px' }}>
                    <BoxContainer
                        marginBottom="24px"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6">Enter your current Fleetpal password</Typography>
                        <Tooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={5000}
                            title={
                                <Typography
                                    variant="tooltip"
                                    sx={{
                                        display: 'flex',
                                        width: '237px',
                                    }}
                                >
                                    You or someone else has already signed up with this email
                                    address, but the email address has not been verified. In order
                                    to connect this Google identity to the existing Fleetpal
                                    account, it is required to enter the correct password or go
                                    through the password recovery procedure to regain access to
                                    Fleetpal.
                                </Typography>
                            }
                        >
                            <Icons.InfoOutlined sx={{ width: '20px', height: '20px' }} />
                        </Tooltip>
                    </BoxContainer>
                    <Form
                        warnWhenUnsavedChanges={false}
                        onSubmit={handleSubmit}
                    >
                        <SetError error={error} />
                        <PasswordInput
                            validate={requiredValidation}
                            onChange={onPasswordChange}
                            source="confirmPassword"
                            label="Fleetpal Password"
                            inputProps={{
                                autoComplete: 'confirmPassword',
                                form: {
                                    autocomplete: 'off',
                                },
                            }}
                        />
                        <BoxContainer justifyContent="space-between">
                            <Button
                                variant="text"
                                onClick={onClose}
                                type="button"
                            >
                                Cancel
                            </Button>
                            <SubmitButton
                                {...(loading && { disabled: true })}
                                icon={null}
                                size="medium"
                                label="Confirm"
                            />
                        </BoxContainer>
                    </Form>
                </CardContent>
            </IdentityVerificationCard>
        </Dialog>
    )
}

export default IdentityVerificationDialog

const SetError: FC<{ error: string }> = ({ error }) => {
    const { setError } = useFormContext()

    useEffect(() => {
        if (error) {
            setError('confirmPassword', { message: error }, { shouldFocus: false })
        }
    }, [error])

    return null
}
