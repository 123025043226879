import { isValidElement, type ReactElement } from 'react'

import {
    ValidationError,
    type ValidationErrorMessage,
    type ValidationErrorMessageWithArgs,
} from 'ra-core'

const InputHelperText = (props: InputHelperTextProps) => {
    const { helperText, error } = props

    if (typeof error === 'boolean') {
        return null
    }

    if (error) {
        if ((error as ValidationErrorMessageWithArgs).message) {
            return <ValidationError error={error} />
        }
        return <>{error}</>
    }

    if (helperText === false) {
        return null
    }

    if (isValidElement(helperText)) {
        return helperText
    }

    return <span dangerouslySetInnerHTML={defaultInnerHTML} />
}

const defaultInnerHTML = { __html: '&#8203;' }

interface InputHelperTextProps {
    helperText?: string | ReactElement | boolean
    error?: ValidationErrorMessage | boolean
}

export default InputHelperText
