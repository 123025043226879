import { type NestedKeys, type Identifier } from 'appTypes'
import { parseNumber, serialize, type Serializer } from 'core'
import { type JobPartModel } from 'resources/workOrders'

export const jobPartWithoutNumberSerializer: Serializer<JobPartWithoutPartFormData> = [
    { name: 'quantity', parse: 'number' },
    {
        name: 'price',
        parse: 'number',
    },
    { name: 'position', parse: 'emptyToNull' },
    'type',
    {
        name: 'part',
        parse: (value) => {
            return serialize(value, [
                { name: 'component', parse: 'emptyToNull' },
                'description',
                { name: 'number', parse: 'emptyToNull' },
                'manufacturer',
                { name: 'unitOfMeasure', parse: 'emptyToNull' },
                'manufacturerPartNumber',
            ])
        },
    },
]

export const jobPartSerializer: Serializer<
    JobPartModel & { formConfig: { inventoryMode: boolean } }
> = [
    { name: 'part', parse: 'emptyToNull' },
    { name: 'quantity', parse: 'number' },
    {
        name: 'price',
        parse: (value, data) => {
            if (data.formConfig.inventoryMode) {
                return undefined
            }
            return parseNumber(value)
        },
    },
    { name: 'position', parse: 'emptyToNull' },
    'type',
]

export const jobPartApiContext = (jobId: Identifier) => {
    return {
        contextType: 'work-order-jobs',
        contextId: jobId,
    }
}

export interface JobPartWithoutPartFormData extends Omit<JobPartModel, 'partData' | 'part'> {
    part: JobPartModel['partData']
}

export type JobPartWithoutPartFormKeys = NestedKeys<JobPartWithoutPartFormData>
