import { type ReactElement } from 'react'

import { useCreatePath } from 'ra-core'

import { UtilityDrawerEditor, useOpenUtilityDrawer } from 'components'
import { NotificationMessage, type Serializer } from 'core'
import { poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'

import PurchaseOrderForm from './PurchaseOrderForm'

interface UnitDrawerProps {
    children: (params: { onClick: () => void }) => ReactElement
    record?: PurchaseOrderModel
}
const purchaseOrderSerializer: Serializer<PurchaseOrderModel> = [
    'description',
    { name: 'shop', parse: ['emptyToNull', 'disableOnEdit'] },
    { name: 'type', parse: ['emptyToNull', 'disableOnEdit'] },
    { name: 'vendor', parse: ['emptyToNull', 'disableOnEdit'] },
]

const isRecordClosed = (record: PurchaseOrderModel) => {
    return record && record.status === 'CLOSED'
}

const PurchaseOrderDrawerToggler = ({ children, record }: UnitDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const createPath = useCreatePath()
    const disabled = isRecordClosed(record)

    return children({
        onClick: () => {
            open({
                extraArgs: {
                    resource: poResource,
                    id: record ? record.id : undefined,
                },
                drawerArgs: {
                    title: disabled
                        ? 'Details'
                        : record
                          ? 'Edit Purchase Order'
                          : 'Create Purchase Order',

                    renderWrapper: (props) => (
                        <UtilityDrawerEditor
                            {...props}
                            serializer={purchaseOrderSerializer}
                            successMessage={({ defaultMessages, response }) => {
                                if (record) {
                                    return defaultMessages.updated
                                }

                                const po = response as PurchaseOrderModel
                                return {
                                    title: defaultMessages.created,
                                    message: (
                                        <NotificationMessage.Navigate
                                            to={createPath({
                                                resource: poResource.resource,
                                                type: 'edit',
                                                id: po.id,
                                            })}
                                        >
                                            {po.number}
                                        </NotificationMessage.Navigate>
                                    ),
                                }
                            }}
                        />
                    ),
                    renderContent: () => (
                        <PurchaseOrderForm
                            inputsProps={
                                record
                                    ? {
                                          vendor: { disabled: true },
                                          shop: { disabled: true },
                                          type: { disabled: true },
                                      }
                                    : null
                            }
                            disabled={disabled}
                        />
                    ),
                    renderBottomRight: (render) => (disabled ? undefined : render()),
                },
            })
        },
    })
}

export default PurchaseOrderDrawerToggler
