import { type FC } from 'react'

import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import {
    costMaskParams,
    type DialogSelectorProps,
    inputQuantityMaskParams,
    MoneyFormTotal,
    TextInput,
    TextInputBase,
    useUtilityDrawerContext,
} from 'components'
import { fixPrecision, requiredValidation, validateMaxLength } from 'core'
import { getSuggestedPrice, type InvoicePartModel } from 'resources/invoices'
import { LineItemTypeKeys } from 'resources/lineItems'
import { PartInput } from 'resources/parts'
import { CK79Input } from 'resources/vmrs'

import { type InvoicePartExtra } from './InvoicePartDrawer'

export interface PartFormProps {
    isDisabled?: (source: keyof (InvoicePartModel & { markup: number })) => boolean
    isHidden?: (source: keyof InvoicePartModel) => boolean
}

const defaultDisabled = () => false

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

const PartForm = ({ isDisabled = defaultDisabled, isHidden }: PartFormProps) => {
    const { setValue } = useFormContext()
    const { extra } = useUtilityDrawerContext()
    const { invoice } = extra as InvoicePartExtra

    return (
        <>
            <InvoicePartInput
                invoiceId={invoice.id}
                disabled={isDisabled?.('part')}
                onSelectedChange={async ({ selected }) => {
                    setValue('orderPrice', selected?.cost ?? 0, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                    })
                    setValue('markup', null, {
                        shouldDirty: true,
                        shouldTouch: true,
                        shouldValidate: true,
                    })

                    if (selected) {
                        const price = await getSuggestedPrice(invoice.id, {
                            itemType: LineItemTypeKeys.PART,
                            part: selected.id,
                        })
                        setValue('price', price.sellingPrice, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                        })
                    }
                }}
            />

            <PriceInput disabled={isDisabled?.('price')} />

            <MarkupInput />

            {isHidden?.('orderQuantity') ? null : (
                <TextInput
                    source="orderQuantity"
                    label="Work Order Quantity"
                    disabled
                />
            )}

            <QuantityInput
                isOrderQuantity={!isHidden?.('orderQuantity')}
                disabled={isDisabled?.('quantity')}
            />

            <MoneyFormTotal
                title="Total"
                inputOne="quantity"
                inputTwo="price"
            />

            {isHidden?.('position') ? null : <PositionContent />}
        </>
    )
}

export default PartForm

const PositionContent = () => {
    const { getValues } = useFormContext()
    const position = getValues('position')

    if (!position) {
        return null
    }
    return <CK79Input disabled />
}
const QuantityInput: FC<{ disabled: boolean; isOrderQuantity: boolean }> = ({
    disabled,
    isOrderQuantity,
}) => {
    const { watch, getValues } = useFormContext()

    const orderQuantity = Number(getValues('orderQuantity'))
    const quantity = Number(watch('quantity') || getValues('quantity'))
    const unitOfMeasure = getValues('partData.unitOfMeasure')

    return (
        <TextInput
            source="quantity"
            label="Quantity"
            disabled={disabled}
            validate={qtyAndCostValidator}
            {...inputQuantityMaskParams}
            helperText={
                isOrderQuantity && orderQuantity > quantity
                    ? `${fixPrecision(orderQuantity - quantity)} ${unitOfMeasure || ''} will not be billed`
                    : undefined
            }
        />
    )
}

const MarkupInput = () => {
    const { watch, getValues } = useFormContext()
    const cost = watch('orderPrice') || getValues('orderPrice')
    const price = watch('price') || getValues('price')

    return (
        // @ts-ignore
        <TextInputBase
            name="markup"
            label="Markup/Margin"
            {...costMaskParams}
            disabled
            value={Number(price || 0) - Number(cost || 0)}
        />
    )
}

const PriceInput = ({ disabled }: { disabled: boolean }) => {
    const { watch, getValues } = useFormContext()
    const cost = watch('orderPrice') || getValues('orderPrice')
    const price = getValues('price') || watch('price')

    return (
        <TextInput
            source="price"
            label="Selling Price"
            {...costMaskParams}
            disabled={disabled}
            helperText={
                price !== null && Number(cost) > Number(price)
                    ? 'Price below average cost'
                    : undefined
            }
            validate={qtyAndCostValidator}
        />
    )
}

export const InvoicePartInput: FC<{ invoiceId: Identifier } & Partial<DialogSelectorProps>> = ({
    invoiceId,
    ...props
}) => {
    return (
        <PartInput
            {...props}
            contextType="invoice-items"
            contextId={invoiceId}
            type="inventory"
        />
    )
}
