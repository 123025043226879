import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { BaseStatusContent } from 'resources/common'

import { type UnitStatusModel } from '../types'

import StatusMenu from './StatusMenu'

interface StatusFieldProps {
    unitId?: Identifier
    record: UnitStatusModel
}

const StatusField: FC<StatusFieldProps> = ({ unitId, record }) => {
    if (!record) {
        return null
    }

    if (unitId) {
        return (
            <StatusMenu
                selectedStatusId={record.id}
                id={unitId}
                renderToggler={(open, isOpen) => (
                    <BaseStatusContent
                        color={record.color}
                        isOpen={isOpen}
                        onClick={open}
                    >
                        {record.name}
                    </BaseStatusContent>
                )}
            />
        )
    }

    return <BaseStatusContent color={record.color}>{record.name}</BaseStatusContent>
}

export default StatusField
