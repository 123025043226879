import { type ReactNode, type ReactElement } from 'react'

interface Props {
    isRequired?: boolean
    label?: string | ReactElement | ReactNode
}

const InputTitle = (props: Props) => {
    const { label, isRequired } = props

    if (!label) {
        return null
    }

    return (
        <span>
            {label}
            {isRequired && <span aria-hidden="true">&thinsp;*</span>}
        </span>
    )
}

InputTitle.displayName = 'InputTitle'

export default InputTitle
