import { useState } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import Icons from 'assets/icons'
import {
    MoneyFormTotal,
    TextInput,
    TextareaInput,
    costMaskParams,
    inputQtyMaskParams,
    useUtilityDrawerContext,
} from 'components'
import { maxLengthValidationText, requiredValidation, validateMaxLength } from 'core'
import { alpha } from 'lib'
import {
    type JobPartWithoutPartFormData,
    type JobPartWithoutPartFormKeys,
} from 'pages/WorkOrders/Jobs/Parts'
import { UOMInput } from 'resources/unitsOfMeasure'
import { CK33Input, CK34Input, CK79Input } from 'resources/vmrs'
import {
    BoxContainer,
    IconBox,
    GridItem,
    GridContainerColumns,
    SectionTitleSmall,
    Divider,
    Collapse,
} from 'ui'
import { inverse } from 'utils'

import { type PartWithoutNumberFormExtra } from './drawerTogglers'

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]
const descriptionValidation = [maxLengthValidationText]

const partSource = (source: keyof JobPartWithoutPartFormData['part']) => `part.${source}`

const MoreDetails = ({
    disabled,
    defaultValues,
}: {
    disabled: boolean
    defaultValues: {
        manufacturerPartNumber: number
    }
}) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Divider sx={{ mb: '28px' }} />

            <BoxContainer
                justifyContent="space-between"
                alignItems="center"
                mb="28px"
            >
                <SectionTitleSmall mb="0px">More Details</SectionTitleSmall>
                <IconBox
                    backgroundColor="transparent"
                    iconColor={(theme) => alpha(theme.palette.text.primary, 0.54)}
                    onClick={() => setOpen(inverse)}
                >
                    {open ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
                </IconBox>
            </BoxContainer>

            <Collapse in={open}>
                <TextInput
                    source={partSource('number')}
                    label="Part No"
                    disabled={disabled}
                />

                <CK34Input
                    disabled={disabled}
                    source={partSource('manufacturer')}
                />

                <TextInput
                    source={partSource('manufacturerPartNumber')}
                    label="Manufacturer Part Number"
                    defaultValue={defaultValues.manufacturerPartNumber}
                    disabled={disabled}
                />

                <UOMInput
                    disabled={disabled}
                    source={partSource('unitOfMeasure')}
                />
            </Collapse>

            <Divider sx={{ mb: '28px' }} />
        </>
    )
}

const DescriptionInput = ({ disabled }: { disabled: boolean }) => {
    const component = useWatch({ name: partSource('component') })
    return (
        <TextareaInput
            source={partSource('description')}
            label="Description"
            disabled={disabled || !component}
            validate={descriptionValidation}
        />
    )
}
const PartWithoutNumberDrawerForm = () => {
    const { setValue } = useFormContext()
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled, record } = extra as PartWithoutNumberFormExtra

    return (
        <>
            <CK33Input
                source={partSource('component')}
                disabled={disabled}
                required
                onSelectedChange={({ selected }) => {
                    setValue(partSource('description'), selected?.text || '')
                }}
            />

            <DescriptionInput disabled={disabled} />

            <MoreDetails
                disabled={disabled}
                defaultValues={{
                    manufacturerPartNumber: record
                        ? record?.partData?.manufacturerPartNumber
                        : null,
                }}
            />

            <GridContainerColumns>
                <GridItem xs={6}>
                    <TextInput
                        source={'quantity' satisfies JobPartWithoutPartFormKeys}
                        label="Quantity"
                        validate={qtyAndCostValidator}
                        {...inputQtyMaskParams}
                        disabled={disabled}
                    />
                </GridItem>
                <GridItem xs={6}>
                    <TextInput
                        source={'price' satisfies JobPartWithoutPartFormKeys}
                        label="Cost"
                        validate={qtyAndCostValidator}
                        {...costMaskParams}
                        disabled={disabled}
                    />
                </GridItem>
            </GridContainerColumns>
            <MoneyFormTotal
                title="Part Total"
                inputOne={'quantity' satisfies JobPartWithoutPartFormKeys}
                inputTwo="price"
            />
            <CK79Input disabled={disabled} />
        </>
    )
}

export default PartWithoutNumberDrawerForm
