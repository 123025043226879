import { type ReactElement } from 'react'

import { ResourceContextProvider, useGetList, useRecordContext } from 'ra-core'

import Icons from 'assets/icons'
import { ViewHeader, formatMoney } from 'components'
import { PurchaseInvoiceCard, type PurchaseInvoiceModel } from 'resources/purchaseInvoices'
import { poResource } from 'resources/purchaseOrders'
import { useWoActionIsDisabled, type WorkOrderModel } from 'resources/workOrders'
import { GridContainerGrid, GridItemCard, IconBox, InfoBadge, PageContent } from 'ui'

import CreateInvoice from './CreateInvoice'
import InvoicesEmpty from './InvoicesEmpty'

const InvoicesSection = () => {
    const record = useRecordContext<WorkOrderModel>()

    const poId = record?.purchaseOrderData.id

    const invoiceResource = poResource.invoices(poId)

    const { isLoading, data, total } = useGetList<PurchaseInvoiceModel>(invoiceResource)
    const disabled = useWoActionIsDisabled()

    let content

    if (isLoading) {
        return null
    }

    if (total) {
        content = (
            <GridContainerGrid>
                {data.map((item) => (
                    <GridItemCard key={item.id}>
                        <PurchaseInvoiceCard
                            disableActions={disabled}
                            invoice={item}
                            readOnly={!record.unitData.isAccessible}
                        />
                    </GridItemCard>
                ))}
            </GridContainerGrid>
        )
    } else {
        content = <InvoicesEmpty />
    }

    let badgeContent: ReactElement | number = 0
    if (total) {
        const v = formatMoney(record.purchaseOrderData.totalInvoices)

        badgeContent = (
            <>
                {total} • {v} Total
            </>
        )
    }

    return (
        <ResourceContextProvider value={invoiceResource}>
            <PageContent>
                <ViewHeader title="Invoices">
                    <ViewHeader.Content>
                        <InfoBadge badgeContent={badgeContent} />
                    </ViewHeader.Content>
                    {disabled ? null : (
                        <ViewHeader.Content placement="rightMobile">
                            <CreateInvoice>
                                {(open) => (
                                    <IconBox
                                        title="Add Invoice"
                                        onClick={open}
                                    >
                                        <Icons.Add />
                                    </IconBox>
                                )}
                            </CreateInvoice>
                        </ViewHeader.Content>
                    )}
                </ViewHeader>
                {content}
            </PageContent>
        </ResourceContextProvider>
    )
}
export default InvoicesSection
