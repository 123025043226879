import { type ReactElement, type FC } from 'react'

import { useCreatePath } from 'ra-core'

import { type Identifier } from 'appTypes'
import { NotificationMessage, type ResourceType } from 'core'
import { customerFields } from 'resources/customers'
import {
    InvoiceDrawerToggler,
    type InvoiceModel,
    invoicesResource,
    InvoiceTypeKeys,
} from 'resources/invoices'
import { shopFields } from 'resources/shops'
import { unitFields } from 'resources/units'
import { woFields, woResource, type WorkOrderModel } from 'resources/workOrders'

interface WoInvoiceDrawerProps {
    record: WorkOrderModel
    children: (open: () => void) => ReactElement
}
const WoInvoiceDrawer: FC<WoInvoiceDrawerProps> = ({ record, children }) => {
    const createPath = useCreatePath()
    const resource = getResource(record.id)
    return (
        <InvoiceDrawerToggler
            resource={resource}
            defaultValues={{
                shop: record.shop,
                customer: record.unitData.customer,
                workOrder: record.id,
                unit: record.unit,
                poNumber: record.salesOrderData?.poNumber,
                paymentTerm: record.salesOrderData?.customerData?.paymentTerm,
            }}
            type={InvoiceTypeKeys.WORK_ORDER}
            successMessage={({ defaultMessages, response }) => {
                const invoice = response as InvoiceModel
                return {
                    title: defaultMessages.created,
                    message: (
                        <NotificationMessage.Navigate
                            to={createPath({
                                resource: invoicesResource.resource,
                                type: 'edit',
                                id: invoice.id,
                            })}
                        >
                            {invoice.number}
                        </NotificationMessage.Navigate>
                    ),
                }
            }}
            isDisabled={(source) => {
                return (
                    source === customerFields.self.source ||
                    source === woFields.self.source ||
                    source === unitFields.self.source ||
                    source === shopFields.self.source
                )
            }}
        >
            {children}
        </InvoiceDrawerToggler>
    )
}

export default WoInvoiceDrawer

const getResource = (id: Identifier): ResourceType => ({
    name: woResource.name,
    resource: woResource.invoice(id),
})
