import { useEffect, useState, type FC } from 'react'

import { type SvgIconElement, type Size } from 'appTypes'
import Icons from 'assets/icons'
import { useDidUpdate } from 'hooks'
import { Collapse, Stack } from 'ui'
import { inverse } from 'utils'

import { useSidebar } from '../SidebarContext'
import { type NavItemConfig } from '../types'

import DrawerItem from './DrawerItem'

interface Props {
    items: NavItemConfig[]
    gap?: Size
    p?: string
}

const DrawerNav: FC<Props> = ({ items, gap = '2px', p }) => {
    return (
        <Stack
            gap={gap}
            p={p}
        >
            {items.map((item) =>
                item.items ? (
                    <Nested
                        item={item}
                        key={item.key}
                    />
                ) : (
                    <Item
                        item={item}
                        key={item.key}
                    />
                ),
            )}
        </Stack>
    )
}

interface ItemProps {
    item: NavItemConfig
    endIcon?: SvgIconElement
    onClick?: () => void
}

const Item: FC<ItemProps> = ({ item, endIcon, onClick }) => {
    const [snap] = useSidebar()
    const isActive = snap.activeElements[item.key]

    return (
        <DrawerItem
            key={item.key}
            to={item.path}
            startIcon={isActive && item.ActiveIcon ? item.ActiveIcon : item.Icon}
            endIcon={endIcon}
            onClick={onClick}
            selected={isActive}
        >
            {item.text}
        </DrawerItem>
    )
}

const Nested: FC<{ item: NavItemConfig }> = ({ item }) => {
    const [snap] = useSidebar()
    const isActive = snap.activeElements[item.key]
    const [show, setShow] = useState(isActive)

    useEffect(() => {
        if (isActive) {
            setShow(true)
        }
    }, [isActive])

    return (
        <>
            <Item
                item={item}
                endIcon={show ? Icons.ExpandLess : Icons.ExpandMore}
                onClick={() => setShow(inverse)}
            />
            {show && (
                <Close
                    setShow={setShow}
                    isActive={isActive}
                />
            )}
            <Collapse in={show}>
                <DrawerNav
                    items={item.items}
                    gap="0px"
                    p="2px 0 0 16px"
                />
            </Collapse>
        </>
    )
}

interface CloseProps {
    setShow: (show: boolean) => void
    isActive: boolean
}

const Close: FC<CloseProps> = ({ setShow, isActive }) => {
    const [snap] = useSidebar()

    useDidUpdate(() => {
        // if an url changes when this is open and this item is no longer active, close the nested menu
        if (!isActive) {
            setShow(false)
        }
    }, [snap.lastNavigated])

    return null
}

export default DrawerNav
