import { useListContext, useShowContext } from 'ra-core'

import Icons from 'assets/icons'
import { ListTotalBadge, SliderView, ViewHeader } from 'components'
import { ActionsMenu } from 'core'
import { alpha, styled } from 'lib'
import { useUnitPMDrawerEditor, type PmModel } from 'resources/pm'
import { type UnitModel, isUnitArchived } from 'resources/units'
import { IconBox, Section, Typography, StyledElement } from 'ui'

import { usePMRepositionDrawer } from './PMReposition'
import { unitPMCardSkeletons } from './UnitPMCard'
import UnitPMCard from './UnitPMCard/UnitPMCard'
import UnitPMEmpty from './UnitPMEmpty'

const Position = styled(Typography)`
    position: absolute;
    top: 6px;
    left: 0;
    color: ${({ theme }) => theme.palette.text.primary};
    padding: 6px 10px 12px;
    background-color: ${({ theme }) => alpha(theme.palette.text.primary, 0.12)};
    border-radius: 4px;
    transform: translateY(-100%);
    z-index: -1;
`

const UnitPMDependentSection = () => {
    const { data, total, isLoading } = useListContext<PmModel>()
    const open = useUnitPMDrawerEditor()
    const reposition = usePMRepositionDrawer()
    const { record: unit } = useShowContext<UnitModel>()
    const isArchived = isUnitArchived(unit)

    let content

    if (total) {
        content = (
            <SliderView
                items={data?.map((item) => (
                    <StyledElement sx={{ position: 'relative' }}>
                        <Position variant="chartTitle">Position {item.rank}</Position>
                        <UnitPMCard
                            unitPmRecord={item}
                            key={item.id}
                        />
                    </StyledElement>
                ))}
            />
        )
    } else {
        content = <UnitPMEmpty type="dependent" />
    }
    if (isLoading) {
        content = (
            <SliderView
                height="346px"
                items={unitPMCardSkeletons}
            />
        )
    }
    return (
        <Section>
            <ViewHeader
                title="Dependent"
                loading={isLoading}
            >
                <ViewHeader.Content>
                    <ListTotalBadge />
                </ViewHeader.Content>
                <ViewHeader.Content placement="rightMobile">
                    <IconBox
                        title={
                            'Dependent scheduling allows you to group Maintenance Intervals ' +
                            'and run multiple maintenance jobs with varying cycle frequencies at once.'
                        }
                    >
                        <Icons.InfoOutlined />
                    </IconBox>
                    {!isArchived && (
                        <ActionsMenu
                            actions={(args, { children }) => [
                                children({
                                    Icon: Icons.Add,
                                    title: 'Create Interval',
                                    disabled: total === 10,
                                    titleOnDisabled: 'Maximum of ten dependent intervals supported',
                                    key: 'create Interval',
                                    onClick: () => open({ type: 'dependent' }),
                                }),
                                children({
                                    Icon: Icons.SwapVertOutlined,
                                    title: 'Reposition',
                                    key: 'reposition',
                                    disabled: total < 2,
                                    onClick: reposition,
                                }),
                            ]}
                        />
                    )}
                </ViewHeader.Content>
            </ViewHeader>
            {content}
        </Section>
    )
}

export default UnitPMDependentSection
