import { type FC } from 'react'

import { useRecordContext } from 'ra-core'

import Icons from 'assets/icons'
import { Header, InnerHeader, ShowHeaderSkeleton, TextInput, type TabType } from 'components'
import {
    api,
    type Action,
    deleteOneAction,
    maxLengthValidationText,
    requiredValidation,
    type ConfirmConfig,
    useConfirm,
} from 'core'
import { deletePOTitle, getDisableDeleteTitle } from 'pages/PurchaseOrders/utils'
import {
    formatPOTotal,
    getPOIcon,
    poFields,
    poResource,
    PoSendAction,
    type PurchaseOrderModel,
} from 'resources/purchaseOrders'
import { Alert, AutoGrid, InfoBadge } from 'ui'

import CloseCreditPoButton from './CloseCreditPoButton'
import POExportPDF from './POExportPDF'
import ReceiveItemsButton from './ReceiveItemsButton'

const PoShowHeader: FC<{ tabs: TabType[] }> = ({ tabs }) => {
    const record = useRecordContext<PurchaseOrderModel>()
    const confirm = useConfirm()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    const Icon = getPOIcon(record.type)
    const isCreditPO = record.type === 'CREDIT'

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="212px"
            tabMinWidth="190px"
            actions={(record, p2) => actions({ record, confirm }, p2)}
            mainAction={isCreditPO ? <CloseCreditPoButton /> : <ReceiveItemsButton />}
        >
            <Header.Info avatar={<Header.Avatar defaultImage={<Icon />} />}>
                <Header.Content>
                    <Header.Title>{record.number} </Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        <AutoGrid.Column>{poFields.status.value(record)}</AutoGrid.Column>
                        <AutoGrid.Column>
                            {typeof record.total === 'number' ? (
                                <InfoBadge badgeContent={formatPOTotal(record.total, false)} />
                            ) : null}
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default PoShowHeader

interface ActionProps {
    record: PurchaseOrderModel
    confirm: (params: ConfirmConfig) => void
}
const descriptionValidation = [maxLengthValidationText, requiredValidation]

const actions: Action = ({ record, confirm }: ActionProps, { children }) => {
    if (!record) {
        return []
    }
    const disableDeleteTitle = getDisableDeleteTitle(record)
    const disableSendPO = record.status === 'CANCELED' || record.status === 'CLOSED'
    return [
        <PoSendAction
            po={record}
            key="send-po"
        >
            {(open) =>
                children({
                    title: 'Send PO',
                    Icon: Icons.SendOutlined,
                    onClick: open,
                    disabled: disableSendPO,
                })
            }
        </PoSendAction>,
        <POExportPDF key="pdf">
            {(open) =>
                children({
                    onClick: open,
                    title: 'Export PDF',
                    Icon: Icons.Pdf,
                    disableCloseOnClick: true,
                })
            }
        </POExportPDF>,
        children({
            key: 'cancel',
            title: 'Cancel',
            Icon: Icons.CancelOutlined,
            disabled: record.status !== 'OPEN' || Boolean(record.dateLastReceived),
            onClick: () =>
                confirm({
                    title: 'Are you sure you want to cancel this purchase order?',
                    closeOnError: false,
                    content: (
                        <>
                            <Alert
                                severity="warning"
                                sx={{ mb: '22px' }}
                            >
                                Cancelling a purchase order is an irreversible action
                            </Alert>
                            <TextInput
                                source="reason"
                                validate={descriptionValidation}
                                label="Reason for Cancellation"
                                multiline
                                rows={3}
                            />
                        </>
                    ),
                    onConfirm: ({ formValues }) => {
                        api.post(`${poResource.resource}/${record.id}/cancel`, formValues)
                    },
                    confirmButtonProps: {
                        children: 'Confirm',
                    },
                    awaitOnConfirm: true,
                }),
        }),
        deleteOneAction({
            disabled: Boolean(disableDeleteTitle),
            children: (params) =>
                children({
                    ...params,
                    titleOnDisabled: disableDeleteTitle,
                }),
            id: record.id,
            confirmConfig: {
                title: deletePOTitle,
            },
        }),
    ]
}
