import { ListBase } from 'ra-core'

import Icons from 'assets/icons'
import { useCanAccess, useShowContext } from 'core'
import { WoJobDrawerToggler, woOperations, type WorkOrderModel } from 'resources/workOrders'
import { IconBox, PageContent } from 'ui'

import WorkOrderJobsResource from './WorkOrderJobsResource'
import WorkOrderJobsSection from './WorkOrderJobsSection'

const WorkOrderJobsOpenDrawerButton = () => {
    const { record: wo } = useShowContext<WorkOrderModel>()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.jobs, wo)

    if (!canAccess.value) {
        return null
    }

    return (
        <WoJobDrawerToggler>
            {(open) => (
                <IconBox
                    title="Create Job"
                    onClick={open}
                >
                    <Icons.Add />
                </IconBox>
            )}
        </WoJobDrawerToggler>
    )
}

const WorkOrderJobs = () => {
    return (
        <PageContent>
            <WorkOrderJobsResource>
                <ListBase perPage={100}>
                    <WorkOrderJobsSection
                        action={<WorkOrderJobsOpenDrawerButton />}
                        gridColumns={{ xs: 12 }}
                    />
                </ListBase>
            </WorkOrderJobsResource>
        </PageContent>
    )
}

export default WorkOrderJobs
