import { formatMoney, InfoCard, InfoCardDetails } from 'components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Divider, Typography, BoxContainer } from 'ui'

import { formatDiscount } from '../../utils'

const TotalPurchaseCard = ({ record }: { record: PurchaseOrderModel }) => {
    const isCredit = record.type === 'CREDIT'

    return (
        <InfoCard>
            <InfoCardDetails<PurchaseOrderModel>
                details={[
                    {
                        label: isCredit ? 'Return Items total' : 'Line Items Total',
                        source: ({ totalItems }) => formatMoney(totalItems),
                    },
                    {
                        label: 'Shipping ',
                        source: ({ shippingTax }) => formatMoney(shippingTax),
                    },
                    {
                        label: 'Miscellaneous',
                        source: ({ miscellaneousTax }) => formatMoney(miscellaneousTax),
                    },
                    {
                        label: 'Discount',
                        source: ({ discount }) => formatDiscount(discount),
                    },
                    {
                        label: 'Sales Tax',
                        source: ({ salesTax }) => formatMoney(salesTax),
                    },
                ]}
                propRecord={record}
            />
            <Divider sx={{ marginY: '10px' }} />
            <BoxContainer justifyContent="space-between">
                <Typography variant="subtitle2">{isCredit ? 'Total Credit' : 'Total'}</Typography>
                <Typography variant="subtitle2">{formatMoney(record.total) || '-'}</Typography>
            </BoxContainer>
        </InfoCard>
    )
}
export default TotalPurchaseCard
