import images from 'assets/images'
import { PasswordInput, TextInput } from 'components'
import { requiredValidation } from 'core'

import ConnectTopSection from './ConnectTopSection'

const CoretexConnect = () => {
    return (
        <>
            <ConnectTopSection
                logo={images.coretexLogo}
                title="Coretex Authentication"
                alertText={
                    <>In order to connect to Coretex we would need your Coretex credentials</>
                }
            />

            <TextInput
                label="Coretex Username"
                source="username"
                validate={requiredValidation}
            />

            <PasswordInput
                label="Coretex Password"
                source="password"
                validate={requiredValidation}
                autoComplete="new-password"
            />
        </>
    )
}

export default CoretexConnect
