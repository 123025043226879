import 'photoswipe/dist/photoswipe.css'
import { ListCardSkeleton, ViewHeader } from 'components'
import { type DefectModel } from 'resources/defects'
import { GridContainer, Section, GridItemLayout, InfoBadge } from 'ui'

import UnitDvirDefectCard from './UnitDvirDefectCard'

const CardSkeletons = new Array(10).fill(0).map((v, i) => (
    <ListCardSkeleton
        height="224px"
        key={i}
    />
))

const UnitDvirDefectsSection = ({ data }: { data: DefectModel[] }) => {
    return (
        <Section>
            <ViewHeader
                title="Defects"
                loading={!data}
            >
                <ViewHeader.Content>
                    <InfoBadge badgeContent={data?.length} />
                </ViewHeader.Content>
            </ViewHeader>

            <GridContainer
                zIndex={1}
                spacing={{ xs: 3, sm: 6 }}
                rowGap="24px"
                position="relative"
            >
                {data
                    ? data.map((def, i) => (
                          <GridItemLayout
                              // TODO: We should not depend on the card static height
                              height={getCardHeight(def)}
                              zIndex={data.length - i}
                              key={def.id}
                              xs={12}
                              sm={6}
                              md={4}
                              lg={3}
                              xl={2}
                          >
                              <UnitDvirDefectCard {...def} />
                          </GridItemLayout>
                      ))
                    : CardSkeletons}
            </GridContainer>
        </Section>
    )
}

export default UnitDvirDefectsSection

const getCardHeight = (defect: DefectModel) => {
    const baseHeight = 224
    const extraHeight = 31
    const maxExtraHeight = 60

    const hasComplaint = Boolean(defect.complaintText)
    const hasSeverity = Boolean(defect.severity)

    if (hasComplaint && hasSeverity) {
        return `${baseHeight + maxExtraHeight}px`
    }

    return `${baseHeight + (hasComplaint || hasSeverity ? extraHeight : 0)}px`
}
