import { type DataListDetails, type Column } from 'components'
import InvoiceLinkButton from 'resources/invoices/components/InvoiceLinkButton'
import PurchaseOrderTitle from 'resources/purchaseOrders/components/PurchaseOrderTitle'
import WorkOrderLinkButton from 'resources/workOrders/components/WorkOrderLinkButton'
import { Typography } from 'ui'

import { type InventoryHistoryModel } from '../../types'

const source = 'referenceNumber' as const
const label = 'Reference Number'

const value = (record: InventoryHistoryModel) => {
    if (record.type === 'MANUAL') {
        return null
    }

    if (record.type === 'PURCHASE_ORDER' && record.source) {
        return <PurchaseOrderTitle record={record.source} />
    }
    if (record.type === 'WORK_ORDER' && record.source) {
        return <WorkOrderLinkButton workOrder={record.source} />
    }
    if (record.type === 'INVOICE' && record.source) {
        return <InvoiceLinkButton {...record.source} />
    }
    return (
        <Typography
            variant="inherit"
            color={(theme) => theme.palette.text.disabled}
        >
            Deleted
        </Typography>
    )
}

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const dataCardRow = () =>
    ({
        source,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

export const referenceNumber = {
    value,
    tableColumn,
    dataCardRow,
}
