import { type FC, type ReactElement } from 'react'

import { ChoicesContextProvider, type ChoicesContextValue, useChoicesContext } from 'ra-core'
import { useFormContext, type UseFormReturn } from 'react-hook-form'

import { FormActionProvider } from 'context'
import { type ApiClientConfig } from 'core/api'

export const createLayerNotFoundKey = 'not-found-option'

interface ReferenceInputCreateLayerProps {
    children: (params: {
        choices: ChoicesContextValue
        formContext: UseFormReturn
        setValue: (value: any) => void
    }) => ReactElement
    emptyChoiceIdentifier?: string
}

export const ReferenceInputCreateLayer: FC<ReferenceInputCreateLayerProps> = ({
    children,
    emptyChoiceIdentifier = 'id',
}) => {
    const choices = useChoicesContext()
    const formContext = useFormContext()
    const emptyChoice = { [emptyChoiceIdentifier]: createLayerNotFoundKey }

    const source = choices.source

    return (
        // To prevent cache reset on create inside the input
        <FormActionProvider value={formActionProviderValue}>
            <ChoicesContextProvider
                // Autocomplete does not show List if choices is empty, this is why add something empty
                value={{ ...choices, allChoices: [emptyChoice, ...(choices.allChoices || [])] }}
            >
                {children({
                    choices,
                    formContext,
                    setValue: (value) => {
                        choices.refetch()
                        const currentValue = formContext.getValues(source)
                        formContext.setValue(
                            source,
                            Array.isArray(currentValue) ? [...currentValue, value] : value,
                            {
                                shouldValidate: true,
                                shouldDirty: true,
                            },
                        )
                    },
                })}
            </ChoicesContextProvider>
        </FormActionProvider>
    )
}

const formActionProviderValue = {
    queryParams: {
        clientConfig: { preventCacheReset: true } satisfies ApiClientConfig,
    },
}
