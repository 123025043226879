import { parseNumber, parseString } from 'core'
import { dateTimeParse } from 'lib'

import { type WoStatusKeys } from '../../types'

export const woActionPaths: { [key in WoStatusKeys]: string } = {
    OPEN: 'open',
    CLOSED: 'close',
    CANCELED: 'cancel',
    COMPLETED: 'complete',
    PENDING: 'pend',
}

export const getCloseBody = ({ formValues, isInvoicingOnly = false }) => {
    const { completed, pm, defects, issue, invoiceOnClose, invoicingOnWoClose, meters } = formValues

    const body: Record<string, any> = {}

    if (invoicingOnWoClose && invoiceOnClose) {
        body.invoiceOnClose = {
            poNumber: parseString(invoiceOnClose.poNumber),
            invoiceDate: dateTimeParse(invoiceOnClose.invoiceDate),
            ...invoiceOnClose,
        }
    }

    if (!isInvoicingOnly) {
        return Object.assign(body, {
            completed: dateTimeParse(completed),
            pmsToReset: pm ? Object.keys(pm).filter((pmId) => pm[pmId]) : [],
            defects: defects
                ? defects.map((defect) => ({
                      ...defect,
                      repairNote: (defect.reset && defect.repairNote) || '',
                  }))
                : [],
            issuesToClose: issue ? Object.keys(issue).filter((issueId) => issue[issueId]) : [],
            meters: meters
                ? Object.keys(meters).reduce((acc, key) => {
                      acc[key] = parseNumber(meters[key])
                      return acc
                  }, {})
                : undefined,
        })
    }

    return body
}
