import { type FC } from 'react'

import { type NoInfer } from 'appTypes'
import { type Column, type DataListDetails } from 'components'

import { InvoiceLinkButton } from '../components'
import { type InvoiceModel } from '../types'

const source = 'invoice' as const
const label = 'Invoice Number'

const Value: FC<Pick<InvoiceModel, 'id' | 'number'>> = (props) => {
    return <InvoiceLinkButton {...props} />
}

const tableColumn = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => InvoiceModel
}) =>
    ({
        field: (id || source) as NoInfer<Source>,
        headerName: label,
        renderCell: ({ row }) => <Value {...dataToRecord(row)} />,
    }) as const satisfies Column

const dataCardRow = <Source extends string = typeof source>({
    id,
    dataToRecord,
}: {
    id?: Source
    dataToRecord: (data) => InvoiceModel
}) =>
    ({
        source: (id || source) as NoInfer<Source>,
        label,
        render: (_, data) => <Value {...dataToRecord(data)} />,
    }) as const satisfies DataListDetails<any, any>

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const self = {
    source,
    label,
    Value,
    tableColumn,
    sort,
    dataCardRow,
}

export default self
