import { type FC, memo, type ReactElement } from 'react'

import { proxy, useSnapshot } from 'valtio'

import { makeid } from 'utils'

interface ProxyType {
    set: (render: () => ReactElement) => () => void
    value: Item[]
}

interface Item {
    render: () => ReactElement
    key: string
}

const renderAway = proxy<ProxyType>({
    set: (render) => {
        const key = makeid()
        renderAway.value = [...renderAway.value, { render, key }]
        return () => {
            renderAway.value = renderAway.value.filter((item) => item.key !== key)
        }
    },
    value: [],
})

export const RenderAwayPlaceholder = () => {
    const snap = useSnapshot(renderAway)

    // console.log(snap.value.length)

    return (
        <>
            {snap.value.map((item) => (
                <Element
                    key={item.key}
                    item={item}
                />
            ))}
        </>
    )
}

const Element: FC<{ item: Item }> = memo(({ item }) => {
    // eslint-disable-next-line react/prop-types
    return item.render()
})

export const useRenderAway = () => {
    return renderAway.set
}
