import { useListController } from 'ra-core'

import { RepositionInput } from 'components'
import { type PmModel } from 'resources/pm'

import PMRepositionLabel from './PMRepositionLabel'

const PMRepositionList = () => {
    const { data, isFetching, error } = useListController<PmModel>({
        filter: { type: 'dependent' },
        sort: { field: 'rank', order: 'DESC' },
    })

    if (isFetching || error) {
        return null
    }

    const repositionData = data.map((pm) => ({
        id: pm.id,
        position: `Position ${pm.rank}`,
        label: <PMRepositionLabel record={pm} />,
        ariaLabel: `Drag ${pm.name}`,
    }))

    return (
        <RepositionInput
            name="parentFirstOrder"
            data={repositionData}
        />
    )
}

export default PMRepositionList
