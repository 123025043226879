import { type FC, type ReactElement } from 'react'

import { useShowContext, useCanAccess } from 'core'
import { attachmentField } from 'resources/attachments'
import { photoSource } from 'resources/gallery'

import { woOperations } from '../../actions'
import { isWorkOrderBillable } from '../../isWorkOrderBillable'
import { type WorkOrderModel } from '../../types'
import { woResource } from '../../utils'

import JobDrawerToggler, { type JobDrawerTogglerProps } from './JobDrawerToggler'

interface Props extends Omit<JobDrawerTogglerProps, 'billable' | 'resource'> {
    children: (open: () => void) => ReactElement
}

const WoJobDrawerToggler: FC<Props> = (props) => {
    const { record: wo } = useShowContext<WorkOrderModel>()
    const getCanAccess = useCanAccess()
    const canAccess = getCanAccess(woOperations.jobs, wo)
    const canAccessNotes = getCanAccess(woOperations.notes, wo)
    const canAccessDocuments = getCanAccess(woOperations.documents, wo)
    const canAccessPhotos = getCanAccess(woOperations.photos, wo)

    return (
        <JobDrawerToggler
            {...props}
            mode={canAccess.value ? undefined : 'readonly'}
            billable={isWorkOrderBillable(wo)}
            resource={{
                name: 'wo-jobs',
                resource: woResource.jobsResource(wo.id),
            }}
            editableFieldsInReadOnlyMode={{
                notesToAdd: canAccessNotes.value,
                [photoSource]: canAccessPhotos.value,
                [attachmentField.source]: canAccessDocuments.value,
            }}
            titleOnDisabled={{
                notesToAdd: canAccessNotes.message,
                [photoSource]: canAccessPhotos.message,
                [attachmentField.source]: canAccessDocuments.message,
            }}
        />
    )
}

export default WoJobDrawerToggler
