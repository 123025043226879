import { useEffect, useState } from 'react'

import { MobileDateTimePicker, type MobileDateTimePickerProps } from '@mui/x-date-pickers'
import { type CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models'

import Icons from 'assets/icons'
import { useInputState, useInput } from 'core/inputs'
import { formatDate as _format, dateFormatsObject } from 'lib'

import TextInputBase from './TextInputBase'
import { type InputProps } from './types'

type DateInputProps = {
    returnDateFormat?: string
    disableFuture?: boolean
    className?: string
    required?: boolean
} & Pick<MobileDateTimePickerProps<any, any>, 'views'> &
    InputProps

const defaultViews: CalendarOrClockPickerView[] = ['year', 'month', 'day', 'hours', 'minutes']

const DateTimeInput2 = (props: DateInputProps) => {
    const {
        views = defaultViews,
        source: sourceProp,
        className,
        label,
        helperText,
        disableFuture = false,
        returnDateFormat = dateFormatsObject.serverDateTime,
        disabled,
        required,
        defaultValue,
        onBlur,
        onChange,
        validate,
    } = props

    const { source, ...state } = useInputState(sourceProp, { disabled })
    const { field, isRequired, fieldState } = useInput({
        source,
        onBlur,
        onChange,
        defaultValue,
        isRequired: required,
        validate,
    })
    const [value, setValue] = useState<Date>(null)
    const fieldValue = field.value
    const error = fieldState.error

    useEffect(() => {
        let newDate: Date | null = null
        if (fieldValue instanceof Date) {
            newDate = fieldValue
        } else {
            newDate = fieldValue ? new Date(String(fieldValue)) : null
        }
        setValue(newDate)
    }, [fieldValue])

    return (
        <MobileDateTimePicker
            className={className}
            label={label}
            views={views}
            disableFuture={disableFuture}
            onAccept={(newDate) => {
                try {
                    field.onChange(
                        returnDateFormat ? _format(newDate as Date, returnDateFormat) : newDate,
                    )
                } catch {
                    field.onChange(newDate)
                }
            }}
            value={value}
            onChange={setValue}
            {...state}
            renderInput={({ inputRef, error: inputError, ...params }) => {
                return (
                    <TextInputBase
                        {...params}
                        ref={inputRef}
                        error={error || inputError}
                        helperText={helperText}
                        required={isRequired}
                        onClear={() => field.onChange(null)}
                        focused={false}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: <Icons.CalendarTodayOutlined />,
                        }}
                    />
                )
            }}
        />
    )
}

export default DateTimeInput2
