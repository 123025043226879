import { TextInput } from 'components'
import { validateMaxLength } from 'core'

const RepairNoteInput = ({ source }: { source: string }) => {
    return (
        <TextInput
            multiline
            rows={2}
            label="Repair Note"
            source={source}
            validate={validate}
        />
    )
}

export default RepairNoteInput

const validate = validateMaxLength(1500)
