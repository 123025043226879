import { type FC } from 'react'

import Icons from 'assets/icons'
import {
    ViewHeader,
    type InfoCardHeaderProps,
    SliderView,
    ListTotalBadge,
    BoxLink,
} from 'components'
import { useCreateResourcePath, useListContext, useResource } from 'core'
import { LineItemTypeKeys } from 'resources/lineItems'
import { partFields } from 'resources/parts'
import { WithIsVendorWO, type JobModel } from 'resources/workOrders'
import { BoxContainer, Section } from 'ui'

import WorkOrderJobCard, { type WorkOrderJobCardItemsActions } from './WorkOrderJobCard'
import VendorWorkOrderExtraActionsDrawerToggler from './WorkOrderJobCard/components/VendorWorkOrderExtraActionsDrawerToggler'
import {
    LaborCreateDrawerToggler,
    PartCreateDrawerToggler,
} from './WorkOrderJobCard/components/WorkOrderJobCardItems/drawers'
import WorkOrderJobsEmpty from './WorkOrderJobsEmpty'
import { WorkOrderJobsFallback } from './WorkOrderJobsResource'
import { AddVendorPartMenu } from './components'

const itemActions: WorkOrderJobCardItemsActions = (job, { children, resource, listContext }) => {
    return [
        <WithIsVendorWO key={LineItemTypeKeys.PART}>
            {(isVendorWorkOrder) =>
                isVendorWorkOrder ? (
                    <AddVendorPartMenu
                        resource={resource}
                        listContext={listContext}
                        renderToggler={(open) =>
                            children({
                                title: (
                                    <BoxContainer
                                        justifyContent="space-between"
                                        component="span"
                                        width="140px"
                                    >
                                        Add Part <Icons.ArrowRightOutlined fontSize="small" />
                                    </BoxContainer>
                                ),
                                Icon: partFields.avatar.Icon,
                                onClick: open,
                                disableCloseOnClick: true,
                            })
                        }
                    />
                ) : (
                    <PartCreateDrawerToggler
                        key={LineItemTypeKeys.PART}
                        resource={resource}
                        listContext={listContext}
                        children={({ onClick }) =>
                            children({ title: 'Add Part', Icon: partFields.avatar.Icon, onClick })
                        }
                    />
                )
            }
        </WithIsVendorWO>,
        <LaborCreateDrawerToggler
            key={LineItemTypeKeys.LABOR}
            resource={resource}
            listContext={listContext}
            job={job}
            children={({ onClick }) =>
                children({
                    title: 'Add Labor',
                    Icon: Icons.ScheduleOutlined,
                    onClick,
                })
            }
        />,
        <VendorWorkOrderExtraActionsDrawerToggler
            key={LineItemTypeKeys.SERVICE}
            resource={resource}
            listContext={listContext}
            title="Add Services"
            itemType={LineItemTypeKeys.SERVICE}
            children={({ onClick }) =>
                children({
                    title: 'Add Services',
                    Icon: Icons.WorkOutlineOutlined,
                    onClick,
                })
            }
        />,
        <WithIsVendorWO key={LineItemTypeKeys.FEE}>
            {(isVendorWorkOrder) =>
                isVendorWorkOrder && (
                    <VendorWorkOrderExtraActionsDrawerToggler
                        resource={resource}
                        listContext={listContext}
                        title="Add Fee"
                        itemType={LineItemTypeKeys.FEE}
                        children={({ onClick }) =>
                            children({
                                title: 'Add Fee',
                                Icon: Icons.Fee,
                                onClick,
                            })
                        }
                    />
                )
            }
        </WithIsVendorWO>,
        <WithIsVendorWO key={LineItemTypeKeys.TAX}>
            {(isVendorWorkOrder) =>
                isVendorWorkOrder && (
                    <VendorWorkOrderExtraActionsDrawerToggler
                        resource={resource}
                        listContext={listContext}
                        title="Add Tax"
                        itemType={LineItemTypeKeys.TAX}
                        children={({ onClick }) =>
                            children({
                                title: 'Add Tax',
                                Icon: Icons.PercentOutlined,
                                onClick,
                            })
                        }
                    />
                )
            }
        </WithIsVendorWO>,
    ]
}

const WorkOrderJobsSlider: FC<InfoCardHeaderProps> = ({ action }) => {
    const jobsListContext = useListContext<JobModel>()

    const { data = [], total, isLoading } = jobsListContext
    const resource = useResource()
    const createPath = useCreateResourcePath()

    let content

    if (isLoading) {
        return <WorkOrderJobsFallback />
    }

    if (!isLoading) {
        if (total) {
            const items = data.map((job, i) => (
                <WorkOrderJobCard
                    key={`${job.name}-${i}`}
                    job={job}
                    jobsListContext={jobsListContext}
                    resource={resource}
                    itemActions={itemActions}
                />
            ))
            if (total >= 4) {
                const path = createPath({
                    type: 'list',
                })
                items.push(
                    <BoxLink
                        to={path}
                        label="SEE ALL JOBS"
                        icon={<Icons.ArrowForward />}
                    />,
                )
            }
            content = (
                <SliderView
                    height="219px"
                    items={items}
                />
            )
        } else {
            content = <WorkOrderJobsEmpty />
        }
    }

    return (
        <Section>
            <ViewHeader title="Jobs">
                <ViewHeader.Content>
                    <ListTotalBadge />
                </ViewHeader.Content>
                <ViewHeader.Content placement="rightMobile">{action}</ViewHeader.Content>
            </ViewHeader>
            {content}
        </Section>
    )
}
export default WorkOrderJobsSlider
