import { useMemo } from 'react'

import clsx from 'clsx'
import { MuiTelInput, matchIsValidTel } from 'mui-tel-input'
import { FieldTitle } from 'ra-core'
import {
    sanitizeInputRestProps,
    InputHelperText,
    type CommonInputProps,
    type ResettableTextFieldProps,
} from 'react-admin'

import { type Validator, useCreateInputId } from 'core/form'
import { useInput } from 'core/inputs'

const telValidator: Validator = (value: string) => {
    if (!value || matchIsValidTel(value)) {
        return null
    }
    return 'Invalid phone number'
}

const TelInput = (props: TelInputProps) => {
    const createId = useCreateInputId()
    const {
        className,
        label = 'Phone',
        source = 'phone',
        helperText,
        onChange,
        validate,
        ...rest
    } = props

    const _validate = useMemo(() => {
        if (!validate) {
            return telValidator
        }
        if (Array.isArray(validate)) {
            return [...validate, telValidator]
        }
        return [validate, telValidator]
    }, [validate])

    const {
        field,
        fieldState: { error, invalid, isTouched },
        formState: { isSubmitted },
        isRequired,
    } = useInput({
        ...props,
        source,
        validate: _validate,
    })

    return (
        <MuiTelInput
            defaultCountry="US"
            focusOnSelectCountry
            id={createId(source)}
            {...field}
            className={clsx('ra-input', `ra-input-${source}`, className)}
            label={
                label !== '' && label !== false ? (
                    <FieldTitle
                        label={label}
                        source={source}
                        isRequired={isRequired}
                    />
                ) : null
            }
            error={(isTouched || isSubmitted) && invalid}
            helperText={
                <InputHelperText
                    touched={isTouched || isSubmitted}
                    error={error?.message}
                    helperText={helperText}
                />
            }
            {...sanitizeInputRestProps(rest)}
        />
    )
}

type TelInputProps = Omit<CommonInputProps, 'source'> &
    Omit<ResettableTextFieldProps, 'label' | 'helperText' | 'source'> & { source?: string }

export default TelInput
