import { type FC } from 'react'

import { useWatch } from 'react-hook-form'

import Icons from 'assets/icons'
import { SelectorOption, DialogSelector, type DialogSelectorProps, TextInputBase } from 'components'
import { AddressSelectOption } from 'resources/address'
import { TagsInSelector } from 'resources/tags'
import { Button } from 'ui'

import customerFields from '../dataConfig'
import { type CustomerModel } from '../types'
import { customersResource } from '../utils'

import { CustomersDrawerToggler } from './CustomersDrawerToggler'

interface CustomerInputProps extends Pick<DialogSelectorProps, 'onSelectedChange'> {
    disabled?: boolean
    required?: boolean
    contextId?: string
    source?: string
    label?: string
}

export const CustomerInput: FC<CustomerInputProps> = ({
    source = 'customer',
    label = 'Customer',
    disabled,
    required,
    contextId,
    onSelectedChange,
}) => {
    return (
        <>
            <DialogSelector<CustomerModel>
                reference={customersResource.resource}
                source={source}
                defaultSelectorProps={{
                    label,
                }}
                onSelectedChange={onSelectedChange}
                disabled={disabled}
                required={required}
                contextId={contextId}
                defaultSelectorValueSource={({ selected }) => selected?.name}
                titleSource={() => 'Customers'}
                renderItem={renderOption}
                renderNextToResultCount={({ control, onSelect }) => (
                    <CustomersDrawerToggler
                        onSuccess={(record) => {
                            onSelect(record.id)
                            control.refetch()
                        }}
                        shortSuccessMessage
                    >
                        {(open) => (
                            <Button
                                startIcon={<Icons.Add />}
                                variant="contained"
                                onClick={open}
                            >
                                Create Customer
                            </Button>
                        )}
                    </CustomersDrawerToggler>
                )}
                noResults={({ searchValue }) => {
                    if (!searchValue) {
                        return {
                            title: 'No Customers Added',
                            text: '',
                            image: (images) => images.listEmpty,
                            imageWidth: '180px',
                        }
                    }
                }}
            />
        </>
    )
}

const renderOption = (record: CustomerModel) => (
    <SelectorOption>
        <SelectorOption.Title avatar={<customerFields.avatar.Value record={record} />}>
            {record.name}
        </SelectorOption.Title>
        <SelectorOption.Details>
            <AddressSelectOption record={record} />
        </SelectorOption.Details>
        <TagsInSelector tags={record.tagsData} />
    </SelectorOption>
)

export const CustomerReadOnlyInput: FC<{ source?: string }> = ({ source = 'customer' }) => {
    const v = useWatch({ name: source })

    return (
        <TextInputBase
            value={v?.name || ''}
            label="Customer"
            disabled
        />
    )
}
