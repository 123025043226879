// eslint-disable-next-line no-restricted-imports
import { type CreatePathParams, useCreatePath, useResourceContext } from 'ra-core'

import { type Optional } from 'appTypes'

export interface UseCreateResourcePathParams
    extends Optional<Omit<CreatePathParams, 'type'>, 'resource'> {
    queryParams?: Record<string, string>
    type: 'list' | 'show' | 'edit' | 'create'
}

const useCreateResourcePath = () => {
    const resource = useResourceContext()
    const createPath = useCreatePath()

    return (params: UseCreateResourcePathParams) => {
        const resourceName = params.resource || resource

        let url = createPath({
            ...params,
            resource: resourceName,
        })

        if (params.queryParams) {
            const queryParams = new URLSearchParams(params.queryParams).toString()
            url += queryParams ? `?${queryParams}` : ''
        }

        return url
    }
}

export default useCreateResourcePath
