import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { Box, Scrollbar } from 'ui'

interface Props extends WithChildrenNode {
    scrollable?: boolean
}

const Body: FC<Props> = ({ children, scrollable }) => {
    const content = (
        <Box
            boxSizing="content-box"
            className="monitor-widget__body"
        >
            {children}
        </Box>
    )

    if (!scrollable) {
        return content
    }

    return (
        <Scrollbar
            replaceP="xb"
            alwaysVisible
            shadow="t"
        >
            {content}
        </Scrollbar>
    )
}

export default Body
