import { type FC } from 'react'

import { useRecordContext } from 'react-admin'
import { useFormContext } from 'react-hook-form'

import {
    useOpenUtilityDrawer,
    UtilityDrawerBackButton,
    UtilityDrawerNextButton,
    UtilityDrawerStepsContent,
    UtilityDrawerStepper,
    UtilityDrawerStepsContextProvider,
    type StepsParser,
} from 'components'
import { type RenderWrapperProps } from 'components/UtilityDrawer'
import { taxesTotal } from 'resources/common'
import { formatPOTotal, poResource, type PurchaseOrderModel } from 'resources/purchaseOrders'

import { useIsMismatch } from '../../hooks'
import {
    DrawerTotal,
    poFeesStep,
    poFeesStepSerializer,
    poInvoiceStep,
    poInvoiceStepSerializer,
} from '../components'
import { type PoStepsExtra } from '../types'

import { type CloseFormData } from './types'

const useClosePo = () => {
    const open = useOpenUtilityDrawer()
    const record = useRecordContext<PurchaseOrderModel>()

    return () => {
        open({
            drawerArgs: {
                title: 'Close PO',
                renderWrapper: (props) => (
                    <Wrapper
                        {...props}
                        po={record}
                    />
                ),
                renderBelowHeader: () => <UtilityDrawerStepper />,
                renderBottomLeft: () => <UtilityDrawerBackButton />,
                renderBottomRight: () => <UtilityDrawerNextButton />,
                renderContent: () => <UtilityDrawerStepsContent />,
                renderAboveFooter: () => (
                    <DrawerTotal
                        text="Total Credit"
                        totalContent={<TotalContent po={record} />}
                    />
                ),
            },
        })
    }
}

export default useClosePo

const TotalContent: FC<{ po: PurchaseOrderModel }> = ({ po }) => {
    const { watch } = useFormContext<CloseFormData>()
    const taxes = watch('taxes')

    return <span>{formatPOTotal(po.total + taxesTotal(taxes), true)}</span>
}

const Wrapper = ({ po, ...props }: RenderWrapperProps & { po: PurchaseOrderModel }) => {
    const extra: PoStepsExtra = {
        po,
    }

    const mismatch = useIsMismatch()

    return (
        <UtilityDrawerStepsContextProvider
            {...props}
            parser={dataParser}
            extra={extra}
            successMessage="Successfully saved"
            submitEndpoint={poResource.close(po.id)}
            onFormError={(error, { formValues }) => {
                if (mismatch.hasError(error)) {
                    formValues = formValues as CloseFormData

                    mismatch.action({
                        objectTotal: po.total + taxesTotal(formValues.taxes),
                        invoiceTotal: Number(formValues.invoice?.amount || 0),
                        objectName: 'Receipt',
                    })
                    return true
                }
            }}
            steps={[poFeesStep(), poInvoiceStep()]}
        />
    )
}

interface ParserExtra {
    isDraft?: boolean
}

const dataParser: StepsParser<CloseFormData, CloseFormData, ParserExtra> = [
    poFeesStepSerializer,
    poInvoiceStepSerializer,
]
