import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    SelectorOption,
    type DialogSelectorProps,
    DialogSelector,
    formatMoney,
    type DialogSelectorUniversalParam,
    useDialogSelectorContext,
} from 'components'
import { type AuthStore } from 'core/auth'
import InventoryItemDrawerToggler from 'resources/inventory/components/InventoryItemDrawerToggler'
import inventoryFields from 'resources/inventory/fields'
import { type PartWithInventoryModel } from 'resources/inventory/types'
import { TagsInSelector } from 'resources/tags'
import { uomFields } from 'resources/unitsOfMeasure'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { Button, IconButton, type ButtonProps } from 'ui'

import partFields from '../fields'
import { type VendorPartModel, type PartModel } from '../types'
import { partsResource } from '../utils'

import { PartDrawerToggler } from './PartDrawerToggler'
import PartScanner from './PartScanner'
import { VendorPartDrawerToggler } from './VendorPartDrawerToggler'

type Record = VendorPartModel | PartModel | PartWithInventoryModel

interface Props<RecordType extends Record = Record>
    extends Pick<
        DialogSelectorProps<RecordType>,
        | 'refetchOnOpen'
        | 'disabled'
        | 'onSelectedChange'
        | 'referenceFilter'
        | 'required'
        | 'resettable'
        | 'renderToggler'
        | 'onChange'
        | 'defaultFilter'
        | 'onClose'
    > {
    successMessage?: string
    disableCreateOption?: boolean
    inputTitle?: string
    contextType: string
    contextId?: Identifier
    type?: PartType
    source?: string
    reference?: string
    createValues?: {
        shop?: Identifier
    }
    allowInventoryCreate?: boolean
}

type PartType = 'vendor' | 'inventory' | 'internal'

export const PartInput = inject('auth')(
    observer(
        ({
            reference = partsResource.resource,
            successMessage,
            disableCreateOption,
            inputTitle = 'Part No',
            auth,
            contextType,
            contextId,
            type: typeProp = 'internal',
            required = true,
            createValues,
            allowInventoryCreate,
            defaultFilter,
            ...props
        }: Props & { auth: AuthStore }) => {
            const type =
                typeProp === 'inventory'
                    ? auth.companySettings.hasInventory
                        ? 'inventory'
                        : 'internal'
                    : typeProp

            const title = getTitle(type)

            return (
                <DialogSelector<Record>
                    source="part"
                    contextType={contextType}
                    contextId={contextId}
                    titleSource={() => title}
                    reference={reference}
                    defaultSelectorProps={{
                        label: inputTitle,
                    }}
                    renderSelectedToggler={({ selected }) => renderOption(selected, type, true)}
                    required={required}
                    renderItem={(record) => renderOption(record, type)}
                    noResults={({ searchValue }) => {
                        if (!searchValue) {
                            return {
                                title: 'No Parts Added',
                                text: '',
                                image: (images) => images.listEmpty,
                                imageWidth: '180px',
                            }
                        }
                    }}
                    renderNoResults={({ filterValues }) =>
                        filterValues.recentlyUsed ? null : undefined
                    }
                    defaultFilter={defaultFilter}
                    filter={{
                        renderRight:
                            type !== 'vendor' &&
                            (() => {
                                return (
                                    <PartScanner
                                        contextType={contextType}
                                        contextId={contextId}
                                        filter={defaultFilter}
                                    />
                                )
                            }),
                    }}
                    renderNextToResultCount={(data) => (
                        <AddButton
                            data={data}
                            partType={type}
                            createValues={createValues}
                            allowInventoryCreate={allowInventoryCreate}
                        />
                    )}
                    defaultSelectorValueSource={({ selected }) => {
                        return selected?.number
                    }}
                    {...props}
                />
            )
        },
    ),
) as <RecordType extends Record = Record>(props: Props<RecordType>) => JSX.Element

const getTitle = (type: PartType) => {
    if (type === 'vendor') {
        return 'Vendor Parts'
    }
    if (type === 'inventory') {
        return 'Part from Inventory'
    }
    return 'Parts Catalog'
}

const Unselect = () => {
    const { onSelect, disabled } = useDialogSelectorContext()

    if (disabled) {
        return null
    }

    return (
        <IconButton
            size="small"
            onClick={() => onSelect(null)}
        >
            <Icons.Clear />
        </IconButton>
    )
}

const renderOption = (record: Record, type: PartType, selected?: boolean) => {
    const inventoryItem =
        (type === 'inventory' ? (record as PartWithInventoryModel).inventoryItems?.[0] : null) ||
        ({} as any)

    const availableQty = !selected && type === 'inventory' ? inventoryItem.availableQuantity : null

    const upc = type === 'vendor' ? null : (record as PartModel).universalProductCode

    const binLocationId = !selected && type === 'inventory' ? inventoryItem.binLocationId : null

    const averateCost = !selected && type === 'inventory' ? inventoryItem.acquisitionCost : null

    return (
        <SelectorOption>
            <SelectorOption.Title
                avatar={<partFields.avatar.Value record={record as VendorPartModel} />}
                rightContent={selected && <Unselect />}
            >
                {record.number}
            </SelectorOption.Title>

            <SelectorOption.Details>
                {typeof availableQty === 'number' ? (
                    <SelectorOption.Row
                        label="Available QTY"
                        Icon={inventoryFields.avatar.Icon}
                    >
                        {availableQty}
                    </SelectorOption.Row>
                ) : null}

                {record.description ? (
                    <SelectorOption.Row
                        label={partFields.description.label}
                        Icon={Icons.DescriptionOutlined}
                    >
                        {record.description}
                    </SelectorOption.Row>
                ) : null}

                <SelectorOption.Row
                    label="Component Code"
                    Icon={Icons.Code}
                >
                    {ck33Fields.self.formatCode(
                        record.componentData.code,
                        record.componentData.level,
                    )}
                </SelectorOption.Row>

                <SelectorOption.Row
                    label="Component Description"
                    Icon={Icons.ComponentDescription}
                >
                    {record.componentData.text}
                </SelectorOption.Row>

                {record.manufacturer ? (
                    <SelectorOption.Row
                        label={ck34Fields.self.label}
                        Icon={Icons.FactoryOutlined}
                    >
                        {ck34Fields.self.value(record.manufacturerData)}
                    </SelectorOption.Row>
                ) : null}

                {upc ? (
                    <SelectorOption.Row
                        label={partFields.upc.label}
                        Icon={Icons.Barcode}
                    >
                        {upc}
                    </SelectorOption.Row>
                ) : null}

                {record.unitOfMeasure ? (
                    <SelectorOption.Row
                        label={uomFields.self.label}
                        Icon={Icons.Straighten}
                    >
                        {uomFields.self.value(record.unitOfMeasureData)}
                    </SelectorOption.Row>
                ) : null}

                {binLocationId ? (
                    <SelectorOption.Row
                        label="Bin Location ID"
                        Icon={Icons.WarehouseOutlined}
                    >
                        {binLocationId}
                    </SelectorOption.Row>
                ) : null}

                {typeof averateCost === 'number' ? (
                    <SelectorOption.Row
                        label="Average Cost"
                        Icon={Icons.TrendingUp}
                    >
                        {formatMoney(averateCost)}
                    </SelectorOption.Row>
                ) : null}
            </SelectorOption.Details>

            {!selected && <TagsInSelector tags={record.tagsData} />}
        </SelectorOption>
    )
}

interface AddButtonProps {
    data: DialogSelectorUniversalParam
    partType: PartType
    createValues?: any
    allowInventoryCreate?: boolean
}

const AddButton: FC<AddButtonProps> = ({ data, partType, createValues, allowInventoryCreate }) => {
    const { control, onSelect } = data

    if (partType === 'vendor') {
        return (
            <VendorPartDrawerToggler
                shortSuccessMessage
                onSuccess={(record) => {
                    onSelect(record.id)
                    control.refetch()
                }}
                children={({ onClick }) => (
                    <ButtonView
                        onClick={onClick}
                        data={data}
                    >
                        Create New Part
                    </ButtonView>
                )}
            />
        )
    }

    if (partType === 'inventory') {
        if (!allowInventoryCreate) {
            return null
        }

        return (
            <InventoryItemDrawerToggler
                values={createValues}
                shortSuccessMessage
                onSuccess={(record) => {
                    onSelect(record.part)
                    control.refetch()
                }}
                children={({ onClick }) => (
                    <ButtonView
                        onClick={onClick}
                        data={data}
                    >
                        Add Part
                    </ButtonView>
                )}
            />
        )
    }

    return (
        <PartDrawerToggler
            shortSuccessMessage
            onSuccess={(record) => {
                onSelect(record.id)
                control.refetch()
            }}
            children={({ onClick }) => (
                <ButtonView
                    onClick={onClick}
                    data={data}
                >
                    Create New Part
                </ButtonView>
            )}
        />
    )
}

const ButtonView: FC<ButtonProps & Pick<AddButtonProps, 'data'>> = ({ data, ...props }) => {
    return (
        <Button
            startIcon={<Icons.Add />}
            variant="contained"
            sx={{ mt: '20px' }}
            {...props}
        />
    )
}
