import Icons from 'assets/icons'
import { useRecordContext } from 'core'
import useClosePo from 'pages/PurchaseOrders/actions/close/close'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { PrimaryButton } from 'ui'

const CloseCreditPoButton = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    const close = useClosePo()

    return (
        <PrimaryButton
            startIcon={<Icons.CloseCreditPO />}
            onClick={close}
            disabled={!record.items || record.status === 'CANCELED' || record.status === 'CLOSED'}
        >
            Close PO
        </PrimaryButton>
    )
}

export default CloseCreditPoButton
