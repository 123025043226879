import { type FC } from 'react'

import { useListContext, useRecordContext } from 'ra-core'

import { AddButton, ViewHeader } from 'components'
import { formatDate, useParams } from 'lib'
import {
    getMeterConfig,
    isUnitArchived,
    type UnitMeterTypes,
    type UnitModel,
} from 'resources/units'
import { InfoBadge } from 'ui'

import { type MeterUrlParams } from '../utils'

import { CreateMeterDrawerToggler } from './UnitMeterDrawerToggler'

const Header: FC = () => {
    const { total, isLoading } = useListContext()
    const record = useRecordContext<UnitModel>()
    const { type } = useParams<MeterUrlParams>()

    const meterConfig = getMeterConfig(type)

    let lastSynced = ''
    if (record.telematicsData && record.telematicsData?.telematicsStatus !== 'DISCONNECTED') {
        lastSynced =
            ' • Last Synced ' +
            formatDate(record.telematicsData.lastSyncedMeters, 'MMM dd, yyyy HH:mm')
    }
    const disabled = !record.settings[type]?.tracked

    return (
        <ViewHeader
            title={meterConfig.name}
            loading={isLoading}
        >
            <ViewHeader.Content>
                <InfoBadge badgeContent={disabled ? 'Not Tracked' : String(total) + lastSynced} />
            </ViewHeader.Content>
            <ViewHeader.Content placement="rightMobile">
                <ActionAdd
                    type={type}
                    disabled={disabled}
                />
            </ViewHeader.Content>
        </ViewHeader>
    )
}

export default Header

const ActionAdd: FC<{ type: UnitMeterTypes; disabled: boolean }> = ({ type, disabled }) => {
    const record = useRecordContext<UnitModel>()

    if (isUnitArchived(record)) {
        return null
    }

    return (
        <CreateMeterDrawerToggler type={type}>
            {(params) => (
                <AddButton
                    disabled={disabled}
                    title="Add Meter"
                    {...params}
                />
            )}
        </CreateMeterDrawerToggler>
    )
}
