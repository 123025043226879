import { useListContext } from 'ra-core'

import { hasPermission, basePermissions, useResourcePermissions } from 'core/permissions'

import { type ActionChildren, type ListActionExtendedOption } from '../Actions'

import { type ArchiveActionArchiveOption, archiveActionBaseOptions } from './archiveActionCommon'
import useArchiveManyFromList, { type ArchiveManyFromListParams } from './useArchiveManyFromList'

interface ArchiveManyFromListActionProps extends ArchiveManyFromListParams {
    children: ActionChildren<{}, ListActionExtendedOption & ArchiveActionArchiveOption>
}

export const ArchiveManyFromListAction = ({
    children,
    ...rest
}: ArchiveManyFromListActionProps) => {
    const archiveMany = useArchiveManyFromList()
    const listContext = useListContext()

    const permissions = useResourcePermissions(rest.resource)

    if (
        !hasPermission(
            rest.isArchived
                ? permissions[basePermissions.unarchiveBulk]
                : permissions[basePermissions.archiveBulk],
        )
    ) {
        return null
    }

    return children(
        {
            ...archiveActionBaseOptions(rest.isArchived),
            onClick: () => {
                archiveMany(rest)
            },
        },
        {
            listContext,
            isArchived: rest.isArchived,
        },
    )
}

export const archiveManyFromListAction = (params: ArchiveManyFromListActionProps) => (
    <ArchiveManyFromListAction
        {...params}
        key="archive-many-from-list"
    />
)
