import { type FC } from 'react'

import { inject } from 'mobx-react'

import Icons from 'assets/icons'
import { type AuthStore, useShowContext, type ActionChildren } from 'core'
import { InventoryItemDrawerToggler } from 'resources/inventory'
import { type PartModel } from 'resources/parts'

interface Props {
    children: ActionChildren
}

const PartAddToInventoryAction: FC<Props> = inject('auth')(({
    auth,
    children,
}: Props & { auth: AuthStore }) => {
    const shops = auth.user.membership.shops
    const { record } = useShowContext<PartModel>()

    return (
        <InventoryItemDrawerToggler values={{ part: record.id }}>
            {({ onClick }) =>
                children({
                    Icon: Icons.InventoryAdd,
                    title: 'Add to Inventory',
                    disabled:
                        record.inventoryCount >= shops.length ||
                        // if there is a selected shop, inventory count is either 0 or 1
                        // 1 means the part is already in the inventory with the current shop
                        (auth.shop && record.inventoryCount === 1),
                    onClick,
                })
            }
        </InventoryItemDrawerToggler>
    )
})
export default PartAddToInventoryAction
