import { formatMoney } from 'components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'

export const deletePOTitle = 'Are you sure you want to delete this PO?'

export const getDisableDeleteTitle = (purchaseOrder: PurchaseOrderModel) => {
    if (purchaseOrder.type === 'WORK_ORDER') {
        return 'WO-generated Purchase Orders cannot be deleted'
    }
    if (purchaseOrder.status === 'CANCELED') {
        return 'Canceled POs cannot be deleted'
    }
    if (purchaseOrder.status === 'CLOSED') {
        return 'Closed POs cannot be deleted'
    }
    if (purchaseOrder.dateLastReceived) {
        return 'Purchase Orders with received items cannot be deleted'
    }
    if (purchaseOrder.status === 'DRAFT') {
        return 'Purchase Orders in Draft mode cannot be deleted'
    }
    return null
}

export const formatDiscount = (value: number) => {
    return formatMoney({ value, toNegative: true, featured: true, negativeFormat: 'accounting' })
}
