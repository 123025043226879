import { type FC } from 'react'

import { useStore } from 'ra-core'

import { useDidUpdate } from 'hooks'
import { useResetQueries } from 'lib'

const ResetCache: FC = () => {
    const [value] = useStore('lastMutationDate', 0)
    const reset = useResetQueries()

    useDidUpdate(() => {
        reset()
    }, [value])

    return null
}

export default ResetCache
