import { type FC } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import {
    useUtilityDrawerContext,
    DateTimeInput,
    TextareaInput,
    TextInput as TextInputBase,
    CheckboxInput,
} from 'components'
import { maxLengthValidationText, requiredValidation, validateMaxLength } from 'core'
import { AttachmentsFormInput } from 'resources/attachments'
import { maxJobNameLength } from 'resources/jobs'
import { CK14Input, CK33InputWithoutParts } from 'resources/vmrs'
import { UnderInputTooltip } from 'ui'

import { type PmModel } from '../../types'
import { PMLastMetersFetch } from '../PMLastMeters'

import UnitPMFormIntervals from './UnitPMFormIntervals'
import { type UnitPMDrawerEditorExtraState } from './types'

const TextInput = TextInputBase<PmModel>

const nameValidation = [validateMaxLength(maxJobNameLength), requiredValidation]

const UnitPMForm: FC = () => {
    const id = useWatch({ name: 'id' })
    const { extra } = useUtilityDrawerContext()
    const { unit, isArchived } = extra as UnitPMDrawerEditorExtraState

    return (
        <>
            <TextInput
                disabled={isArchived}
                source="name"
                label="Name"
                validate={nameValidation}
            />

            <CK33InputWithoutParts
                source="component"
                required
                disabled={isArchived}
            />

            <CK14Input
                disabled={isArchived}
                defaultValueByCode={id ? undefined : '08'}
            />

            <TextareaInput
                disabled={isArchived}
                source="description"
                label="Notes"
                validate={maxLengthValidationText}
            />
            <UnderInputTooltip title={id ? 'Use interval Reset action to edit this value' : ''}>
                <div>
                    <DateTimeInput
                        disableFuture
                        source="lastDone"
                        label="Last Done Date"
                        validate={requiredValidation}
                        disabled={Boolean(id) || isArchived}
                        defaultValue={new Date()}
                    />
                </div>
            </UnderInputTooltip>
            <AutoCreateWoInput disabled={isArchived} />
            <PMLastMetersFetch
                unitId={unit.id}
                archived={unit.archived}
            />
            <UnitPMFormIntervals />
            <AttachmentsFormInput
                disabled={isArchived}
                titleOnDisabled="Unarchive the unit to add document"
            />
        </>
    )
}

export default UnitPMForm

const AutoCreateWoInput: FC<{ disabled: boolean }> = ({ disabled }) => {
    const { clearErrors } = useFormContext()
    return (
        <CheckboxInput
            source="autoCreateWo"
            label='Automatically create a Pending WO when the status changes to "Due Soon".'
            disabled={disabled}
            onChange={() => {
                clearErrors()
            }}
        />
    )
}
