import { type FC, type PropsWithChildren } from 'react'

import { MenuItem, SimpleScrollbar, Skeleton, Stack } from 'ui'

const StatusListContainer: FC<PropsWithChildren & { isLoading: boolean }> = ({
    children,
    isLoading,
}) => {
    return (
        <SimpleScrollbar style={{ maxHeight: '193px', width: '181px' }}>
            {isLoading ? <DropdownSkeleton /> : children}
        </SimpleScrollbar>
    )
}

export default StatusListContainer

const DropdownSkeleton: FC = () => {
    return (
        <Stack>
            {Array(5)
                .fill(null)
                .map((_, i) => {
                    return (
                        <MenuItem key={i}>
                            <Skeleton />
                        </MenuItem>
                    )
                })}
        </Stack>
    )
}
