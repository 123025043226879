import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import { type StepConfig, useUtilityDrawerStepsContext } from 'components'
import { serialize } from 'core'
import {
    PurchaseInvoiceForm,
    type PurchaseInvoiceModel,
    purchaseInvoiceSerializer,
} from 'resources/purchaseInvoices'
import { Alert, Typography } from 'ui'

import { type PoStepsExtra } from '../types'

interface POInvoiceFormProps {
    getSource?: (source: string) => string
    defaultTerm: Identifier
}

const PoInvoiceForm: FC<POInvoiceFormProps> = ({ getSource, defaultTerm }) => {
    return (
        <>
            <Alert
                severity="info"
                sx={{ mb: '20px' }}
            >
                Match the receipt total with the invoice total to close the purchase order.
            </Alert>
            <Typography
                variant="subtitle1"
                mb="20px"
            >
                Add Invoice
            </Typography>
            <PurchaseInvoiceForm
                getSource={getSource}
                defaultValues={{
                    term: defaultTerm,
                }}
            />
        </>
    )
}

const PoInvoiceStep = () => {
    const { extra } = useUtilityDrawerStepsContext()
    const { po } = extra as PoStepsExtra

    return (
        <PoInvoiceForm
            defaultTerm={po.vendorData.paymentTerm}
            getSource={(source) => 'invoice.' + source}
        />
    )
}

export const poInvoiceStep = (): StepConfig => ({
    title: 'Invoice',
    Content: PoInvoiceStep,
})

export const poInvoiceStepSerializer = (data: { invoice: PurchaseInvoiceModel }) => {
    return {
        requestData: {
            invoice: serialize(data.invoice, purchaseInvoiceSerializer),
        },
    }
}
