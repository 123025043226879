import { useListContext } from 'ra-core'

import { renderOnPermission, basePermissions } from 'core/permissions'

import { type ActionChildren, type ListActionExtendedOption } from '../Actions'

import { deleteActionBaseOptions } from './deleteActionCommon'
import useDeleteManyFromList, { type DeleteManyParams } from './useDeleteManyFromList'

// TODO: Action - children second param is optional. Make it required
interface DeleteManyFromListActionProps extends DeleteManyParams {
    children: ActionChildren<{}, ListActionExtendedOption>
}

export const DeleteManyFromListAction = renderOnPermission(
    ({ children, ...rest }: DeleteManyFromListActionProps) => {
        const deleteMany = useDeleteManyFromList()
        const listContext = useListContext()
        return children(
            {
                ...deleteActionBaseOptions,
                onClick: () => {
                    deleteMany(rest)
                },
            },
            {
                listContext,
            },
        )
    },
    basePermissions.destroyBulk,
)

export const deleteManyFromListAction = (params: DeleteManyFromListActionProps) => (
    <DeleteManyFromListAction
        {...params}
        key="delete-many-from-list"
    />
)
