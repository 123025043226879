import { type FC } from 'react'

import { useGetList } from 'ra-core'

import { type Identifier } from 'appTypes'
import {
    type ActionChildren,
    ActionsMenu,
    api,
    type MenuActionProps,
    updatedMessage,
    useFinalErrorHandler,
    useNotify,
} from 'core'
import { BaseStatusIcon, StatusListContainer } from 'resources/common'

import { type UnitStatusModel } from '../types'
import { unitStatusResource } from '../utils'

interface StatusMenuProps extends Pick<MenuActionProps, 'renderToggler'> {
    endpoint?: string
    id?: Identifier
    selectedStatusId?: Identifier
    onSuccess?: () => void
}

const StatusMenu: FC<StatusMenuProps> = ({ renderToggler, ...rest }) => {
    return (
        <ActionsMenu
            renderToggler={renderToggler}
            actions={(params, { children }) => [
                <StatusList
                    key="unit-status-list"
                    children={children}
                    {...rest}
                />,
            ]}
        />
    )
}

export default StatusMenu

const StatusList = ({
    selectedStatusId,
    onSuccess,
    id,
    endpoint,
    children,
}: { children: ActionChildren } & Pick<
    StatusMenuProps,
    'selectedStatusId' | 'onSuccess' | 'id' | 'endpoint'
>) => {
    const { data, isLoading } = useGetList<UnitStatusModel>(unitStatusResource.resource, {
        pagination: {
            page: 1,
            perPage: 1000,
        },
    })
    const errorHandler = useFinalErrorHandler()
    const notify = useNotify()

    return (
        <StatusListContainer isLoading={isLoading}>
            {data?.map((status) => {
                if (status.id === selectedStatusId) {
                    return null
                }

                return children({
                    Icon: <BaseStatusIcon color={status.color} />,
                    title: status.name,
                    key: status.id as string,
                    onClick: async () => {
                        try {
                            await api.post(endpoint || unitStatusResource.changeStatus(id), {
                                status: status.id,
                            })
                            onSuccess?.()
                            notify(updatedMessage)
                        } catch (err) {
                            errorHandler(err)
                        }
                    },
                })
            })}
        </StatusListContainer>
    )
}
