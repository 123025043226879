import { type MouseEventHandler, type ReactElement, useCallback } from 'react'

import {
    type CreateParams,
    type TransformData,
    type UpdateParams,
    useSaveContext,
    useTranslate,
    warning,
    setSubmissionErrors,
} from 'ra-core'
import { useFormContext, useFormState } from 'react-hook-form'
import { type UseMutationOptions } from 'react-query'

import { type DataRecord } from 'appTypes'
import Icons from 'assets/icons'
import { styled } from 'lib'
import { Button, type ButtonProps, CircularProgress } from 'ui'

export const RaSaveButton = <RecordType extends DataRecord = any>(
    props: RaSaveButtonProps<RecordType>,
) => {
    const {
        color = 'primary',
        icon = defaultIcon,
        invalid,
        label = 'ra.action.save',
        onClick,
        mutationOptions,
        saving,
        disabled: disabledProp,
        type = 'submit',
        transform,
        variant = 'contained',
        alwaysEnable = false,
        ...rest
    } = props
    const translate = useTranslate()
    const form = useFormContext()
    const saveContext = useSaveContext()
    const { dirtyFields, isValidating, isSubmitting } = useFormState()
    // useFormState().isDirty might differ from useFormState().dirtyFields (https://github.com/react-hook-form/react-hook-form/issues/4740)
    const isDirty = Object.keys(dirtyFields).length > 0
    // Use form isDirty, isValidating and form context saving to enable or disable the save button
    // if alwaysEnable is undefined
    const disabled = valueOrDefault(
        alwaysEnable === false || alwaysEnable === undefined ? undefined : !alwaysEnable,
        disabledProp || !isDirty || isValidating || saveContext?.saving || isSubmitting,
    )

    warning(
        type === 'submit' &&
            ((mutationOptions && (mutationOptions.onSuccess || mutationOptions.onError)) ||
                transform),
        'Cannot use <SaveButton mutationOptions> props on a button of type "submit". To override the default mutation options on a particular save button, set the <SaveButton type="button"> prop, or set mutationOptions in the main view component (<Create> or <Edit>).',
    )

    const handleSubmit = useCallback(
        async (values) => {
            let errors
            if (saveContext?.save) {
                errors = await saveContext.save(values, {
                    ...mutationOptions,
                    transform,
                })
            }
            if (errors != null) {
                setSubmissionErrors(errors, form.setError)
            }
        },
        [form.setError, saveContext, mutationOptions, transform],
    )

    const handleClick: MouseEventHandler<HTMLButtonElement> = useCallback(
        async (event) => {
            if (onClick) {
                onClick(event)
            }
            if (event.defaultPrevented) {
                return
            }
            if (type === 'button') {
                // this button doesn't submit the form, so it doesn't trigger useIsFormInvalid in <FormContent>
                // therefore we need to check for errors manually
                event.stopPropagation()
                await form.handleSubmit(handleSubmit)(event)
            }
        },
        [onClick, type, form, handleSubmit],
    )

    const displayedLabel = label && translate(label, { _: label })
    // eslint-disable-next-line no-negated-condition
    const finalSaving = typeof saving !== 'undefined' ? saving : saveContext?.saving || isSubmitting

    return (
        <StyledButton
            variant={variant}
            type={type}
            color={color}
            aria-label={displayedLabel}
            disabled={disabled}
            onClick={handleClick}
            // TODO: find a way to display the loading state (LoadingButton from mui Lab?)
            {...rest}
        >
            {finalSaving ? (
                <CircularProgress
                    size={18}
                    thickness={2}
                />
            ) : (
                icon
            )}
            {displayedLabel}
        </StyledButton>
    )
}

const defaultIcon = <Icons.Save />

interface Props<RecordType extends DataRecord = any, MutationOptionsError = unknown> {
    className?: string
    disabled?: boolean
    icon?: ReactElement
    invalid?: boolean
    label?: string
    mutationOptions?: UseMutationOptions<
        RecordType,
        MutationOptionsError,
        CreateParams<RecordType> | UpdateParams<RecordType>
    >
    transform?: TransformData
    saving?: boolean
    variant?: string
}

export type RaSaveButtonProps<RecordType extends DataRecord = any> = Props<RecordType> &
    ButtonProps & {
        alwaysEnable?: boolean
    }

const PREFIX = 'RaSaveButton'

const StyledButton = styled(Button, {
    name: PREFIX,
    overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
    position: 'relative',
    [`& .MuiSvgIcon-root, & .MuiIcon-root, & .MuiCircularProgress-root`]: {
        marginRight: theme.spacing(1),
    },
    [`& .MuiSvgIcon-root, & .MuiIcon-root`]: {
        fontSize: 18,
    },
}))

const valueOrDefault = (value, defaultValue) =>
    typeof value === 'undefined' ? defaultValue : value
