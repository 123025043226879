import { type FC } from 'react'

import { type BaseModel } from 'appTypes'
import { type SortBy, type Column, type DataListDetails, type FilterBy } from 'components'
import { ActionsMenu, ActionsOption } from 'core/actions'
import { getFileExtensionData } from 'core/files'
import { Anchor } from 'ui'
import { getFileName } from 'utils'

import { type WithAttachments } from '../types'

const source = 'attachmentsCount' as const
const label = 'Documents'

const value = (record: WithAttachments & BaseModel) => {
    if (!record.attachmentsCount) {
        return null
    }

    return (
        <ActionsMenu
            actions={() => {
                return getFiles(record).map((file) => (
                    <Row
                        file={file}
                        key={file}
                    />
                ))
            }}
            renderToggler={(open) => <Anchor onClick={open}>{record.attachmentsCount}</Anchor>}
        />
    )
}

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ row }) => value(row),
    }) as const satisfies Column

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const dataCardRow = () =>
    ({
        source,
        label,
        render: (_, data) => value(data),
    }) as const satisfies DataListDetails<any, any>

const filter = () =>
    ({
        id: source,
        label,
    }) as const satisfies FilterBy

const Row: FC<{ file: string }> = ({ file }) => {
    const { Icon, color } = getFileExtensionData(file)
    const name = getFileName(file)

    return (
        <a
            href={file}
            target="_blank"
            rel="noreferrer"
        >
            <ActionsOption
                Icon={Icon}
                iconColor={color}
                title={name}
            />
        </a>
    )
}

const arr = Array(10).fill(0)

const getFiles = (record: BaseModel, source?: string): string[] => {
    const files: string[] = arr.flatMap(
        (v, index) => record[`${source ?? 'attachment'}${index}`] || [],
    )

    return files
}

const getIndexes = (record: BaseModel, source?: string): number[] => {
    const indexes: number[] = arr.flatMap((v, index) =>
        record[`${source ?? 'attachment'}${index}`] ? [index] : [],
    )

    return indexes
}

const attachmentField = {
    sort,
    tableColumn,
    dataCardRow,
    source,
    label,
    value,
    get: getFiles,
    indexes: getIndexes,
    filter,
}
export default attachmentField
