import { type PropsWithChildren, type FC } from 'react'

import { useRecordContext } from 'ra-core'
import { useFormContext, useFormState, type FieldErrors } from 'react-hook-form'

import { type BaseModel } from 'appTypes'
import Icons from 'assets/icons'
import {
    ArrayControllerBox,
    ArrayControllerContextProvider,
    ArrayControllerDeleteIcon,
    ArrayControllerElements,
    FormPreventSubmit,
    useArrayControllerContext,
    useArrayControllerElementContext,
} from 'components'
import { formErrorToString, fileTypeError } from 'core'
import { globalClassNames } from 'lib'
import { DocumentAvatar } from 'resources/documents'
import { maxFileSize } from 'resources/gallery'
import {
    Typography,
    InfoBadge,
    NonDisplayedInput,
    Spacer,
    Tooltip,
    Alert,
    Box,
    Button,
    FormHelperText,
    Stack,
} from 'ui'
import { displayFileExtension, getFileName, getProperty } from 'utils'

import attachmentField from '../fields'
import {
    acceptedFileTypes,
    attachmentsIndexes,
    getAttachmentName,
    validateFileTypes,
} from '../utils'

const AttachmentsFormInput: FC<{
    disabled?: boolean
    title?: string
    source?: string
    titleOnDisabled?: string
}> = ({ titleOnDisabled, disabled, title = 'Documents', source: sourceProp }) => {
    const { setValue, clearErrors, getValues } = useFormContext()
    const record = useRecordContext<BaseModel>()
    const formValues = getValues()
    const finalRecord = record || (formValues as BaseModel)

    return (
        <ArrayControllerContextProvider
            initialArray={() => {
                if (finalRecord) {
                    return attachmentField.indexes(finalRecord, sourceProp)
                }
                return []
            }}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt="10px"
                mb="16px"
            >
                <Spacer>
                    <Typography
                        color="text.primary"
                        variant="subtitle1"
                    >
                        {title}
                    </Typography>
                    <AttachmentsCount />
                </Spacer>
                <AddAttachmentButton
                    titleOnDisabled={titleOnDisabled}
                    disabled={disabled}
                    source={sourceProp}
                />
            </Stack>
            <Stack>
                <ArrayControllerElements
                    onDelete={({ item }) => {
                        const source = getAttachmentName(item, sourceProp)
                        setValue(source, null, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                        })
                        clearErrors(source)
                    }}
                >
                    <AttachmentElement
                        disabled={disabled}
                        source={sourceProp}
                    />
                </ArrayControllerElements>
            </Stack>
            <NoAttachments title={title} />
            <Submittable source={sourceProp} />
        </ArrayControllerContextProvider>
    )
}

export default AttachmentsFormInput

export const AttachmentsCount = () => {
    const { array } = useArrayControllerContext()

    return <InfoBadge badgeContent={String(array.length)} />
}

export const AddAttachmentButton: FC<{
    disabled?: boolean
    source: string
    titleOnDisabled?: string
}> = ({ titleOnDisabled, disabled: disabledProp, source: sourceProp }) => {
    const { append, array, limit } = useArrayControllerContext()
    const { setError, setValue } = useFormContext()

    const upload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = (event.target as HTMLInputElement).files?.[0]
        if (!file) {
            return
        }

        const index = append()
        const source = getAttachmentName(index, sourceProp)
        setValue(source, file, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        })

        if (!validateFileTypes(file)) {
            setError(source, fileTypeError)
        } else if (file.size > maxFileSize.size) {
            setError(source, { message: maxFileSize.errorMessage })
        }

        event.target.value = null
    }

    const disabled = disabledProp || array.length >= limit
    const button = <AttachmentButton disabled={disabled}>Upload File</AttachmentButton>

    if (disabled) {
        return (
            <Tooltip
                title={disabledProp && titleOnDisabled ? titleOnDisabled : `Maximum ${limit} files`}
            >
                <span>{button}</span>
            </Tooltip>
        )
    }

    return (
        <label>
            <NonDisplayedInput
                type="file"
                onChange={upload}
                accept={acceptedFileTypes}
            />
            <Tooltip title={`Add up to ${limit} files, max size 30 MB each`}>
                <span>{button}</span>
            </Tooltip>
        </label>
    )
}

export const AttachmentButton: FC<{ disabled: boolean } & PropsWithChildren> = ({
    disabled,
    children,
}) => {
    return (
        <Button
            variant="text"
            startIcon={<Icons.UploadFileOutlined />}
            component="div"
            disabled={disabled}
            size="small"
        >
            {children}
        </Button>
    )
}
export const AttachmentElement: FC<{ disabled: boolean; source: string }> = ({
    disabled,
    source: sourceProp,
}) => {
    const { item } = useArrayControllerElementContext()
    const { getValues, getFieldState } = useFormContext()
    const source = getAttachmentName(item, sourceProp)

    const file = getValues(source)
    if (!file) {
        return null
    }

    const error = getFieldState(source).error
    const isFileUnsupported = error?.type === fileTypeError.type

    return (
        <ArrayControllerBox
            gap="12px"
            title={
                <Stack
                    direction="row"
                    overflow="hidden"
                    alignItems="center"
                >
                    <DocumentAvatar
                        file={file}
                        invalidType={isFileUnsupported}
                    />
                    <Box
                        ml="12px"
                        overflow="hidden"
                    >
                        <Typography
                            variant="h6"
                            color="text.primary"
                            className={globalClassNames.ellipsis}
                        >
                            {getFileName(file)}
                        </Typography>
                        <Typography
                            variant="chartTitle"
                            color="text.primary"
                        >
                            {displayFileExtension(file)}
                        </Typography>
                    </Box>
                </Stack>
            }
            deleteAlwaysVisible
            renderDeleteButton={() => (
                <ArrayControllerDeleteIcon
                    disabled={disabled}
                    controller={{ alwaysVisible: true }}
                    size="small"
                    sx={{ mb: 'auto' }}
                >
                    <Icons.Close fontSize="inherit" />
                </ArrayControllerDeleteIcon>
            )}
        >
            {error ? <FormHelperText error>{formErrorToString(error)}</FormHelperText> : null}
        </ArrayControllerBox>
    )
}

export const NoAttachments = ({ title }: { title: string }) => {
    const { array } = useArrayControllerContext()

    if (array.length) {
        return null
    }

    return <Alert severity="info">No {title} Added</Alert>
}

const checkForErrors = (err: FieldErrors, source: string): boolean => {
    return attachmentsIndexes.some((index) => getProperty(err, source + index))
}
const Submittable: FC<{ source?: string }> = ({ source = 'attachment' }) => {
    const { errors } = useFormState()

    if (!checkForErrors(errors, source)) {
        return null
    }

    return <FormPreventSubmit />
}
