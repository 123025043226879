import { type FC, useEffect } from 'react'

import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { getSuggestedPrice } from 'resources/invoices'
import { LineItemTypeKeys } from 'resources/lineItems'

const DefaultPriceHandler: FC<{ id: Identifier }> = ({ id }) => {
    const { setValue } = useFormContext()

    useEffect(() => {
        const setPrice = async () => {
            const price = await getSuggestedPrice(id, {
                itemType: LineItemTypeKeys.LABOR,
            })
            setValue('price', price.sellingPrice)
        }
        setPrice()
    }, [])
    return null
}

export default DefaultPriceHandler
