import { useResetStore } from 'ra-core'

import { urls } from 'configs'
import { useBlockerContext } from 'core/blocker'
import { useNavigate, useQueryClient } from 'lib'

import { type AuthStoreLogoutParams } from './authStore'
import useAuthProvider from './useAuthProvider'

const useLogout = ({
    onSuccess,
    logoutParams,
}: {
    onSuccess?: () => void
    logoutParams?: AuthStoreLogoutParams
} = {}) => {
    const authProvider = useAuthProvider()
    const resetStore = useResetStore()
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const blocker = useBlockerContext()

    return (navigateTo: string = urls.login) => {
        authProvider.logout(logoutParams).then(() => {
            blocker({ reset: true })
            onSuccess?.()
            queryClient.clear()
            resetStore()
            if (navigateTo) {
                navigate(navigateTo)
            }
        })
    }
}

export default useLogout
