import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { toNegativeNumber } from 'core'
import { type AuthStore } from 'core/auth'
import { withColor } from 'lib'
import { CountryCodes, type CountryModel } from 'resourcesBase'
import { Typography } from 'ui'

import { priceMaskResolver } from '../format'

type NegativeFormat = 'regular' | 'accounting'

interface MoneyFieldProps {
    value: number | string
    negativeFormat?: NegativeFormat

    /**
     * If true, the value will be highlighted if it's negative
     */
    featured?: boolean

    /**
     * If the value is already formatted with a custom way, you can pass it here
     */
    formattedValue?: string

    /**
     * If true, the value will be formatted as negative
     */
    toNegative?: boolean
}

export const MoneyField: FC<MoneyFieldProps> = inject('auth')(
    observer(
        ({
            value: valueProp,
            auth,
            negativeFormat,
            featured,
            formattedValue,
            toNegative,
        }: MoneyFieldProps & { auth: AuthStore }) => {
            if (valueProp == null) {
                return null
            }

            const num = +valueProp || 0
            const value = toNegative ? toNegativeNumber(num) : num

            const currencySymbol = getCurrencySymbol(auth.currentCompany.countryData)
            const price = _formatMoney(value, currencySymbol, negativeFormat, formattedValue)

            if (featured && +value < 0) {
                return (
                    <Typography
                        variant="inherit"
                        color={withColor('error.main')}
                        component="span"
                    >
                        {price}
                    </Typography>
                )
            }

            return <>{price}</>
        },
    ),
)

interface CurrencySymbolProps {
    discount?: boolean
}

export const CurrencySymbol: FC<CurrencySymbolProps> = inject('auth')(({
    auth,
    discount,
}: CurrencySymbolProps & { auth: AuthStore }) => {
    const symbol = getCurrencySymbol(auth.currentCompany.countryData)

    return (
        <>
            {symbol} {discount ? 'off' : ''}
        </>
    )
})

const getCurrencySymbol = (country: CountryModel) => {
    if (country?.id === CountryCodes.CA) {
        return 'C$'
    }

    return '$'
}

const _formatMoney = (
    value: number,
    symbol: string,
    negativeFormat: NegativeFormat,
    formattedValue: string,
) => {
    const formattedPrice = formattedValue || priceMaskResolver(String(Math.abs(value).toFixed(2)))
    const price = symbol + formattedPrice

    if (+value < 0) {
        if (negativeFormat === 'accounting') {
            return `(${price})`
        }

        // hyphen (-) breaks in new line if there is no enough space. This is why we use minus entity
        return <>&minus;{price}</>
    }

    return price
}

export const formatMoney = (config: MoneyFieldProps | MoneyFieldProps['value']) => {
    const _value = typeof config === 'object' ? config?.value : config

    if (_value == null) {
        return null
    }

    return (
        <MoneyField
            {...(typeof config === 'object' ? config : {})}
            value={_value}
        />
    )
}
