import { createTheme as createBaseTheme, type Breakpoint, type ThemeOptions } from '@mui/material'

import { type ThemeOptionsConfig } from 'lib'

import lightPalette, { type IPalette } from './palette'
import themeTypography, { type ITypography } from './typography'

declare module '@mui/material/styles' {
    interface Palette extends IPalette {}
    interface PaletteOptions extends IPalette {}

    interface PaletteColor extends Partial<IPalette> {}
    interface SimplePaletteColorOptions extends Partial<IPalette> {}
    interface Theme {
        props: typeof extraProps

        palette: IPalette

        typography: ITypography
    }

    interface ThemeOptions extends Partial<Theme> {}
}

const extraProps = {
    drawerWidth: 80,
    headerHeight: 56,
    sidebarPaddingX: 8,
    mobileViewBreakpoint: 'sm' as const satisfies Breakpoint,
}

export type BreakpointsValue = {
    [key in Breakpoint]: number
}

export const themeBreakpoints: BreakpointsValue = {
    xs: 0,
    sm: 720,
    md: 1024,
    lg: 1440,
    xl: 2048,
}

export const themeOption: ThemeOptionsConfig = {
    palette: lightPalette,
}

export interface CreateThemeConfig {
    components: ThemeOptions['components']
    breakpoints?: BreakpointsValue
    props?: Partial<typeof extraProps>
}

export const createTheme = ({
    components,
    breakpoints = themeBreakpoints,
    props,
}: CreateThemeConfig) => {
    const typography = themeTypography(themeOption)

    const rtheme = createBaseTheme({
        direction: 'ltr',
        palette: lightPalette,
        typography,
        props: { ...extraProps, ...props },
        spacing: 4,
        shape: {
            borderRadius: 4,
        },
        components,
        breakpoints: {
            values: breakpoints,
        },
    })

    return rtheme
}

export const cssVariables = {
    pageContentPadding: '--page-content-padding',
    gap: '--gap',
    iconSize: '--icon-size',
    pt: '--pt',
    pb: '--pb',
    pl: '--pl',
    pr: '--pr',
}

export const cssUtils = {
    applyPadding: (value: string) => {
        const { top, right, bottom, left } = getIndividualCssValues(value)

        return {
            [cssVariables.pt]: top,
            [cssVariables.pr]: right,
            [cssVariables.pb]: bottom,
            [cssVariables.pl]: left,
            padding: `var(${cssVariables.pt}) var(${cssVariables.pr}) var(${cssVariables.pb}) var(${cssVariables.pl})`,
        }
    },
}

export const globalClassNames = {
    ellipsis: 'ellipsis',
    autoRow: 'auto-row',
    withGap: 'with-gap',
    displayNone: 'd-none',
    noWrap: 'no-wrap',
    noShrink: 'no-shrink',
    ignorePx: 'ignore-px',
    extendPx: 'exend-px',
    ignorePb: 'ignore-pb',
    extendPb: 'extend-pb',
    hideEmpty: 'hide-empty',
}

export const formHelperTextHeight = '39px'

interface CssIndividualValues {
    top: string
    right: string
    bottom: string
    left: string
}

const getIndividualCssValues = (value: string): CssIndividualValues => {
    if (!value) {
        return {
            top: '0',
            right: '0',
            bottom: '0',
            left: '0',
        }
    }
    const values = value.split(' ').map((v) => v.trim())

    let top, right, bottom, left

    switch (values.length) {
        case 1:
            top = right = bottom = left = values[0]
            break
        case 2:
            top = bottom = values[0]
            right = left = values[1]
            break
        case 3:
            top = values[0]
            right = left = values[1]
            bottom = values[2]
            break
        case 4:
            ;[top, right, bottom, left] = values
            break
        default:
            throw new Error('Invalid padding value')
    }

    return { top, right, bottom, left }
}
