import { type FC } from 'react'

import { ListBase, useListContext, useRecordContext } from 'ra-core'

import Icons from 'assets/icons'
import { SliderView, BoxLink, ViewHeader, SkeletonSliderCardContainer } from 'components'
import { type Action, ActionsMenu } from 'core/actions'
import { Section, Skeleton } from 'ui'

import { type ContactModel } from '../types'

import { ContactCard } from './ContactCard'
import { ContactDrawerToggler } from './ContactDrawerToggler'
import { ContactResource } from './ContactResource'
import { ContactsEmpty } from './ContactsEmpty'
import { ContactsHeader } from './ContactsHeader'

const contactsActions: Action<ContactModel> = (params, { children }) => [
    <ContactDrawerToggler
        key="add-contact"
        children={({ onClick }) =>
            children({ Icon: Icons.Add, title: 'Add Contact Person', onClick })
        }
    />,
    children({
        Icon: Icons.GridView,
        title: 'See All',
        key: 'see-all',
        to: 'contact-persons',
    }),
]

const ContactsSimpleList: FC = () => {
    const { data = [], total, isLoading } = useListContext<ContactModel>()
    const vendor = useRecordContext() || {}

    let content
    if (isLoading) {
        return <ContactsFallback />
    }

    if (total) {
        const items = data.map((contact) => <ContactCard contact={contact} />)
        if (total >= 4) {
            items.push(
                <BoxLink
                    to="contact-persons"
                    sx={{ height: '142px' }}
                    label="SEE ALL CONTACTS"
                    icon={<Icons.ArrowForward />}
                />,
            )
        }
        content = <SliderView items={items} />
    } else {
        content = <ContactsEmpty />
    }

    return (
        <Section>
            <ContactsHeader
                actions={
                    <ActionsMenu
                        record={vendor}
                        actions={contactsActions}
                    />
                }
            />
            {content}
        </Section>
    )
}

interface ContactsSectionProps {
    resourceName: string
}

const ContactsSection: FC<ContactsSectionProps> = ({ resourceName }) => {
    const record = useRecordContext()

    if (!record) {
        return <ContactsFallback />
    }

    return (
        <ContactResource resourceName={resourceName}>
            <ListBase perPage={4}>
                <ContactsSimpleList />
            </ListBase>
        </ContactResource>
    )
}

export default ContactsSection

const SliderSkeleton = () => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height: '145px',
                gap: '10px',
            }}
        >
            <Skeleton
                height="8px"
                width="120px"
                sx={{
                    my: '20px',
                }}
            />
            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}

const contactsSkeletonSlides = new Array(4).fill(<SliderSkeleton />)

const ContactsFallback = () => (
    <Section>
        <ViewHeader
            title={null}
            loading
        />
        <SliderView items={contactsSkeletonSlides} />
    </Section>
)
