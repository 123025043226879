/* eslint-disable no-negated-condition */
import { isValidElement, useEffect, useMemo } from 'react'

import {
    defaultExporter,
    type ListControllerProps,
    type ListControllerResult,
    SORT_ASC,
    useAuthenticated,
    useGetList,
    useListParams,
    useNotify,
    useRecordSelection,
} from 'ra-core'

import { type DataRecord } from 'appTypes'
import { useResourceContext } from 'core/resource'

export const useListController = <RecordType extends DataRecord = any>(
    props: ListControllerProps<RecordType> = {},
): ListControllerResult<RecordType> => {
    const {
        debounce = 500,
        disableAuthentication,
        disableSyncWithLocation,
        exporter = defaultExporter,
        filter,
        filterDefaultValues,
        perPage = 10,
        queryOptions = {},
        sort = defaultSort,
        storeKey,
    } = props
    useAuthenticated({ enabled: !disableAuthentication })
    const resource = useResourceContext(props)
    const { meta, ...otherQueryOptions } = queryOptions

    if (!resource) {
        throw new Error(
            `<List> was called outside of a ResourceContext and without a resource prop. You must set the resource prop.`,
        )
    }
    if (filter && isValidElement(filter)) {
        throw new Error(
            '<List> received a React element as `filter` props. If you intended to set the list filter elements, use the `filters` (with an s) prop instead. The `filter` prop is internal and should not be set by the developer.',
        )
    }

    const notify = useNotify()

    const [query, queryModifiers] = useListParams({
        debounce,
        disableSyncWithLocation,
        filterDefaultValues,
        perPage,
        resource,
        sort,
        storeKey,
    })

    const [selectedIds, selectionModifiers] = useRecordSelection(resource)

    const { data, pageInfo, total, error, isLoading, isFetching, refetch } = useGetList<RecordType>(
        resource,
        {
            pagination: {
                page: query.page,
                perPage: query.perPage,
            },
            sort: { field: query.sort, order: query.order },
            filter: { ...filter, ...query.filter },
            meta,
        },
        {
            keepPreviousData: true,
            retry: false,
            onError: (error) =>
                notify(error?.message || 'ra.notification.http_error', {
                    type: 'error',
                    messageArgs: {
                        _: error?.message,
                    },
                }),
            ...otherQueryOptions,
        },
    )

    // change page if there is no data
    useEffect(() => {
        if (
            query.page <= 0 ||
            (!isFetching && query.page > 1 && (data == null || data?.length === 0))
        ) {
            // Query for a page that doesn't exist, set page to 1
            queryModifiers.setPage(1)
            return
        }
        if (total == null) {
            return
        }
        const totalPages = Math.ceil(total / query.perPage) || 1
        if (!isFetching && query.page > totalPages) {
            // Query for a page out of bounds, set page to the last existing page
            // It occurs when deleting the last element of the last page
            queryModifiers.setPage(totalPages)
        }
    }, [isFetching, query.page, query.perPage, data, queryModifiers, total])

    const currentSort = useMemo(
        () => ({
            field: query.sort,
            order: query.order,
        }),
        [query.sort, query.order],
    )

    return {
        sort: currentSort,
        data,
        defaultTitle: '',
        displayedFilters: query.displayedFilters,
        error,
        exporter,
        filter,
        filterValues: query.filterValues,
        hideFilter: queryModifiers.hideFilter,
        isFetching,
        isLoading,
        onSelect: selectionModifiers.select,
        onToggleItem: selectionModifiers.toggle,
        onUnselectItems: selectionModifiers.clearSelection,
        page: query.page,
        perPage: query.perPage,
        refetch,
        resource,
        selectedIds,
        setFilters: queryModifiers.setFilters,
        setPage: queryModifiers.setPage,
        setPerPage: queryModifiers.setPerPage,
        setSort: queryModifiers.setSort,
        showFilter: queryModifiers.showFilter,
        total,
        hasNextPage: pageInfo
            ? pageInfo.hasNextPage
            : total != null
              ? query.page * query.perPage < total
              : undefined,
        hasPreviousPage: pageInfo ? pageInfo.hasPreviousPage : query.page > 1,
    }
}

const defaultSort = {
    field: 'id',
    order: SORT_ASC,
}

// COPIED FROM RA
// changes:
// filter: { ...filter, ...query.filter,  },  swapped
