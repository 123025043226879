import { cssUtils, styled } from 'lib'
import { Card } from 'ui'

import Body from './Body'
import BottomContent from './BottomContent'
import Primary from './Primary'
import Row, { Rows } from './Row'
import Title from './Title'

const Root = styled(Card)({
    ...cssUtils.applyPadding('20px'),
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
})

const MonitorW = Object.assign(Root, {
    Title,
    Row,
    Rows,
    Primary,
    BottomContent,
    Body,
})

export default MonitorW
