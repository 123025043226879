import { useListContext } from 'ra-core'

import { Order } from 'appTypes'
import Icons from 'assets/icons'
import { globalClassNames } from 'lib'
import { HideOnBreakpoint, BoxContainer, IconButton, IconElement, MenuButton } from 'ui'

import { useListConfig } from '../ListContext'

import { sortOrders } from './utils'

const SortButton = () => {
    const { snap, state } = useListConfig()
    const { sort } = useListContext()
    const selected = snap.config.sorts.find((sortConfig) => sortConfig.id === sort.field)

    const icon = (
        <IconElement
            size="20px"
            sx={{
                transform: sort.order === Order.DESC ? 'scaleY(-1)' : undefined,
            }}
            component={Icons.Sort}
        />
    )

    return (
        <BoxContainer className={globalClassNames.ellipsis}>
            <HideOnBreakpoint
                replaceWith={
                    <IconButton
                        aria-label="Open Sort"
                        size="small"
                        color="inherit"
                        onClick={state.functions.sort.open}
                    >
                        {icon}
                    </IconButton>
                }
            >
                <MenuButton
                    startIcon={icon}
                    onClick={state.functions.sort.open}
                >
                    {selected.label} : {sortOrders.find((order) => order.id === sort.order).label}
                </MenuButton>
            </HideOnBreakpoint>
        </BoxContainer>
    )
}

export default SortButton
