import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useRecordContext, useResourceContext, useShowContext } from 'ra-core'

import { DynamicRoutes, tabRoutes } from 'components'
import { Show } from 'components/resource'
import { urls } from 'configs'
import { type AuthStore, onAction } from 'core'
import { useDidMount } from 'hooks'
import { getCurrentYear, Routes } from 'lib'
import { documentsTab } from 'resources/documents'
import { unitUrls, type UnitModel } from 'resources/units'
import { woResource } from 'resources/workOrders'

import { UnitAssignments } from '../Assignments'
import UnitCostReport from '../CostReport'
import UnitDVIR from '../DVIR'
import UnitExpirations from '../Expirations'
import UnitIssues from '../Issues'
import UnitMeters, { meterTypeUrlParam } from '../Meters'
import UnitNotes from '../Notes'
import UnitOverview from '../Overview'
import UnitPM from '../PM'
import UnitSettings from '../Settings'

import { UnitShowHeader } from './components'

const UnitShow = () => {
    return (
        <Show
            actionConfig={(record: UnitModel) =>
                record.archived ? urls.unitsArchived : urls.units
            }
        >
            <UnitShowBase />
        </Show>
    )
}

export default UnitShow

const UnitTrackActions = () => {
    const { refetch } = useShowContext()

    useDidMount(() => {
        return onAction(woResource.name, ({ name }) => {
            if (name === 'statusChange') {
                refetch()
            }
        })
    })

    return null
}

const UnitShowBase: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const record = useRecordContext<UnitModel>()
        const resource = useResourceContext()
        const isArchived = Boolean(record?.archived)

        const { tabs, routes } = tabRoutes([
            {
                value: '',
                label: 'Overview',
                element: <UnitOverview />,
            },
            {
                value: 'pm',
                label: 'PM',
                element: <UnitPM />,
            },
            {
                value: 'dvir',
                label: 'DVIR',
                element: <UnitDVIR />,
            },
            {
                value: 'issues',
                label: 'Issues',
                element: <UnitIssues />,
            },
            {
                value: `${unitUrls.meters}/*`,
                path: unitUrls.meters,
                label: 'Meters',
                element: (
                    <DynamicRoutes
                        path={`:${meterTypeUrlParam}`}
                        element={<UnitMeters />}
                    />
                ),
            },
            {
                value: 'notes',
                label: 'Notes',
                element: <UnitNotes />,
            },
            documentsTab({
                elementProps: {
                    readOnly: isArchived,
                    resource: record
                        ? {
                              name: 'unit-documents',
                              resource: resource + '/' + record?.id + '/documents',
                          }
                        : null,
                },
            }),
            {
                value: 'unit-expirations',
                label: 'Expirations',
                element: <UnitExpirations readOnly={isArchived} />,
            },
            auth.companySettings.hasAssignments
                ? {
                      value: 'assignments',
                      label: 'Assignments',
                      element: <UnitAssignments />,
                  }
                : null,
            {
                value: 'cost-report/*',
                path: `cost-report/${getCurrentYear()}`,
                label: 'Cost Report',
                element: (
                    <DynamicRoutes
                        path=":year"
                        element={<UnitCostReport />}
                    />
                ),
            },
            {
                value: 'settings',
                label: 'Settings',
                element: <UnitSettings />,
            },
        ])

        return (
            <>
                <UnitShowHeader tabs={tabs} />
                <Routes>{routes}</Routes>
                <UnitTrackActions />
            </>
        )
    }),
)
