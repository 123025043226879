import { type FC } from 'react'

import { ListBase, useRecordContext } from 'ra-core'

import { perPageLimit, RaResourceContextProvider } from 'core'
import { type PmModel } from 'resources/pm'
import { unitResource } from 'resources/units'
import { FlexReverse, PageContent } from 'ui'

import UnitPMDependentSection from './UnitPMDependentSection'
import UnitPMIndependentSection from './UnitPMIndependentSection'

const UnitPM: FC = () => {
    const record = useRecordContext()

    if (!record) {
        return null
    }

    const pmResource = `${unitResource.resource}/${record.id}/pm-schedules`

    return (
        <PageContent>
            <RaResourceContextProvider value={{ resource: pmResource, name: 'intervals' }}>
                <FlexReverse>
                    <ListBase<PmModel>
                        filter={{ type: 'independent' }}
                        perPage={perPageLimit}
                    >
                        <UnitPMIndependentSection />
                    </ListBase>
                    <ListBase<PmModel>
                        filter={{ type: 'dependent' }}
                        perPage={perPageLimit}
                    >
                        <UnitPMDependentSection />
                    </ListBase>
                </FlexReverse>
            </RaResourceContextProvider>
        </PageContent>
    )
}

export default UnitPM
