import { useRecordContext, useResourceContext } from 'ra-core'
import { useFormContext } from 'react-hook-form'

import Icons from 'assets/icons'
import { TextareaInput } from 'components'
import {
    useDataProvider,
    useInput,
    authStore,
    maxLengthValidationText,
    requiredValidation,
    useConfirm,
} from 'core'
import {
    Alert,
    Button,
    Stack,
    Tooltip,
    SimpleScrollbar,
    Typography,
    BoxContainer,
    InfoBadge,
    type TypographyProps,
} from 'ui'

import { type NoteModel } from '../models'

import { Notes } from './Note'

interface NotesInputProps {
    notes?: NoteModel[]
    disabled?: boolean
    titleOnDisabled?: string
    resource?: string
    titleVariant?: TypographyProps['variant']
}
const noteInputValidations = [maxLengthValidationText, requiredValidation]

export const NotesInput = ({ notes: notesProp, ...props }: NotesInputProps) => {
    const form = useFormContext()
    const notes = notesProp ? notesProp : form.watch('notes')

    return (
        <>
            {form ? <RegisterNotes /> : null}
            <NotesContent
                notes={notes}
                {...props}
            />
        </>
    )
}

const defaultNotesValue = []

const RegisterNotes = () => {
    useInput({
        source: 'notesToAdd',
        defaultValue: defaultNotesValue,
    })

    return null
}

const NotesContent = ({
    notes = [],
    disabled,
    titleOnDisabled,
    resource: resourceProp,
    titleVariant = 'subtitle1',
}: NotesInputProps) => {
    const confirm = useConfirm()
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const resource = useResourceContext()
    const form = useFormContext()
    let content = <Alert severity="info">No Notes Added</Alert>

    if (Array.isArray(notes) && notes.length) {
        content = (
            <SimpleScrollbar
                sx={{
                    maxHeight: form ? '254px' : '144px',
                }}
            >
                <Notes notes={notes} />
            </SimpleScrollbar>
        )
    }
    const addButton = (
        <Button
            variant="text"
            color="primary"
            size="small"
            disabled={disabled}
            startIcon={<Icons.AddCommentOutlined />}
            onClick={() => {
                confirm({
                    title: 'Add Note',
                    formProps: {
                        warnWhenUnsavedChanges: true,
                    },
                    content: (
                        <TextareaInput
                            source="note"
                            label="Notes"
                            parse={(v) => {
                                const s = String(v)
                                return s.trimStart()
                            }}
                            validate={noteInputValidations}
                        />
                    ),
                    onConfirm: async ({ formValues }) => {
                        if (form) {
                            const currNotes = form.getValues('notesToAdd')
                            form.setValue('notesToAdd', [formValues.note, ...currNotes])
                            const note: NoteModel = {
                                createdByData: {
                                    name: authStore.user.name,
                                    email: authStore.user.email,
                                },
                                text: formValues.note,
                                source: 'MANUAL',
                            }
                            form.setValue('notes', [note, ...notes], {
                                shouldDirty: true,
                                shouldTouch: true,
                            })
                        } else {
                            await dataProvider.update(resourceProp || resource, {
                                data: { notesToAdd: [formValues.note] },
                                id: record.id,
                                previousData: {},
                            })
                        }
                    },
                    confirmButtonProps: {
                        variant: 'text',
                        size: 'small',
                        startIcon: <Icons.AddCommentOutlined />,
                        children: 'Add Note',
                    },
                    awaitOnConfirm: true,
                })
            }}
        >
            ADD NOTE
        </Button>
    )
    return (
        <Stack
            gap="14px"
            mt="10px"
        >
            <BoxContainer justifyContent="space-between">
                <Typography
                    color="text.primary"
                    variant={titleVariant}
                    component={BoxContainer}
                    gap="8px"
                >
                    Notes <InfoBadge badgeContent={notes.length} />
                </Typography>
                {disabled && titleOnDisabled ? (
                    <Tooltip title={titleOnDisabled}>
                        <div>{addButton}</div>
                    </Tooltip>
                ) : (
                    addButton
                )}
            </BoxContainer>
            {content}
        </Stack>
    )
}
