import { type FC } from 'react'

import { Link } from 'react-router-dom'

import { useCreateResourcePath } from 'core'
import { Anchor } from 'ui'

import { type InvoiceModel } from '../types'
import { invoicesResource } from '../utils'

const InvoiceLinkButton: FC<Pick<InvoiceModel, 'id' | 'number'>> = ({ id, number }) => {
    const createPath = useCreateResourcePath()
    if (!id) {
        return null
    }
    return (
        <Anchor
            component={Link}
            to={createPath({
                resource: invoicesResource.resource,
                id,
                type: 'edit',
            })}
            variant="inherit"
        >
            {number}
        </Anchor>
    )
}

export default InvoiceLinkButton
