import { avatar } from './avatar'
import { date } from './date'
import { description } from './description'
import { name } from './name'
import { shouldChangeUnitStatus } from './shouldChangeUnitStatus'
import { status } from './status'
import { targetStatus } from './targetStatus'
import { threshold } from './threshold'

const expirationFields = {
    name,
    date,
    description,
    shouldChangeUnitStatus,
    threshold,
    status,
    avatar,
    targetStatus,
}

export default expirationFields
