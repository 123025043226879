import { CreateButtonView, Header, ListTotalBadge, MainHeader } from 'components'
import { InventoryItemDrawerToggler } from 'resources/inventory'

const InventoryListHeader = () => {
    return (
        <MainHeader
            mainAction={
                <InventoryItemDrawerToggler>
                    {({ onClick }) => (
                        <CreateButtonView onClick={onClick}>ADD PART</CreateButtonView>
                    )}
                </InventoryItemDrawerToggler>
            }
        >
            <Header.Info>
                <Header.Content>
                    <Header.Title>Inventory</Header.Title>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <ListTotalBadge />
                </Header.Content>
            </Header.Info>
        </MainHeader>
    )
}
export default InventoryListHeader
