import { createContext, type FC, type ReactNode, useContext } from 'react'

const FormInfoContext = createContext<Omit<FormInfoProps, 'children'>>(null)

interface FormInfoProps {
    children: ReactNode
    name?: string
    submitValidate?: (values: any) => Promise<any>
    hasRecord?: boolean
}

const defaultSubmitValidate = async () => null

export const FormInfo: FC<FormInfoProps> = ({
    children,
    name = '',
    submitValidate = defaultSubmitValidate,
    hasRecord,
}) => {
    return (
        <FormInfoContext.Provider value={{ name, submitValidate, hasRecord }}>
            {children}
        </FormInfoContext.Provider>
    )
}

export const useFormInfo = () => {
    return useContext(FormInfoContext)
}
