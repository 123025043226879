import { InfoCard, InfoCardDetails, InfoCardHeader, formatMoney } from 'components'
import { type ReceiptModel } from 'resources/purchaseOrders'
import { Divider, GridContainerColumns, GridItemLayout, Typography, BoxContainer } from 'ui'

import { formatDiscount } from '../../utils'

const FinancialSummaryCard = ({ receiptData }: { receiptData: ReceiptModel }) => {
    return (
        <GridContainerColumns>
            <GridItemLayout>
                <InfoCard>
                    <InfoCardHeader title="Financial Summary" />
                    <InfoCardDetails
                        propRecord={receiptData}
                        details={[
                            {
                                label: 'Parts Total',
                                source: (record) => formatMoney(record.partsTotal),
                            },
                            {
                                label: 'Miscellaneous',
                                source: (record) => formatMoney(record.miscellaneousTax),
                            },
                            {
                                label: 'Discount',
                                source: (record) => formatDiscount(record.discount),
                            },
                            {
                                label: 'Shipping',
                                source: (record) => formatMoney(record.shippingTax),
                            },
                            {
                                label: 'Sales Tax',
                                source: (record) => formatMoney(record.salesTax),
                            },
                        ]}
                    />
                    <Divider sx={{ my: '12px' }} />
                    <BoxContainer justifyContent="space-between">
                        <Typography variant="subtitle2">Receipt Total</Typography>
                        <Typography variant="subtitle2">
                            {formatMoney(receiptData.total)}
                        </Typography>
                    </BoxContainer>
                </InfoCard>
            </GridItemLayout>
        </GridContainerColumns>
    )
}

export default FinancialSummaryCard
