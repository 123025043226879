import { useRef, type FC, type ReactElement } from 'react'

import {
    ShowBase,
    type ShowControllerProps,
    type ShowControllerResult,
    ShowContextProvider,
    useShowController as useRAShowController,
} from 'react-admin'

import { type DataRecord } from 'appTypes'
import { onAction } from 'core/actions'
import { useRecordContext } from 'core/data'
import { elementNotFoundMessage } from 'core/errors'
import { useNotify } from 'core/notifications'
import {
    useResourceContext,
    type UseCreateResourcePathParams,
    useCreateResourcePath,
    useResource,
} from 'core/resource'
import { useDidMount } from 'hooks'
import { type To, useNavigate } from 'lib'

interface Props extends ShowControllerProps {
    children: ReactElement
    navigateOnError?: Partial<UseCreateResourcePathParams>
    controller?: ShowControllerResult
    actionConfig?: (record: DataRecord) => To
}

const Show: FC<Props> = ({ children, ...props }) => {
    const config = useShowControllerConfig(props)
    return (
        <ShowBase {...config}>
            <>
                <TrackRecordActions {...props} />
                {children}
            </>
        </ShowBase>
    )
}

export default Show

interface TrackRecordActionsProps extends Pick<Props, 'actionConfig'> {}

const TrackRecordActions: FC<TrackRecordActionsProps> = ({ actionConfig }) => {
    const resource = useResource()
    const navigate = useNavigate()
    const createPath = useCreateResourcePath()
    const record = useRecordContext()
    const recordRef = useRef(record)
    recordRef.current = record

    useDidMount(() => {
        return onAction(resource.name, ({ name, payload }) => {
            if (name === 'delete' && payload === recordRef.current.id) {
                navigate(
                    actionConfig
                        ? actionConfig(recordRef.current)
                        : createPath({
                              type: 'list',
                              resource: resource.resource,
                          }),
                )
            }
        })
    })

    return null
}

const useShowControllerConfig = (props: Omit<Props, 'children' | 'actionConfig'>) => {
    const notify = useNotify()
    const resource = useResourceContext(props)
    const navigate = useNavigate()
    const createPath = useCreateResourcePath()

    return {
        ...props,
        queryOptions: {
            ...props.queryOptions,
            onError: () => {
                notify({
                    title: elementNotFoundMessage,
                    type: 'error',
                })
                navigate(
                    createPath({
                        type: 'list',
                        resource,
                        ...props.navigateOnError,
                    }),
                )
            },
        },
    }
}

export const useShowController = <RecordType extends DataRecord>(
    props: Omit<Props, 'children' | 'actionConfig'> = {},
) => {
    const config = useShowControllerConfig(props)
    return useRAShowController<RecordType>(config)
}

export const ShowProvider = ShowContextProvider
