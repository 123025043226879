import { useWatch } from 'react-hook-form'

import { formatMoney, InfoCardDetails, SelectInput, useUtilityDrawerStepsContext } from 'components'
import { requiredValidation } from 'core'
import { partFields } from 'resources/parts'
import { type LineItemsModel } from 'resources/purchaseOrders'
import { Alert, Stack, DataAvatar, InfoBadge, Typography, BoxContainer, BoxedSection } from 'ui'

import { type ReceiptFormItem, type ReceiveItemsExtraData } from './types'

const MismatchedItem = ({
    poItem,
    itemData = {},
}: {
    poItem: LineItemsModel
    itemData: Partial<ReceiptFormItem>
}) => {
    return (
        <BoxedSection gap="16px">
            <BoxContainer gap="12px">
                <DataAvatar defaultImage={<partFields.avatar.Icon />} />
                <Stack
                    spacing="4px"
                    overflow="hidden"
                >
                    <Typography
                        variant="h6"
                        lineHeight="20px"
                    >
                        {poItem.partData.number}
                    </Typography>
                    <Typography
                        variant="chartTitle"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    >
                        {poItem.partData.description}
                    </Typography>
                </Stack>
            </BoxContainer>
            <InfoCardDetails
                propRecord={{ id: 'someId' }}
                details={[
                    {
                        label: 'QTY ON ORDER',
                        source: () => poItem.quantity,
                    },
                    {
                        label: 'QTY RECEIVED',
                        source: () => itemData.quantity || 0,
                    },
                    {
                        label: 'PART COST',
                        source: () => formatMoney(poItem.price),
                    },
                ]}
            />
            <SelectInput
                source={`remainingItems.${poItem.id}.type`}
                label="Take an action for this item"
                validate={requiredValidation}
                choices={[
                    {
                        id: 'BACK_ORDER',
                        name: `Backorder ( QTY ${poItem.quantity - (itemData.quantity || 0)} )`,
                    },
                    {
                        id: 'CANCEL',
                        name: 'Cancel',
                    },
                ]}
            />
        </BoxedSection>
    )
}

const PoMismatchedItems = () => {
    const { extra } = useUtilityDrawerStepsContext()
    const { items } = extra as ReceiveItemsExtraData
    const receivedItems = useWatch({ name: 'receivedItems' })

    const mismatchedItems = items.filter((item) => isPoItemMismatched(receivedItems[item.id], item))

    return (
        <Stack spacing="20px">
            <Typography
                variant="subtitle1"
                component={BoxContainer}
                gap="8px"
            >
                Mismatched Items <InfoBadge badgeContent={mismatchedItems.length} />
            </Typography>
            <Alert severity="info">
                Select your course of action for missing or incomplete items below.
            </Alert>
            <Stack spacing="12px">
                {mismatchedItems.map((item) => {
                    return (
                        <MismatchedItem
                            poItem={item}
                            itemData={receivedItems[item.id]}
                            key={item.id}
                        />
                    )
                })}
            </Stack>
        </Stack>
    )
}

export default PoMismatchedItems

export const isPoItemMismatched = (itemData: Partial<ReceiptFormItem>, item: LineItemsModel) => {
    if (!itemData) {
        return true
    }
    return !itemData.received || itemData.quantity < item.quantity
}
