import {
    type ComponentProps,
    type ForwardedRef,
    type ReactElement,
    forwardRef,
    useImperativeHandle,
    useRef,
    useLayoutEffect,
} from 'react'

import { useChoicesContext } from 'core/referenceController'
import { clsx } from 'lib'
import { autocompleteClasses } from 'ui'
import { focusedElementloseFocus } from 'utils'

import { CreateOptionLi, StyledList } from './styled'

export type NullableUlElement = HTMLUListElement | null
export interface ListBoxProps extends React.HTMLAttributes<HTMLUListElement> {}
export type CreateOption = (
    renderOption: (params: ComponentProps<typeof CreateOptionLi>) => ReactElement,
) => ReactElement

export interface ExtendedListBoxProps {
    renderCreateOption?: CreateOption
}

const ListBox = forwardRef(
    (
        { children, renderCreateOption, ...rest }: ListBoxProps & ExtendedListBoxProps,
        ref: ForwardedRef<HTMLUListElement>,
    ) => {
        const innerRef = useRef<HTMLUListElement>(null)
        useImperativeHandle<NullableUlElement, NullableUlElement>(ref, () => innerRef.current)

        const { isFetching } = useChoicesContext()

        useLayoutEffect(() => {
            // scroll list to top after applying filter
            if (!isFetching) {
                innerRef.current?.scrollTo(0, 0)
            }
        }, [isFetching])

        return (
            <>
                <StyledList
                    {...rest}
                    ref={innerRef}
                    role="listbox"
                >
                    {renderCreateOption?.((params = {}) => (
                        <CreateOptionLi
                            {...params}
                            onClick={(event) => {
                                focusedElementloseFocus()
                                params.onClick?.(event)
                            }}
                            className={clsx(autocompleteClasses.option, params.className)}
                        />
                    ))}
                    {children}
                </StyledList>
            </>
        )
    },
)

export default ListBox
