import { type FC, type ReactElement } from 'react'

import { SubmitButton, useOpenUtilityDrawer, UtilityDrawerForm } from 'components'
import { useRecordContext, api, serialize, useSubmit, type Serializer } from 'core'
import {
    inventoryPricingFields,
    InventoryPricingTypeKeys,
    type InventoryPricingModel,
} from 'resources/inventory'
import { pathJoin } from 'utils'

import { InventoryPricingForm } from './InventoryPricingForm'

interface InventoryPricingProps {
    children: (params: { onClick: () => void }) => ReactElement
    record: InventoryPricingModel
    resource: string
}

export const InventoryPricingDrawerToggler: FC<InventoryPricingProps> = ({
    children,
    record,
    resource,
}: InventoryPricingProps) => {
    const open = useOpenUtilityDrawer()
    const partRecord = useRecordContext()

    const submitHandler = useSubmit<InventoryPricingModel>(
        async (formData) => {
            await api.patch(
                pathJoin(resource, record.id),
                serialize(formData, inventoryPricingSerializer(formData.type)),
            )
        },
        {
            successMessage: ({ defaultMessages }) => defaultMessages.updated,
        },
    )

    return children({
        onClick: () => {
            open({
                drawerArgs: {
                    title: 'Edit Customer Price Tier',
                    renderWrapper: (params) => (
                        <UtilityDrawerForm
                            {...params}
                            defaultValues={{
                                shop: record.partTierData.shop,
                                part: partRecord.number,
                                customerPartTier: inventoryPricingFields.partTier.value(record),
                            }}
                            record={record}
                            onSubmit={submitHandler}
                        />
                    ),
                    renderContent: () => <InventoryPricingForm />,
                    renderBottomRight: () => <SubmitButton />,
                },
            })
        },
    })
}

const inventoryPricingSerializer: (
    type: InventoryPricingModel['type'],
) => Serializer<InventoryPricingModel> = (type) => {
    if (type === InventoryPricingTypeKeys.PERCENTAGE) {
        return [
            'type',
            { name: 'markupPercent', parse: 'number' },
            { name: 'costFloor', parse: 'number' },
        ]
    }
    if (type === InventoryPricingTypeKeys.SELLING_PRICE) {
        return ['type', { name: 'price', parse: 'number' }]
    }
    return ['type', { name: 'markup', parse: 'number' }, { name: 'costFloor', parse: 'number' }]
}
