import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { IconBox } from 'ui'

import { PurchaseInvoiceDrawerToggler } from './PurchaseInvoiceDrawerToggler'

const PurchaseInvoiceEditButton: FC<{
    id: Identifier
    resource?: string
    readOnly?: boolean
    disabled?: boolean
}> = ({ id, resource, readOnly, disabled }) => {
    return (
        <PurchaseInvoiceDrawerToggler
            id={id}
            resource={resource}
            readOnly={readOnly}
            disabled={disabled}
        >
            {(onClick) => (
                <IconBox
                    onClick={onClick}
                    title="View/Edit"
                >
                    <Icons.Edit />
                </IconBox>
            )}
        </PurchaseInvoiceDrawerToggler>
    )
}

export default PurchaseInvoiceEditButton
