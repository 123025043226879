import { type FC, type ReactElement } from 'react'

import { type WithChildrenNode } from 'appTypes'
import Icons from 'assets/icons'
import {
    cssVariables,
    getContrastColor,
    globalClassNames,
    Link,
    themeColor,
    withColor,
    type ThemeColorType,
} from 'lib'
import {
    IconElement,
    MenuItem,
    Typography,
    Chip as ChipBase,
    Box,
    MenuList,
    menuItemClasses,
} from 'ui'

interface RowsProps extends WithChildrenNode {
    stretch?: boolean
}

export const Rows: FC<RowsProps> = ({ children, stretch }) => {
    return (
        <MenuList
            sx={{
                p: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: stretch && 'space-between',
                [cssVariables.iconSize]: '16px',
                [cssVariables.gap]: '12px',
                height: '100%',
                width: '100%',
                ...(!stretch && {
                    [`& .${menuItemClasses.root}:not(:last-child)::after`]: {
                        content: '""',
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        height: '1px',
                        background: (theme) => `${theme.palette.other.divider}`,
                    },
                }),
            }}
        >
            {children}
        </MenuList>
    )
}

interface RowProps {
    to?: string
    label: ReactElement | string
    value: ReactElement
}

export const Row: FC<RowProps> = ({ to, label, value }) => {
    return (
        <MenuItem
            disableRipple={!to}
            sx={{
                gap: '12px',
                p: '6px 4px',
                alignItems: 'center',
                borderRadius: (theme) => theme.shape.borderRadius + 'px',
                position: 'relative',
                ...(!to && { '&:hover': { backgroundColor: 'transparent' }, cursor: 'default' }),
                minHeight: 'unset',
            }}
            component={to ? Link : 'div'}
            // @ts-ignore
            to={to}
        >
            <Typography
                variant="body2"
                component="div"
                flexGrow={1}
                className={globalClassNames.ellipsis}
                color={withColor('text.primary')}
            >
                <span>{label}</span>
            </Typography>
            <Box display="flex">{value}</Box>
            {to && (
                <IconElement
                    component={Icons.ArrowForward}
                    color={(theme) => theme.palette.text.primary}
                />
            )}
        </MenuItem>
    )
}

interface ChipProps {
    color: ThemeColorType
    children: string | number
}

export const Chip: FC<ChipProps> = ({ color: colorProp, children }) => {
    return (
        <ChipBase
            size="extraSmall"
            label={children}
            sx={(theme) => {
                const color = themeColor(colorProp, theme)
                return { background: color, color: getContrastColor(color) }
            }}
        />
    )
}
