import { ViewHeader } from 'components'
import { useRecordContext } from 'core'
import { PurchaseOrderCardSkeleton } from 'pages/PurchaseOrders/components'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { GridContainerColumns, GridItemLayout, Section } from 'ui'

import TotalPurchaseCard from './TotalPurchaseCard'
const TotalPurchaseSection = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    if (!(record?.dateLastReceived || record.status === 'CLOSED')) {
        return null
    }

    return (
        <Section>
            <ViewHeader
                title={record.type === 'CREDIT' ? 'Credit Summary' : 'Total Purchase'}
                loading={!record}
            />
            <GridContainerColumns>
                <GridItemLayout>
                    {record ? (
                        <TotalPurchaseCard record={record} />
                    ) : (
                        <PurchaseOrderCardSkeleton height="221px" />
                    )}
                </GridItemLayout>
            </GridContainerColumns>
        </Section>
    )
}

export default TotalPurchaseSection
