import { ListBase, useListContext } from 'ra-core'

import { useShowContext } from 'core'
import { type DefectModel } from 'resources/defects'
import { DVIRNoConnection, getDvirPage } from 'resources/dvir'
import { telematicsExtras } from 'resources/telematics'
import { isUnitArchived, type UnitModel } from 'resources/units'
import { PageContent } from 'ui'

import UnitDvirDefectsSection from './UnitDvirDefectsSection'
import UnitDvirLastSection from './UnitDvirLastSection'

const UnitDVIR = () => {
    const { record, resource } = useShowContext<UnitModel>()
    if (!record) {
        return null
    }

    return (
        <PageContent>
            {!record.telematicsData ||
            record.telematicsData.telematicsStatus === 'DISCONNECTED' ||
            record.telematicsData.status === 'DEACTIVATED' ||
            isUnitArchived(record) ? (
                <DVIRNoConnection preventConnect={Boolean(record.archived)} />
            ) : (
                <ListBase
                    resource={`${resource}/${record.id}/defects`}
                    perPage={100}
                >
                    <UnitDvirContent />
                </ListBase>
            )}
        </PageContent>
    )
}
const UnitDvirContent = () => {
    const { record } = useShowContext<UnitModel>()
    const { data } = useListContext<DefectModel>()
    if (data && !data.length) {
        return getDvirPage(
            record.telematicsData.capabilities.dvirs,
            telematicsExtras[record.telematicsData.provider].providerName,
        )
    }
    return (
        <>
            <UnitDvirLastSection />
            <UnitDvirDefectsSection data={data} />
        </>
    )
}
export default UnitDVIR
