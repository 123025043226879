import { useId } from 'react'

import { useFormContext } from 'react-hook-form'

import { requiredValidation } from 'core/form'
import { useInput } from 'core/inputs'
import { useWillUnmount } from 'hooks'

const FormPreventSubmit = () => {
    const name = useId()
    const { unregister } = useFormContext()
    useInput({ source: name, validate: requiredValidation })

    useWillUnmount(() => {
        unregister(name, {
            keepValue: false,
            keepDefaultValue: false,
            keepIsValid: false,
        })
    })

    return null
}

export default FormPreventSubmit
