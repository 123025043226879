import { type ReactElement } from 'react'

import { type ListControllerResult, useRecordContext, useShowContext } from 'ra-core'

import { useInputStep, useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { renderOnPermission, type ResourceType } from 'core'
import { JobLaborCreate, JobLaborForm } from 'pages/WorkOrders/Jobs/Labor'
import { JobPartInput } from 'pages/WorkOrders/Jobs/Parts/JobPartForm'
import { type WorkOrderItemsExtra } from 'pages/WorkOrders/Jobs/config/constants'
import { LineItemTypeKeys } from 'resources/lineItems'
import {
    type JobPartModel,
    type WorkOrderModel,
    type JobModel,
    useIsVenderWo,
    useWoActionIsDisabled,
    woJobItemPermissions,
} from 'resources/workOrders'

import { JobPartForm, jobPartSerializer, jobPartWithoutNumberSerializer } from '../../../../Parts'

import PartWithoutNumberDrawerForm from './PartWithoutNumberDrawerForm'
export interface LaborFormExtra extends WorkOrderItemsExtra {
    job: JobModel
    workOrder: WorkOrderModel
}

export interface PartWithoutNumberFormExtra
    extends Pick<WorkOrderItemsExtra, 'job' | 'disabledFields'> {
    record?: JobPartModel
}

export const PartCreateWithoutNumberDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const job = useRecordContext<JobModel>()
        const extra: PartWithoutNumberFormExtra = {
            disabledFields: disabled,
            job,
        }

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Part (Without Part Number)',
                              renderWrapper: (params) => (
                                  <UtilityDrawerEditor
                                      {...params}
                                      serializer={jobPartWithoutNumberSerializer}
                                      defaultValues={{
                                          type: LineItemTypeKeys.PART,
                                      }}
                                  />
                              ),
                              renderContent: () => <PartWithoutNumberDrawerForm />,
                              renderBottomRight: (render) => render({ label: 'Add Part' }),
                          },
                          extra,
                      })
                  },
        })
    },
    woJobItemPermissions.addPart,
)

export const PartCreateDrawerToggler = renderOnPermission(
    ({
        title,
        children,
        resource,
        listContext,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        title?: string
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext<WorkOrderModel>()
        const disabled = useWoActionIsDisabled({ workOrder: record })
        const isVendorWo = useIsVenderWo()
        const job = useRecordContext<JobModel>()
        const inputStep = useInputStep()

        if (disabled) {
            return children({
                onClick: () => {
                    /* */
                },
            })
        }

        return children({
            onClick: async () => {
                const formProps = await inputStep((params) => (
                    <JobPartInput
                        isVendorWo={isVendorWo}
                        jobId={job.id}
                        {...params}
                    />
                ))

                if (!formProps) {
                    return
                }

                const extra: WorkOrderItemsExtra = {
                    vendor: record.purchaseOrderData?.vendorData,
                    job,
                    workOrder: record,
                }

                open({
                    extraArgs: {
                        listContext,
                        resource,
                    },
                    drawerArgs: {
                        title: title || 'Add Part',
                        renderWrapper: (params) => (
                            <UtilityDrawerEditor
                                {...params}
                                {...formProps}
                                serializer={jobPartSerializer}
                                defaultValues={{
                                    type: LineItemTypeKeys.PART,
                                }}
                            />
                        ),
                        renderContent: () => <JobPartForm />,
                        renderBottomRight: (render) => render({ label: 'Add' }),
                    },
                    extra,
                })
            },
        })
    },
    woJobItemPermissions.addPart,
)

export const LaborCreateDrawerToggler = renderOnPermission(
    ({
        children,
        resource,
        listContext,
        job,
    }: {
        children: (params: { onClick: () => void }) => ReactElement
        resource: ResourceType
        listContext: ListControllerResult
        job: JobModel
    }) => {
        const open = useOpenUtilityDrawer()
        const { record } = useShowContext()
        const isVendorWo = useIsVenderWo()

        const disabled = useWoActionIsDisabled({ workOrder: record })

        return children({
            onClick: disabled
                ? () => {
                      /* */
                  }
                : () => {
                      open({
                          extraArgs: {
                              listContext,
                              resource,
                          },
                          drawerArgs: {
                              title: 'Add Labor',
                              renderWrapper: (params) => (
                                  <JobLaborCreate
                                      {...params}
                                      isVendorWo={isVendorWo}
                                  />
                              ),
                              renderContent: () => <JobLaborForm />,
                              renderBottomRight: (render) => render({ label: 'Add Labor' }),
                          },
                          extra: {
                              job,
                              workOrder: record,
                          } as LaborFormExtra,
                      })
                  },
        })
    },
    woJobItemPermissions.addLabor,
)
