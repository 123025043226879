import images from 'assets/images'
import { InfoCard, SeeAllLink, ViewHeader } from 'components'
import { useRecordContext } from 'core'
import { formatPOItemTotal } from 'pages/PurchaseOrders/LineItems/utils'
import NoLineItemsCard from 'pages/PurchaseOrders/Overview/components/NoLineItemsCard'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Section, Img, GridItemLayout, GridContainerColumns, Typography } from 'ui'
import { htmlSymbols } from 'utils'

const ReturnItemsTotalSection = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    return (
        <Section>
            <ViewHeader title="Return items">
                <SeeAllLink to="line-items" />
            </ViewHeader>

            {record.items ? <ItemsTotal /> : <NoLineItemsCard />}
        </Section>
    )
}

export default ReturnItemsTotalSection

const ItemsTotal = () => {
    const record = useRecordContext<PurchaseOrderModel>()

    return (
        <GridContainerColumns>
            <GridItemLayout>
                <InfoCard
                    sx={{ display: 'flex', height: '162px', gap: '22px', alignItems: 'center' }}
                >
                    <Img
                        src={images.returnPoItems}
                        sx={{ width: '106px', height: '106px' }}
                    />
                    <div>
                        <Typography variant="h5">
                            {record.items} {htmlSymbols.dot} {formatPOItemTotal(record.total)}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                        >
                            {record.type === 'CREDIT' ? 'Total Credit' : 'Total'}
                        </Typography>
                    </div>
                </InfoCard>
            </GridItemLayout>
        </GridContainerColumns>
    )
}
