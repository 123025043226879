import { alpha, generateNotForwardedProps, styled, themeColor, type ThemeColorType } from 'lib'
import { Chip, type ChipProps } from 'ui'

interface RowChipProps extends Omit<ChipProps, 'sx' | 'color'> {
    chipColor: ThemeColorType
}

const RowChip = styled(
    (props: RowChipProps) => {
        const { chipColor, ...rest } = props
        return <Chip {...rest} />
    },
    {
        shouldForwardProp: generateNotForwardedProps<RowChipProps>(['chipColor']),
    },
)<RowChipProps>(({ theme, chipColor }) => {
    const color = themeColor(chipColor, theme)
    return {
        backgroundColor: alpha(color, 0.15),
        color,
    }
})

export default RowChip
