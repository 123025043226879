import { useMemo, type ReactNode } from 'react'

import {
    EditContextProvider,
    type EditControllerProps,
    type EditProps,
    ResourceContextProvider,
    useEditController,
    useUpdate,
} from 'react-admin'

import { type DataRecord } from 'appTypes'
import { Form, type FormProps } from 'components/form'
import { type DataProviderMeta } from 'core/data'
import { type UseSubmitExtraArgs, useSubmit } from 'core/form'
import { type ResourceType, useResource } from 'core/resource'

import { makeSubmit, useSubmitValidate, type makeSubmitConfig } from './common'

export interface EditCustomProps<RecordType extends DataRecord = any>
    extends Omit<EditProps<RecordType>, 'resource' | 'mutationOptions'>,
        makeSubmitConfig<RecordType>,
        Pick<FormProps, 'formOnError' | 'validate'>,
        Pick<UseSubmitExtraArgs, 'successMessage'> {
    children: ReactNode
    className?: string
    warnWhenUnsavedChanges?: boolean
    resource?: ResourceType
    meta?: DataProviderMeta
    defaultValues?: Partial<RecordType> | ((record: RecordType) => Partial<RecordType>)
    transformRecord?: (record: any) => Partial<RecordType>
}

const Edit = <RecordType extends DataRecord = any>({
    children,
    className,
    onSuccess,
    serializer,
    warnWhenUnsavedChanges,
    formOnError,
    resource: resourceProp,
    meta,
    defaultValues,
    validate,
    successMessage,
    mutationOptions,
    transformRecord,
    ...rest
}: EditCustomProps<RecordType>) => {
    const resource = useResource(resourceProp)
    const [edit] = useUpdate()

    const submitValidate = useSubmitValidate(resource.resource, 'update', {
        params: { id: rest.id },
        meta,
    })

    const submit = makeSubmit(edit, {
        resource,
        onSuccess: (...args) => {
            onSuccess?.(...args)
        },
        serializer,
        meta,
        mutationOptions,
    })

    const onSubmit = useSubmit(submit, {
        successMessage: successMessage ?? (({ defaultMessages }) => defaultMessages.updated),
    })

    return (
        <EditBase
            {...rest}
            // TODO: Remove after RA
            transformRecord={transformRecord}
            id={rest.id as any}
        >
            <Form
                submitValidate={submitValidate}
                validate={validate}
                formOnError={formOnError}
                onSubmit={onSubmit}
                className={className}
                defaultValues={defaultValues}
                warnWhenUnsavedChanges={warnWhenUnsavedChanges}
                hasRecord
            >
                {children}
            </Form>
        </EditBase>
    )
}

export default Edit

const EditBase = ({
    children,
    transformRecord,
    ...props
}: { children: ReactNode } & EditControllerProps) => {
    const controllerProps = useEditController(props)

    const recordBase = controllerProps.record

    const record = useMemo(() => {
        if (recordBase && transformRecord) {
            return { ...recordBase, ...transformRecord(recordBase) }
        }
        return recordBase
    }, [recordBase])

    const body = (
        <EditContextProvider value={{ ...controllerProps, record }}>{children}</EditContextProvider>
    )
    return props.resource ? (
        <ResourceContextProvider value={props.resource}>{body}</ResourceContextProvider>
    ) : (
        body
    )
}
