import { type FC } from 'react'

import { type Identifier } from 'ra-core'

import { PartForm } from './PartForm'

export const VendorPartForm: FC<{ id?: Identifier }> = (props) => {
    return (
        <PartForm
            inputsCfg={{ universalProductCode: { hide: true }, cost: { hide: false } }}
            {...props}
        />
    )
}
