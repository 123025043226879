import { type NoInfer } from 'appTypes'
import { type Column, formatMoney } from 'components'

import { type InvoiceModel } from '../types'

const source = 'totalParts' as const satisfies keyof InvoiceModel
const label = 'Parts'

interface Data extends Pick<InvoiceModel, 'totalParts'> {}

const value = (record: Data) => formatMoney(record.totalParts)

const tableColumn = <Source extends string = typeof source>() =>
    ({
        field: source as NoInfer<Source>,
        headerName: label,
        renderCell: (cell) => value(cell.row),
        align: 'right',
    }) as const satisfies Column

const sort = <Source extends string = typeof source>() =>
    ({
        id: source as NoInfer<Source>,
        label,
    }) as const

const totalParts = {
    source,
    label,
    value,
    sort,
    tableColumn,
}

export default totalParts
