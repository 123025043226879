import { type Serializer, type ConfirmConfig } from 'core'
import { attachmentsSerializer } from 'resources/attachments'
import { getPhotoSerializer } from 'resources/gallery'
import { type IssueModel } from 'resources/issues'

import ActionWhileInProgressText from '../components/ActionWhileInProgressText'

export const deleteConfirmConfig = (record: IssueModel): ConfirmConfig => ({
    title: 'Delete issue?',
    content: (
        <ActionWhileInProgressText
            record={record}
            action="deleted"
        />
    ),
})

export const issueSerializer: Serializer<IssueModel & { notesToAdd?: string[] }> = [
    'name',
    { name: 'unit', parse: ['emptyToNull', 'disableOnEdit'] },
    { name: 'notesToAdd', parse: ['emptyToNull', 'disableOnEdit'] },
    { name: 'priority', parse: 'emptyToNull' },
    { name: 'reported', parse: 'dateTime' },
    { name: 'component', parse: 'emptyToNull' },
    { name: 'reasonForRepair', parse: 'emptyToNull' },
    { name: 'complaint', parse: 'emptyToNull' },
    'description',
    ...(getPhotoSerializer(true) as Serializer<IssueModel & { notesToAdd?: string[] }>),
    ...(attachmentsSerializer as Serializer<IssueModel & { notesToAdd?: string[] }>),
]
