import { useUpdate } from 'ra-core'

import { getRecordPhotos } from 'components'
import {
    useCreateResourcePath,
    useShowContext,
    updatedMessage,
    useFinalErrorHandler,
    useNotify,
    fileTypeError,
} from 'core'
import { maxFileSize } from 'resources/gallery'
import { partsResource } from 'resources/parts'
import { GridContainer, PageContent } from 'ui'

import { NoPhotosCard, PartPhotoGallery, PhotoTabHeader } from './components'

const PartPhotos = () => {
    const { record, isFetching } = useShowContext()
    const notify = useNotify()
    const [update] = useUpdate()

    const errorHandler = useFinalErrorHandler()
    const photoErrorHandler = (error: any, id: string) => {
        if (error?.[id]) {
            notify({
                title: error?.[id].message,
                type: 'error',
            })
        } else {
            errorHandler(error)
        }
    }
    const createPath = useCreateResourcePath()
    if (!record && isFetching) {
        return null
    }

    const { files, count } = getRecordPhotos(record)
    const upload = async (event) => {
        const file = event.target.files?.[0]
        if (!file) {
            return
        }
        const freePhoto = Object.keys(files).find((photoIndex) => {
            return !files[photoIndex]
        })
        const type = file.type
        if (!type.startsWith('image') || type.includes('svg')) {
            notify({
                title: fileTypeError.message,
                type: 'error',
            })
        } else if (file.size > maxFileSize.size) {
            notify({
                title: maxFileSize.errorMessage,
                type: 'error',
            })
        } else {
            try {
                await update(
                    createPath({ resource: partsResource.resource, id: record.id, type: 'edit' }),
                    { data: { [freePhoto]: file } },
                    { returnPromise: true },
                ).then(() => {
                    notify(updatedMessage)
                })
            } catch (error) {
                photoErrorHandler(error, freePhoto)
            }
        }
        event.target.value = null
    }
    const deletePhoto = async (id: string) => {
        try {
            await update(
                createPath({ resource: partsResource.resource, id: record.id, type: 'edit' }),
                { data: { [id]: null } },
                { returnPromise: true },
            )
        } catch (e) {
            photoErrorHandler(e, id)
        }
    }
    return (
        <PageContent>
            <PhotoTabHeader
                photosCount={count}
                upload={upload}
            />
            <GridContainer>
                {count ? (
                    <PartPhotoGallery
                        photos={files}
                        deletePhoto={deletePhoto}
                    />
                ) : (
                    <NoPhotosCard upload={upload} />
                )}
            </GridContainer>
        </PageContent>
    )
}

export default PartPhotos
