import { api } from 'core'
import { useQuery } from 'lib'
import { createReportResource } from 'resources/reports'

import { WidgetTitle, WidgetBaseContainer } from '../../WidgetBase'
import { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { FullBody, ScrollbarBottom } from '../components'

import { IssuesByStatusRows } from './components'

const IssuesByStatusWidget = () => {
    const query = useQuery<ResponseData>(['issues-by-status'], () => {
        return api.get(createReportResource('issues-by-status'))
    })

    const data = query.data

    if (!data) {
        return <WidgetSkeleton />
    }

    return (
        <WidgetBaseContainer>
            <FullBody>
                <WidgetTitle mb="11px">Issues By Status</WidgetTitle>

                <ScrollbarBottom>
                    <IssuesByStatusRows data={data} />
                </ScrollbarBottom>
            </FullBody>
        </WidgetBaseContainer>
    )
}

export default IssuesByStatusWidget

interface ResponseData {
    inProgressCount: number
    openCount: number
    scheduledCount: number
}
