import { type FC } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import {
    type DialogSelectorProps,
    MoneyFormTotal,
    TextInput,
    costMaskParams,
    inputQtyMaskParams,
    useUtilityDrawerContext,
} from 'components'
import { useInput, requiredValidation, validateMaxLength, useRecordContext } from 'core'
import { PartInput, type PartModel } from 'resources/parts'
import { CK79Input } from 'resources/vmrs'
import { type JobPartModelKeys } from 'resources/workOrders'
import { SectionTitleSmall } from 'ui'

import { type WorkOrderItemsExtra } from '../config/constants'

import { jobPartApiContext } from './constants'

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

const quantitySource = 'quantity' satisfies JobPartModelKeys
const priceSource = 'price' satisfies JobPartModelKeys

const JobPartForm = () => {
    const { extra } = useUtilityDrawerContext()
    const { disabledFields: disabled, job, workOrder } = extra as WorkOrderItemsExtra
    const isVendorWo = Boolean(workOrder?.purchaseOrderData)
    const { setValue } = useFormContext()
    const record = useRecordContext()

    return (
        <>
            <JobPartInput
                jobId={job.id}
                isVendorWo={isVendorWo}
                disabled={disabled || Boolean(record?.id)}
                onSelectedChange={({ selected }) => {
                    setValue(priceSource, selected?.cost || 0, {
                        shouldTouch: true,
                        shouldDirty: true,
                    })
                    setValue(quantitySource, null, {
                        shouldTouch: true,
                        shouldDirty: true,
                    })
                }}
            />

            <TextInput
                source={quantitySource}
                label="Quantity"
                validate={qtyAndCostValidator}
                {...inputQtyMaskParams}
                disabled={disabled}
            />

            <TextInput
                source={priceSource}
                label="Cost"
                validate={qtyAndCostValidator}
                {...costMaskParams}
                disabled={disabled || workOrder.inventoryMode}
            />

            <MoneyFormTotal
                title="Part Total"
                inputOne={quantitySource}
                inputTwo={priceSource}
            />

            <Position disabled={disabled} />

            <FormConfig />
        </>
    )
}

export default JobPartForm

const FormConfig = () => {
    const { extra } = useUtilityDrawerContext()
    const { workOrder } = extra as WorkOrderItemsExtra

    useInput({
        source: 'formConfig',
        defaultValue: {
            inventoryMode: workOrder.inventoryMode,
        },
    })

    return null
}

export const JobPartInput: FC<
    {
        jobId: Identifier
        isVendorWo: boolean
    } & Partial<DialogSelectorProps<PartModel>>
> = ({ jobId, isVendorWo, disabled, ...rest }) => {
    return (
        <PartInput
            {...rest}
            {...jobPartApiContext(jobId)}
            type={isVendorWo ? 'vendor' : 'inventory'}
            disabled={disabled}
        />
    )
}

const Position: FC<{ disabled: boolean }> = ({ disabled }) => {
    const { getValues } = useFormContext()
    const part: PartModel = useWatch({ name: 'partData' }) || getValues('partData')

    if (!part?.positionApplicable) {
        return null
    }

    return (
        <>
            <SectionTitleSmall>Position</SectionTitleSmall>
            <CK79Input disabled={disabled} />
        </>
    )
}
