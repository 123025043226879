import { isValidElement, type ReactElement } from 'react'

import { type DataRecord, type Optional } from 'appTypes'
import Icons from 'assets/icons'
import { type BaseActionRenderParams } from 'core/actions'
import { type ListControllerResult, useListContext } from 'core/list'
import { type Theme, alpha, styled, useMediaQuery } from 'lib'
import { IconButton, Button, Typography, BoxContainer, Tooltip } from 'ui'
import { getId } from 'utils'

import { useListSnap } from '../ListContext'
import { ListView } from '../types'

export interface ListBulkActionsParams<RecordType extends DataRecord = DataRecord> {
    listContext: ListControllerResult<RecordType>
    children: (params: Optional<BaseActionRenderParams, 'title'>) => ReactElement
}

export type ListBulkActions<RecordType extends DataRecord = DataRecord> = (
    params: ListBulkActionsParams<RecordType>,
) => ReactElement[]
export interface ListSelectionProps {
    bulkActions?: ListBulkActions
    disableSelectRecord?: (record: DataRecord) => string
}

const ListSelectionContainer = styled('div')`
    background: ${({ theme }) => alpha(theme.palette.primary.main, 0.08)};
    height: 40px;
    width: 100%;
    align-items: center;
    display: flex;
    margin-bottom: 16px;
    border-radius: 4px;
`

const ListSelectionBase = ({ bulkActions, disableSelectRecord }: ListSelectionProps) => {
    const listContext = useListContext()
    const { selectedIds, onSelect } = listContext
    const snap = useListSnap()

    return (
        <ListSelectionContainer>
            <BoxContainer
                sx={{
                    paddingLeft: '8px',
                }}
            >
                <IconButton
                    onClick={() => onSelect([])}
                    sx={{
                        width: '20px',
                        height: '20px',
                    }}
                    aria-label="Unselect all"
                >
                    <Icons.Close />
                </IconButton>
                <Typography
                    sx={{ paddingLeft: '8px' }}
                    variant="body1"
                >
                    {selectedIds.length} Selected
                </Typography>
                {snap.view === ListView.CARD && (
                    <SelectAll disableSelectRecord={disableSelectRecord} />
                )}
            </BoxContainer>

            <BoxContainer
                sx={{
                    marginLeft: 'auto',
                    justifyContent: 'space-between',
                    columnGap: '8px',
                    paddingRight: '8px',
                }}
            >
                {bulkActions?.({
                    listContext,
                    children: ({ Icon, disabled, onClick, title }) => (
                        <Tooltip title={title}>
                            <IconButton
                                disabled={disabled}
                                onClick={onClick}
                            >
                                {isValidElement(Icon) ? Icon : <Icon />}
                            </IconButton>
                        </Tooltip>
                    ),
                })}
            </BoxContainer>
        </ListSelectionContainer>
    )
}

const ListSelection = (props: ListSelectionProps) => {
    const { selectedIds } = useListContext()

    if (selectedIds.length) {
        return <ListSelectionBase {...props} />
    }

    return null
}

export default ListSelection

const SelectAll = ({
    disableSelectRecord,
}: {
    disableSelectRecord?: (record: DataRecord) => string
}) => {
    const { selectedIds, onSelect, data } = useListContext()
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const _data = disableSelectRecord ? data.filter((item) => !disableSelectRecord(item)) : data

    if (selectedIds.length === _data.length) {
        return null
    }

    const handleSelectAll = () => {
        onSelect(_data.map(getId))
    }

    return (
        <Button
            sx={{ marginLeft: '16px', lineHeight: '100%' }}
            onClick={handleSelectAll}
            startIcon={matches ? null : <Icons.SelectAll />}
        >
            SELECT ALL
        </Button>
    )
}
