import { inject, observer } from 'mobx-react'
import { useListContext } from 'ra-core'

import Icons from 'assets/icons'
import {
    BoxLink,
    CardListCard,
    SkeletonSliderCardContainer,
    SliderView,
    ViewHeader,
} from 'components'
import { useShowContext, useCreateResourcePath, type AuthStore } from 'core'
import { getCrossReferenceCardsCfg } from 'pages/Parts/CrossReference/CrossReference'
import { AddReferenceButton } from 'pages/Parts/CrossReference/components'
import { useInventoryContext } from 'resources/inventory'
import { partsResource, type PartModel } from 'resources/parts'
import { Stack, BoxContainer, Section, Skeleton } from 'ui'

import NoResultCard from './NoResultCard'
import SeeAllButton from './SeeAllButton'

const PartCrossReferenceSkeletonCard = () => {
    return (
        <SkeletonSliderCardContainer
            sx={{
                height: '243px',
                gap: '20px',
            }}
        >
            <BoxContainer gap="12px">
                <Skeleton
                    variant="circular"
                    height="42px"
                    width="42px"
                    sx={{ flexShrink: '0' }}
                />
                <Stack
                    spacing="12px"
                    width="100%"
                >
                    <Skeleton height="8px" />
                    <Skeleton
                        height="8px"
                        width="120px"
                    />
                </Stack>
            </BoxContainer>

            <Skeleton />
        </SkeletonSliderCardContainer>
    )
}
const sliderSkeletons = new Array(4).fill(0).map((v, i) => {
    return <PartCrossReferenceSkeletonCard key={i} />
})
const PartCrossReferenceSection = inject('auth')(
    observer(({ auth }: { auth?: AuthStore }) => {
        const createPath = useCreateResourcePath()
        const { isLoading, data, total, resource } = useListContext()
        const { record } = useShowContext()
        const inventoryView = useInventoryContext()
        const navigateTo = (record: PartModel) =>
            createPath({
                resource: partsResource.resource,
                id: record.id,
                type: 'edit',
            })

        let content
        if (isLoading || !record) {
            content = <SliderView items={sliderSkeletons} />
        } else if (total) {
            const items = data.map((part) => (
                <CardListCard<PartModel>
                    cardsCfg={getCrossReferenceCardsCfg(navigateTo, auth, inventoryView, {
                        name: 'cross-reference',
                        resource: navigateTo(record) + '/cross-references',
                    })}
                    record={part}
                    key={part.id}
                />
            ))
            if (total >= 4) {
                items.push(
                    <BoxLink
                        to="cross-reference"
                        label="SEE ALL CROSS-REFERENCES"
                        icon={<Icons.ArrowForward />}
                    />,
                )
            }
            content = <SliderView items={items} />
        } else {
            content = <NoResultCard path={resource} />
        }

        return (
            <Section>
                <ViewHeader
                    title="Cross-reference"
                    loading={isLoading}
                >
                    <ViewHeader.Content placement="rightMobile">
                        {inventoryView ? null : <AddReferenceButton path={resource} />}
                        {total ? <SeeAllButton /> : null}
                    </ViewHeader.Content>
                </ViewHeader>
                {content}
            </Section>
        )
    }),
)

export default PartCrossReferenceSection
