import { useRef, type FC } from 'react'

import { useCreatePath } from 'ra-core'

import { Widget } from 'components'
import { useListController } from 'core'
import { useResources } from 'resources'
import {
    StatusField,
    unitResource,
    unitStatusResource,
    type UnitStatusModel,
} from 'resources/units'

import { WidgetTitle, WidgetBaseContainer } from '../../WidgetBase'
import { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { FullBody, ScrollbarBottom } from '../components'

const UnitStatusesWidget = () => {
    const listController = useListController<UnitStatusModel>({
        resource: unitStatusResource.resource,
        filter: { activeOnly: true },
        perPage: 1000,
    })
    const data = listController.data

    const widgetRef = useRef<HTMLDivElement>()

    if (!data) {
        return <WidgetSkeleton />
    }

    return (
        <WidgetBaseContainer ref={widgetRef}>
            <FullBody>
                <WidgetTitle mb="11px">Unit Statuses</WidgetTitle>

                <ScrollbarBottom>
                    <StatusesList data={data} />
                </ScrollbarBottom>
            </FullBody>
        </WidgetBaseContainer>
    )
}

export default UnitStatusesWidget

const StatusesList: FC<{ data: UnitStatusModel[] }> = ({ data }) => {
    const { unitStatus } = useResources()
    const createPath = useCreatePath()
    const pathBase = createPath({ resource: unitResource.resource, type: 'list' }) + '?filter='

    return (
        <Widget.Rows>
            {data.map((status) => (
                <Widget.Row
                    key={status.id}
                    label={<StatusField record={status} />}
                    value={<Widget.Chip color={status.color}>{status.usage || 0}</Widget.Chip>}
                    to={
                        pathBase +
                        encodeURIComponent(
                            JSON.stringify({
                                [unitStatus.source]: [status.id],
                            }),
                        )
                    }
                />
            ))}
        </Widget.Rows>
    )
}
