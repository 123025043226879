import { type ReactElement } from 'react'

import { type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { formatMoney } from 'components'
import { partFields } from 'resources/parts'
import { ck33Fields, ck34Fields } from 'resources/vmrs'
import { type SvgIconComponent } from 'ui'

import { type BaseLineItemModels, LineItemTypeKeys } from './types'

export const getItemData = <K extends keyof BaseLineItemModels>(item: BaseLineItemModels[K]) =>
    lineItemsDataMap[item.type as K](item)

export type LineItemsMapType = {
    [key in keyof BaseLineItemModels]: (record: BaseLineItemModels[key]) => {
        icon: SvgIconComponent | SvgIconElement
        total: number
        totalCaption?: ReactElement
        subtitle: string
        description: string
    }
}
export const lineItemsDataMap: LineItemsMapType = {
    [LineItemTypeKeys.LABOR]: (record) => ({
        icon: Icons.ScheduleOutlined,
        total: record.price * record.quantity,
        totalCaption: (
            <>
                {record.quantity} x {formatMoney(record.price)}
            </>
        ),
        subtitle: ck33Fields.self.value(record.componentData),
        description: record.description,
    }),
    [LineItemTypeKeys.PART]: (record) => ({
        icon: partFields.avatar.Icon,
        total: record.price * record.quantity,
        totalCaption: (
            <>
                {record.quantity} x {formatMoney(record.price)}
            </>
        ),
        subtitle: `${record.partData.number || ''}${
            record.partData.manufacturerData
                ? ` • ${ck34Fields.self.value(record.partData.manufacturerData)}`
                : ''
        }`,
        description: record.partData.description,
    }),
    [LineItemTypeKeys.FEE]: (record) => ({
        icon: Icons.Fee,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        description: record.description,
    }),
    [LineItemTypeKeys.TAX]: (record) => ({
        icon: Icons.PercentOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        description: record.description,
    }),
    [LineItemTypeKeys.SERVICE]: (record) => ({
        icon: Icons.WorkOutlineOutlined,
        total: record.price,
        subtitle: record.type.charAt(0).toUpperCase() + record.type.slice(1),
        description: record.description,
    }),
}
