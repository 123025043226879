import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { urls } from 'configs'
import { parseNumber, type Serializer, useListContext } from 'core'
import { LinkButton } from 'ui'
import { pathJoin } from 'utils'

import { type InventoryItem } from '../types'
import { inventoryItemsResource } from '../utils'

import InventoryItemFormContent, {
    type InventoryItemFormContentProps,
} from './InventoryItemFormContent'

interface InventoryItemDrawerTogglerProps extends InventoryItemFormContentProps {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    shortSuccessMessage?: boolean
    onSuccess?: (record: InventoryItem) => void
    values?: {
        shop?: Identifier
        part?: Identifier
    }
}

const InventoryItemDrawerToggler: FC<InventoryItemDrawerTogglerProps> = ({
    id,
    children,
    shortSuccessMessage,
    onSuccess,
    values,
}) => {
    const open = useOpenUtilityDrawer()
    const listContext = useListContext()

    return children({
        onClick: () =>
            open({
                drawerArgs: {
                    title: id ? 'Edit Inventory' : 'Add to Inventory',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            defaultValues={values}
                            onSuccess={onSuccess}
                            successMessage={({ defaultMessages, response }) => {
                                if (id) {
                                    return defaultMessages.updated
                                }

                                const data = response as InventoryItem

                                const text = `has been added to the ${data.shopData.name} inventory`

                                if (shortSuccessMessage) {
                                    return data.partData.number + ' ' + text
                                }

                                return {
                                    title: (
                                        <>
                                            <LinkButton to={pathJoin(urls.inventory, data.id)}>
                                                {data.partData.number}
                                            </LinkButton>{' '}
                                            {text}
                                        </>
                                    ),
                                }
                            }}
                            serializer={inventorySerializer}
                            {...params}
                        />
                    ),
                    renderContent: () => (
                        <InventoryItemFormContent
                            disableInput={values && ((source) => Boolean(values[source]))}
                            hidePartDetails={Boolean(values?.part || id)}
                        />
                    ),
                    renderBottomRight: (render) =>
                        id ? render() : render({ icon: <Icons.Add />, label: 'Add' }),
                },
                extraArgs: {
                    listContext,
                    resource: inventoryItemsResource,
                    id,
                },
            }),
    })
}

const parseQuantity = (value, data) => (data.trackInventory ? parseNumber(value) : null)

const inventorySerializer: Serializer<InventoryItem> = [
    'part',
    'shop',
    { name: 'minQuantity', parse: parseQuantity },
    { name: 'maxQuantity', parse: parseQuantity },
    'binLocationId',
    'binDescription',
]

export default InventoryItemDrawerToggler
