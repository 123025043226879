import { Alert, Stack, Typography } from 'ui'

const CollapsibleNotes = ({ note }: { note: string }) => {
    return (
        <Stack>
            <Typography
                variant="chartTitle"
                my="10px"
            >
                Notes
            </Typography>
            {note ? (
                <Typography
                    sx={{ overflowWrap: 'break-word' }}
                    variant="body2"
                    color={(theme) => theme.palette.text.primary}
                >
                    {note}
                </Typography>
            ) : (
                <Alert severity="info">No Notes Added</Alert>
            )}
        </Stack>
    )
}
export default CollapsibleNotes
