import { type ReactNode, useEffect, useState } from 'react'

import { type BarDatum, ResponsiveBar } from '@nivo/bar'
import { stringify } from 'query-string'
import { useListContext, type TextInputProps } from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { integerSpacedMaskResolver } from 'components/format'
import { inputIntegerSpacedMask, TextInput } from 'components/inputs'
import { formatMoney } from 'components/money'
import { api, getFilterReference, prepareFilterResource } from 'core'
import { useResourceContext } from 'core/resource'
import { useDebounce, useDidUpdate } from 'hooks'
import { alpha, useTheme, withColor } from 'lib'
import { Box, Slider as SliderBase, Typography } from 'ui'
import { getProperty } from 'utils'

import { type FilterRenderProps } from '../../types'

export interface FilterRangeInputProps extends FilterRenderProps {
    inputProps?: Partial<TextInputProps>
    integerValuesInput?: boolean
    valueAdornment?: ReactNode
}

const FilterRangeInput = ({
    valueSource,
    inputProps,
    integerValuesInput,
    filterName,
    valueAdornment,
}: FilterRangeInputProps) => {
    const { setValue, getValues } = useFormContext()
    const sourceMin = valueSource + '.min'
    const sourceMax = valueSource + '.max'
    const { filter } = useListContext()
    const resource = useResourceContext()
    const reference = getFilterReference(resource, filterName)

    const finalInputProps = {
        ...inputProps,
        ...(valueAdornment
            ? { InputProps: { endAdornment: valueAdornment, ...inputProps?.InputProps } }
            : undefined),
    }
    const [counts, setCounts] = useState([])

    useEffect(() => {
        if (!filterName) {
            return
        }

        const getData = async () => {
            const data = await api
                .get(
                    prepareFilterResource(reference) +
                        '?' +
                        stringify({
                            ...filter,
                            name: filterName,
                        }),
                )
                .catch(() => null)

            if (!data) {
                return
            }

            const counts = data.map((item, index) => ({ ...item, id: index }))

            setCounts(counts)

            const values = getValues()
            if (getProperty(values, sourceMin) != null || getProperty(values, sourceMax) != null) {
                return
            }

            setValue(sourceMin, counts[0].min)
            setValue(sourceMax, counts[counts.length - 1].max)
        }

        getData()
    }, [filterName])

    const min = counts.length ? counts[0].min : 0
    const max = counts.length ? counts[counts.length - 1].max : 0

    return (
        <Box>
            <Charts
                sourceMin={sourceMin}
                sourceMax={sourceMax}
                min={min}
                max={max}
                counts={counts}
                integerValuesInput={integerValuesInput}
            />
            <Box
                display="flex"
                justifyContent="space-between"
                mt="6px"
                mb="16px"
            >
                <Typography
                    variant="body2"
                    color={withColor('text.secondary')}
                >
                    {integerValuesInput ? integerSpacedMaskResolver(min) : formatMoney(min)}{' '}
                    {valueAdornment}
                </Typography>
                <Typography
                    variant="body2"
                    color={withColor('text.secondary')}
                >
                    {integerValuesInput ? integerSpacedMaskResolver(max) : formatMoney(max)}{' '}
                    {valueAdornment}
                </Typography>
            </Box>

            <Box display="flex">
                <TextInput
                    source={sourceMin}
                    label="From"
                    {...(integerValuesInput
                        ? inputIntegerSpacedMask(
                              valueAdornment
                                  ? { InputProps: { endAdornment: valueAdornment } }
                                  : undefined,
                          )
                        : finalInputProps)}
                    sx={{ marginRight: '25px' }}
                />
                <TextInput
                    source={sourceMax}
                    label="To"
                    {...(integerValuesInput
                        ? inputIntegerSpacedMask(
                              valueAdornment
                                  ? { InputProps: { endAdornment: valueAdornment } }
                                  : undefined,
                          )
                        : finalInputProps)}
                />
            </Box>
        </Box>
    )
}

export default FilterRangeInput

interface SliderProps {
    maxValue: number
    minValue: number
    sourceMin: string
    sourceMax: string
    min: number
    max: number
    step: number
}

type SliderValue = [number, number]

const Slider = ({ sourceMin, sourceMax, min, max, maxValue, minValue, step }: SliderProps) => {
    const { setValue } = useFormContext()
    const [sliderValue, setSliderValue] = useState<SliderValue>([minValue, maxValue])

    const slideChange = (event, values: SliderValue) => {
        setSliderValue(values)
    }

    const setDebouncedValues = useDebounce(
        (values: SliderValue) => {
            setValue(sourceMin, values[0])
            setValue(sourceMax, values[1])
        },
        { time: 100 },
    )

    useDidUpdate(() => {
        setDebouncedValues(sliderValue)
    }, [sliderValue])

    useEffect(() => {
        setSliderValue((oldValue) => {
            if (minValue === oldValue[0] && maxValue === oldValue[1]) {
                return oldValue
            }
            return [minValue, maxValue]
        })
    }, [minValue, maxValue])

    return (
        <SliderBase
            size="small"
            min={min < minValue ? min : minValue}
            max={max > maxValue ? max : maxValue}
            step={step}
            value={sliderValue}
            onChange={slideChange}
        />
    )
}

// TODO: remove BarDatum interface
interface RangeCount extends BarDatum {
    min: number
    max: number
    count: number
    id: number
}

interface ChartsProps {
    sourceMin: string
    sourceMax: string
    min: number
    max: number
    counts: RangeCount[]
    integerValuesInput?: boolean
}

const Charts = ({ sourceMin, sourceMax, min, max, counts, integerValuesInput }: ChartsProps) => {
    const minInputValue = +useWatch({ name: sourceMin })
    const maxInputValue = +useWatch({ name: sourceMax })

    const minValue = isNaN(minInputValue) ? 0 : minInputValue
    const maxValue = isNaN(maxInputValue) ? 0 : maxInputValue
    const theme = useTheme()

    return (
        <>
            <Box
                height="20px"
                mb="6px"
            >
                <ResponsiveBar
                    // 0 is not shown, this is why + 0.3
                    data={counts.map((range) => ({
                        ...range,
                        count: range.count + 0.3,
                    }))}
                    keys={['count']}
                    padding={0.5}
                    indexBy={(d) => String(d.id)}
                    colors={({ data }) => {
                        if ((data.max || 0) >= minValue && (data.min || 0) <= maxValue) {
                            return theme.palette.primary.main
                        }

                        return alpha(theme.palette.primary.main, 0.38)
                    }}
                    enableGridY={false}
                    enableLabel={false}
                    isInteractive={false}
                />
            </Box>
            <Slider
                min={min}
                max={max}
                maxValue={maxValue}
                minValue={minValue}
                sourceMax={sourceMax}
                sourceMin={sourceMin}
                step={integerValuesInput ? 1 : 0.01}
            />
        </>
    )
}
