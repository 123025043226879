import { type DataRecord } from 'appTypes'
import { basePermissions, renderOnPermission, useRecordContext } from 'core'
import {
    type WorkOrderFormProps,
    type WorkOrderModel,
    type WorkOrderType,
} from 'resources/workOrders'
import { Typography } from 'ui'

import { WorkOrderDrawerToggler } from '../components'
import { type WorkOrderEditDrawerTogglerProps } from '../components/WorkOrderDrawerToggler'

export interface WorkOrderSimpleListNoResultsCreateActionProps<RecordType extends DataRecord> {
    createFormInitialValues: (record: RecordType) => Partial<WorkOrderModel>
    workOrderType?: WorkOrderType
    disabledFields?: WorkOrderFormProps['disabledFields']
    createFormConfig?: WorkOrderFormProps['createFormConfig']
    values?: WorkOrderEditDrawerTogglerProps['values']
}

const WorkOrderSimpleListNoResultsCreateAction = renderOnPermission(
    <RecordType extends DataRecord>({
        createFormInitialValues,
        workOrderType,
        disabledFields,
        createFormConfig,
        values,
    }: WorkOrderSimpleListNoResultsCreateActionProps<RecordType>) => {
        const record = useRecordContext<RecordType>()
        const defaultValues = createFormInitialValues(record)

        return (
            <WorkOrderDrawerToggler
                defaultValues={defaultValues}
                disabledFields={disabledFields}
                createFormConfig={createFormConfig}
                values={values}
            >
                {({ onClick }) => (
                    <Typography
                        onClick={onClick}
                        variant="body1"
                        sx={{ cursor: 'pointer', color: (theme) => theme.palette.primary.main }}
                    >
                        {workOrderType === 'vendor' ? 'Create Vendor WO' : 'Create Work Order'}
                    </Typography>
                )}
            </WorkOrderDrawerToggler>
        )
    },
    basePermissions.create,
    'work-orders',
)

export default WorkOrderSimpleListNoResultsCreateAction
