import { useEffect, useRef } from 'react'

import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed'

import { styled, classes } from 'lib'
import {
    Box,
    Step,
    stepConnectorClasses,
    stepIconClasses,
    StepLabel,
    stepLabelClasses,
    Stepper,
} from 'ui'

import { useUtilityDrawerStepsContext } from './UtilityDrawerStepsContext'

const StepperWrapper = styled(Box)`
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.gray};
    padding: 20px;

    & .${stepConnectorClasses.root} {
        width: 12px;
        flex: unset;
    }

    & .${stepLabelClasses.labelContainer} {
        white-space: nowrap;
    }
`

const scrollToStep = (element: Element) => {
    smoothScrollIntoView(element, {
        scrollMode: 'always',
        duration: 0,
    })
}

const UtilityDrawerStepper = () => {
    const { step, steps } = useUtilityDrawerStepsContext()
    const stepperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const element = stepperRef.current

        if (!element) {
            return
        }

        const active = element.querySelector('.step-active')!
        const parent = element.parentElement!

        scrollToStep(active)

        let timer: NodeJS.Timeout

        const observer = new ResizeObserver(() => {
            // Wait for the drawer to finish resizing, otherwise it has weird animation
            timer = setTimeout(() => {
                scrollToStep(active)
            }, 300)
        })

        observer.observe(parent)

        return () => {
            clearTimeout(timer)
            observer.disconnect()
        }
    }, [step])

    return (
        <StepperWrapper>
            <Stepper
                activeStep={step}
                ref={stepperRef}
            >
                {steps.map(({ title }, i) => (
                    <Step
                        sx={{
                            [`&.${classes.completed} .${stepIconClasses.root}`]: {
                                color: (theme) => theme.palette.success.main,
                            },
                        }}
                        className={step === i ? 'step-active' : ''}
                        key={i}
                    >
                        <StepLabel>{title}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </StepperWrapper>
    )
}

export default UtilityDrawerStepper
