import avatar from './avatar'
import closedDate from './closedDate'
import completionDate from './completionDate'
import { created } from './created'
import { customer } from './customer'
import { duration } from './duration'
import expectedCompletionDate from './expectedCompletionDate'
import { number } from './number'
import { priority } from './priority'
import repairPriorityClass from './repairPriorityClass'
import scheduledDate from './scheduledDate'
import self from './self'
import startDate from './startDate'
import status from './status'
import total from './total'
import { woType } from './type'

const woFields = {
    self,
    avatar,
    number,
    duration,
    priority,
    repairPriorityClass,
    customer,
    total,
    status,
    type: woType,
    created,
    completionDate,
    closedDate,
    expectedCompletionDate,
    scheduledDate,
    startDate,
}

export default woFields
