import { type ReactNode } from 'react'

import { useCreatePath } from 'ra-core'

import { Link } from 'lib'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { woResource } from 'resources/workOrders'

const WOPurchaseOrderEditButton = ({
    record,
    children,
}: {
    record: PurchaseOrderModel
    children: ReactNode
}) => {
    const createPath = useCreatePath()
    return (
        <Link
            to={
                createPath({
                    resource: woResource.resource,
                    type: 'edit',
                    id: record.workOrder,
                }) + '/invoice'
            }
        >
            {children}
        </Link>
    )
}

export default WOPurchaseOrderEditButton
