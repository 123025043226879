import { type FC } from 'react'

import { globalClassNames } from 'lib'
import { Typography, type TypographyProps } from 'ui'

const Title: FC<TypographyProps<'div'>> = (props) => {
    return (
        <Typography
            variant="chartTitle"
            component="div"
            className={globalClassNames.ellipsis}
            {...props}
        />
    )
}

export default Title
