import Icons from 'assets/icons'
import { urls } from 'configs'
import { api, emptyOptionValue } from 'core'
import { useQuery } from 'lib'
import { customerFields } from 'resources/customers'
import { issueFields, IssuePriorityKeys, IssueStatusKeys } from 'resources/issues'
import { createReportResource } from 'resources/reports'
import { IconElement } from 'ui'
import { roundPercentages } from 'utils'

import WidgetBase, { WidgetSkeleton } from '../../WidgetBase/WidgetBase'
import { CostPerCategoryClarification } from '../CostPerCategoryWidget/components'

import { Chart, IssuesByStatusRows } from './components'
import { type CriticalIssuesData } from './types'

const CriticalIssuesWidget = () => {
    const query = useQuery<CriticalIssuesData>(['critical-issues'], async () => {
        const data: CriticalIssuesData = await api.get(createReportResource('critical-issues'))

        if (data.totalCount) {
            const [open, scheduled, inProgress] = roundPercentages([
                data.openPercent,
                data.scheduledPercent,
                data.inProgressPercent,
            ])
            data.openPercent = open
            data.scheduledPercent = scheduled
            data.inProgressPercent = inProgress
        }
        return data
    })

    const data = query.data

    if (!data) {
        return <WidgetSkeleton />
    }

    return (
        <WidgetBase
            headerProps={{
                title: 'Critical Issues',
                clarification: (
                    <CostPerCategoryClarification>
                        affecting {data.unitsCount} units
                    </CostPerCategoryClarification>
                ),
                chart: <Chart data={data} />,
                label: String(data.totalCount),
                contentOnSmall: (
                    <IconElement
                        component={Icons.ErrorOutline}
                        color={(theme) => theme.palette.charts.red}
                    />
                ),
            }}
            infoProps={{
                content: (
                    <IssuesByStatusRows
                        extraFilters={{
                            [issueFields.priority.source]: [IssuePriorityKeys.CRITICAL],
                        }}
                        data={data}
                        stretch
                    />
                ),
            }}
            separator="by Status"
            link={
                urls.issues +
                '?filter=' +
                encodeURIComponent(
                    JSON.stringify({
                        [issueFields.priority.source]: [IssuePriorityKeys.CRITICAL],
                        [customerFields.self.source]: [emptyOptionValue],
                        [issueFields.status.source]: [
                            IssueStatusKeys.OPEN,
                            IssueStatusKeys.IN_PROGRESS,
                            IssueStatusKeys.SCHEDULED,
                        ],
                    }),
                )
            }
        />
    )
}

export default CriticalIssuesWidget
