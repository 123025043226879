import { Stack } from '@mui/material'

import { alpha, styled } from 'lib'

const BoxedSection = styled(Stack)`
    padding: 20px;
    background: ${({ theme }) => alpha(theme.palette.text.primary, 0.04)};
    border-radius: 4px;
`

export default BoxedSection
