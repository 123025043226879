import { type Identifier, type BaseModel } from 'appTypes'
import { type WithAttachments } from 'resources/attachments'
import { type WithRemainDatePretty } from 'resources/common'
import { type WithUnit } from 'resources/units'

export interface ExpirationModel
    extends BaseModel,
        WithAttachments,
        WithRemainDatePretty,
        WithUnit {
    name: string
    expirationDate: string
    description: string
    altersUnitStatus: boolean
    thresholdValue: number
    thresholdType: string
    status: StatusKeys
    remainingTillOverdue: string
    targetStatus: Identifier
}

export enum StatusKeys {
    PLANNED = 'PLANNED',
    OVERDUE = 'OVERDUE',
    DUE_SOON = 'DUE_SOON',
    COMPLETED = 'COMPLETED',
}
