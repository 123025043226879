import { type BaseModel } from 'appTypes'
import { type Serializer } from 'core'

import { type Attachments } from './types'

export const getAttachmentName = (index: number, source?: string) =>
    `${source ?? 'attachment'}${index}`

export const getRecordAttachments = (
    record: BaseModel,
    source?: string,
    formValues?: any,
): {
    files: Attachments
    count: number
} => {
    const attachments = record || formValues
    const finalSource = source ?? 'attachment'
    const files = {}
    let count = 0
    for (let i = 0; i <= 9; i += 1) {
        if (attachments && attachments[finalSource + i]) {
            count += 1
        }
        files[finalSource + i] = attachments ? attachments[finalSource + i] : null
    }
    return { files, count }
}

export const attachmentsToArray = (attachments: Attachments) =>
    Object.keys(attachments).map((key) => ({
        id: key,
        file: attachments[key],
    }))

export const MAX_FILE_COUNT = 10

export const attachmentsIndexes = Array.from({ length: MAX_FILE_COUNT }, (_, i) => i)

export const attachmentsSerializer: Serializer = attachmentsIndexes.map((index) => ({
    name: getAttachmentName(index),
    parse: 'file',
}))

const allowedFileTypes: ({ type: string; validate?: (file: File) => boolean } | string)[] = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
    'text/rtf',
    'text/csv',
    'application/xml',
    'text/xml',
    'application/json',
    'text/html',
    'application/xhtml+xml',
    'text/markdown',
    'application/rtf',
    '.nef',
    '.dng',
    { type: 'image/*', validate: (file) => file.type.startsWith('image') },
    { type: '.md', validate: (file) => file.name.endsWith('.md') },
    { type: 'video/*', validate: (file) => file.type.startsWith('video') },
]

export const validateFileTypes = (file: File) =>
    allowedFileTypes.some((item) => {
        if (typeof item === 'object' && item?.validate) {
            return item.validate(file)
        }
        return item === file.type
    })

export const acceptedFileTypes = allowedFileTypes
    .map((file) => (typeof file === 'object' ? file.type : file))
    .join(',')
