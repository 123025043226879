import { type FC } from 'react'

import Icons from 'assets/icons'
import { type ThemeColorType } from 'lib'
import { IconElement } from 'ui'

const BaseStatusIcon: FC<{ color: ThemeColorType }> = ({ color }) => {
    return (
        <IconElement
            component={Icons.Dot}
            color={color}
        />
    )
}

export default BaseStatusIcon
