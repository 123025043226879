import { type FilterBy, FilterDateRangeValue, type SortBy, type Column } from 'components'
import { formatDate } from 'lib'

import { type WorkOrderModel } from '../types'

const source = 'scheduledStart' satisfies keyof WorkOrderModel

const label = 'Scheduled Start Date & Time'

const getValue = (value: string) =>
    formatDate(value, (dateFormats) => dateFormats.shortenedDateTime)

const tableColumn = () =>
    ({
        field: source,
        headerName: label,
        renderCell: ({ value }) => getValue(value),
    }) as const satisfies Column

const sort = () =>
    ({
        id: source,
        label,
    }) as const satisfies SortBy

const filter = () =>
    ({
        id: source,
        label,
        renderComponent: (props) => <FilterDateRangeValue {...props} />,
    }) as const satisfies FilterBy

const scheduledDate = {
    source,
    label,
    tableColumn,
    sort,
    filter,
    value: getValue,
    valueByRecord: (record: WorkOrderModel) => getValue(record.scheduledStart),
}

export default scheduledDate
