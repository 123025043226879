import { type FC } from 'react'

import { type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import {
    InfoCardDetails,
    InfoCardHeader,
    CollapsibleInfoCard,
    CardCollapsibleContent,
} from 'components'
import { type Action, deleteOneAction } from 'core/actions'
import { formatDate, globalClassNames, stringDateFixTimezone } from 'lib'
import {
    AttachmentsCollapsibleContent,
    AttachmentsCollapsibleIconHolder,
} from 'resources/attachments'
import { displayPaymentTerm } from 'resources/paymentTerms'
import { unaccessibleUnitActionMessage } from 'resources/workOrders'
import { Typography, Box, Anchor, type AnchorProps } from 'ui'

import { type PurchaseInvoiceModel } from '../types'
import { formatInvoiceAmount } from '../utils'

import { PurchaseInvoiceDrawerToggler, deleteInvoiceAction } from './PurchaseInvoiceDrawerToggler'
import PurchaseInvoiceEditButton from './PurchaseInvoiceEditButton'

export const PurchaseInvoiceCard = ({
    invoice,
    disableActions,
    resource,
    editOnly,
    readOnly,
}: {
    invoice: PurchaseInvoiceModel
    disableActions?: boolean
    editOnly?: boolean
    resource?: string
    readOnly?: boolean
}) => {
    return (
        <CollapsibleInfoCard>
            <InfoCardHeader
                actions={(p, p2) =>
                    editOnly
                        ? []
                        : invoiceActions(
                              {
                                  id: invoice.id,
                                  disabled: disableActions,
                                  readOnly,
                                  resource,
                              },
                              p2,
                          )
                }
                action={
                    editOnly ? (
                        <PurchaseInvoiceEditButton
                            id={invoice.id}
                            resource={resource}
                            readOnly={readOnly}
                            disabled={disableActions}
                        />
                    ) : null
                }
                title={
                    <InvoiceCardTitle
                        invoice={invoice}
                        disableActions={disableActions}
                        resource={resource}
                        readOnly={readOnly}
                    />
                }
            />
            <InfoCardDetails<PurchaseInvoiceModel>
                details={[
                    {
                        label: 'amount',
                        source: ({ amount }) => (
                            <Typography
                                variant="inherit"
                                color={amount < 0 ? 'error.main' : 'text.primary'}
                            >
                                {formatInvoiceAmount(amount)}
                            </Typography>
                        ),
                    },
                    {
                        label: 'date',
                        source: ({ date }) =>
                            date &&
                            formatDate(
                                stringDateFixTimezone(date),
                                (dateFormats) => dateFormats.shortenedDate,
                            ),
                    },
                    {
                        label: 'payment term',
                        source: ({ paymentTermData }) => displayPaymentTerm(paymentTermData),
                    },
                ]}
                propRecord={invoice}
            />
            <Box pt="10px">
                <CardCollapsibleContent
                    content={[
                        {
                            iconHolder: (
                                <AttachmentsCollapsibleIconHolder
                                    title="Attachments"
                                    key="attachments-icon"
                                    source="attachment"
                                    record={invoice}
                                />
                            ),
                            component: (
                                <AttachmentsCollapsibleContent
                                    source="attachment"
                                    title="Attachments"
                                    key="attachments-content"
                                    record={invoice}
                                    uploadResource={resource}
                                    disabled={readOnly}
                                    titleOnDisabled={unaccessibleUnitActionMessage}
                                />
                            ),
                        },
                    ]}
                />
            </Box>
        </CollapsibleInfoCard>
    )
}

const InvoiceCardTitle = ({
    invoice,
    disableActions,
    resource,
    readOnly,
}: {
    invoice: PurchaseInvoiceModel
    disableActions?: boolean
    resource?: string
    readOnly?: boolean
}) => {
    return (
        <PurchaseInvoiceDrawerToggler
            id={invoice.id}
            disabled={disableActions}
            readOnly={readOnly}
            resource={resource}
        >
            {(open) => (
                <TitleBase
                    invoice={invoice}
                    sx={{
                        cursor: 'pointer',
                    }}
                    onClick={open}
                    color="primary.main"
                />
            )}
        </PurchaseInvoiceDrawerToggler>
    )
}

const TitleBase: FC<{ invoice: PurchaseInvoiceModel } & Omit<AnchorProps, 'children'>> = ({
    invoice,
    ...props
}) => {
    return (
        <Anchor
            className={globalClassNames.ellipsis}
            display="block"
            {...props}
        >
            Invoice No {invoice.number}
        </Anchor>
    )
}

interface InvoiceActionsProps {
    id: Identifier
    readOnly: boolean
    disabled: boolean
    resource?: string
}

const invoiceActions: Action = (
    { id, disabled, readOnly, resource }: InvoiceActionsProps,
    { children },
) => [
    <PurchaseInvoiceDrawerToggler
        id={id}
        key="edit"
        children={(open) => children({ title: 'Edit/View', Icon: Icons.Edit, onClick: open })}
        readOnly={readOnly}
        resource={resource}
        disabled={disabled}
    />,
    deleteOneAction({
        redirectTo: '',
        children,
        id,
        disabled: disabled || readOnly,
        ...deleteInvoiceAction(),
    }),
]
