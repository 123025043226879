import { type FC, type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useInputStep, useOpenUtilityDrawer, UtilityDrawerEditor } from 'components'
import { type Serializer } from 'core'
import {
    deleteInvoiceItemActionParams,
    getInvoiceItemResource,
    type InvoiceModel,
    type InvoicePartModel,
    InvoiceStatusKeys,
    InvoiceTypeKeys,
} from 'resources/invoices'

import PartForm, { InvoicePartInput, type PartFormProps } from './PartForm'

interface InvoicePartDrawerProps extends Pick<PartFormProps, 'isDisabled'> {
    children: (open: () => void) => ReactElement
    invoice: InvoiceModel
    id?: Identifier
    type?: InvoiceTypeKeys
}

const InvoicePartDrawer = ({
    invoice,
    id,
    children,
    type = InvoiceTypeKeys.STANDARD,
    isDisabled,
}: InvoicePartDrawerProps) => {
    const open = useOpenUtilityDrawer()
    const resource = getInvoiceItemResource(invoice.id, id ? undefined : 'add_part')
    const readOnly = invoice.status !== InvoiceStatusKeys.OPEN
    const inputStep = useInputStep()

    return children(async () => {
        let formProps
        if (!id) {
            formProps = await inputStep((params) => (
                <InvoicePartInput
                    invoiceId={invoice.id}
                    {...params}
                />
            ))

            if (!formProps) {
                return
            }
        }

        open({
            extraArgs: {
                resource,
                id,
            },
            drawerArgs: {
                title: id ? <EditTitle readOnly={readOnly} /> : 'Add Part',
                renderWrapper: (params) => (
                    <UtilityDrawerEditor
                        {...params}
                        {...formProps}
                        serializer={serializer}
                    />
                ),
                renderContent: () => (
                    <FormContent
                        isDisabled={isDisabled}
                        id={id}
                        type={type}
                        record={invoice}
                    />
                ),
                renderBottomRight: readOnly
                    ? null
                    : (render) =>
                          render({
                              label: id ? undefined : 'Add',
                          }),
                renderBottomLeft: readOnly ? (render) => render({ children: 'Close' }) : undefined,
                renderTopRight:
                    id && type !== InvoiceTypeKeys.WORK_ORDER && !readOnly
                        ? (render) => render(deleteInvoiceItemActionParams)
                        : null,
            },
            extra: {
                invoice,
            } satisfies InvoicePartExtra,
        })
    })
}

export default InvoicePartDrawer

export interface InvoicePartExtra {
    invoice: InvoiceModel
}
const EditTitle: FC<{ readOnly: boolean }> = ({ readOnly }) => {
    if (readOnly) {
        return <>View Part</>
    }

    return <>Edit Part</>
}

interface FormContentProps extends Pick<PartFormProps, 'isDisabled'> {
    id?: Identifier
    type: InvoiceTypeKeys
    record: InvoiceModel
}

const FormContent: FC<FormContentProps> = ({ isDisabled, id, type, record }) => {
    return (
        <PartForm
            isHidden={(source) => {
                if (type === InvoiceTypeKeys.STANDARD) {
                    return source === 'orderQuantity' || source === 'position'
                }
            }}
            isDisabled={(source) => {
                if (!id) {
                    return isDisabled?.(source)
                }

                if (record.status !== InvoiceStatusKeys.OPEN) {
                    return true
                }

                if (type === InvoiceTypeKeys.WORK_ORDER) {
                    return source === 'part'
                }

                if (source === 'part') {
                    return true
                }
            }}
        />
    )
}

const serializer: Serializer<InvoicePartModel> = ['part', 'quantity', 'price']
