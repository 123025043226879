import { defaultStatuses, options, type StatusConfig } from 'resources/common'
import { IconElement } from 'ui'

import { type IssueModel, IssueStatusKeys } from '../types'

const source = 'status' as const satisfies keyof IssueModel

const getConfig = options.createConfig<StatusConfig<IssueStatusKeys>>([
    {
        ...defaultStatuses.open,
        id: IssueStatusKeys.OPEN,
        color: (theme) => theme.palette.charts.orange,
        icon: (
            <IconElement
                component={defaultStatuses.open.iconComponent}
                color={(theme) => theme.palette.charts.orange}
            />
        ),
    },
    { ...defaultStatuses.inProgress, id: IssueStatusKeys.IN_PROGRESS },
    { ...defaultStatuses.closed, id: IssueStatusKeys.CLOSED },
    {
        ...defaultStatuses.scheduled,
        id: IssueStatusKeys.SCHEDULED,
        icon: (
            <IconElement
                component={defaultStatuses.scheduled.iconComponent}
                color={(theme) => theme.palette.charts.yellow}
            />
        ),
        color: (theme) => theme.palette.charts.yellow,
    },
])

const value = options.makeIconValue(getConfig)

const status = {
    source,
    label: 'Status',
    getConfig,
    value: (data: Pick<IssueModel, 'status'>) => value(data?.status),
}

export default status
