import { type ReactElement, type ReactNode, useEffect } from 'react'

import { useRemoveFromStore } from 'react-admin'

import { type DataRecord } from 'appTypes'
import { type AuthStore } from 'core/auth'
import { type ListControllerResult, useListContext } from 'core/list'
import { useResourceContext } from 'core/resource'

import Datagrid, { ColumnsProvider, type DatagridColumnsProps } from '../../Datagrid'
import { useListSnap } from '../ListContext'
import { FilterDrawer } from '../filter'
import { SortDrawer } from '../sort'
import { ListToolbar, type ListToolbarProps, ListToolbarSkeleton } from '../toolbar'
import { ListView } from '../types'

import CardList, { type CardListProps } from './CardList'
import ListSelection, { type ListSelectionProps } from './ListSelection'
import RenderList, { type RenderListProps } from './RenderList'

export interface ListUiProps<RecordType extends DataRecord = any>
    extends ListSelectionProps,
        Pick<RenderListProps, 'renderNoResults' | 'listFTUProps'> {
    exportFileName?: string
    columnsCfg?: DatagridColumnsProps
    cardsCfg?: CardListProps['cardsCfg']
    renderList?: (params: { listContext: ListControllerResult }) => ReactNode
    disableViewsSwitch?: boolean
    toolbarContent?: Pick<ListToolbarProps, 'contentBeforeViews' | 'contentUnderToolbar'>
    disableManageColumns?: boolean
    disableExportButton?: boolean
    hideSearch?: boolean
    disableSelectRecord?: (record: RecordType) => string
    renderView?: {
        card?: () => ReactElement
    }
}

const ListUi = ({
    exportFileName,
    columnsCfg,
    cardsCfg,
    listFTUProps,
    bulkActions,
    renderList,
    disableViewsSwitch,
    toolbarContent,
    renderNoResults,
    disableManageColumns = false,
    disableExportButton,
    hideSearch,
    disableSelectRecord,
    renderView,
}: ListUiProps & { auth?: AuthStore }) => {
    const snap = useListSnap()
    const listContext = useListContext()

    const { selectedIds, data } = listContext
    const isLoading = !data

    return (
        <>
            <ClearListDataOnUnmount />
            <HandleMultiselectOnListChange />
            <ColumnsProvider
                columnsConfig={columnsCfg}
                disableManageColumns={disableManageColumns}
            >
                {selectedIds.length === 0 ? (
                    isLoading ? (
                        <ListToolbarSkeleton />
                    ) : (
                        <ListToolbar
                            exportFileName={exportFileName}
                            disableViewsSwitch={disableViewsSwitch}
                            disableManageColumns={disableManageColumns}
                            disableExportButton={disableExportButton}
                            hideSearch={hideSearch}
                            {...toolbarContent}
                        />
                    )
                ) : null}
                <ListSelection
                    bulkActions={bulkActions}
                    disableSelectRecord={disableSelectRecord}
                />
                {Boolean(snap.config.filters?.length) && <FilterDrawer />}
                {Boolean(snap.config.sorts?.length) && <SortDrawer />}
                <RenderList
                    renderNoResults={renderNoResults}
                    listFTUProps={listFTUProps}
                >
                    <>
                        {snap.view !== ListView.TABLE}

                        {renderList?.({
                            listContext,
                        })}
                        {!renderList &&
                            (snap.view === ListView.TABLE ? (
                                <>
                                    <Datagrid
                                        disableSelectRecord={disableSelectRecord}
                                        {...columnsCfg}
                                    />
                                </>
                            ) : renderView?.card ? (
                                renderView.card()
                            ) : (
                                <CardList
                                    cardsCfg={cardsCfg}
                                    disableSelectRecord={disableSelectRecord}
                                />
                            ))}
                    </>
                </RenderList>
            </ColumnsProvider>
        </>
    )
}

export default ListUi

// Clear some data from RA store after leaving the list
const ClearListDataOnUnmount = () => {
    const removeFromStore = useRemoveFromStore()
    const resource = useResourceContext()

    useEffect(() => {
        return () => {
            removeFromStore(`${resource}.selectedIds`)
        }
    }, [])

    return null
}

const HandleMultiselectOnListChange = () => {
    const { data, selectedIds, onSelect } = useListContext()

    useEffect(() => {
        const newSelected = selectedIds.filter((selectedId) =>
            data.some(({ id }) => id === selectedId),
        )
        if (newSelected.length !== selectedIds.length) {
            onSelect(newSelected)
        }
    }, [data, selectedIds])

    return null
}
