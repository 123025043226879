import { type ReactElement, type ReactNode } from 'react'

import { type DataRecord, type ObjectAny } from 'appTypes'
import { type SortPayload } from 'core'
import { type ListControllerResult } from 'core/list'
import { type ResourcePreferences } from 'resourcesBase'

export const enum ListView {
    CARD = 'CARD',
    TABLE = 'TABLE',
}

export interface State<RecordType extends DataRecord = DataRecord> {
    view: ListView
    _view: ListView
    filter: {
        isOpen: boolean
        isApplied: boolean
    }
    sort: boolean
    config: Config<RecordType>
    functions: Functions
}

export interface ListControllerProps<RecordType extends DataRecord = DataRecord>
    extends Omit<Config<RecordType>, 'resetColumns' | 'constantColumns'>,
        Pick<ListControllerResult, 'filter'> {
    children: ReactNode
    isLoading?: boolean
    filterValues?: ObjectAny | ((preferences: ResourcePreferences) => ObjectAny)
    disableSyncWithLocation?: boolean
}

export interface Config<RecordType extends DataRecord> {
    filters?: FilterBy<RecordType>[]
    sorts?: SortBy<RecordType>[]
    sort?: SortPayload<RecordType> // default sort
    constantColumns?: { [key: string]: boolean }
    resource?: string
    preferencesName?: string
}

export interface Functions {
    filter: {
        setOpen: (toggle: boolean) => void
        open: () => void
        close: () => void
        reset: () => void
    }

    sort: {
        setOpen: (toggle: boolean) => void
        open: () => void
        close: () => void
        areEqual: (sort1: SortPayload, sort2: SortPayload) => boolean
    }

    view: {
        change: (view: ListView) => void
    }
}

export interface FilterBy<RecordType extends DataRecord = DataRecord> {
    id: keyof RecordType
    label: string
    renderComponent?: (props: FilterRenderProps) => ReactElement
    withOperator?: boolean
}

export interface SortBy<RecordType extends Record<string, any> = Record<string, any>> {
    id: keyof RecordType
    label: string
}

export interface FilterRenderProps {
    // id of the filter
    filterName: string
    // the source of the value inputs
    valueSource: string
    // the source of the inputs except name
    dataSource: string
}
