import { useListContext } from 'ra-core'

import { useShowContext } from 'core'
import { type PurchaseOrderModel } from 'resources/purchaseOrders'
import { Typography } from 'ui'

import { LineItemsDrawerToggler } from '../../actions'

const AddItemsFTUButton = () => {
    const { resource } = useListContext()
    const { record, isLoading } = useShowContext<PurchaseOrderModel>()

    if (isLoading) {
        return null
    }

    const disabled = record.status !== 'OPEN'
    return (
        <LineItemsDrawerToggler
            poRecord={record}
            resource={resource}
        >
            {({ onClick }) => (
                <Typography
                    onClick={disabled ? undefined : onClick}
                    variant="body1"
                    color={disabled ? 'text.disabled' : 'primary.main'}
                    sx={{ cursor: disabled ? 'default' : 'pointer' }}
                >
                    Add Item
                </Typography>
            )}
        </LineItemsDrawerToggler>
    )
}
export default AddItemsFTUButton
