import { type FC, useState } from 'react'

import { type ObjectAny, type Identifier } from 'appTypes'
import Icons from 'assets/icons'
import { MultiFormatScanner, useDialogSelectorContext } from 'components'
import { useDataProvider, useFinalErrorHandler, useNotify } from 'core'
import { Button } from 'ui'

import { type PartModel } from '../types'
import { partsResource } from '../utils'

interface PartScannerProps {
    contextType: string
    contextId: Identifier
    filter?: ObjectAny
}

const PartScanner: FC<PartScannerProps> = ({ contextType, contextId, filter }) => {
    const [isOpen, setIsOpen] = useState(false)

    const dataProvider = useDataProvider()
    const close = () => {
        setIsOpen(false)
    }
    const notify = useNotify()
    const errorHandler = useFinalErrorHandler()
    const { onSelect } = useDialogSelectorContext()

    return (
        <>
            <MultiFormatScanner
                isOpen={isOpen}
                close={close}
                onDecode={async (decodedText, isUpcFormat) => {
                    try {
                        let part: PartModel
                        if (isUpcFormat) {
                            part = (
                                await dataProvider.getList(partsResource.resource, {
                                    filter: {
                                        ...filter,
                                        universalProductCode: decodedText,
                                        contextType,
                                        contextId,
                                    },
                                    pagination: { perPage: 1, page: 1 },
                                    sort: null,
                                })
                            ).data[0]
                        } else {
                            const url = new URL(decodedText)
                            const pathnameParts = url.pathname.split('/')
                            // in case if the url ends with "/"
                            const id = pathnameParts.pop() || pathnameParts.pop()
                            if (!id) {
                                throw new Error()
                            }
                            part = (
                                await dataProvider
                                    .getOne<PartModel>(partsResource.resource, {
                                        id,
                                        meta: {
                                            query: {
                                                contextType,
                                                contextId,
                                                ...filter,
                                            },
                                        },
                                    })
                                    .catch((err) => {
                                        if (err.status === 404) {
                                            throw new Error()
                                        }
                                        errorHandler(err)
                                        return null
                                    })
                            )?.data
                        }

                        if (!part) {
                            throw new Error()
                        }

                        notify('Part Selected')
                        onSelect(part.id)
                    } catch {
                        notify({
                            title: 'No Part information found on scanned code',
                            type: 'error',
                        })
                    } finally {
                        close()
                    }
                }}
            />
            <Button
                startIcon={<Icons.QrCodeScanner />}
                variant="text"
                size="small"
                onClick={() => setIsOpen(true)}
            >
                SCAN
            </Button>
        </>
    )
}
export default PartScanner
