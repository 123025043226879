import { type FC } from 'react'

import { inject, observer } from 'mobx-react'

import { InfoCard, InfoCardDetails, type InfoCardDetailsRow } from 'components'
import { useRecordContext, type AuthStore } from 'core'
import { meterTypesConfig, meterTypesForUnit, meterValue } from 'resources/units'
import { isVendorWo, woFields, WoStatusKeys, type WorkOrderModel } from 'resources/workOrders'

const DescriptionCreatedOnBy: FC = inject('auth')(
    observer(({ auth }: { auth: AuthStore }) => {
        const workOrder = useRecordContext<WorkOrderModel>()

        return (
            <InfoCard>
                <InfoCardDetails<WorkOrderModel>
                    details={[
                        auth.companySettings.hasInvoicing &&
                        workOrder.unitData.customer &&
                        !isVendorWo(workOrder) &&
                        workOrder.status !== WoStatusKeys.PENDING
                            ? {
                                  label: 'Customer PO Number',
                                  source: ({ salesOrderData }) => salesOrderData?.poNumber,
                              }
                            : null,
                        ...meterTypesForUnit.map(
                            (meterType): InfoCardDetailsRow<WorkOrderModel> => {
                                const value = workOrder.metersStart[meterType]

                                if (value == null) {
                                    return null
                                }

                                const meterConfig = meterTypesConfig[meterType]

                                return {
                                    label: `Start ${meterConfig.name}`,
                                    source: () => meterValue(value, meterType),
                                }
                            },
                        ),
                        {
                            label: woFields.scheduledDate.label,
                            source: ({ scheduledStart }) =>
                                woFields.scheduledDate.value(scheduledStart),
                        },
                        workOrder.status === WoStatusKeys.PENDING
                            ? null
                            : {
                                  label: woFields.startDate.label,
                                  source: ({ started }) => woFields.startDate.value(started),
                              },
                        {
                            label: woFields.expectedCompletionDate.label,
                            source: ({ expectedCompletion }) =>
                                woFields.expectedCompletionDate.value(expectedCompletion),
                        },
                    ]}
                />
            </InfoCard>
        )
    }),
)

export default DescriptionCreatedOnBy
