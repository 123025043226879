import { type FC } from 'react'

import { useResourceContext } from 'ra-core'

import { PtTitle } from '../../../components'
import { PTLaborDrawerToggler } from '../../components'
import { type PtLaborModel } from '../../types'
interface Props {
    record: Pick<PtLaborModel, 'name' | 'id' | 'isDefault'>
}

const Value: FC<Props> = ({ record }) => {
    const resource = useResourceContext()

    return (
        <PTLaborDrawerToggler
            id={record.id}
            resource={resource}
        >
            {(open) => (
                <PtTitle
                    showStatus={record.isDefault}
                    onClick={open}
                    tooltip={
                        <>
                            The "Default" setting is your shop's standard pricing for repairs, used
                            whenever a customer doesn't have a specific pricing plan in place.
                        </>
                    }
                >
                    {record.name}
                </PtTitle>
            )}
        </PTLaborDrawerToggler>
    )
}
export default Value
