import { type ReactElement } from 'react'

import { Form, useListContext } from 'ra-core'

import Icons from 'assets/icons'
import { createMessage, useNotify, useShowContext } from 'core'
import api from 'core/api'
import { PartInput, type PartModel } from 'resources/parts'
import { IconBox } from 'ui'

const AddReferenceButton = ({
    path,
    renderElement,
}: {
    path: string
    renderElement?: (onClick: () => void) => ReactElement
}) => {
    const notify = useNotify()
    const { record } = useShowContext()
    const { isLoading } = useListContext<PartModel>()
    if (isLoading || !record) {
        return null
    }

    return (
        <Form>
            <PartInput
                refetchOnOpen
                successMessage=""
                contextType="part-cross-references"
                contextId={record.id}
                renderToggler={({ handleOpen }) => {
                    const onClick = () => {
                        handleOpen()
                    }
                    return renderElement ? (
                        renderElement(onClick)
                    ) : (
                        <IconBox
                            onClick={onClick}
                            title="Add Cross-reference"
                        >
                            <Icons.Add />
                        </IconBox>
                    )
                }}
                onChange={(control, id) => {
                    if (id) {
                        api.post(path, { [control.source]: id }).then(() => {
                            notify(createMessage)
                        })
                    }
                }}
            />
        </Form>
    )
}
export default AddReferenceButton
