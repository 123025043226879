import { type ReactElement } from 'react'

import { filterSearchText } from 'core/data'
import { useChoicesContext } from 'core/referenceController'
import { Box, CircularProgress, List } from 'ui'

import DialogSelectorListItem, { DialogSelectorItemBase } from './DialogSelectorListItem'
import DialogSelectorNoResults from './DialogSelectorNoResults'

import type { DialogSelectorListItemProps } from './DialogSelectorListItem'
import type { DataRecord, Identifier, ExtendRecordType } from 'appTypes'
import type { NoResultsCardProps } from 'components/NoResultsCard/NoResultsCard'

export interface DialogSelectorListProps<T extends DataRecord = any> {
    itemPrimary?: ExtendRecordType<T, string | string[] | ReactElement>
    itemSecondary?: ExtendRecordType<T, string | string[] | ReactElement>
    noResults?: (params: { searchValue: string; filterValues: any }) => {
        title?: string
        text?: string | ReactElement
        image?: NoResultsCardProps['imageSrc']
    }
    selectItem: (id: Identifier) => void
    renderNoResults?: (params: { filterValues: any; searchValue: any }) => ReactElement | undefined
    renderListItem?: (
        record: T,
        render: (params?: Partial<DialogSelectorListItemProps<T>>) => ReactElement,
    ) => ReactElement
    renderItem?: (record: T) => ReactElement
}

const DialogSelectorList = ({
    itemPrimary = 'title',
    itemSecondary = 'text',
    noResults,
    selectItem,
    renderNoResults,
    renderListItem,
    renderItem,
}: DialogSelectorListProps) => {
    const { availableChoices, isLoading, filterValues } = useChoicesContext()

    if (isLoading) {
        return (
            <Box
                display="flex"
                height="100%"
                justifyContent="center"
                alignItems="center"
            >
                <CircularProgress size="80px" />
            </Box>
        )
    }

    if (!availableChoices.length) {
        if (renderNoResults) {
            const noResultRender = renderNoResults({
                filterValues,
                searchValue: filterValues[filterSearchText],
            })
            if (typeof noResultRender !== 'undefined') {
                return noResultRender
            }
        }

        const noResultsConfig = noResults?.({
            searchValue: filterValues[filterSearchText],
            filterValues,
        })

        return <DialogSelectorNoResults {...noResultsConfig} />
    }

    return (
        <List
            sx={{ display: 'flex', height: '100%', flexDirection: 'column' }}
            disablePadding
            role="listbox"
            aria-label="Select option"
        >
            {renderItem
                ? availableChoices.map((choice) => (
                      <DialogSelectorItemBase
                          key={choice.id}
                          onSelect={() => selectItem(choice.id)}
                      >
                          {renderItem(choice)}
                      </DialogSelectorItemBase>
                  ))
                : availableChoices.map((choice) => {
                      const render = (params?: any) => {
                          return (
                              <DialogSelectorListItem
                                  choice={choice}
                                  onSelect={(e, choice) => selectItem(choice.id)}
                                  itemPrimary={itemPrimary}
                                  itemSecondary={itemSecondary}
                                  key={choice.id}
                                  {...params}
                              />
                          )
                      }

                      if (renderListItem) {
                          return renderListItem(choice, render)
                      }

                      return render()
                  })}
        </List>
    )
}

export default DialogSelectorList
