import { type ThemeColorType } from 'lib'
import { defaultPriorities, options, type PriorityConfig } from 'resources/common'
import { IconElement } from 'ui'

import { IssuePriorityKeys, type IssueModel } from '../types'

const priorities = [
    {
        ...defaultPriorities.CRITICAL,
        id: IssuePriorityKeys.CRITICAL,
        icon: (
            <IconElement
                component={defaultPriorities.CRITICAL.iconComponent}
                color={(theme) => theme.palette.charts.red}
            />
        ),
        color: (theme) => theme.palette.charts.red,
    },
    {
        ...defaultPriorities.HIGH,
        id: IssuePriorityKeys.HIGH,
        icon: (
            <IconElement
                component={defaultPriorities.HIGH.iconComponent}
                color={(theme) => theme.palette.charts.orange}
            />
        ),
        color: (theme) => theme.palette.charts.orange,
    },
    {
        ...defaultPriorities.MEDIUM,
        id: IssuePriorityKeys.MEDIUM,
        icon: (
            <IconElement
                component={defaultPriorities.MEDIUM.iconComponent}
                color={(theme) => theme.palette.primary.main}
            />
        ),
        color: (theme) => theme.palette.primary.main,
    },
    {
        ...defaultPriorities.LOW,
        id: IssuePriorityKeys.LOW,
        icon: (
            <IconElement
                component={defaultPriorities.LOW.iconComponent}
                color={(theme) => theme.palette.text.secondary}
            />
        ),
        color: (theme) => theme.palette.text.secondary,
    },
] satisfies IssuePriority[]

interface IssuePriority extends PriorityConfig<IssuePriorityKeys> {
    color: ThemeColorType
}

const getConfig = options.createConfig<IssuePriority>(priorities)

const value = options.makeIconValue(getConfig)

const source: keyof IssueModel = 'priority'

const priority = {
    source,
    getConfig,
    label: 'Priority',
    choices: options.createIdChoices(priorities),
    value: (record: Pick<IssueModel, 'priority'>) => value(record.priority),
    inputValue: options.makeIdValue(value),
}

export default priority
