import { type ReactElement } from 'react'

import { type DataRecord, type ExtendRecordType } from 'appTypes'
import Icons from 'assets/icons'
import { extendRecord } from 'core/data'
import { toolbarClasses, AppBar, Toolbar, Box, Typography } from 'ui'

import DialogSelectorAppBarButton from './DialogSelectorAppBarButton'

export interface DialogSelectorAppBarProps<OptionType extends DataRecord = any> {
    onClose: () => void
    selected: OptionType
    titleSource: ExtendRecordType<OptionType, string | number>
    leftButton?: ReactElement
    paddingX?: string
}

const DialogSelectorAppBar = <OptionType extends DataRecord = any>({
    onClose,
    selected,
    titleSource,
    leftButton,
    paddingX,
}: DialogSelectorAppBarProps<OptionType>) => {
    return (
        <AppBar
            position="static"
            elevation={2}
        >
            <Toolbar
                variant="dense"
                sx={{
                    [`&.${toolbarClasses.root}`]: {
                        gap: '4px',
                        px: paddingX || '20px',
                        // display: 'grid',
                        // gridTemplateColumns: '48px 1fr 48px',
                    },
                }}
            >
                <Box flex="none">{leftButton}</Box>
                <Typography
                    variant="subtitle1"
                    component="div"
                    color="white"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    flexGrow={1}
                >
                    {extendRecord<OptionType>(selected, titleSource)}
                </Typography>
                <DialogSelectorAppBarButton
                    onClick={() => onClose()}
                    aria-label="close"
                >
                    <Icons.Close />
                </DialogSelectorAppBarButton>
            </Toolbar>
        </AppBar>
    )
}

export default DialogSelectorAppBar
