import { Fragment } from 'react'

import { type TabType, Header, InnerHeader, ShowHeaderSkeleton } from 'components'
import { urls } from 'configs'
import { useRecordContext, archiveOneAction, type Action, deleteOneAction } from 'core'
import { WorkOrderDrawerToggler } from 'pages/WorkOrders/components'
import { useResources } from 'resources'
import {
    archiveUnitActionParams,
    deleteUnitAction,
    DownloadRecordAction,
    getMeterConfig,
    isUnitArchived,
    UnitArchivedStatus,
    unitFields,
    UnitIntegrationStatus,
    type UnitModel,
} from 'resources/units'
import { woFields } from 'resources/workOrders'
import { AutoGrid, SvgIcon } from 'ui'

import { CreateMeterDrawerToggler } from '../../Meters'

interface UnitShowHeaderInterface {
    tabs: TabType[]
}

const UnitShowHeader = ({ tabs }: UnitShowHeaderInterface) => {
    const record = useRecordContext<UnitModel>()
    const isArchived = isUnitArchived(record)
    const { unitStatus } = useResources()

    if (!record) {
        return <ShowHeaderSkeleton />
    }

    return (
        <InnerHeader
            tabs={tabs}
            tabWidth="130px"
            tabMinWidth="130px"
            actions={actions}
            backTo={isArchived ? urls.unitsArchived : urls.units}
        >
            <Header.Info
                avatar={
                    <Header.Avatar
                        color={isArchived ? (theme) => theme.palette.text.primary : undefined}
                        imageSrc={record.photo}
                        defaultImage={
                            <SvgIcon
                                component={unitFields.avatar.Icon}
                                inheritViewBox
                                sx={isArchived ? { opacity: 0.54 } : undefined}
                            />
                        }
                    />
                }
            >
                <Header.Content>
                    <AutoGrid.Row>
                        <AutoGrid.Column>
                            <Header.Title>{record.number}</Header.Title>
                        </AutoGrid.Column>
                        <UnitIntegrationStatus />
                    </AutoGrid.Row>
                </Header.Content>
                <Header.Content mobilePlacement="bottom">
                    <AutoGrid.Row>
                        {unitStatus.show && (
                            <AutoGrid.Column>
                                {unitStatus.value(record.statusData, isArchived ? null : record.id)}
                            </AutoGrid.Column>
                        )}
                        <AutoGrid.Column>
                            <UnitArchivedStatus />
                        </AutoGrid.Column>
                    </AutoGrid.Row>
                </Header.Content>
            </Header.Info>
        </InnerHeader>
    )
}

export default UnitShowHeader

const actions: Action<UnitModel> = (record, { children }) => {
    const isArchived = isUnitArchived(record)

    return [
        isArchived ? (
            <Fragment key="archived">
                {deleteOneAction({
                    children,
                    id: record.id,
                    ...deleteUnitAction(record.hasRelatedWorkOrders),
                })}
                <DownloadRecordAction
                    children={children}
                    record={record}
                    key="download-record"
                />
            </Fragment>
        ) : (
            <Fragment key="un-archived">
                <CreateMeterDrawerToggler>
                    {(params, single) => {
                        return children({
                            title: single ? `Add ${single.name}` : 'Add Meters',
                            Icon: single ? single.Icon : getMeterConfig('HUBOMETER').Icon,
                            key: 'create-meter',
                            ...params,
                        })
                    }}
                </CreateMeterDrawerToggler>
                <DownloadRecordAction
                    children={children}
                    record={record}
                    key="download-record"
                />
                <WorkOrderDrawerToggler
                    defaultValues={{
                        unit: record.id,
                    }}
                >
                    {({ onClick }) =>
                        children({
                            title: 'Create Work Order',
                            Icon: woFields.avatar.Icon,
                            key: 'create-wo',
                            onClick,
                        })
                    }
                </WorkOrderDrawerToggler>
            </Fragment>
        ),
        archiveOneAction({
            children,
            ...archiveUnitActionParams(
                isUnitArchived(record),
                Boolean(record.telematicsData),
                record.hasActiveWorkOrders,
                Boolean(record.ongoingCustomerAssignment),
            ),
        }),
    ]
}
