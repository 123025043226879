import { type ReactElement } from 'react'

import { type Identifier, type SvgIconElement } from 'appTypes'
import Icons from 'assets/icons'
import { SelectInput } from 'components'
import { requiredValidation } from 'core'
import { IconElement } from 'ui'

export interface PriorityConfig<Priority extends string> {
    id: Priority
    text: string
    icon: ReactElement
    iconComponent: SvgIconElement
}

export const defaultPriorities = {
    CRITICAL: {
        text: 'Critical',
        icon: <IconElement component={Icons.ErrorOutline} />,
        iconComponent: Icons.ErrorOutline,
    },
    HIGH: {
        text: 'High',
        icon: <IconElement component={Icons.ArrowUpward} />,
        iconComponent: Icons.ArrowUpward,
    },
    MEDIUM: {
        text: 'Medium',
        icon: <IconElement component={Icons.DragHandle} />,
        iconComponent: Icons.DragHandle,
    },
    LOW: {
        text: 'Low',
        icon: <IconElement component={Icons.ArrowDownward} />,
        iconComponent: Icons.ArrowDownward,
    },
} satisfies { [key: string]: Omit<PriorityConfig<any>, 'id'> }

interface Props<Choice extends { id: Identifier }> {
    isRequired?: boolean
    disabled?: boolean
    choices: Choice[]
    optionText: (option: Choice) => string | ReactElement
    defaultValue?: Choice['id']
}

export const PriorityInput = <Choice extends { id: Identifier }>({
    isRequired,
    choices,
    disabled,
    optionText,
    defaultValue,
}: Props<Choice>) => {
    return (
        <SelectInput
            optionText={optionText}
            label="Priority"
            source="priority"
            validate={isRequired ? requiredValidation : undefined}
            choices={choices}
            disableEmptyValue
            defaultValue={defaultValue}
            disabled={disabled}
        />
    )
}
