export interface Taxes {
    miscellaneousTax?: number
    salesTax?: number
    shippingTax?: number
    discount?: number
}

export const taxesTotal = (taxes: Taxes): number => {
    if (!taxes) {
        return 0
    }

    return (
        Number(taxes.shippingTax || 0) +
        Number(taxes.miscellaneousTax || 0) +
        Number(taxes.salesTax || 0) -
        Number(taxes.discount || 0)
    )
}
