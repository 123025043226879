import { type FC, useEffect, useRef } from 'react'

import { type UseFormReturn, useFormContext } from 'react-hook-form'

import api from 'core/api'
import { nonFieldErrors, useErrorHandler } from 'core/errors'
import { useNotify } from 'core/notifications'

import { useUtilityDrawerContext } from '../UtilityDrawerContext'
import UtilityDrawerForm, { type UtilityDrawerFormProps } from '../UtilityDrawerForm'

import { useUtilityDrawerStepsContext } from './UtilityDrawerStepsContext'
import { type StepsParser } from './types'

const UtilityDrawerStepsForm = ({
    children,
    ...props
}: Omit<UtilityDrawerFormProps, 'disableCloseOnSubmit'>) => {
    const { next, isLastStep, onSuccess, successMessage } = useUtilityDrawerStepsContext()
    const { forceClose } = useUtilityDrawerContext()
    const notify = useNotify()
    const formRef = useRef<UseFormReturn>()

    const onSubmit = useStepsSubmit({
        onSubmit: (result, submitConfig) => {
            const _successMessage = submitConfig.successMessage || (isLastStep && successMessage)
            if (_successMessage) {
                notify(_successMessage)
            }
            if (isLastStep) {
                onSuccess?.(result)
                forceClose()
                return
            }
            next(result)
        },
        formRef,
    })

    return (
        <UtilityDrawerForm
            disableCloseOnSubmit
            {...props}
            onSubmit={onSubmit}
        >
            {children}
            <GetForm formRef={formRef} />
        </UtilityDrawerForm>
    )
}

const GetForm: FC<{ formRef: React.MutableRefObject<UseFormReturn> }> = ({ formRef }) => {
    const formContext = useFormContext()

    useEffect(() => {
        formRef.current = formContext
    })

    return null
}

export default UtilityDrawerStepsForm

export interface UtilityDrawerStepsFormSubmitConfig {
    successMessage?: string
    queryParams?: Record<string, any>
}

type SubmitConfig = { successMessage?: string }

const useStepsSubmit = ({
    onSubmit,
    formRef,
}: {
    onSubmit?: (data: any, submitConfig: SubmitConfig) => any
    formRef: React.MutableRefObject<UseFormReturn>
}) => {
    const stepsContext = useUtilityDrawerStepsContext()
    const { step, parser, submitEndpoint, stepsData, isLastStep, onFormError, onFieldError } =
        stepsContext
    const errorHandler = useErrorHandler({
        anonymous: true,
    })

    // _submitConfig is passed from onFormError retry function
    const submit = async (formData, _submitConfig: UtilityDrawerStepsFormSubmitConfig) => {
        stepsData.current.formValues = formData

        const currentStepParsedData: ReturnType<StepsParser<any>[number]> = parser[step](formData, {
            context: stepsContext,
        })

        if (!currentStepParsedData.requestData) {
            return null
        }

        const data = {}

        for (let i = 0; i < step; i += 1) {
            const stepData = parser[i]?.(formData, {
                context: stepsContext,
            }).requestData

            if (stepData) {
                Object.assign(data, stepData)
            }
        }

        Object.assign(data, currentStepParsedData.requestData)

        let response
        try {
            response = await api.post(submitEndpoint, data, {
                params: {
                    ...(!isLastStep && { dryRun: true }),
                    ..._submitConfig?.queryParams,
                },
            })
        } catch (err) {
            if (nonFieldErrors in err) {
                if (
                    onFormError?.(err[nonFieldErrors], {
                        formValues: stepsData.current.formValues,
                        retry: (config) => {
                            // We need to disable the submit button
                            // The form should handle that
                            formRef.current.handleSubmit((data) => submit(data, config))()
                        },
                    })
                ) {
                    return {}
                }
                return err
            }
            // handle 400
            if (typeof err.status !== 'number') {
                if (!currentStepParsedData) {
                    return err
                }

                if (onFieldError?.(err)) {
                    return {}
                }

                return Object.keys(currentStepParsedData.requestData).reduce((errors, dataKey) => {
                    if (err[dataKey]) {
                        errors[dataKey] = err[dataKey]
                    }
                    return errors
                }, {})
            }

            errorHandler(err)
            return {}
        }
        onSubmit?.(response, _submitConfig)

        return {}
    }

    return submit
}
