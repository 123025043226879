import { type FC, type ReactNode } from 'react'

import { ListBase } from 'ra-core'

import { perPageLimit, RaResourceContextProvider } from 'core'

interface Props {
    children: ReactNode
    resource: string
    enabled?: boolean
}

const PaymentsList: FC<Props> = ({ children, resource, enabled }) => {
    return (
        <RaResourceContextProvider value={{ resource, name: 'payments' }}>
            <ListBase
                perPage={perPageLimit}
                queryOptions={{
                    enabled,
                }}
            >
                {children}
            </ListBase>
        </RaResourceContextProvider>
    )
}

export default PaymentsList
