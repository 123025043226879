import { type ReactElement } from 'react'

import { type TextFieldProps, Box, BoxContainer } from 'ui'

import DialogSelectorFilterButtons, {
    type DialogSelectorFilterButtonsProps,
} from './DialogSelectorFilterButtons'
import DialogSelectorSearch from './DialogSelectorSearch'

export interface DialogSelectorFilterProps {
    search?: TextFieldProps
    select?: DialogSelectorFilterButtonsProps
    renderRight?: () => ReactElement
}

const DialogSelectorFilter = ({ search, select, renderRight }: DialogSelectorFilterProps) => {
    return (
        <BoxContainer
            px="20px"
            py="18px"
            bgcolor="gray"
            gap="16px"
        >
            <Box flexGrow={1}>
                <DialogSelectorSearch {...search} />
            </Box>
            {select && <DialogSelectorFilterButtons {...select} />}
            {typeof renderRight === 'function' && renderRight()}
        </BoxContainer>
    )
}

export default DialogSelectorFilter
