import { ResourceLinkButton } from 'components'
import { useCreateResourcePath } from 'core'
import { woResource } from 'resources/workOrders/utils'
import { BoxContainer } from 'ui'

import { type PurchaseOrderModel } from '../types'
import { poResource } from '../utils'

const PurchaseOrderTitle = ({
    record,
}: {
    record: Pick<PurchaseOrderModel, 'id' | 'number'> &
        Partial<Pick<PurchaseOrderModel, 'workOrder' | 'workOrderData'>>
}) => {
    const createPath = useCreateResourcePath()

    return (
        <BoxContainer sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            <ResourceLinkButton
                to={
                    record.workOrder
                        ? createPath({
                              resource: woResource.resource,
                              type: 'edit',
                              id: record.workOrder,
                          }) + '/invoice'
                        : {
                              resource: poResource.resource,
                              type: 'edit',
                              id: record.id,
                          }
                }
            >
                {record.workOrder && record.workOrderData
                    ? `${record.number} • ${record.workOrderData.number}`
                    : record.number}
            </ResourceLinkButton>
        </BoxContainer>
    )
}

export default PurchaseOrderTitle
