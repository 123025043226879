import colors from './colors'
import { alpha } from './utils'

const themePalette = {
    default: {
        light: colors.grey[100],
        main: colors.grey[300],
        dark: colors.grey[500],
    },
    common: {
        black: colors.grey[700],
    },
    white: colors.white,
    primary: {
        light: colors.blue[400],
        main: colors.blue[500],
        dark: colors.blue[700],
        contrastText: colors.white,
    },
    secondary: {
        light: colors.grey[400],
        main: colors.grey[500],
        dark: colors.grey[700],
        contrastText: colors.white,
    },
    error: {
        light: colors.red[200],
        main: colors.red[900],
        dark: '#d80808',
        contrastText: colors.white,
        background: colors.red[50],
        content: '#5d0b0b',
    },
    success: {
        light: colors.lightGreen[200],
        main: colors.lightGreen[500],
        dark: colors.lightGreen[900],
        contrastText: colors.white,
        background: colors.lightGreen[50],
        content: '#0a422d',
    },
    info: {
        light: colors.blue[400],
        main: colors.blue[500],
        dark: colors.blue[900],
        contrastText: colors.white,
        background: colors.blue[50],
        content: '#143d80',
    },
    gray: colors.gray,
    darkGray: '#EEF2F7',
    grey: {
        50: colors.grey[50],
        100: colors.grey[100],
        200: colors.grey[200],
        300: colors.grey[300],
        400: colors.grey[400],
        500: colors.grey[500],
        600: colors.grey[600],
        700: colors.grey[700],
        800: colors.grey[800],
        900: colors.grey[900],
    },
    blue: {
        50: colors.blue[50],
        100: colors.blue[100],
        200: colors.blue[200],
        300: colors.blue[300],
        400: colors.blue[400],
        500: colors.blue[500],
        600: colors.blue[600],
        700: colors.blue[700],
        800: colors.blue[800],
        900: colors.blue[900],
    },
    red: {
        50: colors.red[50],
        100: colors.red[100],
        200: colors.red[200],
        300: colors.red[300],
        400: colors.red[400],
        500: colors.red[500],
        600: colors.red[600],
        700: colors.red[700],
        800: colors.red[800],
        900: colors.red[900],
    },
    lightGreen: {
        50: colors.lightGreen[50],
        100: colors.lightGreen[100],
        200: colors.lightGreen[200],
        300: colors.lightGreen[300],
        400: colors.lightGreen[400],
        500: colors.lightGreen[500],
        600: colors.lightGreen[600],
        700: colors.lightGreen[700],
        800: colors.lightGreen[800],
        900: colors.lightGreen[900],
    },
    deepRed: {
        50: colors.deepRed[50],
        100: colors.deepRed[100],
        200: colors.deepRed[200],
        300: colors.deepRed[300],
        400: colors.deepRed[400],
        500: colors.deepRed[500],
        600: colors.deepRed[600],
        700: colors.deepRed[700],
        800: colors.deepRed[800],
        900: colors.deepRed[900],
        A50: colors.deepRed.A50,
        A100: colors.deepRed.A100,
        A400: colors.deepRed.A400,
        A700: colors.deepRed.A700,
    },
    orange: {
        50: colors.orange[50],
        100: colors.orange[100],
        200: colors.orange[200],
        300: colors.orange[300],
        400: colors.orange[400],
        500: colors.orange[500],
        600: colors.orange[600],
        700: colors.orange[700],
        800: colors.orange[800],
        900: colors.orange[900],
    },
    action: {
        active: 'rgba(35, 52, 97, 0.54)',
        selected: 'rgba(35, 52, 97, 0.08)',
        activeSelected: alpha(colors.blue[500], 0.12),
        hovered: 'rgba(35, 52, 97, 0.1)',
        hoverOpacity: 0.1,
        selectedOpacity: 0.3,
        disabledBackground: colors.grey[50],
        disabled: colors.grey[200],
        disabledOpacity: 0.4,
    },
    sold: {
        main: colors.soldMain,
    },
    lost: {
        main: colors.lostMain,
    },
    empty: {
        main: colors.emptyMain,
    },
    warning: {
        light: colors.orange[200],
        main: colors.orange[500],
        dark: colors.orange[900],
        contrastText: colors.white,
        background: colors.orange[50],
        content: '#7e4600',
    },
    text: {
        primary: colors.grey[900],
        main: colors.grey[900],
        secondary: colors.grey[500],
        disabled: colors.grey[200],
    },
    table: {
        headerMain: '#f4f8ff',
        hoverRow: colors.grey[50],
        sortIcon: colors.grey[500],
        activeRow: colors.blue[50],
    },
    other: {
        tooltip: colors.grey[900],
        divider: 'rgba(35, 52, 97, 0.12)',
        grey: alpha(colors.grey[900], 0.04),
        inputBorder: 'rgba(0, 0, 0, 0.23)',
    },
    charts: {
        red: '#FF3131',
        orange: '#FF764C',
        blue: '#0066FF',
        green: '#1ECD8C',
        yellow: '#FFCE66',
        purple: '#7367F0',
        greenBody: colors.lightGreen[500],
        yellowBody: '#FFBA27',
        infoIcon: '#A5B0C7',
        disable: '#E4E6EC',
    },
}

type PaletteType = typeof themePalette
export interface IPalette extends PaletteType {}

export interface PalettePropOverrides extends Record<keyof IPalette, true> {}

export default themePalette
