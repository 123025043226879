import { type FC, type MouseEvent } from 'react'

import { useRecordContext } from 'core'
import { AddWorkOrderMenu } from 'pages/Units/components'
import { getPhotosFromRecordAsObject } from 'resources/gallery'
import { type IssueModel } from 'resources/issues'
import { type NoteModel } from 'resources/notes'
import { type UnitModel } from 'resources/units'

interface Props {
    renderToggler: (params: {
        onClick: (event: MouseEvent) => void
        disabled: boolean
        titleOnDisabled: string
    }) => JSX.Element
}

const AddToWO: FC<Props> = ({ renderToggler }) => {
    const record = useRecordContext<IssueModel>()

    return (
        <AddWorkOrderMenu<IssueModel>
            unit={record.unitData as UnitModel}
            options={{
                woDefaultValues: {
                    unit: record.unit as string,
                },
                woDisabledFields: {
                    unit: true,
                },
                jobDefaultValues: {
                    name: record.name,
                    component: record.component,
                    reasonForRepair: record.reasonForRepair as string,
                    complaint: record.complaint as string,
                    description: record.description,
                    notes: record.notes.map((note): NoteModel => ({ ...note, source: 'ISSUE' })),
                    ...getPhotosFromRecordAsObject(record, { reindex: true, skipEmpty: true }),
                },
            }}
            parentRecord={record}
            renderToggler={(open) =>
                renderToggler({
                    onClick: open,
                    disabled: record.status !== 'OPEN',
                    titleOnDisabled: 'Only open issues can be added to a work order',
                })
            }
        />
    )
}

export default AddToWO
