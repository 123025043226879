import { type ReactElement, createContext, useMemo, useContext } from 'react'

// eslint-disable-next-line no-restricted-imports
import { ResourceContextProvider, useResourceContext } from 'ra-core'

interface ResourceDataContextResult {
    name: string
}

const ResourceDataContext = createContext<ResourceDataContextResult>(
    {} as ResourceDataContextResult,
)
export const useResourceData = () => useContext(ResourceDataContext)

interface ResourceDataContextProviderProps {
    name?: string
    children: ReactElement
    resource?: string
}

export const ResourceDataContextProvider = ({
    name,
    children,
    resource: resourceProp,
}: ResourceDataContextProviderProps) => {
    const resource = useResourceContext({ resource: resourceProp })
    const resourceName = name || resource
    const value: ResourceDataContextResult = useMemo(
        () => ({
            name: resourceName,
        }),
        [resourceName],
    )

    return <ResourceDataContext.Provider value={value}>{children}</ResourceDataContext.Provider>
}

export interface ResourceContextProviderProps {
    children: ReactElement
    value: {
        name?: string
        resource: string
    }
}

export const RaResourceContextProvider = ({ children, value }: ResourceContextProviderProps) => {
    return (
        <ResourceContextProvider value={value.resource}>
            <ResourceDataContextProvider
                name={value.name}
                resource={value.resource}
            >
                {children}
            </ResourceDataContextProvider>
        </ResourceContextProvider>
    )
}

interface UseResourceProps {
    name?: string
    resource?: string
}

export interface ResourceType {
    resource: string
    name: string
}

export const useResource = (
    props: UseResourceProps | ((params: ResourceType) => UseResourceProps) = {},
): ResourceType => {
    const resource = useResourceContext()

    const { name } = useResourceData()

    const result = {
        name: name || resource,
        resource,
    }

    if (typeof props === 'function') {
        return {
            ...result,
            ...props(result),
        }
    }

    return {
        ...result,
        ...props,
    }
}
