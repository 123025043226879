import { useGetList } from 'ra-core'

import { perPageLimit } from 'core'

import { type Role } from '../types'
import { rolesResource } from '../utils'

export const useGetRoles = () =>
    useGetList<Role>(rolesResource.resource, {
        pagination: {
            page: 1,
            perPage: perPageLimit,
        },
    })
