import { type Identifier } from 'appTypes'
import { parseNumber, type Serializer, type ResourceConfig } from 'core'
import { pathJoin } from 'utils'

import { type WorkOrderModel } from './types'

const resource = 'work-orders'

export const woResource = {
    name: 'work-orders',
    resource,
    jobsResource: (id: Identifier) => pathJoin(resource, id, 'jobs'),
    updateStatus: (id: Identifier, action: string) => pathJoin(resource, id, action),
    edit: (id: Identifier) => pathJoin(resource, id),
    invoice: (id: Identifier) => pathJoin(resource, id, 'invoice'),
} satisfies ResourceConfig

export const woPermissions = {
    open: 'open',
    close: 'close',
    cancel: 'cancel',
    pend: 'pend',
    complete: 'complete',
}

export interface WoTypeChoice {
    id: Identifier
    name: string
}

export const woTypeChoices: WoTypeChoice[] = [
    { id: 'internal', name: 'Internal' },
    { id: 'vendor', name: 'Vendor' },
]

export const workOrderSerializer: Serializer<WorkOrderModel & { poNumber: string }> = [
    'status',
    { name: 'unit', parse: ['emptyToNull', 'disableOnEdit'] },
    'priority',
    'repairPriorityClass',
    {
        name: 'started',
        parse: 'dateTime',
    },
    {
        name: 'expectedCompletion',
        parse: 'dateTime',
    },
    {
        name: 'scheduledStart',
        parse: 'dateTime',
    },
    'description',
    { name: 'shop', parse: 'emptyToNull' },
    {
        name: 'vendor',
        parse: (value) => {
            if (value) {
                return value
            }
            return undefined
        },
    },
    'poNumber',
    {
        name: 'metersStart',
        parse: (value, data) => {
            return Object.keys(value).reduce((acc, meterType) => {
                if (data.unitData.settings[meterType]?.tracked) {
                    acc[meterType] = parseNumber(value[meterType])
                }
                return acc
            }, {})
        },
    },
]
