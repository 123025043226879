import { type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { BooleanInput, type BooleanInputProps, DateInput } from 'components'
import { type AuthStore, requiredValidation } from 'core'
import { CustomerPONumberInput } from 'resources/customers'
import { invoiceFields } from 'resources/invoices'
import { paymentTermFields, PaymentTermInput } from 'resources/paymentTerms'
import { isWorkOrderBillable } from 'resources/workOrders/isWorkOrderBillable'
import { SectionTitleSmall, Stack } from 'ui'

import { type WorkOrderModel } from '../../types'

interface Props {
    workOrder: WorkOrderModel
}

const InvoicingContent: FC<Props> = inject('auth')(
    observer(({ workOrder, auth }: Props & { auth: AuthStore }) => {
        if (
            !auth.companySettings.hasInvoicing ||
            workOrder.salesOrderData?.invoiceData ||
            !isWorkOrderBillable(workOrder)
        ) {
            return null
        }

        return (
            <Stack gap="13px">
                <SectionTitleSmall>Invoicing</SectionTitleSmall>
                <InvoiceSwitch
                    defaultValue={workOrder.shopData.invoicingSettings.invoiceOnWorkOrderClose}
                    inputResetValues={() => ({
                        [invoiceFields.poNumber.source]: workOrder.salesOrderData?.poNumber,
                        [paymentTermFields.self.source]:
                            workOrder.salesOrderData?.customerData?.paymentTerm,
                        [invoiceFields.invoiceDate.source]: new Date(),
                    })}
                />
                <InvoicingInputs workOrder={workOrder} />
            </Stack>
        )
    }),
)

export default InvoicingContent

const InvoiceSwitch: FC<
    Pick<BooleanInputProps, 'defaultValue'> & {
        inputResetValues: () => { poNumber: string; paymentTerm: Identifier }
    }
> = ({ defaultValue, inputResetValues }) => {
    const { setValue } = useFormContext()

    return (
        <BooleanInput
            defaultValue={defaultValue}
            source="invoicingOnWoClose"
            label="Invoice on WO Close"
            onChange={({ target }) => {
                if (!target.checked) {
                    setValue('invoiceOnClose', inputResetValues(), { shouldValidate: true })
                }
            }}
            helperText=""
        />
    )
}

const InvoicingInputs: FC<Props> = ({ workOrder }) => {
    const { getValues, watch } = useFormContext()
    const invoicingOnWoClose = watch('invoicingOnWoClose') || getValues('invoicingOnWoClose')

    if (!invoicingOnWoClose) {
        return null
    }

    return (
        <>
            <CustomerPONumberInput
                source={getSource(invoiceFields.poNumber.source)}
                defaultValue={workOrder.salesOrderData?.poNumber}
            />

            <DateInput
                source={getSource(invoiceFields.invoiceDate.source)}
                label={invoiceFields.invoiceDate.label}
                validate={requiredValidation}
                defaultValue={new Date()}
            />

            <PaymentTermInput
                source={getSource(paymentTermFields.self.source)}
                label={paymentTermFields.self.label}
                defaultValue={workOrder.salesOrderData?.customerData?.paymentTerm}
            />
        </>
    )
}

const getSource = (name: string) => 'invoiceOnClose.' + name
