import { useCallback, useMemo } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'
import { type UseQueryOptions } from 'react-query'

import { type Identifier, type DataRecord } from 'appTypes'
import { useFormInfo } from 'context'
import { useRecordContext } from 'core/data'
import { useGetList } from 'core/dp'
import { type FilterPayload, type SortPayload } from 'core/types'
import { getProperty, mergeReferences } from 'utils'

import { type ChoicesContextValue } from './types'
import { useGetManyAggregate } from './useGetManyAggregate'
import { useReferenceParams } from './useReferenceParams'

export const useReferenceInputController = <RecordType extends DataRecord = any>(
    props: UseReferenceArrayInputParams<RecordType>,
): ChoicesContextValue<RecordType> => {
    const {
        debounce,
        enableGetChoices,
        filter,
        page: initialPage = 1,
        perPage: initialPerPage = 25,
        sort: initialSort = { field: 'id', order: 'DESC' },
        queryOptions = {},
        reference,
        source,
        multiple,
        contextType,
        contextId,
    } = props
    const { getValues, getFieldState, setValue } = useFormContext()
    const { hasRecord } = useFormInfo()
    const record = useRecordContext()

    const isDirty = getFieldState(source).isDirty

    const shouldFetchValue =
        !hasRecord || (Boolean(record) && Boolean(!getProperty(record, source + 'Data') || isDirty))

    const _value = useWatch({ name: source }) ?? getValues(source)
    const { meta, ...otherQueryOptions } = queryOptions

    const value = useMemo(() => {
        return Array.isArray(_value) ? _value : _value ? [_value] : emptyArray
    }, [_value])

    const metaValue = useMemo(() => {
        return {
            query: {
                contextType,
                contextId,
            },
        }
    }, [contextType, contextId])

    const {
        data: selectedData = emptyArray,
        error: errorGetMany,
        isLoading: isLoadingGetMany,
        isFetching: isFetchingGetMany,
        refetch: refetchGetMany,
    } = useGetManyAggregate<RecordType>(
        reference,
        {
            ids: value || emptyArray,
            meta: metaValue,
        },
        {
            enabled: value.length > 0 && shouldFetchValue,
            onSuccess: (data) => {
                if (data) {
                    setValue(source + 'Data', multiple ? data : data[0])
                }
            },
        },
    )

    const [params, paramsModifiers] = useReferenceParams({
        resource: reference,
        page: initialPage,
        perPage: initialPerPage,
        sort: initialSort,
        debounce,
        filter,
    })

    const isGetMatchingEnabled = enableGetChoices ? enableGetChoices(params.filterValues) : true

    const {
        data: matchingReferences,
        total,
        query,
        queryKey,
    } = useGetList<RecordType>(
        reference,
        {
            pagination: {
                page: params.page,
                perPage: params.perPage,
            },
            sort: { field: params.sort, order: params.order as any },
            filter: {
                ...params.filter,
                ...filter,
                contextType,
                contextId,
            },
            meta,
        },
        {
            retry: false,
            enabled: isGetMatchingEnabled,
            keepPreviousData: true,
            ...otherQueryOptions,
        },
    )

    const {
        error: errorGetList,
        isLoading: isLoadingGetList,
        isFetching: isFetchingGetList,
        refetch: refetchGetMatching,
    } = query

    const finalMatchingReferences =
        matchingReferences && matchingReferences.length > 0
            ? mergeReferences(matchingReferences, selectedData)
            : selectedData.length > 0
              ? selectedData
              : matchingReferences

    const refetch = useCallback(() => {
        refetchGetMany()
        refetchGetMatching()
    }, [refetchGetMany, refetchGetMatching])

    const currentSort = useMemo(
        () => ({
            field: params.sort,
            order: params.order,
        }),
        [params.sort, params.order],
    )
    return {
        sort: currentSort,
        allChoices: finalMatchingReferences,
        availableChoices: matchingReferences,
        selectedChoices: selectedData,
        displayedFilters: params.displayedFilters,
        error: errorGetMany || errorGetList,
        filter,
        filterValues: params.filterValues,
        hideFilter: paramsModifiers.hideFilter,
        isFetching: isFetchingGetMany || isFetchingGetList,
        isLoading: isLoadingGetMany || isLoadingGetList,
        page: params.page,
        perPage: params.perPage,
        refetch,
        resource: reference,
        setFilters: paramsModifiers.setFilters,
        setPage: paramsModifiers.setPage,
        setPerPage: paramsModifiers.setPerPage,
        setSort: paramsModifiers.setSort,
        showFilter: paramsModifiers.showFilter,
        source,
        total,
        hasNextPage: null,
        hasPreviousPage: null,
        isFromReference: true,
        queryKey,
    }
}

const emptyArray = []

export interface UseReferenceArrayInputParams<RecordType extends DataRecord = any> {
    contextType: string
    contextId?: Identifier
    debounce?: number
    filter?: FilterPayload
    queryOptions?: UseQueryOptions<{
        data: RecordType[]
        total?: number
        pageInfo?: {
            hasNextPage?: boolean
            hasPreviousPage?: boolean
        }
    }> & { meta?: any }
    page?: number
    perPage?: number
    record?: RecordType
    reference: string
    resource?: string
    sort?: SortPayload
    source: string
    enableGetChoices?: (filters: any) => boolean
    multiple?: boolean
}
