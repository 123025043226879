import { type FC } from 'react'

import { useFormContext, useWatch } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import {
    BooleanInput,
    TextInput,
    formatMoney,
    useUtilityDrawerStepsContext,
    costMaskParams,
    inputQtyPositiveMaskParams,
} from 'components'
import { requiredValidation, validateMaxLength } from 'core'
import { useDidMount, useDidUpdate } from 'hooks'
import { globalClassNames } from 'lib'
import { partFields } from 'resources/parts'
import { Stack, InfoBadge, DataAvatar, Typography, BoxContainer, BoxedSection } from 'ui'

import { type ReceiveFormData, type ReceiptFormItem, type ReceiveItemsExtraData } from './types'

interface ItemProps {
    item: ReceiveItemsExtraData['items'][number]
}

const qtyAndCostValidator = [validateMaxLength(10, 'Invalid value'), requiredValidation]

const Item: FC<ItemProps> = ({ item }) => {
    const { watch, unregister, register, getValues } = useFormContext()
    const _getSource = (name?: keyof ReceiptFormItem) => getSource(name, item.id)
    const checked = Boolean(watch(_getSource('received')))

    useDidMount(() => {
        const itemSource = _getSource()
        if (getValues(itemSource)) {
            return
        }
        register(itemSource, {
            value: {},
        })
    })

    useDidUpdate(() => {
        if (!checked) {
            unregister(_getSource('quantity'))
            unregister(_getSource('price'))
        }
    }, [checked])

    return (
        <BoxedSection gap="16px">
            <BoxContainer gap="12px">
                <DataAvatar defaultImage={<partFields.avatar.Icon />} />
                <Stack
                    spacing="4px"
                    overflow="hidden"
                >
                    <Typography
                        variant="h6"
                        lineHeight="20px"
                    >
                        {item.partData.number}
                    </Typography>
                    <Typography
                        variant="chartTitle"
                        className={globalClassNames.ellipsis}
                    >
                        {item.partData.description}
                    </Typography>
                </Stack>
            </BoxContainer>
            <BooleanInput
                source={_getSource('received')}
                label="Received"
                helperText={false}
            />
            {checked ? (
                <>
                    <Stack spacing="20px">
                        <BoxContainer gap="24px">
                            <TextInput
                                source={_getSource('qtyOrder')}
                                label="Qty on Order"
                                disabled
                                defaultValue={item.quantity}
                                {...inputQtyPositiveMaskParams}
                            />
                            <TextInput
                                source={_getSource('quantity')}
                                label="QTY Received"
                                {...inputQtyPositiveMaskParams}
                                validate={qtyAndCostValidator}
                            />
                        </BoxContainer>
                        <TextInput
                            source={_getSource('price')}
                            label="Cost"
                            validate={qtyAndCostValidator}
                            {...costMaskParams}
                        />
                    </Stack>
                    <BoxContainer justifyContent="space-between">
                        <Typography
                            variant="tooltip"
                            color="text.disabled"
                        >
                            TOTAL COST
                        </Typography>
                        <Typography variant="subtitle2">
                            <ItemTotalCost id={item.id} />
                        </Typography>
                    </BoxContainer>
                </>
            ) : null}
        </BoxedSection>
    )
}

const ItemTotalCost = ({ id }: { id: Identifier }) => {
    const [cost, qty] = useWatch({
        name: [getSource('price', id), getSource('quantity', id)],
    })

    return formatMoney((qty || 0) * (cost || 0))
}

const POVerifyItems = () => {
    const { extra } = useUtilityDrawerStepsContext()
    const { items } = extra as ReceiveItemsExtraData

    return (
        <>
            <Typography
                variant="subtitle1"
                component={BoxContainer}
                gap="8px"
                mb="20px"
            >
                Ordered Items <InfoBadge badgeContent={items.length} />
            </Typography>
            <Stack spacing="12px">
                {items.map((item) => (
                    <Item
                        key={item.id}
                        item={item}
                    />
                ))}
            </Stack>
        </>
    )
}

const itemsName: keyof ReceiveFormData = 'receivedItems'

const getSource = (name: keyof ReceiptFormItem, id: Identifier) =>
    `${itemsName}.${id}${name ? '.' + (name as string) : ''}`

export default POVerifyItems
