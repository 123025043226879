import { type MouseEventHandler, type FC, type PropsWithChildren } from 'react'

import { type Size } from 'appTypes'
import Icons from 'assets/icons'
import { type ThemeColorType } from 'lib'
import { BoxContainer, TextWithIcon, Tooltip, Typography } from 'ui'

import BaseStatusIcon from './BaseStatusIcon'

const BaseStatusContent: FC<
    PropsWithChildren & {
        onClick?: MouseEventHandler
        color: ThemeColorType
        isOpen?: boolean
        gap?: Size
        disabled?: boolean
        titleOnDisabled?: string
    }
> = ({ onClick, children, color, isOpen, gap, disabled, titleOnDisabled }) => {
    const content = (
        <>
            <TextWithIcon icon={<BaseStatusIcon color={color} />}>
                <Typography
                    component="span"
                    variant="inherit"
                    gap={gap}
                >
                    {children}
                </Typography>
            </TextWithIcon>
            {isOpen === undefined || disabled ? null : isOpen ? (
                <Icons.KeyboardArrowUpOutlined />
            ) : (
                <Icons.KeyboardArrowDownOutlined />
            )}
        </>
    )

    if (onClick && !disabled) {
        return (
            <BoxContainer
                sx={{ cursor: 'pointer' }}
                onClick={onClick}
                role="button"
                gap="4px"
            >
                {content}
            </BoxContainer>
        )
    }

    if (titleOnDisabled && disabled) {
        return (
            <Tooltip title={titleOnDisabled}>
                <div>{content}</div>
            </Tooltip>
        )
    }

    return content
}

export default BaseStatusContent
