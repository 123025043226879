import { type FC } from 'react'

import { DateTimeInput } from 'components'
import { requiredValidation, validateDateBefore, type Validator } from 'core'
import { Box, SectionTitleSmall } from 'ui'

import woFields from '../../fields'
import { type WoStatusKeys, type WorkOrderModel } from '../../types'

import WoCloseFields from './CloseFields'
import InvoicingContent from './InvoicingContent'

interface Props {
    workOrder: WorkOrderModel
    status: WoStatusKeys
    removeInvoicing?: boolean
}

const CloseContent: FC<Props> = ({ workOrder, removeInvoicing, status }) => {
    return (
        <Box mt="12px">
            <SectionTitleSmall>Date & Time Details</SectionTitleSmall>
            <DateTimeInput
                source={woFields.completionDate.source}
                label={woFields.completionDate.label}
                disableFuture
                validate={completionDateValidator}
                defaultValue={new Date()}
            />
            <WoCloseFields
                workOrder={workOrder}
                status={status}
            />
            {!removeInvoicing && <InvoicingContent workOrder={workOrder} />}
        </Box>
    )
}

export default CloseContent

const completionDateValidator: Validator[] = [
    validateDateBefore('started', 'Completion date cannot be before starting date'),
    requiredValidation,
]
