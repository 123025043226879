import { type FC, type PropsWithChildren, type ReactNode } from 'react'

import { useFieldArray, useFormContext } from 'react-hook-form'

import { type Identifier } from 'appTypes'
import { ArrayControllerContextProviderBase, UtilityDrawerForm } from 'components'
import { useDataProvider, useResourceContext, useSubmit, type Serializer, serialize } from 'core'
import { memberFields } from 'resources/members'
import { type MemberModel } from 'resourcesBase'

interface MemberRow extends Pick<MemberModel, 'email' | 'role' | 'viewUnitsWithoutDomicile'> {
    shops: Identifier[]
}
export const membersCreateBulkLimit: number = 10
const ArrayControllerContextProvider = ({ children }: PropsWithChildren) => {
    const { control } = useFormContext()

    const fields = useFieldArray({
        control,
        name: 'data',
    })

    return (
        <ArrayControllerContextProviderBase
            value={{
                array: fields.fields,
                append: (item) => {
                    fields.append({}, { shouldFocus: false })
                    return item
                },
                remove: ({ index }) => {
                    fields.remove(index)
                },
                setArray: () => {
                    //
                },
                limit: membersCreateBulkLimit,
            }}
        >
            {children}
        </ArrayControllerContextProviderBase>
    )
}

const inviteMemberSerializer: Serializer<MemberRow> = [
    { name: memberFields.email.source, parse: 'email' },
    {
        name: memberFields.role.source,
        parse: 'string',
    },
    {
        name: memberFields.shops.source,
        parse: 'emptyToNull',
    },
    { name: memberFields.viewUnitsWithoutDomicile.source, parse: 'boolean' },
]
export const MemberInviteWrapper: FC<{ children: ReactNode }> = ({ children }) => {
    const dataProvider = useDataProvider()
    const resource = useResourceContext()

    const submitHandler = useSubmit(
        async (formData) => {
            const res = await dataProvider.createMany(resource + '/create_bulk', {
                data: {
                    data: formData.data.map((member: MemberRow) =>
                        serialize(member, inviteMemberSerializer),
                    ),
                },
            })
            return res
        },
        {
            successMessage: 'Successfully invited!',
        },
    )

    return (
        <UtilityDrawerForm
            onSubmit={submitHandler}
            defaultValues={{ data: [{}] }}
        >
            <ArrayControllerContextProvider>{children}</ArrayControllerContextProvider>
        </UtilityDrawerForm>
    )
}
