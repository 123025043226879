/* eslint-disable @typescript-eslint/default-param-last */
import { useRef } from 'react'

import { type DeleteManyParams, type MutationMode, useEvent } from 'ra-core'
import {
    useMutation,
    type UseMutationOptions,
    type UseMutationResult,
    type MutateOptions,
    type QueryKey,
} from 'react-query'

import { type DataRecord } from 'appTypes'
import { useDataProvider } from 'core/data'

export const useDeleteMany = <RecordType extends DataRecord = any, MutationError = unknown>(
    resource?: string,
    params: Partial<DeleteManyParams<RecordType>> = {},
    options: UseDeleteManyOptions<RecordType, MutationError> = {},
): UseDeleteManyResult<RecordType, MutationError> => {
    const dataProvider = useDataProvider()
    const reactMutationOptions = options
    const paramsRef = useRef<Partial<DeleteManyParams<RecordType>>>({})
    const snapshot = useRef<Snapshot>([])

    const mutation = useMutation<
        RecordType['id'][],
        MutationError,
        Partial<UseDeleteManyMutateParams<RecordType>>
    >(
        ({
            resource: callTimeResource = resource,
            ids: callTimeIds = paramsRef.current.ids,
            meta: callTimeMeta = paramsRef.current.meta,
        } = {}) =>
            dataProvider
                .deleteMany<RecordType>(callTimeResource, {
                    ids: callTimeIds,
                    meta: callTimeMeta,
                })
                .then(({ data }) => data),
        {
            ...reactMutationOptions,
            onMutate: async (variables: Partial<UseDeleteManyMutateParams<RecordType>>) => {
                if (reactMutationOptions.onMutate) {
                    const userContext = (await reactMutationOptions.onMutate(variables)) || {}
                    return {
                        snapshot: snapshot.current,
                        // @ts-ignore
                        ...userContext,
                    }
                }
                // Return a context object with the snapshot value
                return { snapshot: snapshot.current }
            },
            onError: (
                error: MutationError,
                variables: Partial<UseDeleteManyMutateParams<RecordType>> = {},
                context: { snapshot: Snapshot },
            ) => {
                if (reactMutationOptions.onError) {
                    return reactMutationOptions.onError(error, variables, context)
                }
                // call-time error callback is executed by react-query
            },
            onSuccess: (
                data: RecordType['id'][],
                variables: Partial<UseDeleteManyMutateParams<RecordType>> = {},
                context: unknown,
            ) => {
                if (reactMutationOptions.onSuccess) {
                    reactMutationOptions.onSuccess(data, variables, context)
                    // call-time success callback is executed by react-query
                }
            },
            onSettled: (
                data: RecordType['id'][],
                error: MutationError,
                variables: Partial<UseDeleteManyMutateParams<RecordType>> = {},
                context: { snapshot: Snapshot },
            ) => {
                if (reactMutationOptions.onSettled) {
                    return reactMutationOptions.onSettled(data, error, variables, context)
                }
            },
        },
    )

    const mutate = async (
        callTimeResource: string = resource,
        callTimeParams: Partial<DeleteManyParams<RecordType>> = {},
        updateOptions: MutateOptions<
            RecordType['id'][],
            unknown,
            Partial<UseDeleteManyMutateParams<RecordType>>,
            unknown
        > & { mutationMode?: MutationMode } = {},
    ) => {
        const { onSuccess, onSettled, onError } = updateOptions

        // store the hook time params *at the moment of the call*
        // because they may change afterwards, which would break the undoable mode
        // as the previousData would be overwritten by the optimistic update
        paramsRef.current = params

        return mutation.mutate(
            { resource: callTimeResource, ...callTimeParams },
            { onSuccess, onSettled, onError },
        )
    }

    return [useEvent(mutate), mutation]
}

type Snapshot = [key: QueryKey, value: any][]

export interface UseDeleteManyMutateParams<RecordType extends DataRecord = any> {
    resource?: string
    ids?: RecordType['id'][]
    meta?: any
}

export type UseDeleteManyOptions<
    RecordType extends DataRecord = any,
    MutationError = unknown,
> = UseMutationOptions<
    RecordType['id'][],
    MutationError,
    Partial<UseDeleteManyMutateParams<RecordType>>
> & { mutationMode?: MutationMode }

export type UseDeleteManyResult<RecordType extends DataRecord = any, MutationError = unknown> = [
    (
        resource?: string,
        params?: Partial<DeleteManyParams<RecordType>>,
        options?: MutateOptions<
            RecordType['id'][],
            MutationError,
            Partial<UseDeleteManyMutateParams<RecordType>>,
            unknown
        > & { mutationMode?: MutationMode },
    ) => Promise<void>,
    UseMutationResult<
        RecordType['id'][],
        MutationError,
        Partial<DeleteManyParams<RecordType> & { resource?: string }>,
        unknown
    >,
]
