import { type ReactElement } from 'react'

import { type Identifier } from 'appTypes'
import { useInputStep, useOpenUtilityDrawer } from 'components'
import UtilityDrawerEditor from 'components/Drawer/UtilityDrawerEditor'
import { useListContext, type Serializer } from 'core'
import { type LineItemsModel, type PurchaseOrderModel } from 'resources/purchaseOrders'

import LineItemsForm, { LineItemPartInput } from './LineItemsForm'
import { deleteOneParams } from './utils'

interface LineItemsDrawerTogglerProps {
    children: (params: { onClick: () => void }) => ReactElement
    id?: Identifier
    resource: string
    poRecord: PurchaseOrderModel
}

const serializer: Serializer<LineItemsModel & { poType: PurchaseOrderModel['type'] }> = [
    { name: 'part', parse: ['emptyToNull', 'disableOnEdit'] },
    { name: 'price', parse: 'number' },
    {
        name: 'quantity',
        parse: 'number',
    },
]

const LineItemsDrawerToggler = ({
    children,
    id,
    resource,
    poRecord,
}: LineItemsDrawerTogglerProps) => {
    const open = useOpenUtilityDrawer()
    const inputStep = useInputStep()
    const { total } = useListContext()

    return children({
        onClick: async () => {
            let formProps
            if (!id) {
                formProps = await inputStep((params) => (
                    <LineItemPartInput
                        po={poRecord}
                        {...params}
                    />
                ))

                if (!formProps) {
                    return
                }
            }

            open({
                extraArgs: {
                    id,
                    resource: {
                        resource,
                        name: 'line-items',
                    },
                },
                drawerArgs: {
                    title: id ? 'Edit Item' : 'Add Item',
                    renderWrapper: (params) => (
                        <UtilityDrawerEditor
                            {...params}
                            successMessage={id ? undefined : 'Line Item successfully added'}
                            serializer={serializer}
                            {...params}
                            {...formProps}
                        />
                    ),
                    renderContent: () => <LineItemsForm po={poRecord} />,
                    renderBottomRight: (render) => render({ label: id ? undefined : 'ADD' }),
                    renderTopRight: (render) => render(deleteOneParams(poRecord, total)),
                },
            })
        },
    })
}

export default LineItemsDrawerToggler
