import { type FC } from 'react'

import { withColor } from 'lib'
import { Box, Typography } from 'ui'

import PeriodSelectorInput, { type ChangeProps } from './PeriodSelectorInput'
import PeriodSelectorText from './PeriodSelectorText'

const PeriodSelector: FC<ChangeProps> = ({ onChange }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            color={withColor('text.secondary')}
            justifyContent={{
                xs: 'space-between',
                md: 'flex-start',
            }}
        >
            <Box
                display="flex"
                alignItems="center"
            >
                <Typography
                    variant="caption"
                    color="inherit"
                    mr="8px"
                >
                    Period:
                </Typography>

                <PeriodSelectorInput onChange={onChange} />
            </Box>
            <Typography
                variant="caption"
                color={withColor('text.disabled')}
                ml="8px"
            >
                <PeriodSelectorText />
            </Typography>
        </Box>
    )
}

export default PeriodSelector
