import { type GlobalStylesProps } from '@mui/material'
import { pickersToolbarClasses } from '@mui/x-date-pickers/internals'

import { cssVariables, globalClassNames } from 'lib'

export const globalStyle: GlobalStylesProps['styles'] = (theme) => {
    return `

        :root {
            ${cssVariables.pageContentPadding}: 0px;
        }
        
        html {
            min-height: 100%;
            height:100%;
        }
        body{
            height:100%;
        }
        #root {
            box-sizing: border-box;
            display: flex;
            min-height:100%;
            flex-direction: column;
            position: relative;
        }
    
        
        html body {
            padding: 0;
            margin: 0;
            min-height:100%;
            background-color: ${theme.palette.gray};
        }
        
        input {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            filter: none;
            border: 0;
        
            &::-ms-reveal,
            &::-ms-clear {
                display: none;
            }
        }
        
        a {
            text-decoration: none;
        }

        .${globalClassNames.displayNone} {
            display: none !important;
        }

        .${pickersToolbarClasses.penIconButton} {
            display: none !important;
        }
        
        .scroll-lock {
            overflow: hidden;
        }
        .${globalClassNames.ellipsis} {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .${globalClassNames.autoRow} {
            display: inline-grid;
            grid-auto-flow: column;
            grid-auto-columns: auto;
        }

        .${globalClassNames.withGap} {
            gap: var(${cssVariables.gap});
        }

        .${globalClassNames.noWrap} {
            white-space: nowrap;
        }

        .${globalClassNames.noShrink} {
            flex-shrink: 0;
        }

        .${globalClassNames.ignorePx} {
            margin-right: calc(var(${cssVariables.pr}) * -1);
            margin-left: calc(var(${cssVariables.pl}) * -1);
        }

        .${globalClassNames.extendPx} {
            padding-left: var(${cssVariables.pl});
            padding-right: var(${cssVariables.pr});
        }
            
        .${globalClassNames.ignorePb} {
            margin-bottom: calc(var(${cssVariables.pb}) * -1);
        }

        .${globalClassNames.extendPb} {
            padding-bottom: var(${cssVariables.pb});
        }

        .pswp {
            z-index: 1250;
        }

        .pswp__top-bar {
            position: initial;
        }

        .pswp__counter{
            ${theme.breakpoints.down('sm')}{
                position:a bsolute;
                margin: 0;
                top: 25px;
                left: 50%;
                transform: translate(-50%, 0%);

            }
        }

        div.pswp__img--placeholder {
            background: none !important;
        }

        a {
            word-break: break-word;
        }

        [data-shadow="top"] {
            box-shadow: inset 0 8px 14px -10px rgba(0, 0, 0, 0.3);
        }

        [data-shadow="bottom"] {
            box-shadow: inset 0 -8px 14px -10px rgba(0, 0, 0, 0.3);
        }

        [data-shadow="vertical"] {
            box-shadow:
                inset 0 8px 14px -10px rgba(0, 0, 0, 0.3),
                inset 0 -8px 14px -10px rgba(0, 0, 0, 0.3);
        }
        
        .${globalClassNames.hideEmpty}:empty {
            display: none;
        }
                
    `
}
