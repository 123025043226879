import { type PropsWithChildren, useEffect, type FC } from 'react'

import { inject, observer } from 'mobx-react'
import { useWatch, useFormContext } from 'react-hook-form'

import {
    type DialogSelectorProps,
    TextareaInput,
    BooleanInput,
    DateTimeInput2,
    CombinedInputs,
} from 'components'
import {
    authStore,
    requiredValidation,
    maxLengthValidationText,
    type AuthStore,
    validateDateBefore,
} from 'core'
import { PriorityInput } from 'resources/common'
import { CustomerPONumberInput, CustomerPopUpInput } from 'resources/customers'
import { ShopInput, ShopReadOnlyInput } from 'resources/shops'
import { VendorInput } from 'resources/vendors'
import { type ShopModel } from 'resourcesBase'
import { SectionTitleSmall } from 'ui'

import woFields from '../fields'
import { type WoKeys, WoPriorityKeys, type WorkOrderModel, WoStatusKeys } from '../types'
import { woResource } from '../utils'

import WoSelectInput from './WoSelectInput'
import WorkOrderFormMeter from './WorkOrderFormMeter'
import { WorkOrderUnitInput } from './WorkOrderUnitInput'

export interface WorkOrderFormProps {
    disabledFields?: {
        [key in keyof (WorkOrderModel & {
            vendorWorkOrderSwitch: boolean
            vendorInput: string
            poNumber: string
        })]?: boolean
    }
    hiddenFields?: {
        [key in keyof (WorkOrderModel & {
            vendorWorkOrderSwitch: boolean
            vendorInput: string
            poNumber: string
        })]?: boolean
    }
    createFormConfig?: { unit: { defaultFilter: DialogSelectorProps['defaultFilter'] } }
}

const WorkOrderForm: FC<WorkOrderFormProps> = inject('auth')(
    observer(
        ({
            disabledFields,
            createFormConfig,
            hiddenFields,
            auth,
        }: WorkOrderFormProps & { auth: AuthStore }) => {
            return (
                <>
                    {hiddenFields?.status ? null : (
                        <StatusSelect disabled={disabledFields?.status} />
                    )}

                    <HideOnPending>
                        {disabledFields?.vendor ? null : (
                            <>
                                <VendorWoSwitch disabled={disabledFields?.vendorWorkOrderSwitch} />

                                <VendorInputElement disabled={disabledFields?.vendorInput} />
                            </>
                        )}

                        <WOShopInput disabled={disabledFields?.shop} />
                    </HideOnPending>

                    <WorkOrderUnitInput
                        disabled={disabledFields?.unit}
                        defaultFilter={createFormConfig?.unit?.defaultFilter}
                    />

                    {auth.companySettings.hasDomiciles ? (
                        <ShopReadOnlyInput
                            source={domicileSource}
                            label="Domicile"
                        />
                    ) : null}
                    <HideOnPending>
                        <CustomerPopUpInput />

                        {hiddenFields?.poNumber ? null : (
                            <WoCustomerPONumberInput disabled={disabledFields?.poNumber} />
                        )}
                    </HideOnPending>

                    <PriorityInput
                        choices={woFields.priority.choices}
                        optionText={woFields.priority.inputValue}
                        isRequired
                        defaultValue={WoPriorityKeys.LOW}
                    />

                    <WoSelectInput
                        optionText={woFields.repairPriorityClass.inputValue}
                        label={woFields.repairPriorityClass.label}
                        source={woFields.repairPriorityClass.source}
                        choices={woFields.repairPriorityClass.choices}
                        validate={requiredValidation}
                        disableEmptyValue
                    />

                    <TextareaInput<WorkOrderModel>
                        source="description"
                        label="Description"
                        validate={maxLengthValidationText}
                    />
                    <SectionTitleSmall>Date & Time</SectionTitleSmall>

                    <CombinedInputs
                        sources={[
                            woFields.scheduledDate.source,
                            woFields.expectedCompletionDate.source,
                        ]}
                    />

                    <DateTimeInput2
                        source={woFields.scheduledDate.source}
                        label={woFields.scheduledDate.label}
                    />

                    <HideOnPending>
                        <DateTimeInput2
                            source={woFields.startDate.source}
                            label={woFields.startDate.label}
                            required
                            defaultValue={new Date()}
                        />
                    </HideOnPending>

                    <DateTimeInput2
                        source={woFields.expectedCompletionDate.source}
                        label={woFields.expectedCompletionDate.label}
                        validate={expectedCompletionValidate}
                    />
                    <HideOnPending>
                        <WorkOrderFormMeter />
                    </HideOnPending>
                </>
            )
        },
    ),
)

const expectedCompletionValidate = [
    validateDateBefore(
        woFields.scheduledDate.source,
        'Verify selected dates: The completion date needs to be later than the start',
    ),
]

const HideOnPending: FC<PropsWithChildren> = ({ children }) => {
    const { watch, getValues } = useFormContext()
    const status = getValues(woFields.status.source) || watch(woFields.status.source)

    if (status === WoStatusKeys.PENDING) {
        return null
    }

    return <>{children}</>
}
const StatusSelect: FC<{ disabled?: boolean }> = inject('auth')(
    observer(({ auth, disabled }: { auth: AuthStore; disabled?: boolean }) => {
        const choices = woFields.status.choices.filter(
            (status) => status.id === WoStatusKeys.OPEN || status.id === WoStatusKeys.PENDING,
        )

        return (
            <WoSelectInput
                optionText={woFields.status.inputValue}
                label="WO Status"
                source={woFields.status.source}
                choices={choices}
                validate={requiredValidation}
                disableEmptyValue
                defaultValue={auth.companyPreferences.defaultWoStatus}
                disabled={disabled}
            />
        )
    }),
)

const customerSource = 'unitData.customer' satisfies WoKeys
const domicileSource = 'unitData.domicileData' satisfies WoKeys
const swichSource = 'vendorWorkOrderSwitch'

export const WoCustomerPONumberInput = ({ disabled }: { disabled?: boolean }) => {
    const { watch, getValues } = useFormContext()
    const customer = watch(customerSource) || getValues(customerSource)
    const isVendorWo = watch(swichSource) || getValues(swichSource)

    if (!customer || isVendorWo) {
        return null
    }

    return <CustomerPONumberInput disabled={disabled} />
}

export const VendorWoSwitch = ({ disabled }: { disabled?: boolean }) => {
    const { setValue } = useFormContext()

    return (
        <BooleanInput
            source="vendorWorkOrderSwitch"
            defaultValue={authStore.preferences.defaultWoType}
            label="Vendor Work Order"
            onChange={({ target }) => {
                if (!target.checked) {
                    setValue('vendor', null)
                }
                authStore.updatePreferences({
                    defaultWoType: target.checked,
                })
            }}
            color={disabled ? 'default' : 'primary'}
            disabled={disabled}
        />
    )
}

export const VendorInputElement = ({ disabled }: { disabled?: boolean }) => {
    const { watch } = useFormContext()
    const vendorsWorkOrder =
        useWatch({
            name: 'vendorWorkOrderSwitch',
        }) || watch('vendorWorkOrderSwitch')

    if (!vendorsWorkOrder) {
        return null
    }

    return (
        <VendorInput
            vendorType="REPAIR_SERVICES"
            source="vendor"
            required
            disabled={disabled}
            label="Vendor"
        />
    )
}

export const WOShopInput = ({ disabled }: { disabled?: boolean }) => {
    const { setValue, watch, getValues } = useFormContext()
    const domicile: ShopModel = getValues(domicileSource) || watch(domicileSource)

    useEffect(() => {
        if (domicile) {
            setValue('shop', domicile.id)
        }
    }, [domicile?.id])

    return (
        <ShopInput
            noSelectedShop={Boolean(domicile)}
            contextType={woResource.resource}
            required
            onChange={(shopId) => {
                if (!shopId) {
                    setValue('unit', null)
                }
            }}
            disabled={disabled || Boolean(domicile)}
        />
    )
}

export default WorkOrderForm
