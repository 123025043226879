import { type FC } from 'react'

import { Alert, Stack } from 'ui'

import { isVendorWo } from '../../isVendorWo'
import { type WorkOrderModel } from '../../types'

const PendingContent: FC<{ workOrder: WorkOrderModel }> = ({ workOrder }) => {
    return (
        <Stack gap="10px">
            {workOrder.jobItemsCount ? (
                <Alert severity="warning">
                    Changing the status deletes line items but keeps jobs intact.
                </Alert>
            ) : null}
            {isVendorWo(workOrder) ? (
                <Alert severity="warning">The assigned vendor will be removed.</Alert>
            ) : null}
            <Alert severity="warning">Linked issues will be returned to Scheduled status.</Alert>
        </Stack>
    )
}

export default PendingContent
