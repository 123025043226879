export const woOperations = {
    cancel: 'cancel',
    open: 'open',
    close: 'close',
    invoice: 'invoice',
    jobs: 'jobs',
    jobItems: 'jobItems',
    photos: 'photos',
    notes: 'notes',
    editVendor: 'editVendor',
    documents: 'documents',
    changeStatus: 'changeStatus',
}
