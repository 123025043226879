import { type FC } from 'react'

import { type WithChildrenNode } from 'appTypes'
import { cssUtils } from 'lib'
import { Box } from 'ui'

export const FullBody: FC<WithChildrenNode> = ({ children }) => {
    return (
        <Box
            boxSizing="border-box"
            height="100%"
            overflow="hidden"
            sx={{
                ...cssUtils.applyPadding('20px'),
            }}
        >
            {children}
        </Box>
    )
}
